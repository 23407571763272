import api from '../../api';
import {
  GetVehicleReportDataProps,
  GetVehicleReportDataResponse,
} from './types';

export async function getVehicleReport({
  params: { dateIni, dateFim, licensePlate },
}: GetVehicleReportDataProps): Promise<GetVehicleReportDataResponse> {
  const res = await api.get<GetVehicleReportDataResponse>(
    `/v2/vehicles/report?dateIni=${dateIni}&dateFim=${dateFim}&licensePlate=${licensePlate.replace(
      '-',
      '',
    )}`,
  );
  return res.data;
}
