import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px 30px;
  flex: 1;
  color: ${(props) => props.theme.colors.black};

  .navigate {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    button {
      border: none;
      background: none;
      height: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 18px;
        width: 18px;
        color: ${(props) => props.theme.colors.black};
        margin-right: 18px;
      }
    }
    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 0px;
    }
  }

  form {
    background: ${(props) => props.theme.colors.white};
    border-radius: 8px;
    padding: 36px 74px;
    width: 100%;

    h2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin: 0px;
      padding: 0px;
      text-align: center;
      color: ${(props) => props.theme.colors.black};
      margin-bottom: 5px;
    }
    div.content {
      display: flex;
      width: 100%;
      margin: 12px 0px;

      div {
        flex: 1;
        p {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: ${(props) => props.theme.colors.black};
          margin-bottom: 10px;
        }
        input {
          width: 100%;
          height: 50px;
          align-items: center;
          background: ${(props) => props.theme.colors.white};
          border: 1px solid ${(props) => props.theme.colors.gray1};
          box-sizing: border-box;
          border-radius: 4px;
          padding: 0px 10px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: ${(props) => props.theme.colors.gray7};
        }
      }
    }
  }
  div.map {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    height: 580px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 12px;
    overflow: hidden;

    span {
      margin: 30px 0px;

      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      color: ${(props) => props.theme.colors.black};
    }
  }
  div.resul {
    margin: 20px 0px;
    width: 100%;
    height: 60px;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background-color: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.primary};
      border-radius: 8px;
      width: 250px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 16px;
      font-family: 'Montserrat';
      font-weight: 600;
      color: ${(props) => props.theme.colors.black};
      transition: 0.3s;
    }
    button:hover {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.white};
    }

    p {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      color: ${(props) => props.theme.colors.primary};
      margin: 0px !important;
      padding: 0px;
    }

    span {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 12px;
      text-align: center;
      color: ${(props) => props.theme.colors.primary};
      margin: 0px 5px !important;
      padding: 0px;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  border: 0.6px solid ${(props) => props.theme.colors.gray1};
  margin: 20px 0px;
`;

export const NameMap = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: ${(props) => props.theme.colors.black};
`;

export const ItemMap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 30px;
    width: 30px;
    color: ${(props) => props.theme.colors.black};
  }

  span {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px;
    margin-top: -3px;
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
    margin: 0px !important;
    padding: 0px;
  }
`;
