import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 244px;
  height: 40px;

  margin-left: auto;
  @media screen and (max-width: 550px) {
    margin: 0px auto;
  }

  #export-excel {
    width: 244px;
    height: 40px;

    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};

    display: flex;
    justify-content: center;
    border-radius: 4px;
    border: none;
    align-items: center;

    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;

    svg {
      margin-right: 14px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
`;
