import styled from 'styled-components';

export const Container = styled.div`
  padding: 58px 72px 48px 49px;
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;

export const ContainerCadastro = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 36px 74px 30px 74px;
  margin-top: 16px;
  width: 100%;

  @media screen and (max-width: 900px) {
    padding: 22px;
  }

  #filialUser {
    color: ${(props) => props.theme.colors.black2};
    width: 100%;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }

  .containerButtons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .selectStyle {
    color: ${(props) => props.theme.colors.black2};
    width: 100%;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }
`;

export const BoxRowInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  column-gap: 1rem;

  .containerInputAndLabel {
    width: 100%;
    input {
      margin-top: 0 !important;
    }
  }

  .daysWeek {
    margin-left: 0 !important;
  }

  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const BoxMiddle = styled.div`
  width: 14px;
`;

export const HeaderForm = styled.h3`
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;

  color: ${(props) => props.theme.colors.black2};
  font-size: 16px;
  font-weight: 700;
`;

export const InputForm = styled.input`
  width: 100%;
  height: 50px;
  background-color: ${(props) => props.theme.colors.white};

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;

  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding-left: 16px;
`;
