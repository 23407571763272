function formatedHHMMBySeconds(timeInSeconds: number): string {
  const minuteAll = Math.floor(timeInSeconds / 60);
  const second = timeInSeconds % 60;
  const hour = Math.floor(minuteAll / 60);
  const minute = minuteAll % 60;

  const hourString = hour < 10 ? `0${hour}` : hour;
  const minuteString = minute < 10 ? `0${minute}` : minute;
  const secondString = second < 10 ? `0${second}` : second;

  return `${hourString}:${minuteString}:${secondString}`;
}

interface Props {
  hourFinalHHmmss: string;
  hourInicialHHmmss: string;
}

export function diffTimeHours({
  hourFinalHHmmss,
  hourInicialHHmmss,
}: Props): string {
  const [hour1, minute1, seconds1] = hourFinalHHmmss.split(':');
  const [hour2, minute2, seconds2] = hourInicialHHmmss.split(':');

  const minutesAll1 = Number(minute1) + Number(hour1) * 60;
  const minutesAll2 = Number(minute2) + Number(hour2) * 60;
  const secondsAll1 = minutesAll1 * 60 + Number(seconds1);
  const secondsAll2 = minutesAll2 * 60 + Number(seconds2);
  if (secondsAll1 >= secondsAll2) {
    const diff = secondsAll1 - secondsAll2;
    return formatedHHMMBySeconds(diff);
  }
  const secondsInOneDay = 60 * 60 * 24;
  const diff = secondsInOneDay + secondsAll1 - secondsAll2;

  return formatedHHMMBySeconds(diff);
}
