import { shade } from 'polished';
import styled from 'styled-components';

export const ButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.error};
    align-items: center;
    justify-content: center;
    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const ContainerHeaderDados = styled.h1`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${(props) => props.theme.colors.black2};
`;

export const ContainerSearch = styled.div`
  width: 100%;
  margin: 10px 0;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 1152px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  strong {
    border: 1px solid ${(props) => props.theme.colors.black2};
    box-sizing: border-box;
    border-radius: 20px;
    padding: 13px 34px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.black2};
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const List = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 5px;
  margin-right: 5px;
  max-height: 35vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const Card = styled.button`
  height: 70px;
  width: 95%;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray1};
  border-radius: 6px;
  display: flex;

  .line {
    width: 1rem;
    height: 100%;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .info {
    display: flex;
    flex: 1;
    padding: 10px;
    column-gap: 10px;
    div {
      display: flex;
      flex: 1;
      flex-direction: column;
      p {
        color: ${(props) => props.theme.colors.black2};
        font-weight: 600;
        font-size: 14px;
        font-family: Montserrat, sans-serif;
        margin: 0px;
      }
      span {
        color: ${(props) => props.theme.colors.black4};
        font-size: 14px;
        font-family: Montserrat, sans-serif;
      }
    }
  }
  @media screen and (max-width: 400px) {
    height: 120px;
    .info {
      flex-direction: column;
      column-gap: 10px;
    }
  }
`;

export const ContainerMapDetails = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 32px;
  border: none !important;

  display: flex;
  align-items: center;
  flex: 1;

  ul {
    margin-left: 32px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    li {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-size: 18px;
      line-height: 27px;

      display: flex;
      align-items: flex-start;
      justify-content: center;

      strong {
        font-weight: 600;
        color: ${(props) => props.theme.colors.black2};
        margin-bottom: 5px;
      }

      span {
        max-width: 400px;
        font-weight: normal;
        color: ${(props) => props.theme.colors.black2};
        margin-left: 12px;
      }

      a {
        margin-left: 12px;
        text-decoration: underline;
      }
    }
  }

  img {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    object-fit: cover;
  }

  article {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .revs {
    display: flex;
    margin-left: 10px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
    border: none;
  }
`;

export const ButtonImgNull = styled.button`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  border: none;
  outline: none;
  width: 128px;
  height: 128px;
  transition: transform 0s;
  cursor: auto;

  &:hover {
    transform: scale(1);
  }

  svg {
    color: ${(props) => props.theme.colors.black2};
    margin-bottom: -5px;
  }
`;

export const ButtonImg = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  transition: transform 0.2s;
  width: 164px;
  height: 164px;

  &:hover {
    transform: scale(1.08);
  }

  img {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const ContainerButtonDetalhes = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;

  button:last-child {
    background: transparent;
    border: 1px solid ${(props) => props.theme.colors.black2};
    box-sizing: border-box;
    margin-left: 12px;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.black2};

    &:hover {
      background: ${(props) => shade(0.1, props.theme.colors.white)};
    }
  }

  @media screen and (max-width: 1152px) {
    justify-content: flex-start;

    button {
      width: 25% !important;
    }
  }
`;

export const ZoomImg = styled.div`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 8px 20px;

  max-height: 350px;
  overflow: auto;

  section {
    background: ${(props) => props.theme.colors.white2};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin: 12px auto;

    article {
      padding-top: 12px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      padding: 12px 0;

      display: flex;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.colors.black2};
    }

    svg {
      color: ${(props) => props.theme.colors.black2};
    }
  }

  h1 {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1152px) {
    width: calc(100vw - 100px);
    height: 380px;
  }
`;

interface Props {
  option: string;
}

export const FilterStatus = styled.div<Props>`
  display: flex;
  margin-top: 15px;
  column-gap: 10px;

  button {
    height: 20px;
    width: 20px;
    padding: 4px;
    border-radius: 10px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
    transition: 0.5s;

    span {
      visibility: hidden;
      font-size: 12px;
      font-family: Montserrat, sans-serif;
    }

    &:hover {
      width: 200px;
      height: 22px;
      span {
        visibility: visible;
      }
    }
  }

  .con {
    background-color: ${(props) => props.theme.colors.success};
    width: ${(props) => (props.option === 'con' ? '200px' : '20px')};
    span {
      visibility: ${(props) => (props.option === 'con' ? 'visible' : 'hidden')};
    }
    &:hover {
      background-color: ${(props) => shade(0.2, props.theme.colors.success)};
    }
  }
  .cp {
    background-color: ${(props) => props.theme.colors.successShade};
    width: ${(props) => (props.option === 'cp' ? '200px' : '20px')};
    span {
      visibility: ${(props) => (props.option === 'cp' ? 'visible' : 'hidden')};
    }
    &:hover {
      background-color: ${(props) =>
        shade(0.2, props.theme.colors.successShade)};
    }
  }
  .ini {
    background-color: ${(props) => props.theme.colors.info};
    width: ${(props) => (props.option === 'ini' ? '200px' : '20px')};
    span {
      visibility: ${(props) => (props.option === 'ini' ? 'visible' : 'hidden')};
    }
    &:hover {
      background-color: ${(props) => shade(0.2, props.theme.colors.info)};
    }
  }
  .nr {
    background-color: ${(props) => props.theme.colors.error};
    width: ${(props) => (props.option === 'nr' ? '200px' : '20px')};
    span {
      visibility: ${(props) => (props.option === 'nr' ? 'visible' : 'hidden')};
    }
    &:hover {
      background-color: ${(props) => shade(0.2, props.theme.colors.error)};
    }
  }
  .can {
    background-color: ${(props) => props.theme.colors.error};
    width: ${(props) => (props.option === 'can' ? '200px' : '20px')};
    span {
      visibility: ${(props) => (props.option === 'can' ? 'visible' : 'hidden')};
    }
    &:hover {
      background-color: ${(props) => shade(0.2, props.theme.colors.error)};
    }
  }
  .tod {
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray1};
    color: ${(props) => props.theme.colors.black2};
    width: ${(props) => (props.option === 'tod' ? '200px' : '20px')};
    span {
      visibility: ${(props) => (props.option === 'tod' ? 'visible' : 'hidden')};
    }
    &:hover {
      background-color: ${(props) => shade(0.1, props.theme.colors.white)};
    }
  }
`;
