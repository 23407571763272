import styled from 'styled-components';

export const ButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.error};
    align-items: center;
    justify-content: center;
    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const ContainerBody = styled.div`
  font-family: 'Montserrat';

  padding: 0px 74px 30px 74px;

  @media screen and (max-width: 600px) {
    padding: 22px;
  }

  #supervisores {
    color: ${(props) => props.theme.colors.black2};
    width: 100%;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }
`;
