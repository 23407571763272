import React, { useCallback, useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { ContainerTh, Thead } from './styles';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const HeaderTable = ({ headers, onSorting }: any) => {
  const [sortingField, setSortingField] = useState('');
  const [sortingOrder, setSortingOrder] = useState('asc');

  const onSortingChange = useCallback(
    (field: string) => {
      const order =
        field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';

      setSortingField(field);
      setSortingOrder(order);
      onSorting(field, order);
    },
    [onSorting, sortingField, sortingOrder],
  );

  return (
    <Thead>
      <tr>
        {headers.map(({ name, field, sortable }: any) => (
          <ContainerTh
            key={field}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            <h3>{name}</h3>

            {sortingField && sortingField === field && (
              <button type="button">
                {sortingOrder === 'asc' ? (
                  <AiOutlineArrowDown size={20} />
                ) : (
                  <AiOutlineArrowUp size={20} />
                )}
              </button>
            )}
          </ContainerTh>
        ))}
      </tr>
    </Thead>
  );
};

export default HeaderTable;
