export const returnNameRiscos = (
  stringIndex: string | undefined,
  riscos: any[] | undefined,
): string | undefined => {
  if (riscos?.length !== 0) {
    const nameFilial = riscos?.find((e?) => e.id === stringIndex);
    return nameFilial?.name;
  }
  return `${stringIndex}-`;
};
