import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Montserrat';

  padding: 0px 74px 30px 74px;

  @media screen and (max-width: 600px) {
    padding: 22px;
  }

  .containerHourDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 49.5%;
    }
  }

  #typePeriodicidade {
    color: ${(props) => props.theme.colors.black2};
    width: 100%;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }
`;

export const ContainerCheckLabel = styled.div`
  div {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    margin-bottom: 10px;

    input {
      width: 25px;
      height: 25px;
    }
    span {
      font-size: small;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black2};
    }
  }
`;

export const ContainerButtonForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
`;
