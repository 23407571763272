import styled from 'styled-components';

export const ContainerAlert = styled.div`
  background: ${(props) => props.theme.colors.primary};
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;

  border: 2px solid ${(props) => props.theme.colors.gray12};

  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white};
  /* border-color: #cc7a00; */

  svg {
    margin-right: 16px;
  }
  p {
    margin: 0;
    font-size: 14px;
  }
`;
