import Swal from 'sweetalert2';

interface RegisterUser {
  cpf: string;
  IdEmpresas: number;
  IdEmpresaFilial: number;
  name: string;
  acesso: number;
  email: string;
  telefone: string;
  postoId?: number;
  diasTrab: string;
  turnoIni: string;
  turnoFim: string;
  origem: string;
  alternada: boolean;
  dtAdmissao: string;
}

const alertMissinginformation = (InputName: string): void => {
  Swal.fire({
    icon: 'error',
    title: `O campo(a) ${InputName} está vazio!`,
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    showConfirmButton: false,
  });
};

export const validationUser = (objUser: RegisterUser): boolean => {
  if (objUser.acesso === 99) {
    alertMissinginformation('"Tipo de acesso"');
    return false;
  }
  // Colaborador
  if (objUser.acesso === 0) {
    if (objUser.IdEmpresaFilial === 0) {
      alertMissinginformation('"Filial"');
      return false;
    }
    if (objUser.postoId === 0) {
      alertMissinginformation('"Posto ou Cliente"');
      return false;
    }
    if (objUser.name === '') {
      alertMissinginformation('"Nome"');
      return false;
    }
    if (objUser.cpf === '') {
      alertMissinginformation('"Cpf"');
      return false;
    }
    if (objUser.email === '') {
      alertMissinginformation('"E-mail"');
      return false;
    }
    if (objUser.telefone === '') {
      alertMissinginformation('"Telefone"');
      return false;
    }
    if (objUser.diasTrab === '' && !objUser.alternada) {
      alertMissinginformation('"Dias Trabalhados"');
      return false;
    }
    if (objUser.dtAdmissao === '' && objUser.alternada) {
      alertMissinginformation('"Data de Admissão"');
      return false;
    }
  }
  // Coordenador e Supervisor
  if (objUser.acesso === 1 || objUser.acesso === 2) {
    if (objUser.IdEmpresaFilial === 0) {
      alertMissinginformation('"Filial"');
      return false;
    }
    if (objUser.name === '') {
      alertMissinginformation('"Nome"');
      return false;
    }
    if (objUser.cpf === '') {
      alertMissinginformation('"Cpf"');
      return false;
    }
    if (objUser.diasTrab === '' && !objUser.alternada) {
      // console.log('foi aqui mesmo');
      // console.log('foi aqui mesmo');
      alertMissinginformation('"Dias Trabalhados"');
      return false;
    }
    if (objUser.dtAdmissao === '' && objUser.alternada) {
      alertMissinginformation('"Data de Admissão"');
      return false;
    }
  }
  // Vigilante
  if (objUser.acesso === 30) {
    if (objUser.IdEmpresaFilial === 0) {
      alertMissinginformation('"Filial"');
      return false;
    }
    if (objUser.postoId === 0) {
      alertMissinginformation('"Posto"');
      return false;
    }
    if (objUser.name === '') {
      alertMissinginformation('"Name"');
      return false;
    }
    if (objUser.cpf === '') {
      alertMissinginformation('"Cpf"');
      return false;
    }
    if (objUser.diasTrab === '' && !objUser.alternada) {
      alertMissinginformation('"Dias Trabalhados"');
      return false;
    }
    if (objUser.dtAdmissao === '' && objUser.alternada) {
      alertMissinginformation('"Data de Admissão"');
      return false;
    }
    if (objUser.turnoIni === '') {
      alertMissinginformation('"Turno inicial"');
      return false;
    }
    if (objUser.turnoFim === '') {
      alertMissinginformation('"Turno final"');
      return false;
    }
  }

  return true;
};
