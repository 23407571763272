import styled from 'styled-components';

export const Input = styled.div`
  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  padding: 0 10px 0 10px;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;

  input {
    width: 100%;
    background: ${(props) => props.theme.colors.white};
    border: none;
    color: ${(props) => props.theme.colors.primary};
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Container = styled.div`
  .suggestionDiv {
    padding: 5px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary} !important;
    background-color: ${(props) => props.theme.colors.secondary} !important;
  }
`;
