import styled from 'styled-components';

export const ContainerMenuDrawer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 232px;
  max-height: calc(100vh - 80px);
  border-right: 1px solid ${(props) => props.theme.colors.white3};
  overflow: auto;

  @media screen and (max-width: 1152px) {
    position: fixed;
    width: 232px
    height: 100vh;
    max-height: calc(100vh - 80px);
    z-index: 3;
  }
`;

export const List = styled.ul`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  padding-top: 20px;

  li {
    button {
      margin-top: 40px;
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      height: 100%;
      width: 100%;
      color: ${(props) => props.theme.colors.gray10} !important;
      text-decoration: none;
      div {
        padding-left: 0px;
        padding-right: 0px;
        height: 100%;
        width: 50px;
      }
      border-style: none;
      svg {
        margin: auto 5px auto 10px;
        path {
          fill: ${(props) => props.theme.colors.gray10} !important;
        }
      }
      transition: color 0.2s;

      &:hover {
        color: ${(props) => props.theme.colors.primary} !important;
        svg {
          path {
            fill: ${(props) => props.theme.colors.primary}!important;
            transition: fill 0.2s;
          }
        }
      }
    }

    a {
      padding-left: 0px;
      padding-right: 0px;
      div {
        height: 100%;
        width: 50px;
        padding-left: 0px;
        padding-right: 0px;
      }
      color: ${(props) => props.theme.colors.gray10};
      text-decoration: none;
      display: flex;
      svg {
        //transform: rotate(45deg);
        margin: auto 16px auto 16px;
        path {
          fill: ${(props) => props.theme.colors.gray10} !important;
        }
      }

      transition: color 0.2s;

      &:hover {
        color: ${(props) => props.theme.colors.primary} !important;

        svg {
          margin-bottom: auto;
          margin-top: auto;
          margin-right: 16px;
          path {
            fill: ${(props) => props.theme.colors.primary} !important;
            transition: fill 0.2s;
          }
        }
      }
    }

    & + li {
      margin-top: 40px;
    }

    @media screen and (max-width: 1400px) {
      & + li {
        margin-top: 40px;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    font-size: 16px;
  }
`;

interface ButtonProps {
  teste?: string;
}

export const LiList = styled.div<ButtonProps>`
  width: 100%;
  font-size: 14px;
  font-weight: 500;

  /* display: ${(props) => props.teste}; */

  margin-top: 10px;
  margin-bottom: 10px;

  a {
    div {
      display: inline-flex;
      align-items: center;
      width: 100%;

      svg {
        width: 2px;
        height: 48px;
        margin: auto 10px auto 15%;
      }
    }
  }
`;

export const ArrowSvg = styled.svg<ButtonProps>`
  transition: transform 1s;

  transform: rotate(
    ${(props) => (props.teste === 'block' ? '0deg' : '-90deg')}
  ) !important;
`;
