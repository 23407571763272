import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip, useMediaQuery, Zoom } from '@material-ui/core';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FaCalendar, FaFont, FaSearch, FaTimes } from 'react-icons/fa';
import { FiFilter, FiInfo } from 'react-icons/fi';
import { VscFilePdf } from 'react-icons/vsc';
import { RiFileExcel2Line } from 'react-icons/ri';
import moment from 'moment';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import { orderByDistance } from 'geolib';
import Axios from 'axios';

import Search from '../../components/Search';
import { Loading } from '../../components/Loading';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { Clientes } from '../../models/Clientes';
import { Filiais } from '../../models/Filiais';
import { UsuarioBD } from '../../models/User';
import { GetRotaComplete } from '../../models/Rota';
import { GetDirectionsMapbox } from '../DetailsRotaAprov/interface';
import api from '../../services/api';

import {
  AtMedioPosto,
  FilterOBJ,
  KMRota,
  ORDERBY,
  QtdClientesSup,
  QtdVisitasRxNR,
  // RotasAprov,
  RotaVisGraf,
  TimeAvarageAllCliente,
  TimeAvarageAllPosto,
  TimeAvarageCliente,
  TimeRota,
  UtilApp,
  UtilAppAll,
  VisGraf,
} from './interface';
import {
  Container,
  ContainerSearch,
  ContainerTitle,
  HeaderDate,
  SemDados,
} from './styles';
import { ModalFilterAudRota } from './ModalFilter';

export const AuditoriaRota: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const { addToast } = useToast();

  const [filter, setFilter] = useState<FilterOBJ>({
    companyId: [],
    clientId: [],
    userId: [],
    rotaId: [],
  });
  const [fontSizeGraph, setFontSizeGraph] = useState(11);
  const isDesktop = useMediaQuery('(min-width:1150px) and (max-width:1500px)');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [rangeInicial, setRangeInicial] = useState('');
  const [rangeFinal, setRangeFinal] = useState('');

  const [loadingItens, setLoadingItens] = useState(false);
  const [loadingHist, setLoadingHist] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  // const [showClientOrPost, setShowClientOrPost] = useState(true);

  const [rotasVis, setRotasVis] = useState<RotaVisGraf[]>([]);
  const [users, setUsers] = useState<UsuarioBD[]>([]);
  const [empresas, setEmpresas] = useState<Filiais[]>([]);
  const [clients, setClients] = useState<Clientes[]>([]);

  const getDistance = useCallback(async (rota: RotaVisGraf) => {
    if (rota.Visita.length > 1) {
      const init: ORDERBY = { lat: -3.7371362, lng: -38.4993369 };
      const points: ORDERBY[] = rota.Visita.map((vit) => ({
        lat: vit.latitude,
        lng: vit.longitude,
      }));
      const resu = orderByDistance(init, points) as ORDERBY[];

      const locations = resu.map((item) => `${item.lng},${item.lat}`).join(';');

      return Axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${locations}?geometries=geojson&access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`,
      )
        .then((resp) => {
          const result: GetDirectionsMapbox = resp.data;
          const { distance } = result.routes[0];
          return distance;
        })
        .catch((err) => {
          console.log(err.message);
          return 0;
        });
    }
    return 0;
  }, []);

  const getHist = useCallback(
    async (dateIni: string, dateEnd: string) => {
      const idEP =
        empresaPrincipal.length > 0 && empresaPrincipal[0].ID === 1
          ? 0
          : empresaPrincipal[0].ID;
      setLoadingHist(true);

      api
        .get(`/rotas?dataIni=${dateIni}&dataFim=${dateEnd}&companyId=${idEP}`)
        .then(async (response) => {
          const visitasHist: GetRotaComplete[] = response.data;

          const listRotas: RotaVisGraf[] = [];
          visitasHist
            .filter(
              (it) =>
                it.status !== 'A Aprovar' &&
                it.status !== 'Rejeitada' &&
                it.status !== 'Reprovada' &&
                it.type !== 'checkIn' &&
                it.type !== 'checkOut',
            )
            .forEach((item) => {
              const findIndex = listRotas.findIndex(
                (i) =>
                  i.rotaId === item.rotaId &&
                  i.userId === item.supervisorId &&
                  i.date === item.data,
              );
              if (findIndex === -1) {
                const hist = item.Historico.sort((a, b) => {
                  if (a.timeIni > b.timeIni) return 1;
                  if (a.timeIni < b.timeIni) return -1;
                  return 0;
                });
                const obj: RotaVisGraf = {
                  companyId: item.companyId,
                  date: item.data,
                  distance: 0,
                  nameUser: '',
                  rotaId: item.rotaId,
                  userId: item.supervisorId,
                  Visita: [
                    {
                      id: item.Visita.id,
                      idCliente: item.Visita.Posto.clienteId,
                      idPosto: item.Visita.Posto.id,
                      latitude: item.Visita.Posto.latitude,
                      longitude: item.Visita.Posto.longitude,
                      namePosto: item.Visita.Posto.name,
                      nameCliente: '',
                      status:
                        item.status === 'Concluido' ||
                        item.status === 'Concluido com pendencias'
                          ? 'Concluído'
                          : 'Não concluído',
                      timeEnd:
                        hist.length > 0 ? hist[hist.length - 1].timeFim : '',
                      timeIni: hist.length > 0 ? hist[0].timeIni : '',
                    },
                  ],
                };
                listRotas.push(obj);
              } else {
                const hist = item.Historico.sort((a, b) => {
                  if (a.timeIni > b.timeIni) return 1;
                  if (a.timeIni < b.timeIni) return -1;
                  return 0;
                });
                const vist: VisGraf = {
                  id: item.Visita.id,
                  idCliente: item.Visita.Posto.clienteId,
                  idPosto: item.Visita.postoId,
                  latitude: item.Visita.Posto.latitude,
                  longitude: item.Visita.Posto.longitude,
                  namePosto: item.Visita.Posto.name,
                  nameCliente: '',
                  status:
                    item.status === 'Concluido' ||
                    item.status === 'Concluido com pendencias'
                      ? 'Concluído'
                      : 'Não concluído',
                  timeEnd: hist.length > 0 ? hist[hist.length - 1].timeFim : '',
                  timeIni: hist.length > 0 ? hist[0].timeIni : '',
                };
                listRotas[findIndex].Visita.push(vist);
              }
            });
          const listRotDist: RotaVisGraf[] = [];
          listRotas.forEach(async (item) => {
            listRotDist.push({ ...item, distance: await getDistance(item) });
          });
          setTimeout(() => {
            setLoadingHist(false);
            setRotasVis(listRotDist);
            setRangeInicial(dateIni);
            setRangeFinal(dateEnd);
          }, 1500);
        })
        .catch((err) => {
          console.log(err.message);
          setLoadingHist(false);
        });
    },
    [empresaPrincipal, getDistance],
  );

  const getDados = useCallback(async () => {
    setLoadingItens(true);
    const responseUser = await api.get(
      `/allUsers/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
      }`,
    );
    const responseCompany = await api.get(
      `empresas/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
      }`,
    );

    const clientesGet = await api.get(
      `/clientes/empresa?empresaId=${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );

    setEmpresas(responseCompany.data);
    setUsers(responseUser.data);
    setClients(clientesGet.data);
    setLoadingItens(false);
  }, [empresaPrincipal]);

  const handleSearch = useCallback((): void => {
    if (searchDataInicial === '') {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Data inicial não preenchida.',
      });
      return;
    }
    if (searchDataFinal === '') {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Data final não preenchida.',
      });
      return;
    }
    if (searchDataFinal < searchDataInicial) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Data final menor que a inicial.',
      });
      return;
    }
    getHist(searchDataInicial, searchDataFinal);
  }, [addToast, getHist, searchDataFinal, searchDataInicial]);

  const renderMouth = useCallback((mouth: number): string => {
    switch (mouth) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return '';
    }
  }, []);

  const dateRender = useMemo(() => {
    if (rangeInicial === '' || rangeFinal === '') {
      return '';
    }
    const [yearStart, mouthStart, dayStart] = rangeInicial.split('-');
    const [yearEnd, mouthEnd, dayEnd] = rangeFinal.split('-');
    if (rangeInicial === rangeFinal)
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    if (yearStart === yearEnd && mouthStart === mouthEnd) {
      return `${dayStart} a ${dayEnd} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    }
    if (yearStart === yearEnd && mouthStart !== mouthEnd) {
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} a ${dayEnd} de ${renderMouth(Number(mouthEnd))} de ${yearStart}`;
    }
    return `${dayStart} de ${renderMouth(
      Number(mouthStart),
    )} de ${yearStart} a ${dayEnd} de ${renderMouth(
      Number(mouthEnd),
    )} de ${yearEnd}`;
  }, [rangeFinal, rangeInicial, renderMouth]);

  useEffect(() => {
    getDados();
    getHist(
      moment().subtract(1, 'days').format('YYYY-MM-DD'),
      moment().subtract(1, 'days').format('YYYY-MM-DD'),
    );
  }, [getDados, getHist]);

  useEffect(() => {
    document.querySelectorAll('text').forEach((a) => {
      // eslint-disable-next-line no-param-reassign
      a.style.fontSize = `${fontSizeGraph}px`;
    });
  }, [fontSizeGraph]);

  const loading = useMemo(() => {
    return loadingItens || loadingHist;
  }, [loadingHist, loadingItens]);

  const rotasWithFilter = useMemo(() => {
    let dados = rotasVis;
    dados = dados.map((i) => {
      const user = users.find((u) => u.id === i.userId);
      const vist: VisGraf[] = i.Visita.map((v) => {
        const cli = clients.find((c) => c.id === String(v.idCliente));
        return { ...v, nameCliente: !cli ? '' : cli.name };
      });
      return {
        ...i,
        nameUser: !user ? '' : user.nome,
        Visita: vist.filter((v) => v.nameCliente !== ''),
      };
    });

    if (filter.companyId.length > 0) {
      dados = dados.filter((item) =>
        filter.companyId.find((i) => i.value === String(item.companyId)),
      );
    }
    if (filter.clientId.length > 0) {
      dados = dados
        .map((item) => {
          const vist = item.Visita.filter((v) =>
            filter.clientId.find((i) => i.value === String(v.idCliente)),
          );
          return { ...item, Visita: vist };
        })
        .filter((i) => i.Visita.length > 0);
    }
    if (filter.userId.length > 0) {
      dados = dados.filter((item) =>
        filter.userId.find((i) => i.value === String(item.userId)),
      );
    }
    if (filter.rotaId.length > 0) {
      dados = dados.filter((item) =>
        filter.rotaId.find((i) => String(i.value) === item.rotaId),
      );
    }
    return dados.filter((i) => i.nameUser !== '');
  }, [
    clients,
    filter.clientId,
    filter.companyId,
    filter.rotaId,
    filter.userId,
    rotasVis,
    users,
  ]);

  // TOTAL DE ROTAS: numero total de rotas aprovadas por supervisor por periodo de tempo.
  // const dadosRotasAprov = useMemo(() => {
  //   const dados: RotasAprov[] = [];
  //   users.forEach((user) => {
  //     const qtd = rotasWithFilter.filter((rot) => rot.userId === user.id)
  //       .length;
  //     if (qtd > 0) {
  //       const obj: RotasAprov = {
  //         id: user.nome,
  //         userId: user.id,
  //         value: qtd,
  //       };
  //       dados.push(obj);
  //     }
  //   });
  //   return dados.sort((a, b) => {
  //     if (a.id > b.id) return 1;
  //     if (a.id < b.id) return -1;
  //     return 0;
  //   });
  // }, [rotasWithFilter, users]);

  // QUANTIDADE DE CLIENTES VISITADOS: quantidade de visitas feitas no dia.
  const dadosQtdClientesDay = useMemo(() => {
    const dados: QtdClientesSup[] = [];
    const aux: QtdClientesSup[] = [];
    rotasWithFilter.forEach((rot) => {
      rot.Visita.filter((vis) => vis.status === 'Concluído').forEach((vis) => {
        const findIndex = aux.findIndex(
          (i) => i.clienteId === vis.idCliente && i.userId === rot.userId,
        );
        if (findIndex === -1) {
          const obj: QtdClientesSup = {
            clienteId: vis.idCliente,
            id: rot.nameUser,
            value: 1,
            userId: rot.userId,
          };
          aux.push(obj);
        } else {
          aux[findIndex].value += 1;
        }
      });
    });
    aux.forEach((item) => {
      const findIndex = dados.findIndex((i) => i.userId === item.userId);
      if (findIndex === -1) {
        const obj: QtdClientesSup = {
          clienteId: 0,
          id: item.id,
          userId: item.userId,
          value: 1,
          // value: item.value,
        };
        dados.push(obj);
      } else {
        dados[findIndex].value += 1;
        // dados[findIndex].value += item.value;
      }
    });
    return dados.sort((a, b) => {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      return 0;
    });
  }, [rotasWithFilter]);

  // TOTAL DE VISITAS REALIZADAS, TOTAL DE VISITAS NÃO REALIZADAS: pegar por status de realizado e não realizado a visita.
  const dadosQtdRxNR = useMemo(() => {
    const dados: QtdVisitasRxNR[] = [];
    rotasWithFilter.forEach((rot) => {
      const findIndex = dados.findIndex((i) => i.userId === rot.userId);
      if (findIndex === -1) {
        const qtdVist = rot.Visita.filter((vis) => vis.status === 'Concluído')
          .length;
        const obj: QtdVisitasRxNR = {
          'não realizadas': rot.Visita.length - qtdVist,
          id: rot.nameUser,
          realizadas: qtdVist,
          userId: rot.userId,
        };
        dados.push(obj);
      } else {
        const qtdVist = rot.Visita.filter((vis) => vis.status === 'Concluído')
          .length;
        dados[findIndex].realizadas += qtdVist;
        dados[findIndex]['não realizadas'] += rot.Visita.length - qtdVist;
      }
    });
    return dados;
  }, [rotasWithFilter]);

  const getTimeDiff = useCallback(
    (timeIni: string, timeEnd: string): number => {
      const ini = new Date(timeIni).getTime() / 1000;
      const end = new Date(timeEnd).getTime() / 1000;
      if (ini > end) return ini - end;
      return end - ini;
    },
    [],
  );

  // TEMPO GERAL DA ROTA: calcular o tempo total de todas as visitas daquela rota e mostrar como geral da rota.
  const dadosTimePorRota = useMemo(() => {
    const dados: TimeRota[] = [];
    rotasWithFilter.forEach((rot) => {
      let time = 0;
      rot.Visita.forEach((vist) => {
        if (vist.timeIni && vist.timeEnd) {
          time += getTimeDiff(vist.timeEnd, vist.timeIni);
        }
      });
      if (time > 0) {
        const obj: TimeRota = {
          id: `${moment(rot.date).format('DD/MM')}-${rot.nameUser}`,
          date: rot.date,
          rotaId: rot.rotaId,
          userId: rot.userId,
          value: time,
        };
        dados.push(obj);
      }
    });
    return dados.sort((a, b) => {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      return 0;
    });
  }, [getTimeDiff, rotasWithFilter]);

  // TEMPO DE ATENDIMENTO MÉDIO POR POSTO
  const dadosAtMedioPosto = useMemo(() => {
    const aux: TimeAvarageAllPosto[] = [];
    rotasWithFilter.forEach((rot) => {
      rot.Visita.forEach((vis) => {
        if (vis.timeIni && vis.timeEnd) {
          const findIndex = aux.findIndex((i) => i.postoId === vis.idPosto);
          if (findIndex === -1) {
            const obj: TimeAvarageAllPosto = {
              postoId: vis.idPosto,
              namePosto: vis.namePosto,
              timeAll: getTimeDiff(vis.timeIni, vis.timeEnd),
              total: 1,
            };
            aux.push(obj);
          } else {
            aux[findIndex].timeAll += getTimeDiff(vis.timeIni, vis.timeEnd);
            aux[findIndex].total += 1;
          }
        }
      });
    });

    const dados: AtMedioPosto[] = aux.map((item) => ({
      id: `${item.postoId}•${item.namePosto}`,
      idPosto: item.postoId,
      value: item.timeAll / item.total,
    }));

    return dados.sort((a, b) => {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      return 0;
    });
  }, [getTimeDiff, rotasWithFilter]);

  // TEMPO MÉDIO DE ATENDIMENTO POR CLIENTE: somar o tempo total de cada visita dentro da rota e dividir pelo numero de visitas.
  const dadosMediaCliente = useMemo((): TimeAvarageCliente[] => {
    const aux: TimeAvarageAllCliente[] = [];
    rotasWithFilter.forEach((rot) => {
      rot.Visita.forEach((vis) => {
        if (vis.timeIni && vis.timeEnd) {
          const findIndex = aux.findIndex((i) => i.clienteId === vis.idCliente);
          if (findIndex === -1) {
            const obj: TimeAvarageAllCliente = {
              clienteId: vis.idCliente,
              nameClient: vis.nameCliente,
              timeAll: getTimeDiff(vis.timeIni, vis.timeEnd),
              total: 1,
            };
            aux.push(obj);
          } else {
            aux[findIndex].timeAll += getTimeDiff(vis.timeIni, vis.timeEnd);
            aux[findIndex].total += 1;
          }
        }
      });
    });

    return aux
      .map((item) => ({
        clienteId: item.clienteId,
        id: item.nameClient,
        value: item.timeAll / item.total,
      }))
      .sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
  }, [getTimeDiff, rotasWithFilter]);

  // KM DE ROTAS: pegar a km das rotas geradas a partir da melhor rota
  const dadosKmRotas = useMemo(() => {
    const dados: KMRota[] = [];
    rotasWithFilter.forEach((item) => {
      const findIndex = dados.findIndex((i) => i.userId === item.userId);
      if (findIndex === -1) {
        const obj: KMRota = {
          id: item.nameUser,
          userId: item.userId,
          value: item.distance,
        };
        dados.push(obj);
      } else {
        dados[findIndex].value += item.distance;
      }
    });
    return dados
      .map((item) => ({
        ...item,
        value: (item.value / 1000).toFixed(2),
      }))
      .sort((a, b) => {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      });
  }, [rotasWithFilter]);

  // UTILIZAÇÃO DO APP: Procentagem de Feitas/Aprovadas
  const dadosUtilApp = useMemo(() => {
    const aux: UtilAppAll[] = [];
    rotasWithFilter.forEach((rot) => {
      const findIndex = aux.findIndex((i) => i.userId === rot.userId);
      if (findIndex === -1) {
        const done = rot.Visita.filter((i) => i.status === 'Concluído').length;
        const obj: UtilAppAll = {
          aprov: rot.Visita.length,
          feito: done,
          id: rot.nameUser,
          userId: rot.userId,
        };
        aux.push(obj);
      } else {
        const done = rot.Visita.filter((i) => i.status === 'Concluído').length;
        aux[findIndex].aprov += rot.Visita.length;
        aux[findIndex].feito += done;
      }
    });
    const dados: UtilApp[] = aux.map((i) => ({
      id: i.id,
      userId: i.userId,
      value: Number((100 * (i.feito / i.aprov)).toFixed(2)),
    }));

    return dados.sort((a, b) => {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      return 0;
    });
  }, [rotasWithFilter]);

  const verificarDados = useCallback((): boolean => {
    return (
      // dadosRotasAprov.length > 0 ||
      dadosQtdClientesDay.length > 0 ||
      dadosQtdRxNR.length > 0 ||
      dadosTimePorRota.length > 0 ||
      dadosMediaCliente.length > 0 ||
      dadosKmRotas.length > 0 ||
      dadosUtilApp.length > 0 ||
      dadosAtMedioPosto.length > 0
    );
  }, [
    dadosKmRotas.length,
    dadosMediaCliente.length,
    dadosQtdClientesDay.length,
    dadosQtdRxNR.length,
    // dadosRotasAprov.length,
    dadosTimePorRota.length,
    dadosUtilApp.length,
    dadosAtMedioPosto.length,
  ]);

  const handlePDF = useCallback((): void => {
    if (verificarDados()) {
      const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(18);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text('Resumo', 103, 20, 'center');
      let posY: number;
      posY = 30;
      // if (dadosRotasAprov.length > 0) {
      //   doc.setFontType('bold');
      //   doc.setFontSize(16);
      //   doc.text('Total de Rotas Aprovadas', 103, 40, 'center');
      //   dadosRotasAprov.forEach((res, index: number) => {
      //     index === 0 ? (posY += 22) : (posY += 10);
      //     doc.setFontSize(14);
      //     doc.setFontType('normal');
      //     doc.text(
      //       `${res.id}: ${res.value} ${res.value === 1 ? 'rota' : 'rotas'}`,
      //       10,
      //       posY,
      //       'left',
      //     );
      //     if (index === dadosRotasAprov.length - 1) posY += 30;
      //   });
      //   if (posY > 190) {
      //     doc.addPage();
      //     posY = 20;
      //   }
      // }
      if (dadosQtdClientesDay.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Total de Clientes visitados', 103, posY, 'center');
        dadosQtdClientesDay.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${res.value} ${res.value > 0 ? 'visitas' : 'visita'}`,
            10,
            posY,
            'left',
          );
          if (index === dadosQtdClientesDay.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosQtdRxNR.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Visitas realizadas e não realizadas', 103, posY, 'center');
        dadosQtdRxNR.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id} - realizadas: ${res.realizadas} ${
              res.realizadas > 0 ? 'visitas' : 'visita'
            }`,
            10,
            posY,
            'left',
          );
          posY += 6;
          doc.text(
            `${res.id} - não realizadas: ${res['não realizadas']} ${
              res['não realizadas'] > 0 ? 'visitas' : 'visita'
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosQtdRxNR.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosTimePorRota.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Tempo geral da rota', 103, posY, 'center');
        dadosTimePorRota.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          const min = Math.floor((res.value ?? 0) / 60);
          const seg = Math.floor((res.value ?? 0) - min * 60);
          const time = `${min} minutos e ${seg} segundos`;
          doc.text(`${res.id}: ${time}`, 10, posY, 'left');
          if (index === dadosTimePorRota.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosMediaCliente.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Tempo médio de atendimento por cliente', 103, posY, 'center');
        dadosMediaCliente.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          const min = Math.floor((res.value ?? 0) / 60);
          const seg = Math.floor((res.value ?? 0) - min * 60);
          const time = `${min} minutos e ${seg} segundos`;
          doc.text(`${res.id}: ${time}`, 10, posY, 'left');
          if (index === dadosMediaCliente.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosAtMedioPosto.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Tempo médio de atendimento por posto', 103, posY, 'center');
        dadosAtMedioPosto.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          const min = Math.floor((res.value ?? 0) / 60);
          const seg = Math.floor((res.value ?? 0) - min * 60);
          const time = `${min} minutos e ${seg} segundos`;
          doc.text(`${res.id}: ${time}`, 10, posY, 'left');
          if (index === dadosAtMedioPosto.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosKmRotas.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Km de Rotas por Supervisor', 103, posY, 'center');
        dadosKmRotas.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(`${res.id}: ${res.value}Km`, 10, posY, 'left');
          if (index === dadosKmRotas.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosUtilApp.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Utilização do App', 103, posY, 'center');
        dadosUtilApp.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(`${res.id}: ${res.value}%`, 10, posY, 'left');
          if (index === dadosUtilApp.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      doc.autoPrint();
      doc.output('dataurlnewwindow');
    } else {
      addToast({
        type: 'error',
        title: 'Sem dados',
        description: 'Não há dados para exportar',
      });
    }
  }, [
    addToast,
    dadosKmRotas,
    dadosMediaCliente,
    dadosQtdClientesDay,
    dadosQtdRxNR,
    // dadosRotasAprov,
    dadosTimePorRota,
    dadosUtilApp,
    verificarDados,
    dadosAtMedioPosto,
  ]);

  const handleExcel = useCallback((): void => {
    if (verificarDados()) {
      const workbook = XLSX.utils.book_new();
      // if (dadosRotasAprov.length > 0) {
      //   const wkRotasProv = XLSX.utils.json_to_sheet(
      //     dadosRotasAprov.map((val) => ({
      //       SUPERVISOR: val.id,
      //       QUANTIDADE: val.value,
      //     })),
      //   );
      //   XLSX.utils.book_append_sheet(
      //     workbook,
      //     wkRotasProv,
      //     'Total de Rotas Aprovadas',
      //   );
      // }
      if (dadosQtdClientesDay.length > 0) {
        const wkQtdClientesDay = XLSX.utils.json_to_sheet(
          dadosQtdClientesDay.map((val) => ({
            DIA: val.id,
            QUANTIDADE: val.value,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkQtdClientesDay,
          'Total de Clientes visitados',
        );
      }
      if (dadosQtdRxNR.length > 0) {
        const wkQtdRxNR = XLSX.utils.json_to_sheet(
          dadosQtdRxNR.map((val) => ({
            SUPERVISOR: val.id,
            FEITAS: val.realizadas,
            'NÃO FEITAS': val['não realizadas'],
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkQtdRxNR,
          'Visitas feitas e não feitas',
        );
      }
      if (dadosTimePorRota.length > 0) {
        const wkTimePorRota = XLSX.utils.json_to_sheet(
          dadosTimePorRota.map((val) => {
            const min = Math.floor((val.value ?? 0) / 60);
            const seg = Math.floor((val.value ?? 0) - min * 60);
            const time = `${min}m${seg}s`;

            return {
              ROTA: val.id,
              TEMPO: time,
            };
          }),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkTimePorRota,
          'Tempo geral da rota',
        );
      }
      if (dadosMediaCliente.length > 0) {
        const wkMediaCliente = XLSX.utils.json_to_sheet(
          dadosMediaCliente.map((val) => {
            const min = Math.floor((val.value ?? 0) / 60);
            const seg = Math.floor((val.value ?? 0) - min * 60);
            const time = `${min}m${seg}s`;

            const obj = {
              CLIENTE: val.id,
              TEMPO: time,
            };
            return obj;
          }),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkMediaCliente,
          'Tempo médio por cliente',
        );
      }
      if (dadosAtMedioPosto.length > 0) {
        const wkMediaPosto = XLSX.utils.json_to_sheet(
          dadosAtMedioPosto.map((val) => {
            const min = Math.floor((val.value ?? 0) / 60);
            const seg = Math.floor((val.value ?? 0) - min * 60);
            const time = `${min}m${seg}s`;

            const obj = {
              POSTO: val.id,
              TEMPO: time,
            };
            return obj;
          }),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkMediaPosto,
          'Tempo médio por posto',
        );
      }
      if (dadosKmRotas.length > 0) {
        const wkKmRotas = XLSX.utils.json_to_sheet(
          dadosKmRotas.map((val) => ({
            SUPERVISOR: val.id,
            DISTÂNCIA: `${val.value}Km`,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkKmRotas,
          'Km de Rotas por Supervisor',
        );
      }
      if (dadosUtilApp.length > 0) {
        const wkUtilApp = XLSX.utils.json_to_sheet(
          dadosUtilApp.map((val) => ({
            SUPERVISOR: val.id,
            PORCENTAGEM: `${val.value}%`,
          })),
        );
        XLSX.utils.book_append_sheet(workbook, wkUtilApp, 'Utilização do App');
      }
      XLSX.writeFile(workbook, 'AuditoriaRota.xlsx');
    } else {
      addToast({
        type: 'error',
        title: 'Sem dados',
        description: 'Não há dados para exportar',
      });
    }
  }, [
    addToast,
    dadosKmRotas,
    dadosMediaCliente,
    dadosQtdClientesDay,
    dadosQtdRxNR,
    // dadosRotasAprov,
    dadosTimePorRota,
    dadosUtilApp,
    verificarDados,
    dadosAtMedioPosto,
  ]);

  return (
    <Container>
      {showFilter && (
        <ModalFilterAudRota
          isVisible={showFilter}
          onClose={() => setShowFilter(false)}
          empresas={empresas.map((i) => ({
            label: i.NOME,
            value: String(i.ID),
          }))}
          users={users
            .filter((i) => i.acesso === '2')
            .map((i) => ({ label: i.nome, value: String(i.id) }))}
          clientes={clients.map((i) => ({
            label: i.name,
            value: i.id,
          }))}
          rotas={rotasVis.map((i) => ({ label: i.rotaId, value: i.rotaId }))}
          filterPrin={filter}
          setFilterPrin={setFilter}
        />
      )}
      <ContainerTitle>
        <header>
          <h1>Auditoria Rota</h1>
          <Tooltip
            title={
              'Nessa página você tem acesso a visualização dos gráficos ' +
              'para fim de análises estratégicas.'
            }
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </header>
        <div>
          {(filter.companyId.length > 0 ||
            filter.clientId.length > 0 ||
            filter.userId.length > 0 ||
            filter.rotaId.length > 0) && (
            <button
              type="button"
              className="danger"
              title="Remover Filtros"
              onClick={() => {
                setFilter({
                  companyId: [],
                  clientId: [],
                  userId: [],
                  rotaId: [],
                });
              }}
            >
              <FaTimes size={20} />
            </button>
          )}
          <button
            type="button"
            className="danger"
            title="Diminuir fonte"
            onClick={() => setFontSizeGraph((s) => s - 1)}
          >
            <FaFont size={15} />
          </button>
          <button
            type="button"
            title="Aumentar fonte"
            onClick={() => setFontSizeGraph((s) => s + 1)}
          >
            <FaFont size={20} />
          </button>
          <button
            type="button"
            className="btnsvg"
            onClick={() => setShowFilter(true)}
          >
            <FiFilter size={20} />
          </button>
          <button
            type="button"
            className="btnsvg"
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <FaCalendar size={20} />
          </button>
        </div>
      </ContainerTitle>
      <div className="container">
        {showCalendar && (
          <ContainerSearch className="container-searcher" isFilterDateInterval>
            <Search
              onSearch={(value: string) => {
                setSearchDataInicial(value);
              }}
              value={searchDataInicial}
              nomePlaceHolder="Buscar data inicial"
              type="date"
            />

            <Search
              onSearch={(value: string) => {
                setSearchDataFinal(value);
              }}
              value={searchDataFinal}
              nomePlaceHolder="Buscar data final"
              type="date"
            />
            <button type="button" onClick={handleSearch}>
              <FaSearch size={20} />
            </button>
          </ContainerSearch>
        )}
        <HeaderDate>
          <h2>{dateRender}</h2>
          <div>
            <button
              title="Exportar para PDF"
              type="button"
              className="danger"
              onClick={handlePDF}
            >
              <VscFilePdf size={22} />
            </button>
            <button
              title="Exportar para Excel"
              type="button"
              onClick={handleExcel}
            >
              <RiFileExcel2Line size={22} />
            </button>
          </div>
        </HeaderDate>
        <div className="row">
          {/* <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">Total de Rotas Aprovadas</span>
            {loading && <Loading component />}
            {dadosRotasAprov.length > 0 && !loading && (
              <ResponsivePie
                data={dadosRotasAprov}
                margin={
                  !isDesktop
                    ? { top: 60, right: 0, bottom: 60, left: 140 }
                    : { top: 60, right: 40, bottom: 60, left: 40 }
                }
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: -120,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosRotasAprov.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div> */}
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              Tempo de atendimento médio por posto
            </span>
            {loading && <Loading component />}
            {dadosAtMedioPosto.length > 0 && !loading && (
              <ResponsivePie
                data={dadosAtMedioPosto}
                margin={
                  !isDesktop
                    ? { top: 60, right: 0, bottom: 60, left: 140 }
                    : { top: 60, right: 40, bottom: 60, left: 40 }
                }
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                valueFormat={(d) => {
                  // const hour = Math.floor((d ?? 0) / 3600);
                  const min = Math.floor((d ?? 0) / 60); // - hour * 60);
                  const seg = Math.floor((d ?? 0) - min * 60);
                  // const seg = Math.floor((d ?? 0) - (hour * 60 + min) * 60);
                  // console.log(d, `${hour}h${min}m${seg}s`);
                  return `${min}m${seg}s`;
                }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: -120,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosAtMedioPosto.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              Quantidade de Clientes Visitados
            </span>
            {loading && <Loading component />}
            {dadosQtdClientesDay.length > 0 && !loading && (
              <ResponsivePie
                data={dadosQtdClientesDay}
                margin={
                  !isDesktop
                    ? { top: 60, right: 0, bottom: 60, left: 140 }
                    : { top: 60, right: 40, bottom: 60, left: 40 }
                }
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: -120,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosQtdClientesDay.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              Total de visitas realizadas e não realizadas
            </span>
            {loading && <Loading component />}
            {dadosQtdRxNR.length > 0 && !loading && (
              <ResponsiveBar
                data={dadosQtdRxNR}
                margin={{ top: 60, right: 40, bottom: 60, left: 40 }}
                borderWidth={2}
                keys={['realizadas', 'não realizadas']}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                groupMode="grouped"
                colorBy="indexValue"
                axisLeft={null}
                axisBottom={null}
                borderRadius={5}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                theme={{
                  tooltip: {
                    container: {
                      position: 'absolute',
                      bottom: 10,
                      left: -50,
                    },
                  },
                }}
              />
            )}
            {dadosQtdRxNR.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">Tempo Geral da Rota</span>
            {loading && <Loading component />}
            {dadosTimePorRota.length > 0 && !loading && (
              <ResponsivePie
                data={dadosTimePorRota}
                margin={
                  !isDesktop
                    ? { top: 60, right: 0, bottom: 60, left: 140 }
                    : { top: 60, right: 40, bottom: 60, left: 40 }
                }
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                valueFormat={(d) => {
                  const hour = Math.floor((d ?? 0) / 3600);
                  const min = Math.floor((d ?? 0) / 60 - hour * 60);
                  // const seg = Math.floor((d ?? 0) - min * 60);
                  const seg = Math.floor((d ?? 0) - (hour * 60 + min) * 60);
                  // console.log(d, `${hour}h${min}m${seg}s`);
                  return `${hour}h${min}m${seg}s`;
                }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: -120,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosTimePorRota.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              Tempo Médio de atendimento por Cliente
            </span>
            {loading && <Loading component />}
            {dadosMediaCliente.length > 0 && !loading && (
              <ResponsivePie
                data={dadosMediaCliente}
                margin={
                  !isDesktop
                    ? { top: 60, right: 0, bottom: 60, left: 140 }
                    : { top: 60, right: 40, bottom: 60, left: 40 }
                }
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                valueFormat={(d) => {
                  // const hour = Math.floor((d ?? 0) / 3600);
                  const min = Math.floor((d ?? 0) / 60); // - hour * 60);
                  const seg = Math.floor((d ?? 0) - min * 60);
                  // const seg = Math.floor((d ?? 0) - (hour * 60 + min) * 60);
                  // console.log(d, `${hour}h${min}m${seg}s`);
                  return `${min}m${seg}s`;
                }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: -120,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosMediaCliente.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">Km de Rotas por Supervisor</span>
            {loading && <Loading component />}
            {dadosKmRotas.length > 0 && !loading && (
              <ResponsivePie
                data={dadosKmRotas}
                margin={
                  !isDesktop
                    ? { top: 60, right: 0, bottom: 60, left: 140 }
                    : { top: 60, right: 40, bottom: 60, left: 40 }
                }
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                valueFormat={(d) => {
                  return `${d}km`;
                }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: -120,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosKmRotas.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">Utilização do App</span>
            {loading && <Loading component />}
            {dadosUtilApp.length > 0 && !loading && (
              <ResponsivePie
                data={dadosUtilApp}
                margin={
                  !isDesktop
                    ? { top: 60, right: 0, bottom: 60, left: 140 }
                    : { top: 60, right: 40, bottom: 60, left: 40 }
                }
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                valueFormat={(d) => `${d}%`}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: -120,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosUtilApp.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
