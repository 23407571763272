import { shade } from 'polished';
import styled from 'styled-components';
import Button from '../../../components/Button';

export const Container = styled.div`
  font-family: 'Montserrat';
  padding: 0px 74px 30px 74px;
  color: ${(props) => props.theme.colors.black2};

  p {
    color: ${(props) => props.theme.colors.black2};
    margin: 0px;
    margin-top: 10px;
  }

  @media screen and (max-width: 600px) {
    padding: 22px;
  }

  .action {
    width: 100%;
    display: flex;
    column-gap: 20px;
    align-items: center;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      row-gap: 20px;
    }
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.error};
    align-items: center;
    justify-content: center;
    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const ButtonFiltro = styled(Button)`
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
  &:hover {
    background: ${(props) => shade(0.2, props.theme.colors.white)};
  }
`;
