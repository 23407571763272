import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    border: none;
    width: 100%;
  }

  input::placeholder {
    color: grey;
  }
`;

export const ContainerLupa = styled.div`
  padding-right: 17px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #b0b0b0;
    width: 19px;
    height: 19px;
  }
`;
