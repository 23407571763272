import React, { useCallback, useState, useMemo } from 'react';
import Search from '../Search-isolate';
import { maskCep } from '../Input/mask';

import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerTitleClient,
  ContainerSelectClient,
  ContainerFilter,
  ContainerSelectVazio,
} from './styles';
import { ContainerObservation } from '../../pages/CadastroRotas/styles';

interface ResponseGetClientesGeral {
  A1_BAIRRO: string;
  A1_CEP: string;
  A1_COD: string;
  A1_END: string;
  A1_EST: string;
  A1_MUN: string;
  A1_NOME: string;
  ATIVO: boolean;
  ID_A1: number;
  Id_EmpresaFilial: number;
  LATITUDE_CLIENTE: string;
  LONGITUDE_CLIENTE: string;
  id: number;
  id_table_rotasClientes: number;
  status: boolean;
}

interface Modal {
  dadosClientesGeral?: ResponseGetClientesGeral[];
  onClose?(): void;
  onAdd?(cliente: ResponseGetClientesGeral): void;
}

const Modal: React.FC<Modal> = ({
  onClose = () => {
    '';
  },
  dadosClientesGeral,
  onAdd = (cliente: ResponseGetClientesGeral) => {
    return cliente;
  },
}) => {
  const [clienteSelecionado, setClienteSelecionado] = useState('');
  const [searchNomeClientePosto, setSearchNomeClientePosto] = useState('');
  const [searchCep, setSearchCep] = useState('');
  const [selectVazio, setSelectVazio] = useState(false);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose();
      }
    },
    [onClose],
  );

  const handleSearchClient = useCallback(
    (idCliente: string) => {
      dadosClientesGeral?.forEach((cliente) => {
        if (idCliente === cliente.id.toString().trim()) {
          onAdd({ ...cliente, id_table_rotasClientes: cliente.id });
          onClose();
        }
      });

      setSelectVazio(true);
    },
    [dadosClientesGeral, onAdd, onClose],
  );

  const responseData = useMemo(() => {
    let computedResponses: ResponseGetClientesGeral[] = [];
    computedResponses = dadosClientesGeral || [];

    if (searchNomeClientePosto) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetClientesGeral) =>
          res.A1_NOME.toLowerCase().includes(
            searchNomeClientePosto.toLowerCase(),
          ),
      );
    }

    let CEP: string = searchCep.replace('.', '');
    CEP = CEP.replace('.', '');
    CEP = CEP.replace('-', '');
    if (searchCep) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetClientesGeral) => res.A1_CEP.includes(CEP),
      );
    }

    computedResponses.length
      ? setClienteSelecionado(computedResponses[0].id.toString().trim())
      : setClienteSelecionado('');

    computedResponses.length && setSelectVazio(false);

    return computedResponses;
  }, [dadosClientesGeral, searchCep, searchNomeClientePosto]);

  return (
    <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
      <Container>
        <ContainerTitleClient>
          <h2>Selecione o cliente/posto:</h2>
        </ContainerTitleClient>

        <ContainerFilter>
          <div>
            <Search
              inputColor="#d9d9d9"
              className="search1"
              onSearch={(value: string) => {
                setSearchNomeClientePosto(value);
              }}
              nomePlaceHolder="Buscar Nome"
            />

            <Search
              inputColor="#d9d9d9"
              className="search1"
              onSearch={(value: string) => {
                setSearchCep(maskCep(value));
              }}
              nomePlaceHolder="Buscar CEP"
            />
          </div>
        </ContainerFilter>

        <ContainerSelectClient borderProps={selectVazio}>
          <select
            onChange={(e) =>
              setClienteSelecionado(e.target.value.split('-')[0].trim())
            }
          >
            {responseData.map((cliente) => (
              <option key={cliente.id}>
                {`${cliente.id} - ${cliente.A1_NOME.trim()} / CEP: ${maskCep(
                  cliente.A1_CEP.trim(),
                )}`}
              </option>
            ))}
          </select>

          {selectVazio && (
            <ContainerSelectVazio>
              Nenhum cliente/posto foi selecionado
            </ContainerSelectVazio>
          )}
        </ContainerSelectClient>

        <ContainerButton>
          <button
            type="button"
            onClick={() => {
              handleSearchClient(clienteSelecionado);
            }}
          >
            Adicionar
          </button>

          <button
            type="button"
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </button>
        </ContainerButton>
      </Container>
    </ContainerModal>
  );
};

export default Modal;
