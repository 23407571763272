import { Reducer } from 'redux';
import { IStateResultQueryDate } from './types';

const INITIAL_STATE: IStateResultQueryDate = {
  resultQueryDate: {
    clientes: [],
    supervisores: [],
    rota: [],
  },
};

const mapQueryDate: Reducer<IStateResultQueryDate> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case 'UPDATE_QUERY_DATE': {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
export default mapQueryDate;
