import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

interface SelectProps {
  borderProps: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.7);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 700px;
  height: 230px;
  max-width: 90%;
  max-height: 80%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  animation: ${appearFromRight} 300ms;
`;

export const ContainerButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 50%;
  right: 50%;

  button {
    padding: 10px;
    background-color: #004d00;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
    &:hover {
      background-color: ${shade(0.2, '#004d00')};
    }

    & + button {
      margin-left: 15px;
      background-color: #800000;
      &:hover {
        background-color: ${shade(0.2, '#800000')};
      }
    }
  }
`;

export const ContainerTitleClient = styled.div`
  position: absolute;
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 5px 5px 0 0;
  top: 0;

  h2 {
    text-align: center;
    margin-top: 8px;
    color: #000;
  }
`;

export const ContainerSelectClient = styled.div<SelectProps>`
  margin-top: 15px;
  width: 90%;
  select {
    border-radius: 10px;
    width: 100%;
    height: 50px;
    color: #000;
    outline: none;
    border: ${(props) =>
      props.borderProps
        ? '2px solid red'
        : `2px solid ${props.theme.colors.success}`};
  }
`;

export const ContainerFilter = styled.div`
  display: flex;
  margin-top: 35px;
  justify-content: initial;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }

  div {
    display: flex;
    svg {
      margin-bottom: 2px;
    }
    border-bottom-left-radius: 10px;
    & + div {
      margin-left: 25px;
    }

    input {
      width: 100%;
    }
  }

  .search1 {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const ContainerSelectVazio = styled.span`
  color: red;
`;
