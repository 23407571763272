import React, { useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';
import Swal from 'sweetalert2';

import NewModal from '../NewModal';

import { ButtonClose, Container, Select } from './styles';

type Turno = 'Diurno' | 'Noturno';

interface Props {
  visible: boolean;
  onClose: () => void;
  onConfirm: (turno: Turno) => void;
}

export const ModalManualGenerate: React.FC<Props> = ({
  onClose,
  visible,
  onConfirm,
}) => {
  const [turno, setTurno] = useState<Turno | ''>('');

  const handleSubmit = useCallback(() => {
    if (turno !== '') {
      onConfirm(turno);
      onClose();
    } else {
      Swal.fire({
        icon: 'info',
        title: `Turno não escolhido!`,
      });
    }
  }, [onClose, onConfirm, turno]);

  return (
    <NewModal isVisible={visible}>
      <ButtonClose>
        <button
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          <FiX size={22} />
        </button>
      </ButtonClose>
      <Container>
        <h2>Geração Manual</h2>
        <p>
          Atenção! O processo atual irá forçar a geração das rotas segundo terno
          selecionado. Todas as rotas já geradas serão sobrescritas e caso não
          estaja, em, aprovação automática, voltando para a aprovação.
        </p>

        <Select
          value={turno}
          onChange={(e) => {
            setTurno(e.target.value as Turno | '');
          }}
        >
          <option value="">Escolher o Turno</option>
          <option value="Diurno">Diurno</option>
          <option value="Noturno">Noturno</option>
        </Select>

        <p>Deseja continuar?</p>

        <div className="action">
          <button type="button" className="cancel" onClick={onClose}>
            <p>Cancelar</p>
          </button>
          <button type="button" className="confirm" onClick={handleSubmit}>
            <p>Confirmar</p>
          </button>
        </div>
      </Container>
    </NewModal>
  );
};
