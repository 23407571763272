import React, { useMemo, useState } from 'react';
import { Container, InputTextFilter, SelectFilter } from './styled';
import { ReactComponent as SearchIcon } from '../../assets/search_black_24dp.svg';

export interface FilterProps {
  name: string;
  field: string;
  sortable: boolean;
}

export interface PropsSearch {
  arraySetState: React.SetStateAction<any>;
  arrayState: any[];
  selectFilters: FilterProps[];
}

export const NewSearch: React.FC<PropsSearch> = ({
  arraySetState,
  arrayState,
  selectFilters,
}) => {
  // console.log(arrayState, 'CLIENTE');
  const [busca, setBusca] = useState('');
  const [selectedOption, setSelectedOption] = useState(selectFilters[0].field);

  useMemo(() => {
    const filtered = arrayState.filter((e) => {
      return (
        e[selectedOption]
          .toLocaleLowerCase()
          .indexOf(busca.toLocaleLowerCase()) > -1
      );
    });
    arraySetState(filtered);
  }, [arraySetState, arrayState, busca, selectedOption]);

  return (
    <Container>
      <InputTextFilter>
        <SearchIcon />
        <input
          type="text"
          placeholder="Buscar"
          onChange={(e) => setBusca(e.target.value)}
        />
      </InputTextFilter>
      <SelectFilter>
        <h4>Filtro:</h4>
        <select
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e.target.value);
          }}
        >
          {selectFilters?.map((element) => {
            return (
              <option key={Math.random()} value={element.field}>
                {element.name}
              </option>
            );
          })}
        </select>
      </SelectFilter>
    </Container>
  );
};
