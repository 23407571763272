import styled from 'styled-components';

export const Container = styled.div`
  font-family: Montserrat, sans-serif;

  height: 48px;
  width: 100%;

  display: flex;
  flex-direction: row;

  margin-top: 16px;
  margin-bottom: 25px;
`;

export const InputTextFilter = styled.div`
  height: 48px;
  width: 100%;

  /* padding-left: 10px; */
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.white4};

  display: flex;
  svg {
    margin: auto 0;
  }

  input {
    width: 100%;
    /* margin-left: 10px; */
    border: none;
    ::placeholder {
      font-size: 16px;
      color: ${(props) => props.theme.colors.gray4};
    }
  }
`;

export const SelectFilter = styled.div`
  height: 48px;
  width: 200px;

  background-color: ${(props) => props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.colors.white4};

  color: ${(props) => props.theme.colors.black2};
  display: flex;
  flex-direction: row;

  padding-left: 10px;
  h4 {
    margin: auto 0 !important;
  }
  select {
    width: 100%;
    border: none;
  }
`;
