import Leaflet from 'leaflet';

import logoPoint from '../../assets/VectorPoint.svg';
import logoPointActive from '../../assets/VectorPointActive.svg';
import logoPointAndamento from '../../assets/VectorPointAndamento.svg';
import logoPointAndamento1 from '../../assets/VectorPointAndamento1.svg';
import logoPointAndamento2 from '../../assets/VectorPointAndamento2.svg';
import logoPointAndamento3 from '../../assets/VectorPointAndamento3.svg';
import logoPointAndamento4 from '../../assets/VectorPointAndamento4.svg';
import logoPointAndamento5 from '../../assets/VectorPointAndamento5.svg';
import logoPointAndamento6 from '../../assets/VectorPointAndamento6.svg';
import logoPointAndamento7 from '../../assets/VectorPointAndamento7.svg';
import logoPointAndamento8 from '../../assets/VectorPointAndamento8.svg';
import logoPointAndamento9 from '../../assets/VectorPointAndamento9.svg';
import logoPointAndamento10 from '../../assets/VectorPointAndamento10.svg';
import logoPointAndamento11 from '../../assets/VectorPointAndamento11.svg';
import logoPointAndamento12 from '../../assets/VectorPointAndamento12.svg';
import logoPointAndamento13 from '../../assets/VectorPointAndamento13.svg';
import logoPointAndamento14 from '../../assets/VectorPointAndamento14.svg';
import logoPointAndamento15 from '../../assets/VectorPointAndamento15.svg';
import logoPointAndamento16 from '../../assets/VectorPointAndamento16.svg';
import logoPointAndamento17 from '../../assets/VectorPointAndamento17.svg';
import logoPointAndamento18 from '../../assets/VectorPointAndamento18.svg';
import logoPointAndamento19 from '../../assets/VectorPointAndamento19.svg';
import logoPointAndamento20 from '../../assets/VectorPointAndamento20.svg';
import logoPointAndamento21 from '../../assets/VectorPointAndamento20+.svg';
import logoPointCancel from '../../assets/VectorPointCancel.svg';
import logoPointConcluido from '../../assets/VectorPointConcluido.svg';
import logoPointConcluido1 from '../../assets/VectorPointConcluido1.svg';
import logoPointConcluido2 from '../../assets/VectorPointConcluido2.svg';
import logoPointConcluido3 from '../../assets/VectorPointConcluido3.svg';
import logoPointConcluido4 from '../../assets/VectorPointConcluido4.svg';
import logoPointConcluido5 from '../../assets/VectorPointConcluido5.svg';
import logoPointConcluido6 from '../../assets/VectorPointConcluido6.svg';
import logoPointConcluido7 from '../../assets/VectorPointConcluido7.svg';
import logoPointConcluido8 from '../../assets/VectorPointConcluido8.svg';
import logoPointConcluido9 from '../../assets/VectorPointConcluido9.svg';
import logoPointConcluido10 from '../../assets/VectorPointConcluido10.svg';
import logoPointConcluido11 from '../../assets/VectorPointConcluido11.svg';
import logoPointConcluido12 from '../../assets/VectorPointConcluido12.svg';
import logoPointConcluido13 from '../../assets/VectorPointConcluido13.svg';
import logoPointConcluido14 from '../../assets/VectorPointConcluido14.svg';
import logoPointConcluido15 from '../../assets/VectorPointConcluido15.svg';
import logoPointConcluido16 from '../../assets/VectorPointConcluido16.svg';
import logoPointConcluido17 from '../../assets/VectorPointConcluido17.svg';
import logoPointConcluido18 from '../../assets/VectorPointConcluido18.svg';
import logoPointConcluido19 from '../../assets/VectorPointConcluido19.svg';
import logoPointConcluido20 from '../../assets/VectorPointConcluido20.svg';
import logoPointConcluido21 from '../../assets/VectorPointConcluido20+.svg';
import logoPointInacabado from '../../assets/VectorPointInacabado.svg';
import logoPointInacabado1 from '../../assets/VectorPointInacabado1.svg';
import logoPointInacabado2 from '../../assets/VectorPointInacabado2.svg';
import logoPointInacabado3 from '../../assets/VectorPointInacabado3.svg';
import logoPointInacabado4 from '../../assets/VectorPointInacabado4.svg';
import logoPointInacabado5 from '../../assets/VectorPointInacabado5.svg';
import logoPointInacabado6 from '../../assets/VectorPointInacabado6.svg';
import logoPointInacabado7 from '../../assets/VectorPointInacabado7.svg';
import logoPointInacabado8 from '../../assets/VectorPointInacabado8.svg';
import logoPointInacabado9 from '../../assets/VectorPointInacabado9.svg';
import logoPointInacabado10 from '../../assets/VectorPointInacabado10.svg';
import logoPointInacabado11 from '../../assets/VectorPointInacabado11.svg';
import logoPointInacabado12 from '../../assets/VectorPointInacabado12.svg';
import logoPointInacabado13 from '../../assets/VectorPointInacabado13.svg';
import logoPointInacabado14 from '../../assets/VectorPointInacabado14.svg';
import logoPointInacabado15 from '../../assets/VectorPointInacabado15.svg';
import logoPointInacabado16 from '../../assets/VectorPointInacabado16.svg';
import logoPointInacabado17 from '../../assets/VectorPointInacabado17.svg';
import logoPointInacabado18 from '../../assets/VectorPointInacabado18.svg';
import logoPointInacabado19 from '../../assets/VectorPointInacabado19.svg';
import logoPointInacabado20 from '../../assets/VectorPointInacabado20.svg';
import logoPointInacabado21 from '../../assets/VectorPointInacabado20+.svg';
import logoPointPendent from '../../assets/VectorPointPendent.svg';

const mapPoint = Leaflet.icon({
  iconUrl: logoPoint,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointActive = Leaflet.icon({
  iconUrl: logoPointActive,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess = Leaflet.icon({
  iconUrl: logoPointConcluido,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess1 = Leaflet.icon({
  iconUrl: logoPointConcluido1,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess2 = Leaflet.icon({
  iconUrl: logoPointConcluido2,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess3 = Leaflet.icon({
  iconUrl: logoPointConcluido3,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess4 = Leaflet.icon({
  iconUrl: logoPointConcluido4,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess5 = Leaflet.icon({
  iconUrl: logoPointConcluido5,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess6 = Leaflet.icon({
  iconUrl: logoPointConcluido6,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess7 = Leaflet.icon({
  iconUrl: logoPointConcluido7,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess8 = Leaflet.icon({
  iconUrl: logoPointConcluido8,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess9 = Leaflet.icon({
  iconUrl: logoPointConcluido9,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess10 = Leaflet.icon({
  iconUrl: logoPointConcluido10,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess11 = Leaflet.icon({
  iconUrl: logoPointConcluido11,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess12 = Leaflet.icon({
  iconUrl: logoPointConcluido12,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess13 = Leaflet.icon({
  iconUrl: logoPointConcluido13,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess14 = Leaflet.icon({
  iconUrl: logoPointConcluido14,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess15 = Leaflet.icon({
  iconUrl: logoPointConcluido15,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess16 = Leaflet.icon({
  iconUrl: logoPointConcluido16,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess17 = Leaflet.icon({
  iconUrl: logoPointConcluido17,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess18 = Leaflet.icon({
  iconUrl: logoPointConcluido18,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess19 = Leaflet.icon({
  iconUrl: logoPointConcluido19,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess20 = Leaflet.icon({
  iconUrl: logoPointConcluido20,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointSuccess21 = Leaflet.icon({
  iconUrl: logoPointConcluido21,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointCancel = Leaflet.icon({
  iconUrl: logoPointCancel,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento = Leaflet.icon({
  iconUrl: logoPointAndamento,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento1 = Leaflet.icon({
  iconUrl: logoPointAndamento1,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento2 = Leaflet.icon({
  iconUrl: logoPointAndamento2,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento3 = Leaflet.icon({
  iconUrl: logoPointAndamento3,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento4 = Leaflet.icon({
  iconUrl: logoPointAndamento4,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento5 = Leaflet.icon({
  iconUrl: logoPointAndamento5,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento6 = Leaflet.icon({
  iconUrl: logoPointAndamento6,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento7 = Leaflet.icon({
  iconUrl: logoPointAndamento7,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento8 = Leaflet.icon({
  iconUrl: logoPointAndamento8,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento9 = Leaflet.icon({
  iconUrl: logoPointAndamento9,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento10 = Leaflet.icon({
  iconUrl: logoPointAndamento10,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento11 = Leaflet.icon({
  iconUrl: logoPointAndamento11,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento12 = Leaflet.icon({
  iconUrl: logoPointAndamento12,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento13 = Leaflet.icon({
  iconUrl: logoPointAndamento13,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento14 = Leaflet.icon({
  iconUrl: logoPointAndamento14,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento15 = Leaflet.icon({
  iconUrl: logoPointAndamento15,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento16 = Leaflet.icon({
  iconUrl: logoPointAndamento16,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento17 = Leaflet.icon({
  iconUrl: logoPointAndamento17,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento18 = Leaflet.icon({
  iconUrl: logoPointAndamento18,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento19 = Leaflet.icon({
  iconUrl: logoPointAndamento19,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento20 = Leaflet.icon({
  iconUrl: logoPointAndamento20,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointAndamento21 = Leaflet.icon({
  iconUrl: logoPointAndamento21,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado = Leaflet.icon({
  iconUrl: logoPointInacabado,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado1 = Leaflet.icon({
  iconUrl: logoPointInacabado1,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado2 = Leaflet.icon({
  iconUrl: logoPointInacabado2,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado3 = Leaflet.icon({
  iconUrl: logoPointInacabado3,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado4 = Leaflet.icon({
  iconUrl: logoPointInacabado4,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado5 = Leaflet.icon({
  iconUrl: logoPointInacabado5,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado6 = Leaflet.icon({
  iconUrl: logoPointInacabado6,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado7 = Leaflet.icon({
  iconUrl: logoPointInacabado7,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado8 = Leaflet.icon({
  iconUrl: logoPointInacabado8,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado9 = Leaflet.icon({
  iconUrl: logoPointInacabado9,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado10 = Leaflet.icon({
  iconUrl: logoPointInacabado10,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado11 = Leaflet.icon({
  iconUrl: logoPointInacabado11,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado12 = Leaflet.icon({
  iconUrl: logoPointInacabado12,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado13 = Leaflet.icon({
  iconUrl: logoPointInacabado13,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado14 = Leaflet.icon({
  iconUrl: logoPointInacabado14,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado15 = Leaflet.icon({
  iconUrl: logoPointInacabado15,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado16 = Leaflet.icon({
  iconUrl: logoPointInacabado16,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado17 = Leaflet.icon({
  iconUrl: logoPointInacabado17,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado18 = Leaflet.icon({
  iconUrl: logoPointInacabado18,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado19 = Leaflet.icon({
  iconUrl: logoPointInacabado19,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado20 = Leaflet.icon({
  iconUrl: logoPointInacabado20,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointInacabado21 = Leaflet.icon({
  iconUrl: logoPointInacabado21,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

const mapPointPendent = Leaflet.icon({
  iconUrl: logoPointPendent,
  iconSize: [48, 48],
  iconAnchor: [29, 68],
  popupAnchor: [170, 2],
});

function getIconSuccessNumber(val: number): Leaflet.Icon {
  switch (val) {
    case 0:
      return mapPointSuccess;
    case 1:
      return mapPointSuccess1;
    case 2:
      return mapPointSuccess2;
    case 3:
      return mapPointSuccess3;
    case 4:
      return mapPointSuccess4;
    case 5:
      return mapPointSuccess5;
    case 6:
      return mapPointSuccess6;
    case 7:
      return mapPointSuccess7;
    case 8:
      return mapPointSuccess8;
    case 9:
      return mapPointSuccess9;
    case 10:
      return mapPointSuccess10;
    case 11:
      return mapPointSuccess11;
    case 12:
      return mapPointSuccess12;
    case 13:
      return mapPointSuccess13;
    case 14:
      return mapPointSuccess14;
    case 15:
      return mapPointSuccess15;
    case 16:
      return mapPointSuccess16;
    case 17:
      return mapPointSuccess17;
    case 18:
      return mapPointSuccess18;
    case 19:
      return mapPointSuccess19;
    case 20:
      return mapPointSuccess20;
    default:
      return mapPointSuccess21;
  }
}

function getIconAndamentoNumber(val: number): Leaflet.Icon {
  switch (val) {
    case 0:
      return mapPointAndamento;
    case 1:
      return mapPointAndamento1;
    case 2:
      return mapPointAndamento2;
    case 3:
      return mapPointAndamento3;
    case 4:
      return mapPointAndamento4;
    case 5:
      return mapPointAndamento5;
    case 6:
      return mapPointAndamento6;
    case 7:
      return mapPointAndamento7;
    case 8:
      return mapPointAndamento8;
    case 9:
      return mapPointAndamento9;
    case 10:
      return mapPointAndamento10;
    case 11:
      return mapPointAndamento11;
    case 12:
      return mapPointAndamento12;
    case 13:
      return mapPointAndamento13;
    case 14:
      return mapPointAndamento14;
    case 15:
      return mapPointAndamento15;
    case 16:
      return mapPointAndamento16;
    case 17:
      return mapPointAndamento17;
    case 18:
      return mapPointAndamento18;
    case 19:
      return mapPointAndamento19;
    case 20:
      return mapPointAndamento20;
    default:
      return mapPointAndamento21;
  }
}

function getIconInacabadoNumber(val: number): Leaflet.Icon {
  switch (val) {
    case 0:
      return mapPointInacabado;
    case 1:
      return mapPointInacabado1;
    case 2:
      return mapPointInacabado2;
    case 3:
      return mapPointInacabado3;
    case 4:
      return mapPointInacabado4;
    case 5:
      return mapPointInacabado5;
    case 6:
      return mapPointInacabado6;
    case 7:
      return mapPointInacabado7;
    case 8:
      return mapPointInacabado8;
    case 9:
      return mapPointInacabado9;
    case 10:
      return mapPointInacabado10;
    case 11:
      return mapPointInacabado11;
    case 12:
      return mapPointInacabado12;
    case 13:
      return mapPointInacabado13;
    case 14:
      return mapPointInacabado14;
    case 15:
      return mapPointInacabado15;
    case 16:
      return mapPointInacabado16;
    case 17:
      return mapPointInacabado17;
    case 18:
      return mapPointInacabado18;
    case 19:
      return mapPointInacabado19;
    case 20:
      return mapPointInacabado20;
    default:
      return mapPointInacabado21;
  }
}

export function getMarkerIcon(index: number, status: string): Leaflet.Icon {
  switch (status) {
    case 'Concluido':
      return getIconSuccessNumber(index);
    case 'Concluido com pendencias':
      return getIconInacabadoNumber(index);
    case 'Andamento':
      return getIconAndamentoNumber(index);
    case 'Aprovada':
      return mapPointPendent;
    case 'A iniciar':
      return mapPointPendent;
    case 'Cancelado':
      return mapPointCancel;
    case 'Não realizado':
      return mapPointCancel;
    case 'Em deslocamento':
      return mapPointInacabado;
    case 'No local':
      return mapPointAndamento;
    default:
      return mapPoint;
  }
}
