export const maskCpf = (value: any) => {// eslint-disable-line
  return (
    value
      .replace(/\D/g, '')
      // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2')
      // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar
      // o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  );
  // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const maskMonthYear = (value: any) => {// eslint-disable-line
  // eslint-disable-line
  value = value.substr(0, 7);// eslint-disable-line
  return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1/$2');
};

export const maskDateOfBirth = (value: any) => {// eslint-disable-line
  value = value.substr(0, 10);// eslint-disable-line
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');
};
export function isDate(date: any) {// eslint-disable-line
  const day = date.substr(0, 2);
  const math = date.substr(3, 2);
  const yaer = date.substr(6, 4);
  let check = true;

  day < 1 && (check = false);
  day > 31 && (check = false);

  math < 1 && (check = false);
  math > 12 && (check = false);

  yaer < 1940 && (check = false);

  return check;
  // var cargo = salario <= 1000 ? 'junior' : salario <= 5000 ? 'senior' : 'diretor';
}

export const maskTel = (tel: any) => {// eslint-disable-line
  tel = tel.substr(0, 16);// eslint-disable-line

  tel = tel // eslint-disable-line
    .replace(/\D/g, '')
    .replace(/^(\d)/, '($1')
    .replace(/(.{3})(\d)/, '$1)$2')
    .replace(/(.{3})(\d)/, '$1 $2')
    .replace(/(.{6})(\d)/, '$1 $2');
  if (tel.length == 12) {// eslint-disable-line
    tel = tel.replace(/(.{1})$/, '-$1');// eslint-disable-line
  } else if (tel.length == 13) {// eslint-disable-line
    tel = tel.replace(/(.{2})$/, '-$1');// eslint-disable-line
  } else if (tel.length == 14) {// eslint-disable-line
    tel = tel.replace(/(.{3})$/, '-$1');// eslint-disable-line
  } else if (tel.length == 15) {// eslint-disable-line
    tel = tel.replace(/(.{4})$/, '-$1');// eslint-disable-line
  }
  return tel;
};
export const maskCnpj = (cnpj: any) => {// eslint-disable-line
  cnpj = cnpj.substr(0, 18);// eslint-disable-line

  cnpj = cnpj// eslint-disable-line
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');

  return cnpj;
};

export const maskCep = (cep: any) => {// eslint-disable-line
  cep = cep// eslint-disable-line
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/\.(\d{3})(\d)/, '.$1-$2');

  return cep;
};

export function isCpf(c: any) {// eslint-disable-line
  if ((c = c.replace(/[^\d]/g, '')).length != 11) return false;// eslint-disable-line

  if (c == '00000000000') return false;// eslint-disable-line

  let r;
  let s = 0;
  let i = 1;
  for (i = 1; i <= 9; i++) s += parseInt(c[i - 1]) * (11 - i);// eslint-disable-line

  r = (s * 10) % 11;

  if (r == 10 || r == 11) r = 0;// eslint-disable-line

  if (r != parseInt(c[9])) return false;// eslint-disable-line

  s = 0;

  for (i = 1; i <= 10; i++) s += parseInt(c[i - 1]) * (12 - i);// eslint-disable-line

  r = (s * 10) % 11;

  if (r == 10 || r == 11) r = 0;// eslint-disable-line

  if (r != parseInt(c[10])) return false;// eslint-disable-line

  return true;
}

export function moedaReal(num: any) {// eslint-disable-line
  return num
    .toFixed(2) // casas decimais
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export const maskDateYear = (value: any) => {// eslint-disable-line
  value = value.substr(0, 9);// eslint-disable-line
  return value.replace(/\D/g, '').replace(/(\d{4})(\d)/, '$1/$2');
};
