import { shade } from 'polished';
import styled from 'styled-components';

export const ButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.error};
    align-items: center;
    justify-content: center;
    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  margin: 20px;

  header {
    padding-bottom: 30px;
    border-bottom: 0.6px solid ${(props) => props.theme.colors.gray9};
    margin-bottom: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 45px;
      color: ${(props) => props.theme.colors.black};
    }
  }

  section {
    overflow: auto;

    table {
      width: 100%;
      background: ${(props) => props.theme.colors.white};
      border-collapse: collapse;

      thead {
        position: sticky;
        top: 0;
        z-index: 2;
        background: ${(props) => props.theme.colors.white};

        th {
          padding: 20px 30px;
          text-align: left;
          white-space: nowrap;

          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: ${(props) => props.theme.colors.black};
        }

        th:nth-last-child(-n + 1) {
          text-align: center;
        }
      }

      tbody {
        tr {
          td {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            color: ${(props) => props.theme.colors.black};
            padding: 20px 30px;
            white-space: nowrap;

            button {
              border: none;
              background: transparent;
              margin: 0 auto;

              display: flex;
              align-items: center;
              justify-content: center;
            }

            aside {
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                background: transparent;
                border: none;
                color: ${(props) => props.theme.colors.gray9};

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                transition: color 0.2s;

                &:hover {
                  color: ${(props) => props.theme.colors.success};
                }

                & + button {
                  margin-left: 15px;

                  transition: color 0.2s;

                  &:hover {
                    color: ${(props) => props.theme.colors.error};
                  }
                }

                svg {
                  width: 25px;
                  height: 25px;
                }
              }
            }
          }

          &:nth-child(odd) {
            background-color: ${(props) => props.theme.colors.gray6};
          }
        }
      }
    }
  }

  button[type='submit'] {
    margin: 30px auto 0 auto;
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 8px;
    margin-top: 12px;
  }

  .rem {
    background: ${(props) => props.theme.colors.error};
    svg {
      color: ${(props) => props.theme.colors.white};
    }
    &:hover {
      background: ${(props) => shade(0.2, props.theme.colors.error)};
    }
  }
`;

export const Content = styled.div`
  width: 100%;
`;

export const DivInput = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  column-gap: 20px;

  article {
    display: flex;
    flex-direction: column;
    flex: 1;

    div {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: center;
      border: 1px solid ${(props) => props.theme.colors.gray12};
      box-sizing: border-box;
      border-radius: 4px;

      input {
        font-family: 'Montserrat';
        font-size: 16px;
        line-height: 16px;
        border: none;
        align-items: center;
        color: ${(props) => props.theme.colors.black2};
        width: 100%;
        padding: 10px 5px;
      }
    }
  }
`;

export const ButtonAdd = styled.button`
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.success};
  border: none;
  border-radius: 8px;
  margin-left: 20px;
  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 22px;
  }
`;

export const ButtonRem = styled.button`
  height: 36px;
  width: 36px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.error};
  border: none;
  border-radius: 8px;
  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 22px;
  }
`;

export const CalendarDiv = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  align-items: center;

  div.sem {
    display: flex;
    margin-left: 25px;
    column-gap: 1rem;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;

    div.sem {
      margin: 0px;
    }
  }
  @media screen and (max-width: 500px) {
    div.sem {
      margin: 0px;
      column-gap: 6px;
      max-width: 100%;
    }
  }
`;
