import Leaflet from 'leaflet';

import logoPoint from '../../assets/VectorPoint.svg';
import logoPointActive from '../../assets/VectorPointActive.svg';
import logoPointAndamento from '../../assets/VectorPointAndamento.svg';
import logoPointCancel from '../../assets/VectorPointCancel.svg';
import logoPointConcluido from '../../assets/VectorPointConcluido.svg';
import logoPointInacabado from '../../assets/VectorPointInacabado.svg';
import logoPointPendent from '../../assets/VectorPointPendent.svg';

const mapPoint = Leaflet.icon({
  iconUrl: logoPoint,
  iconSize: [48, 48],
  iconAnchor: [29, 50],
  popupAnchor: [-4, -32],
});

const mapPointActive = Leaflet.icon({
  iconUrl: logoPointActive,
  iconSize: [48, 48],
  iconAnchor: [29, 50],
  popupAnchor: [-4, -32],
});

const mapPointSuccess = Leaflet.icon({
  iconUrl: logoPointConcluido,
  iconSize: [48, 48],
  iconAnchor: [29, 50],
  popupAnchor: [-4, -32],
});

const mapPointCancel = Leaflet.icon({
  iconUrl: logoPointCancel,
  iconSize: [48, 48],
  iconAnchor: [29, 50],
  popupAnchor: [-4, -32],
});

const mapPointAndamento = Leaflet.icon({
  iconUrl: logoPointAndamento,
  iconSize: [48, 48],
  iconAnchor: [29, 50],
  popupAnchor: [-4, -32],
});

const mapPointInacabado = Leaflet.icon({
  iconUrl: logoPointInacabado,
  iconSize: [48, 48],
  iconAnchor: [29, 50],
  popupAnchor: [-4, -32],
});

const mapPointPendent = Leaflet.icon({
  iconUrl: logoPointPendent,
  iconSize: [48, 48],
  iconAnchor: [29, 50],
  popupAnchor: [-4, -32],
});

export {
  mapPoint,
  mapPointActive,
  mapPointAndamento,
  mapPointCancel,
  mapPointInacabado,
  mapPointPendent,
  mapPointSuccess,
};
