import React, { useMemo, useCallback, useState, useContext } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineClockCircle } from 'react-icons/ai';

import { ThemeContext } from 'styled-components';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { FiEdit } from 'react-icons/fi';
// import IconEditWhite from '../../assets/IconEditWhite.svg';
// import { ResponseGet } from '../../pages/CadastroAgendaRonda';

import {
  HeaderMonth,
  HeaderWeek,
  BodyMonth,
  Content,
  ContainerLine,
  ContainerNotion,
  ButtonWeek,
  ButtonCard,
  ContainerSemResultados,
} from './styles';
import { AgendaRonda, types } from '../../models/AgendaRonda';

export interface DiaSemana {
  dia: number;
  diaDaSemana: number;
  dataFormat: Date;
}

interface DiaMes {
  dia: number;
  diaFormat: string;
  diaDaSemana: string;
  diaDaSemanaSmall: string;
}

interface CalendarioProps {
  data: AgendaRonda[];
  type?: 'leitura' | 'escrita';
  dataSearch?: string;
  onClickCardEdit?: (x: AgendaRonda, y: string) => void;
}

export const Calendario: React.FC<CalendarioProps> = ({
  data,
  type = 'escrita',
  dataSearch = '',
  onClickCardEdit = (x: AgendaRonda, y: string) => {
    return [x, y];
  },
}) => {
  const meses = [
    { id: 0, name: 'Janeiro' },
    { id: 1, name: 'Fevereiro' },
    { id: 2, name: 'Marco' },
    { id: 3, name: 'Abril' },
    { id: 4, name: 'Maio' },
    { id: 5, name: 'Junho' },
    { id: 6, name: 'Julho' },
    { id: 7, name: 'Agosto' },
    { id: 8, name: 'Setembro' },
    { id: 9, name: 'Outubro' },
    { id: 10, name: 'Novembro' },
    { id: 11, name: 'Dezembro' },
  ];

  const semana = [
    { id: 0, name: 'Domingo', small: 'DOM' },
    { id: 1, name: 'Segunda', small: 'SEG' },
    { id: 2, name: 'Terca', small: 'TER' },
    { id: 3, name: 'Quarta', small: 'QUA' },
    { id: 4, name: 'Quinta', small: 'QUI' },
    { id: 5, name: 'Sexta', small: 'SEX' },
    { id: 6, name: 'Sabado', small: 'SAB' },
  ];

  const { colors } = useContext(ThemeContext);
  const [dataAtual, setDataAtual] = useState(
    moment.tz(moment(), 'America/Fortaleza').format('YYYY-MM-DD 00:00:00'),
  );
  const [anoAtual, setAnoAtual] = useState(new Date().getUTCFullYear());
  const [mesAtual, setMesAtual] = useState(new Date().getMonth());
  const [selectDayWeekButton, setSelectDayWeekButton] = useState<DiaMes>({
    diaFormat: moment().format('YYYY-MM-DD'),
    dia: new Date().getDay(),
    diaDaSemana: semana[new Date().getDay()].name,
    diaDaSemanaSmall: semana[new Date().getDay()].small,
  });

  const mesAno = useMemo(() => {
    const findMonth = meses.find((mes) => mes.id === mesAtual);

    return findMonth || { id: 0, name: 'Janeiro' };
  }, [mesAtual, meses]);

  const dataResponseGetRevisions = useMemo(() => {
    // console.log(selectDayWeekButton, data);
    const dataAux: AgendaRonda[] = data.filter((item) => {
      if (item.type === 'sem') {
        return item.freqSem.split(' - ').find((i) => {
          switch (Number(i)) {
            case 1:
              return selectDayWeekButton.diaDaSemana === 'Segunda';
            case 2:
              return selectDayWeekButton.diaDaSemana === 'Terca';
            case 3:
              return selectDayWeekButton.diaDaSemana === 'Quarta';
            case 4:
              return selectDayWeekButton.diaDaSemana === 'Quinta';
            case 5:
              return selectDayWeekButton.diaDaSemana === 'Sexta';
            case 6:
              return selectDayWeekButton.diaDaSemana === 'Sabado';
            case 0:
              return selectDayWeekButton.diaDaSemana === 'Domingo';
            default:
              return false;
          }
        });
      }
      if (item.type === 'men') {
        const dia = selectDayWeekButton.diaFormat.split('-')[2];
        return item.freqMes.split(' - ').find((i) => i === dia);
      }
      if (item.type === 'ano') {
        return item.freqAno.split(' - ').find((i) => {
          const [, mes, dia] = selectDayWeekButton.diaFormat.split('-');
          return i === `${dia}/${mes}`;
        });
      }
      if (item.type === '12X') {
        const sem1 = item.freqSem.split(' - ').find((i) => {
          switch (Number(i)) {
            case 1:
              return selectDayWeekButton.diaDaSemana === 'Segunda';
            case 2:
              return selectDayWeekButton.diaDaSemana === 'Terca';
            case 3:
              return selectDayWeekButton.diaDaSemana === 'Quarta';
            case 4:
              return selectDayWeekButton.diaDaSemana === 'Quinta';
            case 5:
              return selectDayWeekButton.diaDaSemana === 'Sexta';
            case 6:
              return selectDayWeekButton.diaDaSemana === 'Sabado';
            case 0:
              return selectDayWeekButton.diaDaSemana === 'Domingo';
            default:
              return false;
          }
        });
        const sem2 = item.freqSem2.split(' - ').find((i) => {
          switch (Number(i)) {
            case 1:
              return selectDayWeekButton.diaDaSemana === 'Segunda';
            case 2:
              return selectDayWeekButton.diaDaSemana === 'Terca';
            case 3:
              return selectDayWeekButton.diaDaSemana === 'Quarta';
            case 4:
              return selectDayWeekButton.diaDaSemana === 'Quinta';
            case 5:
              return selectDayWeekButton.diaDaSemana === 'Sexta';
            case 6:
              return selectDayWeekButton.diaDaSemana === 'Sabado';
            case 0:
              return selectDayWeekButton.diaDaSemana === 'Domingo';
            default:
              return false;
          }
        });
        return sem1 || sem2;
      }
      return false;
    });

    const dataAuxSort = dataAux.sort((a, b) => {
      if (a.horaIni > b.horaFim) {
        return 1;
      }
      if (a.horaIni < b.horaIni) {
        return -1;
      }
      if (a.horaIni === b.horaIni && a.horaFim > b.horaFim) {
        return 1;
      }
      if (a.horaIni === b.horaFim && a.horaFim < b.horaFim) {
        return -1;
      }
      return 0;
    });

    let computedResponses: AgendaRonda[] = [];
    computedResponses = dataAuxSort;

    if (dataSearch) {
      computedResponses = computedResponses.filter((res: AgendaRonda) =>
        [
          res.Usuario.nome,
          res.Ronda.name,
          res.Ronda.Categoria.name,
          res.id,
          res.horaIni,
          res.horaFim,
        ].some(
          (item) =>
            item &&
            item
              .toString()
              .toLowerCase()
              .includes(dataSearch.toString().toLowerCase()),
        ),
      );
    }

    return computedResponses;
  }, [data, dataSearch, selectDayWeekButton]);

  // console.log('ARREYFINAL', dataResponseGetRevisions);

  const previousMonth = useCallback(() => {
    const date = new Date(
      new Date(dataAtual).setMonth(new Date(dataAtual).getMonth() - 1),
    );

    setDataAtual(
      moment.tz(date, 'America/Fortaleza').format('YYYY-MM-DD 00:00:00'),
    );
    setMesAtual(date.getMonth());
    setAnoAtual(date.getFullYear());
  }, [dataAtual]);

  const nextMonth = useCallback(() => {
    const date = new Date(
      new Date(dataAtual).setMonth(new Date(dataAtual).getMonth() + 1),
    );

    setDataAtual(
      moment.tz(date, 'America/Fortaleza').format('YYYY-MM-DD 00:00:00'),
    );
    setMesAtual(date.getMonth());
    setAnoAtual(date.getFullYear());
  }, [dataAtual]);

  const timeList = useMemo(() => {
    let horarioInicialList: number;
    let horarioFinalList = 0;

    // console.log('REst', dataResponseGetRevisions);
    const dataTemp = dataResponseGetRevisions; // novo
    // const dataTemp = dataResponseGetRevisions.filter((agendamento) =>
    //   agendamento.diasSemana.includes(selectDayWeekButton),
    // );

    if (dataTemp.length) {
      const dataFilter = dataTemp.filter((element) => element.horaFim !== '');

      horarioInicialList = Number(dataFilter[0].horaIni.split(':')[0]);
      // horarioFinalList = Number(
      //   dataFilter[dataFilter.length - 1].horaBaseFinal.split(':')[0],
      // );
      horarioFinalList = Number(
        dataFilter[dataFilter.length - 1].horaFim.split(':')[0],
      );

      let dataArray: number[] = Array.from(Array(horarioFinalList + 1).keys());

      dataArray = dataArray.filter(
        (element) =>
          element >= horarioInicialList && element <= horarioFinalList,
      );

      return dataArray;
    }

    return [];
  }, [dataResponseGetRevisions]);

  const checkDataAgendamento = useCallback(
    (element) => {
      const dataAgendamento = dataResponseGetRevisions.map((agendamento) => {
        // if (agendamento.diasSemana.includes(selectDayWeekButton)) {
        const horarioLimpezaInicial = agendamento.horaIni;
        // const horarioLimpezaFinal = agendamento.horaBaseFinal;

        // if (!agendamento.isRevisao) {
        if (String(element + 1).split('').length === 1) {
          const elementFormat = `0${element + 1}:00`;
          let elementFormatAux;

          if (String(element + 2).split('').length === 1) {
            elementFormatAux = `0${element + 2}:00`;
          } else {
            elementFormatAux = `${element + 2}:00`;
          }

          if (
            // (horarioLimpezaInicial >= elementFormat &&
            //   horarioLimpezaInicial < elementFormatAux) ||
            // (horarioLimpezaFinal >= elementFormat &&
            //   horarioLimpezaFinal <= elementFormatAux)
            horarioLimpezaInicial >= elementFormat &&
            horarioLimpezaInicial < elementFormatAux
          ) {
            return (
              <ButtonCard
                key={uuid()}
                backgroundProps={colors.primary}
                typeProps={type}
                onClick={() => {
                  onClickCardEdit(agendamento, selectDayWeekButton.diaDaSemana);
                }}
              >
                <ul>
                  <li>
                    {`Nome: ${agendamento.Usuario.nome} ${
                      agendamento.userId2 ? `ou ${agendamento.userId2}` : ''
                    }`}
                  </li>
                  <li>{`Ronda: ${agendamento.Ronda.name}`}</li>
                  {/* <li>{`Periodiciade: ${agendamento.nomePeriodicidade}`}</li> */}
                  <li>{`Categoria: ${agendamento.Ronda.Categoria.name}`}</li>
                  <li>{`Horario: de ${agendamento.horaIni}h até ${agendamento.horaFim}h`}</li>
                  {agendamento.Revisoes?.length > 0 && (
                    <li>
                      {`Revisões: ${agendamento.Revisoes.map(
                        (it) => `${it.horaIni} - ${it.horaFim}`,
                      ).join(' | ')}`}
                    </li>
                  )}
                  {type === 'escrita' && (
                    <FiEdit
                      style={{
                        position: 'absolute',
                        top: 12,
                        right: 15,
                      }}
                      color={colors.white}
                    />
                  )}
                </ul>
              </ButtonCard>
            );
          }
          return <></>;
        }
        const elementFormat = `${element + 1}:00`;
        const elementFormatAux = `${element + 2}:00`;

        if (
          // (horarioLimpezaInicial >= elementFormat &&
          //   horarioLimpezaInicial < elementFormatAux) ||
          // (horarioLimpezaFinal >= elementFormat &&
          //   horarioLimpezaFinal <= elementFormatAux)
          horarioLimpezaInicial >= elementFormat &&
          horarioLimpezaInicial < elementFormatAux
        ) {
          return (
            <ButtonCard
              key={uuid()}
              backgroundProps={colors.primary}
              typeProps={type}
              onClick={() => {
                onClickCardEdit(agendamento, selectDayWeekButton.diaDaSemana);
              }}
            >
              <ul>
                <li>
                  {`Nome: ${agendamento.Usuario.nome} ${
                    agendamento.userId2 ? `ou ${agendamento.userId2}` : ''
                  }`}
                </li>
                <li>{`Ronda: ${agendamento.Ronda.name}`}</li>
                {/* <li>{`Periodiciade: ${agendamento.nomePeriodicidade}`}</li> */}
                <li>
                  Tipo:{' '}
                  {types.find((val) => val.value === agendamento.type)?.label ||
                    ''}
                </li>
                <li>{`Horario: de ${agendamento.horaIni}h até ${agendamento.horaFim}h`}</li>
                {agendamento.Revisoes?.length > 0 && (
                  <li>
                    {`Revisões: ${agendamento.Revisoes.map(
                      (it) => `${it.horaIni} - ${it.horaFim}`,
                    ).join(' | ')}`}
                  </li>
                )}
                {/* {type === 'escrita' && <FiEdit color={colors.white} />} */}
              </ul>
            </ButtonCard>
          );
        }
        return <></>;
        // }

        // if (String(element + 1).split('').length === 1) {
        //   const elementFormat = `0${element + 1}:00`;
        //   let elementFormatAux;

        //   if (String(element + 2).split('').length === 1) {
        //     elementFormatAux = `0${element + 2}:00`;
        //   } else {
        //     elementFormatAux = `${element + 2}:00`;
        //   }

        //   if (
        //     // (horarioLimpezaInicial >= elementFormat &&
        //     //   horarioLimpezaInicial < elementFormatAux) ||
        //     // (horarioLimpezaFinal >= elementFormat &&
        //     //   horarioLimpezaFinal <= elementFormatAux)
        //     horarioLimpezaInicial >= elementFormat &&
        //     horarioLimpezaInicial < elementFormatAux
        //   ) {
        //     return (
        //       <ButtonCard
        //         key={uuid()}
        //         backgroundProps={colors.warning} // orange
        //         typeProps={type}
        //         onClick={() => {
        //           onClickCardEdit(agendamento, selectDayWeekButton.diaDaSemana);
        //         }}
        //       >
        //         <ul>
        //           <li>{`Nome: ${agendamento.user.nome}`}</li>
        //           <li>{`Ronda: ${agendamento.Agenda.ronda.nome}`}</li>
        //           {/* <li>{`Periodiciade: ${agendamento.status}`}</li> */}
        //           <li>Tipo: {agendamento.Agenda.ronda.categoria.name}</li>
        //           <li>{`Horario: de ${agendamento.horaInicio}h até ${agendamento.horaFim}h`}</li>

        //           {type === 'escrita' && <FiEdit color={colors.white} />}
        //         </ul>
        //       </ButtonCard>
        //     );
        //   }
        //   return <></>;
        // }
        // const elementFormat = `${element + 1}:00`;
        // const elementFormatAux = `${element + 2}:00`;

        // if (
        //   // (horarioLimpezaInicial >= elementFormat &&
        //   //   horarioLimpezaInicial < elementFormatAux) ||
        //   // (horarioLimpezaFinal >= elementFormat &&
        //   //   horarioLimpezaFinal <= elementFormatAux)
        //   horarioLimpezaInicial >= elementFormat &&
        //   horarioLimpezaInicial < elementFormatAux
        // ) {
        //   return (
        //     <ButtonCard
        //       key={uuid()}
        //       backgroundProps={colors.warning} // orange
        //       typeProps={type}
        //       onClick={() => {
        //         onClickCardEdit(agendamento, selectDayWeekButton.diaDaSemana);
        //       }}
        //     >
        //       <ul>
        //         <li>{`Nome: ${agendamento.user.nome}`}</li>
        //         <li>{`Ronda: ${agendamento.Agenda.ronda.nome}`}</li>
        //         {/* <li>{`Periodiciade: ${agendamento.nomePeriodicidade}`}</li> */}
        //         <li>Tipo:</li>
        //         <li>{`Horario: de ${agendamento.horaInicio}h até ${agendamento.horaFim}h`}</li>

        //         {type === 'escrita' && <FiEdit color={colors.white} />}
        //       </ul>
        //     </ButtonCard>
        //   );
        // }
        // return <></>;
        // } -- if de cima
        // return <></>;
      });

      return <section>{dataAgendamento}</section>;
    },
    [
      colors.primary,
      colors.white,
      dataResponseGetRevisions,
      onClickCardEdit,
      selectDayWeekButton.diaDaSemana,
      type,
    ],
  );

  const diasDoMesShow: DiaMes[] = useMemo(() => {
    const diasAll = new Date(anoAtual, mesAtual + 1, 0).getDate();
    const contentDias: DiaMes[] = [];
    for (let i = 1; i <= diasAll; i += 1) {
      const mesString =
        mesAtual + 1 <= 9 ? `0${mesAtual + 1}` : String(mesAtual + 1);
      const diaString = i <= 9 ? `0${i}` : String(i);
      const dateFormt = `${anoAtual}-${mesString}-${diaString}`;
      const dateDate = new Date(`${dateFormt} 12:00:00`);
      contentDias.push({
        dia: dateDate.getDate(),
        diaDaSemana: semana[dateDate.getDay()].name,
        diaFormat: dateFormt,
        diaDaSemanaSmall: semana[dateDate.getDay()].small,
      });
    }
    return contentDias;
  }, [anoAtual, mesAtual, semana]);

  // console.log(timeList);

  return (
    <>
      <HeaderMonth>
        <h2>
          {mesAno.name}, {anoAtual}
        </h2>

        <button type="button" onClick={() => previousMonth()}>
          <IoIosArrowBack />
        </button>

        <button type="button" onClick={() => nextMonth()}>
          <IoIosArrowForward />
        </button>
      </HeaderMonth>

      <Content typeProps={type}>
        <div style={{ overflow: 'auto' }}>
          <HeaderWeek>
            {diasDoMesShow.map((item, index) => {
              return (
                <ButtonWeek
                  key={index.toString()}
                  type="button"
                  onClick={() => setSelectDayWeekButton(item)}
                  isChecked={selectDayWeekButton.diaFormat === item.diaFormat}
                >
                  <p>{item.diaDaSemanaSmall}</p>
                  <p>{item.dia}</p>
                </ButtonWeek>
              );
            })}
            {/* <ButtonWeek
            type="button"
            onClick={() => setSelectDayWeekButton('Domingo')}
            isChecked={selectDayWeekButton === 'Domingo'}
          >
            <p>DOM</p>
          </ButtonWeek>
          <ButtonWeek
            type="button"
            onClick={() => setSelectDayWeekButton('Segunda')}
            isChecked={selectDayWeekButton === 'Segunda'}
          >
            <p>SEG</p>
          </ButtonWeek>
          <ButtonWeek
            type="button"
            onClick={() => setSelectDayWeekButton('Terça')}
            isChecked={selectDayWeekButton === 'Terça'}
          >
            <p>TER</p>
          </ButtonWeek>
          <ButtonWeek
            type="button"
            onClick={() => setSelectDayWeekButton('Quarta')}
            isChecked={selectDayWeekButton === 'Quarta'}
          >
            <p>QUA</p>
          </ButtonWeek>
          <ButtonWeek
            type="button"
            onClick={() => setSelectDayWeekButton('Quinta')}
            isChecked={selectDayWeekButton === 'Quinta'}
          >
            <p>QUI</p>
          </ButtonWeek>
          <ButtonWeek
            type="button"
            onClick={() => setSelectDayWeekButton('Sexta')}
            isChecked={selectDayWeekButton === 'Sexta'}
          >
            <p>SEX</p>
          </ButtonWeek>
          <ButtonWeek
            type="button"
            onClick={() => setSelectDayWeekButton('Sábado')}
            isChecked={selectDayWeekButton === 'Sábado'}
          >
            <p>SAB</p>
          </ButtonWeek> */}
          </HeaderWeek>
        </div>

        {!data.length && (
          <ContainerSemResultados>
            <h2>Não existem agendamentos cadastrados ainda.</h2>
          </ContainerSemResultados>
        )}

        <BodyMonth>
          <aside>
            {timeList.map((horario) => (
              <div key={horario}>
                <article>
                  <AiOutlineClockCircle /> <p>{horario}h</p>
                </article>
              </div>
            ))}
          </aside>

          <ContainerLine contTime={timeList.length} />

          <ContainerNotion>
            {timeList.map((horario) => checkDataAgendamento(horario - 1))}
          </ContainerNotion>
        </BodyMonth>
      </Content>
    </>
  );
};
