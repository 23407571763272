interface FormatedTimebyMinutesProps {
  seconds: number;
}

export function formatedTimebySeconds({
  seconds,
}: FormatedTimebyMinutesProps): string {
  const hour = Math.floor(seconds / (60 * 60));
  const hourInSecunds = hour * 60 * 60;

  const min = Math.floor((seconds - hourInSecunds) / 60);
  const minutesInSecunds = min * 60;

  const secs = seconds - hourInSecunds - minutesInSecunds;

  const hourString = hour < 10 ? `0${hour}` : hour;
  const minString = min < 10 ? `0${min}` : min;
  const secsString = secs < 10 ? `0${secs}` : secs;

  return `${hourString}:${minString}:${secsString}`;
}
