import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip, useMediaQuery, Zoom } from '@material-ui/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FaCalendar, FaFont, FaSearch, FaTimes } from 'react-icons/fa';
import { FiFilter, FiInfo } from 'react-icons/fi';
import { VscFilePdf } from 'react-icons/vsc';
import { RiFileExcel2Line } from 'react-icons/ri';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { animated } from '@react-spring/web';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import moment from 'moment';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { AgendamentoRonda, AgendaRonda } from '../../models/AgendaRonda';
import { UsuarioBD } from '../../models/User';
import { Filiais } from '../../models/Filiais';
import { HistoricRondaV2 } from '../../models/HistoricAgendaAgendamento';
import Search from '../../components/Search';
import { Loading } from '../../components/Loading';
import api from '../../services/api';

import { ModalFilterAuditoria } from './ModalFilter';
import {
  FilterOBJ,
  NumberOcorrencia,
  ActiveRealizNotRealiz,
  AverageTimeVig,
  RondasForVig,
  PrevistRealiz,
  HistoricToGrafs,
  HistTarefas,
  AgeHist,
  Hour,
} from './interfaces';

import {
  Container,
  ContainerSearch,
  ContainerTitle,
  HeaderDate,
  SemDados,
} from './styles';

interface AgendaWithAgendament extends AgendaRonda {
  agendaments: AgendamentoRonda[];
}

export const AuditoriaRonda: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const { addToast } = useToast();
  // Numero de ocorrencias
  // Atividades realizadas/não realizadas
  // Previsto X Realizado
  // Quantas ronda feitas por vigilante e total
  // Tempo médio da ronda por vigilante

  const isDesktop = useMediaQuery('(min-width:1150px) and (max-width:1500px)');

  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [rangeInicial, setRangeInicial] = useState('');
  const [rangeFinal, setRangeFinal] = useState('');
  const [filter, setFilter] = useState<FilterOBJ>({
    categoriaId: [],
    companyId: [],
    rondaId: [],
    setorId: [],
    userId: [],
    vistoriaId: [],
  });

  const [loadingItens, setLoadingItens] = useState(false);
  const [loadingHist, setLoadingHist] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const [fontSizeGraph, setFontSizeGraph] = useState(11);
  const [agendas, setAgendas] = useState<AgendaWithAgendament[]>([]);
  const [users, setUsers] = useState<UsuarioBD[]>([]);
  const [empresas, setEmpresas] = useState<Filiais[]>([]);
  const [historic, setHistoric] = useState<HistoricRondaV2[]>([]);

  const getHist = useCallback(
    async (dateIni: string, dateEnd: string) => {
      const idEP = empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : 0;
      setLoadingHist(true);

      api
        .get(
          `/historicrondas?companyId=${idEP}&dataIni=${dateIni}&dataFim=${dateEnd}`,
        )
        .then((resp) => {
          setLoadingHist(false);
          setHistoric(resp.data);
          setRangeInicial(dateIni);
          setRangeFinal(dateEnd);
        })
        .catch((err) => {
          setLoadingHist(false);
          console.log('ERR', err.message);
        });
    },
    [empresaPrincipal],
  );

  const getDados = useCallback(async () => {
    setLoadingItens(true);
    const idEP = empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : 0;

    const responseAgenda = await Promise.all([
      api.get(`/agendarondas/agendamentos?companyId=${idEP}`),
      api.get(`/agendarondas?companyId=${idEP}`),
    ]);
    const agendamentAll: AgendamentoRonda[] = responseAgenda[0].data;
    const agendaAll: AgendaRonda[] = responseAgenda[1].data;

    const agendWithAgendam: AgendaWithAgendament[] = agendaAll.map((item) => {
      return {
        ...item,
        agendaments: agendamentAll.filter((i) => i.agendaId === item.id),
      };
    });

    const responseUser = await api.get(
      `/allUsers/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
      }`,
    );
    const responseCompany = await api.get(
      `empresas/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
      }`,
    );

    setLoadingItens(false);
    setAgendas(agendWithAgendam.filter((i) => i.active));
    setUsers(responseUser.data);
    setEmpresas(responseCompany.data);
  }, [empresaPrincipal]);

  useEffect(() => {
    getDados();
    getHist(
      moment().subtract(7, 'days').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    );
  }, [getDados, getHist]);

  useEffect(() => {
    document.querySelectorAll('text').forEach((a) => {
      // eslint-disable-next-line no-param-reassign
      a.style.fontSize = `${fontSizeGraph}px`;
    });
  }, [fontSizeGraph]);

  const handleSearch = useCallback((): void => {
    if (searchDataInicial === '') {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Data inicial não preenchida.',
      });
      return;
    }
    if (searchDataFinal === '') {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Data final não preenchida.',
      });
      return;
    }
    if (searchDataFinal < searchDataInicial) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Data final menor que a inicial.',
      });
      return;
    }
    getHist(searchDataInicial, searchDataFinal);
  }, [addToast, getHist, searchDataFinal, searchDataInicial]);

  const getStatusHist = useCallback(
    (
      status: ('FEITO' | 'CANCELADO' | 'NÃO FEITO')[],
      dateAgend: string,
    ):
      | 'Concluído'
      | 'Concluído com Pendências'
      | 'Não Realizado'
      | 'Cancelado'
      | 'A iniciar' => {
      // 'FEITO' | 'CANCELADO' | 'NÃO FEITO'
      if (!status.includes('FEITO')) {
        if (!status.includes('NÃO FEITO')) {
          return 'Cancelado';
        }
        const now = moment().format('YYYY-MM-DD');
        if (dateAgend === now) {
          return 'A iniciar';
        }
        return 'Não Realizado';
      }
      if (status.includes('CANCELADO') || status.includes('NÃO FEITO')) {
        return 'Concluído com Pendências';
      }
      return 'Concluído';
    },
    [],
  );

  const historicAll = useMemo(() => {
    const lists: HistoricToGrafs[] = [];
    if (!rangeInicial || !rangeFinal) {
      return lists;
    }

    agendas.forEach((ag) => {
      const histAg = historic.filter((i) => i.Agendamento.agendaId === ag.id);
      const aux: AgeHist[] = [];
      histAg.forEach((his) => {
        const findIndex = aux.findIndex(
          (i) =>
            (i.created === his.timeIni || i.created === his.createdAt) &&
            i.idAgendamento === his.agendamentoId,
        );
        if (findIndex === -1) {
          const obj: AgeHist = {
            created: his.timeIni ? his.timeIni : his.createdAt,
            historic: [his],
            idAgendamento: his.agendamentoId,
          };
          aux.push(obj);
        } else {
          aux[findIndex].historic.push(his);
        }
      });
      const vist = ag.Ronda.Vistorias;
      const horarios: Hour[] = [
        { hourIni: ag.horaIni, hourFim: ag.horaFim },
        ...ag.Revisoes.map((i) => ({
          hourIni: i.horaIni,
          hourFim: i.horaFim,
        })),
      ];
      const auxSort = aux.sort((a, b) => {
        if (a.created > b.created) return 1;
        if (a.created < b.created) return -1;
        return 0;
      });
      ag.agendaments
        .filter((i) => i.dataAgend >= rangeInicial && i.dataAgend <= rangeFinal)
        .forEach((agm) => {
          const hist = auxSort.filter((i) => i.idAgendamento === agm.id);
          let histIndex = -1;

          horarios.forEach((hour) => {
            const hisFind = hist.find((i, index) => index > histIndex);
            if (hisFind) {
              histIndex = +1;
            }
            const histsFind = hisFind ? hisFind.historic : [];
            const vists: HistTarefas[] = [];
            vist.forEach((vt) => {
              const find = histsFind.find((i) => i.vistoriaId === vt.id);
              if (find) {
                const objV: HistTarefas = {
                  createdAt: find.createdAt,
                  observacao: find.observacao,
                  ocorrencia: find.ocorrencia,
                  taskId: vt.id,
                  status: find.image ? 'FEITO' : 'CANCELADO',
                  taskName: vt.name,
                  timeEnd: find.timeFim,
                  timeIni: find.timeIni,
                };
                vists.push(objV);
              } else {
                const objV: HistTarefas = {
                  createdAt: '',
                  observacao: '',
                  ocorrencia: '',
                  taskId: vt.id,
                  taskName: vt.name,
                  timeEnd: '',
                  timeIni: '',
                  status: 'NÃO FEITO',
                };
                vists.push(objV);
              }
            });

            if (vists.length > 0) {
              const obj: HistoricToGrafs = {
                agendaId: ag.id,
                agendamentId: agm.id,
                agendEnd: hour.hourFim,
                agendIni: hour.hourIni,
                companyId: agm.companyId,
                dateAgend: agm.dataAgend,
                histTarefas: vists,
                status: getStatusHist(
                  vists.map((i) => i.status),
                  agm.dataAgend,
                ),
                User: {
                  id: ag.Usuario.id,
                  name: ag.Usuario.nome,
                },
              };
              lists.push(obj);
            }
          });

          hist
            .filter((_, index) => index > histIndex)
            .forEach((ht) => {
              const obj: HistoricToGrafs = {
                agendaId: ag.id,
                agendamentId: agm.id,
                agendEnd: ag.horaFim,
                agendIni: ag.horaIni,
                companyId: agm.companyId,
                dateAgend: agm.dataAgend,
                histTarefas: ht.historic.map((i) => ({
                  createdAt: i.createdAt,
                  observacao: i.observacao,
                  ocorrencia: i.ocorrencia,
                  status: i.image ? 'FEITO' : 'CANCELADO',
                  taskId: i.vistoriaId,
                  taskName: vist.find((v) => v.id === i.vistoriaId)?.name || '',
                  timeEnd: i.timeFim,
                  timeIni: i.timeIni,
                })),
                status: getStatusHist(
                  ht.historic.map((i) => (i.image ? 'FEITO' : 'CANCELADO')),
                  agm.dataAgend,
                ),
                User: {
                  id: ag.Usuario.id,
                  name: ag.Usuario.nome,
                },
              };

              lists.push(obj);
            });
        });
    });

    const histFil = lists
      .filter((i) => {
        if (filter.companyId.length > 0) {
          return filter.companyId.find((comp) => i.companyId === comp.value);
        }
        return true;
      })
      .filter((i) => {
        if (filter.userId.length > 0) {
          return filter.userId.find((user) => i.User.id === user.value);
        }
        return true;
      })
      .filter((i) => {
        if (filter.rondaId.length > 0) {
          return filter.rondaId.find((rond) => i.agendaId === rond.value);
        }
        return true;
      })
      // .filter((i) => {
      //   if (filter.setorId.length > 0) {
      //     return filter.setorId.find((fil) => i.agendaId === fil.value);
      //   }
      //   return true;
      // })
      .map((i) => {
        if (filter.vistoriaId.length > 0) {
          return {
            ...i,
            histTarefas: i.histTarefas.filter((ht) =>
              filter.vistoriaId.find((vist) => vist.value === ht.taskId),
            ),
          };
        }
        return i;
      })
      .filter((i) => i.histTarefas.length > 0);

    return histFil;
  }, [
    agendas,
    filter.companyId,
    filter.rondaId,
    filter.userId,
    filter.vistoriaId,
    getStatusHist,
    historic,
    rangeFinal,
    rangeInicial,
  ]);

  const loading = useMemo(() => {
    return loadingItens || loadingHist;
  }, [loadingHist, loadingItens]);

  const renderMouth = useCallback((mouth: number): string => {
    switch (mouth) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return '';
    }
  }, []);

  const stringHMToSegs = useCallback((val: string): number => {
    const [hour, min] = val.split(':');
    return (Number(hour) * 60 + Number(min)) * 60;
  }, []);

  const diffHMToSegs = useCallback(
    (hour1: string, hour2: string): number => {
      return stringHMToSegs(hour2) - stringHMToSegs(hour1);
    },
    [stringHMToSegs],
  );

  const dateRender = useMemo(() => {
    if (rangeInicial === '' || rangeFinal === '') {
      return '';
    }
    const [yearStart, mouthStart, dayStart] = rangeInicial.split('-');
    const [yearEnd, mouthEnd, dayEnd] = rangeFinal.split('-');
    if (rangeInicial === rangeFinal)
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    if (yearStart === yearEnd && mouthStart === mouthEnd) {
      return `${dayStart} a ${dayEnd} de ${renderMouth(
        Number(mouthStart),
      )} de ${yearStart}`;
    }
    if (yearStart === yearEnd && mouthStart !== mouthEnd) {
      return `${dayStart} de ${renderMouth(
        Number(mouthStart),
      )} a ${dayEnd} de ${renderMouth(Number(mouthEnd))} de ${yearStart}`;
    }
    return `${dayStart} de ${renderMouth(
      Number(mouthStart),
    )} de ${yearStart} a ${dayEnd} de ${renderMouth(
      Number(mouthEnd),
    )} de ${yearEnd}`;
  }, [rangeFinal, rangeInicial, renderMouth]);

  const dadosOcorrencias = useMemo((): NumberOcorrencia[] => {
    const qtdOcorrenc: NumberOcorrencia[] = [];

    if (historic.length === 0) return qtdOcorrenc;

    const hist = historic
      .filter((i) => {
        if (filter.companyId.length > 0) {
          return filter.companyId.find(
            (comp) => i.Agendamento.companyId === comp.value,
          );
        }
        return true;
      })
      .filter((i) => {
        if (filter.userId.length > 0) {
          return filter.userId.find((user) => i.userId === user.value);
        }
        return true;
      })
      .filter((i) => {
        if (filter.rondaId.length > 0) {
          return filter.rondaId.find(
            (rond) => i.Agendamento.agendaId === rond.value,
          );
        }
        return true;
      })
      .filter((i) => {
        if (filter.vistoriaId.length > 0) {
          return filter.vistoriaId.find((vist) => i.vistoriaId === vist.value);
        }
        return true;
      });

    empresas.forEach((emp) => {
      const value = hist.filter(
        (i) => i.Agendamento.companyId === emp.ID && i.ocorrencia !== '',
      ).length;

      if (value !== 0) {
        const obj: NumberOcorrencia = {
          companyId: emp.ID,
          id: emp.NOME,
          value,
        };
        qtdOcorrenc.push(obj);
      }
    });

    return qtdOcorrenc;
  }, [
    empresas,
    filter.companyId,
    filter.rondaId,
    filter.userId,
    filter.vistoriaId,
    historic,
  ]);

  const dadosActiviesRorN = useMemo((): ActiveRealizNotRealiz[] => {
    const dados: ActiveRealizNotRealiz[] = [];

    if (historicAll.length === 0) {
      return dados;
    }
    empresas.forEach((emp) => {
      let notDone = 0;
      let done = 0;
      historicAll
        .filter((i) => i.companyId === emp.ID)
        .forEach((hist) => {
          const aux = hist.histTarefas.filter((i) => !i.createdAt).length;
          notDone += aux;
          done += hist.histTarefas.length - aux;
        });

      if (done !== 0 || notDone !== 0) {
        const obj: ActiveRealizNotRealiz = {
          'não realizadas': notDone,
          companyId: emp.ID,
          id: emp.NOME,
          realizadas: done,
        };
        dados.push(obj);
      }
    });

    return dados;
  }, [empresas, historicAll]);

  const dadosPrevistRealiz = useMemo((): PrevistRealiz[] => {
    const dados: PrevistRealiz[] = [];

    const hist: HistoricToGrafs[] = historicAll
      .map((item) => {
        return {
          ...item,
          histTarefas: item.histTarefas.filter(
            (i) => !!i.timeIni && !!i.timeEnd,
          ),
        };
      })
      .filter((i) => i.histTarefas.length > 0);
    if (hist.length > 0) {
      empresas.forEach((emp) => {
        let prevSegs = 0;
        // let numberAgens = 0;
        let realSegs = 0;
        // let numberTarfs = 0;

        hist
          .filter((i) => i.companyId === emp.ID)
          .forEach((h) => {
            if (h.agendEnd !== '' && h.agendIni !== '') {
              const diffSegsAgend = diffHMToSegs(h.agendIni, h.agendEnd);
              // numberAgens += 1;
              prevSegs += diffSegsAgend;
            }

            const find = h.histTarefas.find((i) => i.timeIni && i.timeEnd);
            if (find) {
              const diffSegsDone =
                (new Date(find.timeEnd).getTime() -
                  new Date(find.timeIni).getTime()) /
                1000;
              // numberTarfs += 1;
              realSegs += diffSegsDone;
            }
          });

        if (prevSegs !== 0 || realSegs !== 0) {
          const obj: PrevistRealiz = {
            companyId: emp.ID,
            id: emp.NOME,
            previsto: prevSegs,
            // numberAgens === 0
            //   ? prevSegs
            //   : Number((prevSegs / numberAgens).toFixed(1)),
            realizado: realSegs,
            // numberTarfs === 0
            //   ? realSegs
            //   : Number((realSegs / numberTarfs).toFixed(1)),
          };
          dados.push(obj);
        }
      });
    }

    return dados;
  }, [diffHMToSegs, empresas, historicAll]);

  const dadosQtdRondasVig = useMemo((): RondasForVig[] => {
    const dados: RondasForVig[] = [];

    if (historicAll.length > 0) {
      users.forEach((user) => {
        let done = 0;
        const total = historicAll
          .filter((hist) => hist.User.id === user.id)
          .map((i) => {
            done += i.status !== 'Não Realizado' ? 1 : 0;
            return i;
          }).length;

        if (total !== 0) {
          const obj: RondasForVig = {
            feitas: done,
            id: user.nome,
            total,
            userId: user.id,
          };
          dados.push(obj);
        }
      });
    }

    return dados;
  }, [historicAll, users]);

  const dadosTMedioVig = useMemo((): AverageTimeVig[] => {
    const dados: AverageTimeVig[] = [];

    if (historicAll.length > 0) {
      users.forEach((user) => {
        let time = 0;
        let qtdRond = 0;
        historicAll
          .filter((hist) => hist.User.id === user.id)
          .forEach((h) => {
            const find = h.histTarefas.find((i) => i.timeIni && i.timeEnd);
            if (find) {
              const diffSegsDone =
                (new Date(find.timeEnd).getTime() -
                  new Date(find.timeIni).getTime()) /
                1000;

              time += diffSegsDone;
              qtdRond += 1;
            }
          });

        if (qtdRond > 0) {
          const obj: AverageTimeVig = {
            id: user.nome,
            userId: user.id,
            value: Number((time / qtdRond).toFixed(1)),
          };
          dados.push(obj);
        }
      });
    }

    return dados;
  }, [historicAll, users]);

  const verificarDados = useCallback((): boolean => {
    return (
      dadosOcorrencias.length > 0 ||
      dadosActiviesRorN.length > 0 ||
      dadosPrevistRealiz.length > 0 ||
      dadosQtdRondasVig.length > 0 ||
      dadosTMedioVig.length > 0
    );
  }, [
    dadosActiviesRorN.length,
    dadosOcorrencias.length,
    dadosPrevistRealiz.length,
    dadosQtdRondasVig.length,
    dadosTMedioVig.length,
  ]);

  const handlePDF = useCallback((): void => {
    if (verificarDados()) {
      const doc = new jsPDF(); // eslint-disable-line
      doc.setFontSize(18);
      doc.setTextColor(0, 0, 0);
      doc.setFontType('bold');
      doc.text('Resumo', 103, 20, 'center');
      let posY: number;
      posY = 30;
      if (dadosOcorrencias.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Número de ocorrências', 103, 40, 'center');
        dadosOcorrencias.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontSize(14);
          doc.setFontType('normal');
          doc.text(
            `${res.id}: ${res.value} ${
              res.value === 1 ? 'ocorrência' : 'ocorrências'
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosOcorrencias.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosActiviesRorN.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Atividades feitas e não feitas', 103, posY, 'center');
        dadosActiviesRorN.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id} - feitas: ${res.realizadas} ${
              res.realizadas > 0 ? 'atividades' : 'atividade'
            }`,
            10,
            posY,
            'left',
          );
          posY += 6;
          doc.text(
            `${res.id} - não feitas: ${res['não realizadas']} ${
              res['não realizadas'] > 0 ? 'atividades' : 'atividade'
            }`,
            10,
            posY,
            'left',
          );
          if (index === dadosActiviesRorN.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosPrevistRealiz.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Tempo Previsto X Realizado', 103, posY, 'center');
        dadosPrevistRealiz.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          const minPrev = Math.floor((res.previsto ?? 0) / 60);
          const segPrev = Math.floor((res.previsto ?? 0) - minPrev * 60);
          const prev = `${minPrev}min:${segPrev}seg`;
          const minRea = Math.floor((res.realizado ?? 0) / 60);
          const segRea = Math.floor((res.realizado ?? 0) - minRea * 60);
          const real = `${minRea}min:${segRea}seg`;
          doc.text(`${res.id} - previsto: ${prev}`, 10, posY, 'left');
          posY += 6;
          doc.text(`${res.id} - realizado: ${real}`, 10, posY, 'left');
          if (index === dadosPrevistRealiz.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosQtdRondasVig.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text(
          'Quantas ronda feitas por vigilante e total',
          103,
          posY,
          'center',
        );
        dadosQtdRondasVig.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          doc.text(
            `${res.id}: ${res.feitas} de ${res.total}`,
            10,
            posY,
            'left',
          );
          if (index === dadosQtdRondasVig.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      if (dadosTMedioVig.length > 0) {
        doc.setFontType('bold');
        doc.setFontSize(16);
        doc.text('Tempo médio das atividades', 103, posY, 'center');
        dadosTMedioVig.forEach((res, index: number) => {
          index === 0 ? (posY += 22) : (posY += 10);
          doc.setFontType('normal');
          doc.setFontSize(14);
          const min = Math.floor((res.value ?? 0) / 60);
          const seg = Math.floor((res.value ?? 0) - min * 60);
          const time = `${min} minutos e ${seg} segundos`;
          doc.text(`${res.id}: ${time}`, 10, posY, 'left');
          if (index === dadosTMedioVig.length - 1) posY += 30;
        });
        if (posY > 190) {
          doc.addPage();
          posY = 20;
        }
      }
      doc.autoPrint();
      doc.output('dataurlnewwindow');
    } else {
      addToast({
        type: 'error',
        title: 'Sem dados',
        description: 'Não há dados para exportar',
      });
    }
  }, [
    addToast,
    dadosActiviesRorN,
    dadosOcorrencias,
    dadosPrevistRealiz,
    dadosQtdRondasVig,
    dadosTMedioVig,
    verificarDados,
  ]);

  const handleExcel = useCallback((): void => {
    if (verificarDados()) {
      const workbook = XLSX.utils.book_new();
      if (dadosOcorrencias.length > 0) {
        const wkDadosOcorrencia = XLSX.utils.json_to_sheet(
          dadosOcorrencias.map((val) => ({
            STATUS: val.id,
            QUANTIDADE: val.value,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkDadosOcorrencia,
          'Número de ocorrências',
        );
      }
      if (dadosActiviesRorN.length > 0) {
        const wkDadosActivesRonN = XLSX.utils.json_to_sheet(
          dadosActiviesRorN.map((val) => ({
            NOME: val.id,
            FEITAS: val.realizadas,
            'NÃO FEITAS': val['não realizadas'],
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkDadosActivesRonN,
          'Atividades feitas e não feitas',
        );
      }
      if (dadosPrevistRealiz.length > 0) {
        const wkDadosPrevistRealiz = XLSX.utils.json_to_sheet(
          dadosPrevistRealiz.map((val) => {
            const minPrev = Math.floor((val.previsto ?? 0) / 60);
            const segPrev = Math.floor((val.previsto ?? 0) - minPrev * 60);
            const prev = `${minPrev}m${segPrev}s`;
            const minRea = Math.floor((val.realizado ?? 0) / 60);
            const segRea = Math.floor((val.realizado ?? 0) - minRea * 60);
            const real = `${minRea}m${segRea}s`;

            const obj = {
              NOME: val.id,
              PREVISTO: prev,
              REALIADO: real,
            };
            return obj;
          }),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkDadosPrevistRealiz,
          'Tempo Previsto X Realizado',
        );
      }
      if (dadosQtdRondasVig.length > 0) {
        const wkQtdRondasVig = XLSX.utils.json_to_sheet(
          dadosQtdRondasVig.map((val) => ({
            NOME: val.id,
            FEITAS: val.feitas,
            TOTAL: val.total,
          })),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkQtdRondasVig,
          'Quantas ronda por vigilante',
        );
      }
      if (dadosTMedioVig.length > 0) {
        const wkAtividadeMedia = XLSX.utils.json_to_sheet(
          dadosTMedioVig.map((val) => {
            const min = Math.floor((val.value ?? 0) / 60);
            const seg = Math.floor((val.value ?? 0) - min * 60);
            const time = `${min}m${seg}s`;

            const obj = {
              NOME: val.id,
              TEMPO: time,
            };
            return obj;
          }),
        );
        XLSX.utils.book_append_sheet(
          workbook,
          wkAtividadeMedia,
          'Tempo da ronda por vigilante',
        );
      }
      XLSX.writeFile(workbook, 'AuditoriaRonda.xlsx');
    } else {
      addToast({
        type: 'error',
        title: 'Sem dados',
        description: 'Não há dados para exportar',
      });
    }
  }, [
    addToast,
    dadosActiviesRorN,
    dadosOcorrencias,
    dadosPrevistRealiz,
    dadosQtdRondasVig,
    dadosTMedioVig,
    verificarDados,
  ]);

  return (
    <Container>
      {showFilter && (
        <ModalFilterAuditoria
          isVisible={showFilter}
          onClose={() => setShowFilter(false)}
          empresas={empresas.map((i) => ({ label: i.NOME, value: i.ID }))}
          users={users.map((i) => ({ label: i.nome, value: i.id }))}
          filterPrin={filter}
          setFilterPrin={setFilter}
        />
      )}
      <ContainerTitle>
        <header>
          <h1>Auditoria Ronda</h1>

          <Tooltip
            title={
              'Nessa página você tem acesso a visualização dos gráficos ' +
              'para fim de análises estratégicas.'
            }
            arrow
            TransitionComponent={Zoom}
          >
            <span>
              <AiOutlineQuestionCircle />
            </span>
          </Tooltip>
        </header>
        <div>
          {(filter.categoriaId.length > 0 ||
            filter.companyId.length > 0 ||
            filter.rondaId.length > 0 ||
            filter.setorId.length > 0 ||
            filter.userId.length > 0 ||
            filter.vistoriaId.length > 0) && (
            <button
              type="button"
              className="danger"
              title="Remover Filtros"
              onClick={() => {
                setFilter({
                  categoriaId: [],
                  companyId: [],
                  rondaId: [],
                  setorId: [],
                  userId: [],
                  vistoriaId: [],
                });
              }}
            >
              <FaTimes size={20} />
            </button>
          )}
          <button
            type="button"
            className="danger"
            title="Diminuir fonte"
            onClick={() => setFontSizeGraph((s) => s - 1)}
          >
            <FaFont size={15} />
          </button>
          <button
            type="button"
            title="Aumentar fonte"
            onClick={() => setFontSizeGraph((s) => s + 1)}
          >
            <FaFont size={20} />
          </button>
          <button
            type="button"
            className="btnsvg"
            onClick={() => setShowFilter(true)}
          >
            <FiFilter size={20} />
          </button>
          <button
            type="button"
            className="btnsvg"
            onClick={() => setShowCalendar(!showCalendar)}
          >
            <FaCalendar size={20} />
          </button>
        </div>
      </ContainerTitle>
      <div className="container">
        {showCalendar && (
          <ContainerSearch className="container-searcher" isFilterDateInterval>
            <Search
              onSearch={(value: string) => {
                setSearchDataInicial(value);
              }}
              value={searchDataInicial}
              nomePlaceHolder="Buscar data inicial"
              type="date"
            />

            <Search
              onSearch={(value: string) => {
                setSearchDataFinal(value);
              }}
              value={searchDataFinal}
              nomePlaceHolder="Buscar data final"
              type="date"
            />
            <button type="button" onClick={handleSearch}>
              <FaSearch size={20} />
            </button>
          </ContainerSearch>
        )}
        <HeaderDate>
          <h2>{dateRender}</h2>
          <div>
            <button
              title="Exportar para PDF"
              type="button"
              className="danger"
              onClick={handlePDF}
            >
              <VscFilePdf size={22} />
            </button>
            <button
              title="Exportar para Excel"
              type="button"
              onClick={handleExcel}
            >
              <RiFileExcel2Line size={22} />
            </button>
          </div>
        </HeaderDate>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">Número de ocorrências</span>
            {loading && <Loading component />}
            {dadosOcorrencias.length > 0 && !loading && (
              <ResponsivePie
                data={dadosOcorrencias}
                margin={{ top: 60, right: 40, bottom: 60, left: 40 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: 0,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosOcorrencias.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              Atividades realizadas/não realizadas
            </span>
            {loading && <Loading component />}
            {dadosActiviesRorN.length > 0 && !loading && (
              <ResponsiveBar
                data={dadosActiviesRorN}
                // margin={{ top: 30, right: 130, bottom: 60, left: -10 }}
                margin={{ top: 60, right: 40, bottom: 60, left: 40 }}
                borderWidth={2}
                keys={['realizadas', 'não realizadas']}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                groupMode="grouped"
                colorBy="indexValue"
                axisLeft={null}
                axisBottom={null}
                borderRadius={5}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                // valueFormat={
                //   (d) =>
                //     d !== 0
                //       ? `${Math.floor((d ?? 0) / 3600000)}h${Math.floor(
                //           ((d ?? 0) / 60000) % 60,
                //         )}m`
                //       : ''
                //   // eslint-disable-next-line react/jsx-curly-newline
                // }
                // legends={[
                //   {
                //     dataFrom: 'indexes',
                //     anchor: 'bottom-right',
                //     direction: 'column',
                //     justify: false,
                //     translateX: 140,
                //     translateY: 0,
                //     itemsSpacing: 0,
                //     itemWidth: 140,
                //     itemHeight: 33,
                //     itemDirection: 'bottom-to-top',
                //     itemOpacity: 0.85,
                //     symbolSize: 7,
                //     effects: [
                //       {
                //         on: 'hover',
                //         style: {
                //           itemOpacity: 1,
                //         },
                //       },
                //     ],
                //   },
                // ]}
              />
            )}
            {dadosActiviesRorN.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">Tempo Previsto X Realizado</span>
            {loading && <Loading component />}
            {dadosPrevistRealiz.length > 0 && !loading && (
              <ResponsiveBar
                data={dadosPrevistRealiz}
                margin={{ top: 60, right: 40, bottom: 60, left: 40 }}
                borderWidth={2}
                keys={['previsto', 'realizado']}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                groupMode="grouped"
                colorBy="indexValue"
                axisLeft={null}
                axisBottom={null}
                borderRadius={5}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                valueFormat={(d) => {
                  const hour = Math.floor((d ?? 0) / 3600);
                  const min = Math.floor((d ?? 0) / 60 - hour * 60);
                  const seg = Math.floor((d ?? 0) - (hour * 60 + min) * 60);
                  // console.log(d, `${hour}h${min}m${seg}s`);
                  if (hour === 0) {
                    return `${min}m${seg}s`;
                  }
                  return `${hour}h${min}m${seg}s`;
                }}
                legends={
                  !isDesktop
                    ? [
                        {
                          dataFrom: 'indexes',
                          anchor: 'bottom-right',
                          direction: 'column',
                          justify: false,
                          translateX: 140,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemWidth: 140,
                          itemHeight: 33,
                          itemDirection: 'bottom-to-top',
                          itemOpacity: 0.85,
                          symbolSize: 7,
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosPrevistRealiz.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              Quantas ronda feitas por vigilante e total
            </span>
            {loading && <Loading component />}
            {dadosQtdRondasVig.length > 0 && !loading && (
              <ResponsiveBar
                data={dadosQtdRondasVig}
                // margin={{ top: 30, right: 130, bottom: 60, left: -10 }}
                margin={{ top: 60, right: 40, bottom: 60, left: 40 }}
                borderWidth={2}
                keys={['feitas', 'total']}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                colors={{ scheme: 'pastel1' }}
                groupMode="grouped"
                colorBy="indexValue"
                axisLeft={null}
                axisBottom={null}
                borderRadius={5}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                // valueFormat={
                //   (d) =>
                //     d !== 0
                //       ? `${Math.floor((d ?? 0) / 3600000)}h${Math.floor(
                //           ((d ?? 0) / 60000) % 60,
                //         )}m`
                //       : ''
                //   // eslint-disable-next-line react/jsx-curly-newline
                // }
                legends={
                  !isDesktop
                    ? [
                        {
                          dataFrom: 'indexes',
                          anchor: 'bottom-right',
                          direction: 'column',
                          justify: false,
                          translateX: 140,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemWidth: 140,
                          itemHeight: 33,
                          itemDirection: 'bottom-to-top',
                          itemOpacity: 0.85,
                          symbolSize: 7,
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosQtdRondasVig.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-6 h-100 d-flex flex-column">
            <span className="text-center">
              Tempo médio da ronda por vigilante
            </span>
            {loading && <Loading component />}
            {dadosTMedioVig.length > 0 && !loading && (
              <ResponsivePie
                data={dadosTMedioVig}
                // margin={{ top: 40, right: 0, bottom: 40, left: 120 }}
                margin={{ top: 60, right: 40, bottom: 60, left: 40 }}
                innerRadius={0.5}
                colors={{ scheme: 'pastel1' }}
                arcLinkLabelsSkipAngle={10}
                arcLabelsSkipAngle={10}
                arcLabelsComponent={({ label, style }: any) => (
                  <animated.g
                    transform={style.transform}
                    style={{ pointerEvents: 'none' }}
                  >
                    <text
                      textAnchor="middle"
                      dominantBaseline="central"
                      fill={style.textColor}
                      style={{
                        fontSize: 13,
                        color: '#000',
                        fontWeight: 400,
                      }}
                    >
                      <tspan>{label}</tspan>
                      {/* <tspan x={0} y={11}>
                        min
                      </tspan> */}
                    </text>
                  </animated.g>
                )}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                sortByValue
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                enableArcLinkLabels={false}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                valueFormat={(d) => {
                  const hour = Math.floor((d ?? 0) / 3600);
                  const min = Math.floor((d ?? 0) / 60 - hour * 60);
                  const seg = Math.floor((d ?? 0) - (hour * 60 + min) * 60);
                  // console.log(d, `${hour}h${min}m${seg}s`);
                  if (hour === 0) {
                    return `${min}m${seg}s`;
                  }
                  return `${hour}h${min}m${seg}s`;
                }}
                legends={
                  !isDesktop
                    ? [
                        {
                          anchor: 'top-left',
                          direction: 'column',
                          justify: false,
                          translateX: -130,
                          translateY: 0,
                          itemWidth: 100,
                          itemHeight: 25,
                          itemsSpacing: 0,
                          symbolSize: 25,
                          itemDirection: 'left-to-right',
                        },
                      ]
                    : undefined
                }
              />
            )}
            {dadosTMedioVig.length === 0 && !loading && (
              <SemDados>
                <FiInfo color="#B0B0B0" size={40} />
                <span>Sem dados</span>
              </SemDados>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};
