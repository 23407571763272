import styled from 'styled-components';

import { Page, Text, View, Document } from '@react-pdf/renderer';

export const Wrapper = styled(Document)`
  padding-top: 35;
  padding-bottom: 65;
  width: 794px;
  padding: 40px;
`;

export const PageContainer = styled(Page)`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  width: 100%;
`;

export const CreatedAtText = styled(Text)`
  font-size: 12px;
  color: black;
  margin-left: auto;
`;

export const HeaderContainer = styled(View)`
  font-size: 12px;
  color: black;
  margin-left: auto;
`;
