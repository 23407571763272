import React, { useCallback, useState, useMemo } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import Swal from 'sweetalert2';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import ModalConexao from '../../components/ModalConexao';
import DropDownPagination from '../../components/DropDownPagination';
import HeaderTable from '../../components/HeaderTable';
import PaginationComponent from '../../components/Pagination';
import { useAuth } from '../../hooks/auth';

import {
  Container,
  ContainerTable,
  ContainerPagination,
  ContainerSearch,
  ContainerSemResultados,
  ContentSearch,
  SearchCustom,
} from './styles';

interface ResponseGet {
  ABN_DESC: string;
  CENTRO_CUSTO: string;
  COD_CLIENTE: string;
  CPF: string;
  DESC_CENTRO_CUSTO: string;
  FILIAL: string;
  FUNCAO: string;
  Id_EmpresaFilial: number;
  LOCAL: string;
  NOME_CLIENTE: string;
  NOME_EMPRESA: string;
  NOME_FUNCIONARIO: string;
  POSTO: string;
  createdAt: string;
  dataFinal: string;
  dataInicial: string;
  dataManutencao: string;
  descMotivo: string;
  horaFinal: string;
  horaFinalTratada: string;
  horaInicial: string;
  horaInicialTratada: string;
  id: number;
  matricula: string;
  motivoManutencaoId: number;
  observacao: string;
  updatedAt: string;
  ENVIADO_PROTHEUS: boolean;
}

const headers = [
  { name: 'Filial', field: 'FILIAL', sortable: true },
  { name: 'Nome', field: 'NOME_FUNCIONARIO', sortable: true },
  { name: 'Matrícula', field: 'matricula', sortable: true },
  { name: 'Função', field: 'FUNCAO', sortable: true },
  { name: 'Descrição', field: 'ABN_DESC', sortable: true },
  { name: 'Data Inicial', field: 'dataInicial', sortable: true },
  { name: 'Data Final', field: 'dataFinal', sortable: true },
  { name: 'Data da Manutenção', field: 'dataManutencao', sortable: true },
  { name: 'Descrição do Motivo', field: 'descMotivo', sortable: true },
  { name: 'Hora Inicial', field: 'horaInicial', sortable: true },
  { name: 'Hora Final', field: 'horaFinal', sortable: true },
  { name: 'Observação', field: 'observacao', sortable: true },
  {
    name: 'Enviado para o Protheus',
    field: 'ENVIADO_PROTHEUS',
    sortable: true,
  },
];

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '30', id: '30' },
  { valor: '40', id: '40' },
  { valor: '50', id: '50' },
  { valor: 'Todos', id: '1' },
];

const CadastroApontamento: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [isFocusedDateManut, setIsFocusedDateManut] = useState('');
  const [searchMatricula, setSearchMatricula] = useState('');
  const [searchMatriculaTable, setSearchMatriculaTable] = useState('');
  const [searchFilial, setSearchFilial] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchFuncao, setSearchFuncao] = useState('');
  const [searchDescricao, setSearchDescricao] = useState('');
  const [searchDescricaoDoMotivo, setSearchDescricaoDoMotivo] = useState('');
  const [searchObservacao, setSearchObservacao] = useState('');
  const [searchDataManutencao, setSearchDataManutencao] = useState('');
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [isFocusedDateInicial, setIsFocusedDateInicial] = useState('');
  const [isFocusedDateFinal, setIsFocusedDateFinal] = useState('');
  const [searchDataInicialBtn, setSearchDataInicialBtn] = useState('');
  const [searchDataFinalBtn, setSearchDataFinalBtn] = useState('');

  const handleSearchApontamento = useCallback(
    (matricula, dataInit, dataFim) => {
      if (dataInit.trim() !== '' && dataFim.trim() !== '') {
        setLoading(true);

        const matriculaFormat = matricula === '' ? 0 : matricula;

        api
          .get(
            `/apontamento?matricula=${matriculaFormat}&dataInicio=${searchDataInicialBtn}&dataFim=${searchDataFinalBtn}&companyId=${
              empresaPrincipal.length > 0
                ? empresaPrincipal[0].Id_Empresas
                : '0'
            }`,
          )
          .then((data) => {
            setLoading(false);

            if (data.data.batidas.length === 0) {
              Swal.fire({
                icon: 'info',
                title: 'Apontamento não encontrado!',
                text:
                  'Não existem registros de apontamentos relacionados à matrícula ou intervalo de datas informados.',
              });
            } else {
              setResponse(data.data.batidas);
            }
          })
          .catch(() => {
            setLoading(false);
            if (navigator.onLine) {
              Swal.fire({
                icon: 'info',
                title: 'Apontamento não encontrado!',
                text:
                  'Não existem registros de apontamentos relacionados à matrícula ou intervalo de datas informados.',
              });
            }
          });
      } else if (navigator.onLine) {
        Swal.fire({
          icon: 'info',
          title: 'Campo(s) vazio(s)!',
          text: 'Favor preecher os campos Data Inicial e Data Final.',
        });
      }
    },
    [empresaPrincipal, searchDataFinalBtn, searchDataInicialBtn],
  );

  const handleDateFormat = useCallback((data) => {
    const dataFormatTemp = data.split('-').reverse().join('/');
    return dataFormatTemp;
  }, []);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (searchMatriculaTable) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.matricula
          .toString()
          .toLowerCase()
          .includes(searchMatriculaTable.toString()),
      );
    }

    if (searchFilial) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.FILIAL.toString().toLowerCase().includes(searchFilial.toString()),
      );
    }

    if (searchNome) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME_FUNCIONARIO.toString()
          .toLowerCase()
          .includes(searchNome.toString()),
      );
    }

    if (searchFuncao) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.FUNCAO.toString().toLowerCase().includes(searchFuncao),
      );
    }

    if (searchDescricao) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.ABN_DESC.toString().toLowerCase().includes(searchDescricao),
      );
    }

    if (searchDataManutencao) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        moment(res.dataManutencao)
          .format('DD/MM/YYYY')
          .toString()
          .toLowerCase()
          .includes(searchDataManutencao),
      );
    }

    if (searchDescricaoDoMotivo) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.descMotivo &&
          res.descMotivo
            .toString()
            .toLowerCase()
            .includes(searchDescricaoDoMotivo),
      );
    }

    if (searchObservacao) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.observacao.toString().toLowerCase().includes(searchObservacao),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    searchMatriculaTable,
    searchFilial,
    searchNome,
    searchFuncao,
    searchDescricao,
    searchDataManutencao,
    searchDescricaoDoMotivo,
    searchObservacao,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  return (
    <>
      <ModalConexao />
      <Container>
        <ContainerSearch>
          <div className="divSearchButtonGeral">
            <SearchCustom
              type="number"
              onSearch={(value: string) => {
                setSearchMatricula(value);
              }}
              nomePlaceHolder="Matrícula"
            />
            <SearchCustom
              type={isFocusedDateInicial || 'text'}
              onSearch={(value: string) => {
                setSearchDataInicialBtn(value);
              }}
              onFocus={() => {
                setIsFocusedDateInicial('date');
              }}
              onBlur={() => {
                searchDataInicialBtn === '' && setIsFocusedDateInicial('text');
              }}
              nomePlaceHolder="Data Inicial"
            />
            <SearchCustom
              type={isFocusedDateFinal || 'text'}
              onSearch={(value: string) => {
                setSearchDataFinalBtn(value);
              }}
              onFocus={() => {
                setIsFocusedDateFinal('date');
              }}
              onBlur={() => {
                searchDataFinalBtn === '' && setIsFocusedDateFinal('text');
              }}
              nomePlaceHolder="Data Final"
            />
            <button
              type="button"
              onClick={
                () =>
                  handleSearchApontamento(
                    searchMatricula,
                    searchDataInicialBtn,
                    searchDataFinalBtn,
                  ) // ////
              }
            >
              <AiOutlineSearch size={22} />
            </button>
          </div>
        </ContainerSearch>

        <ContainerSearch>
          <ContentSearch>
            <div className="divSearchGeral">
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchFilial(value);
                }}
                nomePlaceHolder="Buscar Filial"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchNome(value);
                }}
                nomePlaceHolder="Buscar Nome"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchMatriculaTable(value);
                }}
                nomePlaceHolder="Buscar Matrícula"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchFuncao(value);
                }}
                nomePlaceHolder="Buscar Função"
              />
            </div>
            <div className="divSearchGeral">
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchDescricao(value);
                }}
                nomePlaceHolder="Buscar Descrição"
              />
              <SearchCustom
                type={isFocusedDateManut || 'text'}
                onSearch={(value: string) => {
                  setSearchDataManutencao(handleDateFormat(value));
                }}
                onFocus={() => {
                  setIsFocusedDateManut('date');
                }}
                onBlur={() => {
                  searchDataManutencao === '' && setIsFocusedDateManut('text');
                }}
                nomePlaceHolder="Buscar Data da Manutenção"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchDescricaoDoMotivo(value);
                }}
                nomePlaceHolder="Buscar Descrição do Motivo"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchObservacao(value);
                }}
                nomePlaceHolder="Buscar Observação"
              />
            </div>
          </ContentSearch>
        </ContainerSearch>

        {response.length === 0 || totalItems === 0 ? (
          <ContainerSemResultados>
            {response.length === 0 ? (
              <h2>
                Favor preecher os campos Data Inicial, Data Final e/ou Matrícula
                para realizar a busca.
              </h2>
            ) : (
              <h2>Não existem dados referentes aos filtros informados.</h2>
            )}
          </ContainerSemResultados>
        ) : (
          <div className="divTable">
            <ContainerTable>
              <table id="tableListagemApontamentos">
                <HeaderTable
                  headers={headers}
                  onSorting={
                    (field: string, order: string) =>
                      setSorting({ field, order }) // ////
                  }
                />

                <tbody>
                  {responseData.map((res) => (
                    <tr key={uuid()}>
                      <td>
                        {res.FILIAL !== null && res.FILIAL.trim() !== ''
                          ? res.FILIAL
                          : '-'}
                      </td>
                      <td>
                        {res.NOME_FUNCIONARIO !== null &&
                        res.NOME_FUNCIONARIO.trim() !== ''
                          ? res.NOME_FUNCIONARIO
                          : '-'}
                      </td>
                      <td>
                        {res.matricula !== null && res.matricula.trim() !== ''
                          ? res.matricula
                          : '-'}
                      </td>
                      <td>
                        {res.FUNCAO !== null && res.FUNCAO.trim() !== ''
                          ? res.FUNCAO
                          : '-'}
                      </td>
                      <td>
                        {res.ABN_DESC !== null && res.ABN_DESC.trim() !== ''
                          ? res.ABN_DESC
                          : '-'}
                      </td>
                      <td>
                        {res.dataInicial !== null &&
                        res.dataInicial.trim() !== ''
                          ? moment(res.dataInicial).format('DD/MM/YYYY')
                          : '-'}
                      </td>
                      <td>
                        {res.dataFinal !== null && res.dataFinal.trim() !== ''
                          ? moment(res.dataFinal).format('DD/MM/YYYY')
                          : '-'}
                      </td>
                      <td>
                        {res.dataManutencao !== null &&
                        res.dataManutencao.trim() !== ''
                          ? moment(res.dataManutencao).format('DD/MM/YYYY')
                          : '-'}
                      </td>
                      <td>{res.descMotivo !== null ? res.descMotivo : '-'}</td>
                      <td>
                        {res.horaInicialTratada !== null &&
                        res.horaInicialTratada.trim() !== ''
                          ? res.horaInicialTratada
                          : '-'}
                      </td>
                      <td>
                        {res.horaFinalTratada !== null &&
                        res.horaFinalTratada.trim() !== ''
                          ? res.horaFinalTratada
                          : '-'}
                      </td>
                      <td>
                        {res.observacao !== null && res.observacao.trim() !== ''
                          ? res.observacao
                          : '-'}
                      </td>
                      <td>
                        {res.ENVIADO_PROTHEUS ? (
                          <>
                            <FiCheckCircle size={24} color="#00e600" />
                            <span style={{ display: 'none' }}>Sim</span>
                          </>
                        ) : (
                          <>
                            <FiXCircle size={24} color="#ff0000" />
                            <span style={{ display: 'none' }}>Não</span>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>

            <ContainerPagination>
              {totalItems > 0 && (
                <>
                  <div className="divPaginacao">
                    {/* <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    /> */}
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />
                  </div>

                  <ReactHTMLTableToExcel
                    id="export-excel"
                    className="btn"
                    table="tableListagemApontamentos"
                    filename={`${searchMatricula}-listagem-apontamentos-${moment().format(
                      'DD-MM-YYYY',
                    )}`}
                    sheet="tablexls"
                    buttonText="Exportar Excel"
                  />
                </>
              )}
            </ContainerPagination>
          </div>
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default CadastroApontamento;
