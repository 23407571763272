import { createStore } from 'redux';
import rootReducer from './modules/rootReducer';
import { IStateResultFilter } from './modules/map/types';
import { IStateResultQueryDate } from './modules/mapQueryDate/types';
import { IStateClientResultFilter } from './modules/clientRegister/types';
import { StateDrawer } from './modules/drawer/types';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IState {
  map: IStateResultFilter;
  mapQueryDate: IStateResultQueryDate;
  clientRegister: IStateClientResultFilter;
  drawer: StateDrawer;
}

const store = createStore(rootReducer);

export default store;
