import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  widthProps?: string;
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => props.widthProps};

  background: ${(props) => props.theme.colors.secondary};
  border: 1px solid ${(props) => props.theme.colors.gray1};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 16px;

  display: flex;
  align-items: center;

  & + p {
    margin-top: 18px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: ${props.theme.colors.error};
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border: 1px solid ${props.theme.colors.primary};
    `}

  select {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    border: none;
    background-color: transparent;
    flex: 1;
    display: flex;
    align-items: center;

    color: ${(props) => props.theme.colors.black};

    &::placeholder {
      color: ${(props) => props.theme.colors.gray9};
    }

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.white} inset;
  }

  select:-webkit-autofill {
    -webkit-text-fill-color: ${(props) => props.theme.colors.gray9} !important;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    width: 20px;
    height: 20px;
    margin: 0;
    color: ${(props) => props.theme.colors.error};
  }

  span {
    background: ${(props) => props.theme.colors.error};
    color: ${(props) => props.theme.colors.white};

    &::before {
      border-color: ${(props) => props.theme.colors.error} transparent;
    }
  }
`;
