import styled from 'styled-components';

export const Container = styled.div`
  .daysWeek {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .selected {
      background-color: ${(props) => props.theme.colors.primary};
      h3 {
        color: ${(props) => props.theme.colors.white} !important;
      }
    }
    button {
      background-color: ${(props) => props.theme.colors.white4};
      color: ${(props) => props.theme.colors.gray3};
      border: none;
      width: 40px;
      height: 40px;

      font-size: 15px;

      display: flex;

      border-radius: 50%;
      h3 {
        margin: auto;
      }
    }
  }
`;
