import { shade } from 'polished';
import styled from 'styled-components';

export const ButtonClose = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.error};
    align-items: center;
    justify-content: center;
    svg {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const Container = styled.div`
  padding: 0px 74px 30px 74px;

  @media screen and (max-width: 600px) {
    padding: 22px;
  }

  h2 {
    color: ${(props) => props.theme.colors.black2};
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-align: center;
  }
  p {
    color: ${(props) => props.theme.colors.black2};
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }

  .dates {
    margin-top: 1rem;
    display: flex;
    column-gap: 1rem;
    width: 100%;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  .action {
    margin-top: 1rem;
    display: flex;
    column-gap: 1rem;
    width: 100%;

    button {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: none;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: ${(props) => props.theme.colors.white};
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        margin: 0px;
      }
    }

    .cancel {
      background-color: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.gray1};
      p {
        color: ${(props) => props.theme.colors.black2};
      }
      &:hover {
        background-color: ${(props) => shade(0.2, props.theme.colors.white)};
      }
    }
    .confirm {
      background-color: ${(props) => props.theme.colors.primary};
      p {
        color: ${(props) => props.theme.colors.white};
      }
      &:hover {
        background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
      }
    }
  }
`;

export const InputForm = styled.input`
  width: 100%;
  height: 50px;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black2};

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;

  border: 1px solid ${(props) => props.theme.colors.gray1};
  border-radius: 4px;
  padding-left: 16px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray1};
  box-sizing: border-box;
  border-radius: 4px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.colors.black2};
  margin-top: 1rem;
  margin-bottom: 41px;
`;
