import React, { useCallback, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { Filiais } from '../../models/Filiais';
import Button from '../../components/Button';
import ListRegistButton from '../../components/ListRegistButton';
import NewImportExel from '../../components/NewImportExel';
import NewInput from '../../components/NewInput';
import { NewSearch } from '../../components/NewSearch';
import PaginationComponent from '../../components/Pagination';
import ModalConexao from '../../components/ModalConexao';

import Table from '../../components/Table';
import { TitlePage } from '../../components/TitlePage/styled';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

import {
  Container,
  ContainerCadastro,
  DivLineBottom,
  HeaderForm,
  LabelInputForm,
} from './styles';

interface GetRiscos {
  id: number;
  name: string;
  companyId: number;
  createdAt: string;
}

const headers = [
  // { name: 'Identificador', field: 'id', sortable: true },
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Empresa', field: 'companyId', sortable: true },
  { name: 'Data de criação', field: 'createdAt', sortable: true },
  { name: 'Editar', field: 'editOption', sortable: true },
  { name: 'Remover', field: 'deleteOption', sortable: true },
];
const headersFilter = [{ name: 'Nome', field: 'name', sortable: true }];

const CadastroRiscos: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const { colors } = useContext(ThemeContext);
  const { go } = useHistory();
  const [filiais, setFiliais] = useState<Filiais[]>([]);
  // const [filiaisRiscos, setFiliaisRiscos] = useState(0);
  const [riscos, setRiscos] = useState<GetRiscos[]>([]);
  const [nameRisco, setNameRisco] = useState('');
  const [idRisco, setIdRisco] = useState(0);
  const [RiscosFiltered, setRiscosFiltered] = useState<GetRiscos[]>([]);
  const [cadastrar, setCadastrar] = useState(false);

  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const handleSubmit = useCallback(async () => {
    if (nameRisco !== '') {
      if (idRisco !== 0) {
        api
          .put('/riscos/', {
            id: idRisco,
            name: nameRisco,
            companyId: empresaPrincipal[0].ID,
          })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Risco cadastrada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              setCadastrar(false);
              // eslint-disable-next-line no-restricted-globals
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            console.log('Num foi não hein');
          });
      } else {
        api
          .post('/riscos/', {
            name: nameRisco,
            companyId: empresaPrincipal[0].ID,
          })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Risco cadastrada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              setCadastrar(false);
              // eslint-disable-next-line no-restricted-globals
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            console.log('Num foi não hein');
          });
      }
    }
  }, [empresaPrincipal, idRisco, nameRisco]);

  const getItems = useCallback(async () => {
    const RiscosGet = await api.get(
      `riscos?companyId=${empresaPrincipal[0].ID}`,
    );
    const FiliaisGet = await api.get(`/empresas/${empresaPrincipal[0].ID}`);
    setFiliais(FiliaisGet.data);
    setRiscos(RiscosGet.data);
    setRiscosFiltered(RiscosGet.data);
  }, [empresaPrincipal]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const handleSubmitEdit = (idRiscosEdit: number): void => {
    const setorCurrent = riscos.find(({ id }) => id === idRiscosEdit);

    setNameRisco(setorCurrent?.name!);
    setIdRisco(setorCurrent?.id!);
    setCadastrar(true);
  };

  const handleSubmitDelete = useCallback(
    (idRis: number): void => {
      Swal.fire({
        title: 'Tem certeza que deseja deletar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: colors.success,
        cancelButtonColor: colors.error,
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          const obj = { id: idRis };
          api
            .delete('/riscos', { data: obj })
            .then((resp) => {
              Swal.fire({
                icon: 'success',
                title: 'Risco deletedo com sucesso!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
              console.log('RESP', resp.data);
              setTimeout(() => {
                setCadastrar(false);
                go(0);
              }, 1000);
            })
            .catch((err) => {
              console.log('ERRR', err.message);
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro ao deletar Risco!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
            });
        }
      });
    },
    [colors.error, colors.success, go],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Riscos</TitlePage>
        <ListRegistButton
          object={{ list: 'Listar', regist: 'Registrar' }}
          setStateCadastrar={setCadastrar}
          stateCadastrar={cadastrar}
        />
        {!cadastrar && (
          <>
            <NewSearch
              arraySetState={setRiscosFiltered}
              arrayState={riscos}
              selectFilters={headersFilter}
            />
            <Table
              header={headers}
              filiais={filiais}
              body={RiscosFiltered.filter(
                (e, i) => i >= indexInitPg && i <= indexEndPg,
              )}
              tableId="TableRiscos"
              setEditId={(id) => {
                handleSubmitEdit(id);
              }}
              setRemoveId={(id) => {
                handleSubmitDelete(id);
              }}
            />
            <PaginationComponent
              arrayStateFiltered={RiscosFiltered}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />

            <NewImportExel fileName="Riscos" tableId="TableRiscos" />
          </>
        )}
        {cadastrar && (
          <ContainerCadastro>
            <HeaderForm>Cadastro de Risco</HeaderForm>
            <DivLineBottom />

            <LabelInputForm>Nome</LabelInputForm>
            <NewInput
              name="Riscos"
              placeholder="Digite aqui..."
              setStateValue={setNameRisco}
              stateValue={nameRisco}
            />
            <div className="buttonContainer">
              <Button id="cancel">Cancelar</Button>
              <Button onClick={handleSubmit}>Salvar</Button>
            </div>
          </ContainerCadastro>
        )}
      </Container>
    </>
  );
};

export default CadastroRiscos;
