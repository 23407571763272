import styled from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  title?: string;
  className?: string;
  status?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  div {
    background-color: ${(props) => {
      if (props.className === 'classPendente') {
        return '#ff9900';
      }
      if (props.className === 'classEmAndamento') {
        return '#0000cc';
      }
      if (props.className === 'classFinalizadaEConcluida') {
        return '#00cc00';
      }
      if (props.className === 'classFinalizadaComPendencias') {
        return '#000000';
      }
      if (props.className === 'classCancelado') {
        return '#e60000';
      }
      return '';
    }};

    opacity: ${(props) => {
      if (props.status === props.title) {
        return '1';
      }
      if (props.status === null && props.title === 'Pendente') {
        return '1';
      }
      return '0.3';
    }};
  }

  Span {
    width: auto;
    text-align: center;
    min-width: ${(props) => {
      if (props.className === 'classPendente') {
        return '70px';
      }
      if (props.className === 'classEmAndamento') {
        return '100px';
      }
      if (props.className === 'classFinalizadaEConcluida') {
        return '140px';
      }
      if (props.className === 'classFinalizadaComPendencias') {
        return '100px';
      }
      if (props.className === 'classCancelado') {
        return '75px';
      }
      if (props.className === 'classPdf') {
        return '140px';
      }
      if (props.className === 'classExcel') {
        return '140px';
      }
      if (props.className === 'classFilter') {
        return '70px';
      }
      if (props.className === 'default') {
        return '200px';
      }
      return '90px';
    }};
    background: ${(props) => {
      if (props.className === 'classPendente') {
        return '#ff9900';
      }
      if (props.className === 'classEmAndamento') {
        return '#0000cc';
      }
      if (props.className === 'classFinalizadaEConcluida') {
        return '#00cc00';
      }
      if (props.className === 'classFinalizadaComPendencias') {
        return '#000000';
      }
      if (props.className === 'classCancelado') {
        return '#e60000';
      }
      if (props.className === 'classPdf') {
        return '#e60000';
      }
      if (props.className === 'classExcel') {
        return '#006600';
      }
      if (props.className === 'classFilter') {
        return `${shade(0.3, props.theme.colors.primary)}`;
      }
      if (props.className === 'default') {
        return `${shade(0.3, props.theme.colors.primary)}`;
      }
      return '#fff';
    }};
    padding: 8px;
    border-radius: 14px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.5s;
    visibility: hidden;

    position: absolute;
    z-index: 1;
    bottom: calc(100% + 12px);
    left: ${(props) => {
      if (props.className === 'classPendente') {
        return '50%';
      }
      if (props.className === 'classEmAndamento') {
        return '50%';
      }
      if (props.className === 'classFinalizadaEConcluida') {
        return '50%';
      }
      if (props.className === 'classFinalizadaComPendencias') {
        return '50%';
      }
      if (props.className === 'classCancelado') {
        return '50%';
      }
      if (props.className === 'classExcel') {
        return '55%';
      }
      return '48%';
    }};
    transform: translateX(-50%);
    //text-shadow: 1px 1px 1px #000;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 12px; //14

    &::before {
      content: '';
      border-style: solid;
      border-color: ${(props) => {
        if (props.className === 'classPendente') {
          return '#ff9900 transparent';
        }
        if (props.className === 'classEmAndamento') {
          return '#0000cc transparent';
        }
        if (props.className === 'classFinalizadaEConcluida') {
          return '#00cc00 transparent';
        }
        if (props.className === 'classFinalizadaComPendencias') {
          return '#000000 transparent';
        }
        if (props.className === 'classCancelado') {
          return '#e60000 transparent';
        }
        if (props.className === 'classPdf') {
          return '#e60000 transparent';
        }
        if (props.className === 'classExcel') {
          return '#006600 transparent';
        }
        if (props.className === 'classFilter') {
          return `${shade(0.3, props.theme.colors.primary)} transparent`;
        }
        return '#fff';
      }};
      border-width: 6px 6px 0 6px;
      bottom: 20px;
      top: 100%;
      position: absolute;

      left: 50%;
      transform: translateX(-50%);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
