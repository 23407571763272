import { shade } from 'polished';
import styled, { keyframes } from 'styled-components';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  animation: ${appearFromRight} 1s;

  padding: 58px 72px 48px 49px;
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;
  overflow-x: hidden;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }

  .widthTotal {
    width: 100%;
  }
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromRight} 1s;
`;

export const ContainerCadastro = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 36px 74px 30px 74px;
  margin-top: 16px;
  width: 100%;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;

export const DivLineBottom = styled.div`
  width: 100%;
  height: 1px;
  margin: 25px auto 25px auto;

  background-color: ${(props) => props.theme.colors.gray1};
`;
export const HeaderForm = styled.h3`
  text-align: center;

  color: ${(props) => props.theme.colors.black2};
  font-size: 16px;
  font-weight: 700;
`;

export const ContainerInputForm = styled.div`
  font-family: 'Montserrat';
  #empresaId {
    color: ${(props) => props.theme.colors.black2};
    width: 100%;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }
`;

export const LabelInputForm = styled.h3`
  color: ${(props) => props.theme.colors.black2};
  margin-bottom: 10px;

  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
`;

export const InputForm = styled.input`
  width: 100%;
  height: 50px;
  margin-bottom: 41px;
  background-color: ${(props) => props.theme.colors.white};

  font-family: 'Montserrat', sans-serif;
  font-size: 16px;

  border: 1px solid ${(props) => props.theme.colors.gray1};
  border-radius: 4px;
  padding-left: 16px;
`;

export const SubHeaderEvidence = styled.h3`
  color: ${(props) => props.theme.colors.gray6};
  margin-top: 32px;

  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
`;

export const ButtonAddPost = styled.button`
  height: 20px;
  border: none;
  background-color: transparent;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  color: ${(props) => props.theme.colors.info};
`;

export const ContainerTablePost = styled.div`
  width: 100%;
  margin: 10px 0;
  overflow: auto;

  table {
    font-family: 'Montserrat', 'sans-serif';
    color: ${(props) => props.theme.colors.black2};
    overflow: auto;
    white-space: nowrap;
    table-layout: fixed;
    text-align: left;
    font-weight: 500;
    font-size: 14px;

    thead {
      width: 9000px;
      td {
        padding-left: 48px;
        min-width: 200px;
        height: 40px;
      }
    }

    tr:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.white3};
      height: 60px;
      td {
        padding-left: 48px;
        min-width: 200px;
      }
    }
    tr:nth-child(even) {
      background-color: transparent;
      height: 60px;
      td {
        padding-left: 48px;
        min-width: 200px;
      }
    }
  }
`;

export const ContainerButtonForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
`;

interface PropsButtonForm {
  background?: string;
}

export const ButtonForm = styled.button<PropsButtonForm>`
  width: 49.5%;
  height: 40px;
  background-color: ${(props) => props.background};
  color: ${(props) =>
    props.background === '#FFFFFF'
      ? props.theme.colors.primary
      : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray12};
  border-radius: 4px;
`;

export const ButtonTable = styled.button`
  background: ${(props) => props.theme.colors.white};
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.primary};

  svg {
    height: 14px;
    width: 14px;
  }
`;

export const ContentFilter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonIcon = styled.button`
  background: ${(props) => props.theme.colors.primary};
  height: 36px;
  width: 36px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
  }

  svg {
    height: 16px;
    width: 16px;
    color: ${(props) => props.theme.colors.white};
  }
`;
