interface GetValuesInJustificativaProps {
  hourInitial: string;
  hourFinal: string;
  kmInitial: number;
  kmFinal: number;
  justificativa: string;
}

// hi|hf|ki|kf|kp|justificativa
export function getValuesInJustificativa(
  justificativa: string,
): GetValuesInJustificativaProps {
  const values = justificativa.split('|');
  try {
    switch (values.length) {
      case 1:
        return {
          hourInitial: '',
          hourFinal: '',
          kmFinal: -1,
          kmInitial: -1,
          justificativa,
        };
      case 2:
        return {
          hourInitial: values[0] !== '' ? `${values[0]}:00` : '',
          hourFinal: '',
          kmInitial: -1,
          kmFinal: -1,
          justificativa: values[1],
        };
      case 3:
        return {
          hourInitial: values[0] !== '' ? `${values[0]}:00` : '',
          hourFinal: values[1] !== '' ? `${values[1]}:00` : '',
          kmInitial: -1,
          kmFinal: -1,
          justificativa: values[2],
        };
      case 4:
        return {
          hourInitial: values[0] !== '' ? `${values[0]}:00` : '',
          hourFinal: values[1] !== '' ? `${values[1]}:00` : '',
          kmInitial: Number(values[2]),
          kmFinal: -1,
          justificativa: values[3],
        };
      case 5:
        return {
          hourInitial: values[0] !== '' ? `${values[0]}:00` : '',
          hourFinal: values[1] !== '' ? `${values[1]}:00` : '',
          kmInitial: Number(values[2]),
          kmFinal: Number(values[3]),
          justificativa: values[4],
        };

      default:
        return {
          hourInitial: '',
          hourFinal: '',
          kmFinal: -1,
          kmInitial: -1,
          justificativa: values[values.length - 1],
        };
    }
  } catch {
    return {
      hourInitial: '',
      hourFinal: '',
      kmFinal: -1,
      kmInitial: -1,
      justificativa: values[values.length - 1],
    };
  }
}
