import styled from 'styled-components';

export const UlPagination = styled.ul`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  list-style: none;
  li + li {
    margin-left: 0.2rem;
  }
  button {
    border-radius: 4px;
    width: 32px;
    height: 32px;

    color: ${(props) => props.theme.colors.gray7};

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }
  .activatedButton {
    background-color: ${(props) => props.theme.colors.gray1};
  }
`;
export const Container = styled.div`
  color: ${(props) => props.theme.colors.gray9};
  width: 100%;
  height: 64px;

  display: flex;
  flex-direction: row;
  justify-content: end;

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80px;
  }
`;

export const ContainerSelectPaginations = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin: auto auto;
  display: flex;
  flex-direction: row;

  select {
    font-family: 'Montserrat', sans-serif;
    background-color: transparent;
    border: none;
    margin-right: 20px;
  }

  h4 {
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .strong {
    font-weight: 700 !important;
  }
  .textEvidence {
    color: ${(props) => props.theme.colors.black3};
  }
`;

export const ContainerPagination = styled.div`
  font-family: 'Montserrat', sans-serif;
  margin: auto 10px auto 0;
  width: 250px;
  display: flex;
  flex-direction: row;

  padding-right: 10px;

  li {
    list-style-type: none;
  }

  .active {
    background-color: ${(props) => props.theme.colors.white4};
    border-radius: 4px;
  }
  .page-item {
    width: 32px;
    height: 32px;
    align-self: auto;
    display: flex;
    span {
      margin: auto;
    }
    a {
      color: ${(props) => props.theme.colors.gray7};
      margin: auto;
    }
  }

  .sr-only {
    display: none;
  }
`;
