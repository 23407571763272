import styled from 'styled-components';

export const ContainerH1 = styled.h1`
  font-size: 24px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${(props) => props.theme.colors.black2};
`;

export const TitlePage = styled.h1`
  font-size: 24px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${(props) => props.theme.colors.black2};
`;
