import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import jsPDF from 'jspdf';
import { FiRefreshCw } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Table from '../../components/Table';
import PaginationComponent from '../../components/Pagination';
import NewImportExel from '../../components/NewImportExel';
import { GetRotaComplete } from '../../models/Rota';
import { UsuarioBD } from '../../models/User';
import { Clientes } from '../../models/Clientes';
import ModalConexao from '../../components/ModalConexao';
import { getValuesInJustificativa } from '../../utils/getValuesInJustificativa';

import {
  Container,
  TitlePage,
  Filter,
  Options,
  Actions,
  ButtonPDF,
  Animeted,
  ContainerInputDate,
} from './styles';

interface ItemTable {
  postoId: number;
  postoName: string;
  userName: string;
  atividade: string;
  status: string;
  observacao: string;
  justificativa: string;
  statusRota: string;
  dateAprov: string;
  userIdAprov: number;
  userNameAprov: string;
  dataMarcada: string;
  dataInicio: string;
  dataFinal: string;
  imagem: string;
  userId: number;
  visitaId: number;
  clientId: number;
  clientName: string;
  descricao: string;
}

interface ItemPDF {
  visitaId: number;
  postoName: string;
  dataMarcada: string;
  dataAprov: string;
  userAprov: string;
  status: string;
  Tarefas: {
    name: string;
    init: string;
    fin: string;
    justificativa: string;
    obervacao: string;
    status: string;
    userName: string;
  }[];
}

const headers = [
  { name: 'Id Visita', field: 'visitaId', sortable: false },
  { name: 'Cliente', field: 'clientName', sortable: true },
  { name: 'Posto', field: 'postoName', sortable: true },
  { name: 'Data de Aprovação', field: 'dateAprov', sortable: true },
  { name: 'Nome do supervisor', field: 'userName', sortable: true },
  { name: 'Data da Visita', field: 'dataMarcada', sortable: true },
  { name: 'Atividade', field: 'atividade', sortable: true },
  { name: 'Hora Inicial', field: 'dataInicio', sortable: true },
  { name: 'Hora Final', field: 'dataFinal', sortable: true },
  { name: 'Imagem', field: 'imagem', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Descrição', field: 'descricao', sortable: true },
  { name: 'Observação', field: 'observacao', sortable: true },
  { name: 'Justificativa', field: 'justificativa', sortable: true },
  { name: 'Status da Rota', field: 'statusRota', sortable: true },
];

export const AcompanhamentoClientes: React.FC = () => {
  const { empresaPrincipal } = useAuth();

  const [isSelectedSupervisor, setIsSelectedSupervisor] = useState(false);
  const [dateInit, setDateInit] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [clienteIdSelect, setClienteIdSelect] = useState(-1);
  const [clientes, setClientes] = useState<Clientes[]>([]);
  const [userIdSelect, setUserIdSelect] = useState(-1);
  const [usersVig, setUsersVig] = useState<UsuarioBD[]>([]);
  const [usersAll, setUsersAll] = useState<UsuarioBD[]>([]);
  const [listHist, setListHist] = useState<ItemTable[]>([]);
  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (dateInit === '' || dateEnd === '') return;
    setIsLoading(true);
    api
      .get(`/rotas?dataIni=${dateInit}&dataFim=${dateEnd}`)
      .then((resp) => {
        const rotasHist: GetRotaComplete[] = resp.data;
        // console.log(rotasHist);
        const vistApr = rotasHist.filter(
          (it) =>
            it.status !== 'A Aprovar' &&
            it.status !== 'Rejeitada' &&
            it.status !== 'Reprovada' &&
            it.type !== 'checkIn' &&
            it.type !== 'checkOut' &&
            it.Historico.length > 0,
        );
        // console.log(vistApr);

        const objItem: ItemTable[] = [];
        vistApr.forEach((item) => {
          item.Visita.Posto.Tarefas.forEach((tarf) => {
            const find = item.Historico.find((i) => i.tarefaId === tarf.id);
            if (find) {
              objItem.push({
                atividade: tarf.name,
                dataFinal: moment(
                  !find.timeFim ? find.updatedAt : find.timeFim,
                ).format('HH:mm'),
                dataInicio: moment(
                  !find.timeIni ? find.createdAt : find.timeIni,
                ).format('HH:mm'),
                imagem: find.image
                  ? `https://app-qrseguranca-ambiente.s3.amazonaws.com/${find.image}`
                  : '',
                justificativa: '-', // find.justificativa,
                observacao: find.observacao,
                postoName: item.Visita.Posto.name,
                status: find.image ? 'FEITO' : 'FEITO SEM IMAGEM',
                statusRota:
                  item.status === 'Aprovada' ? 'A iniciar' : item.status,
                userName: '',
                userId: item.supervisorId,
                postoId: item.Visita.Posto.id,
                visitaId: Number(item.visitaId),
                dataMarcada: moment(item.data).format('DD/MM/YYYY'),
                clientId: item.Visita.Posto.clienteId,
                clientName: '',
                dateAprov: !item.approvedDate
                  ? ''
                  : moment(item.approvedDate).format('DD/MM/YYYY'),
                userIdAprov: !item.approvedBy ? -1 : item.approvedBy,
                userNameAprov: '',
                descricao: find.descricao,
              });
            } else {
              const { justificativa } = getValuesInJustificativa(
                item.justificativa || '',
              );
              objItem.push({
                atividade: tarf.name,
                dataFinal: '',
                dataInicio: '',
                imagem: '',
                justificativa, // histFilter[0].justificativa,
                observacao: '',
                postoName: item.Visita.Posto.name,
                status:
                  item.status === 'Aprovada' ? 'A INICIAR' : 'NÃO REALIZADA',
                statusRota:
                  item.status === 'Aprovada' ? 'A Iniciar' : item.status,
                userName: '',
                userId: item.supervisorId,
                postoId: item.Visita.Posto.id,
                visitaId: Number(item.visitaId),
                dataMarcada: moment(item.data).format('DD/MM/YYYY'),
                clientId: item.Visita.Posto.clienteId,
                clientName: '',
                dateAprov: !item.approvedDate
                  ? ''
                  : moment(item.approvedDate).format('DD/MM/YYYY'),
                userIdAprov: !item.approvedBy ? -1 : item.approvedBy,
                userNameAprov: '',
                descricao: '',
              });
            }
          });
          if (item.type === 'intercorrencia') {
            item.Historico.forEach((hit) => {
              objItem.push({
                atividade: 'INTERCORRÊNCIA',
                dataFinal: moment(
                  !hit.timeFim ? hit.updatedAt : hit.timeFim,
                ).format('HH:mm'),
                dataInicio: moment(
                  !hit.timeIni ? hit.createdAt : hit.timeIni,
                ).format('HH:mm'),
                imagem: hit.image
                  ? `https://app-qrseguranca-ambiente.s3.amazonaws.com/${hit.image}`
                  : '',
                justificativa: '-', // hit.justificativa,
                observacao: hit.observacao,
                postoName: item.Visita.Posto.name,
                status: hit.image ? 'FEITO' : 'FEITO SEM IMAGEM',
                statusRota:
                  item.status === 'Aprovada' ? 'A iniciar' : item.status,
                userName: '',
                userId: item.supervisorId,
                postoId: item.Visita.Posto.id,
                visitaId: Number(item.visitaId),
                dataMarcada: moment(item.data).format('DD/MM/YYYY'),
                clientId: item.Visita.Posto.clienteId,
                clientName: '',
                dateAprov: !item.approvedDate
                  ? ''
                  : moment(item.approvedDate).format('DD/MM/YYYY'),
                userIdAprov: !item.approvedBy ? -1 : item.approvedBy,
                userNameAprov: '',
                descricao: hit.descricao,
              });
            });
          }
        });
        setListHist(objItem);
      })
      .catch((err) => {
        console.log(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dateEnd, dateInit]);

  const getClients = useCallback(() => {
    api
      .get(
        `/clientes/empresa?empresaId=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : '0'
        }`,
      )
      .then((response) => {
        const resp: Clientes[] = response.data;
        // console.log(resp);
        setClientes(
          resp.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          }),
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [empresaPrincipal]);

  const getUsers = useCallback(async () => {
    const usersGet = await api.get(
      `/allUsers/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );
    const usersResp: UsuarioBD[] = usersGet.data;
    // console.log(usersAll);
    setUsersVig(
      usersResp
        .filter((item) => item.acesso === '2')
        .sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        }),
    );
    setUsersAll(usersResp);
  }, [empresaPrincipal]);

  const getNameCliente = useCallback(
    (id: number) => {
      const find = clientes.find((i) => i.id === String(id));
      if (find) {
        return find.name;
      }
      return '';
    },
    [clientes],
  );

  const getNameUser = useCallback(
    (id: number) => {
      const find = usersAll.find((i) => i.id === id);
      if (find) {
        return find.nome;
      }
      return '';
    },
    [usersAll],
  );

  useEffect(() => {
    getUsers();
    getClients();
  }, [getClients, getUsers]);

  const handlePDF = useCallback(
    (listHistVist: ItemTable[]) => {
      const listPDF: ItemPDF[] = [];
      listHistVist.forEach((item) => {
        const index = listPDF.findIndex((i) => i.visitaId === item.visitaId);
        if (index === -1) {
          listPDF.push({
            visitaId: item.visitaId,
            postoName: item.postoName,
            dataMarcada: item.dataMarcada,
            status: item.statusRota,
            dataAprov: item.dateAprov,
            userAprov: item.userNameAprov,
            Tarefas: [
              {
                fin: item.dataFinal,
                init: item.dataInicio,
                justificativa: item.justificativa,
                name: item.atividade,
                obervacao: item.observacao,
                status: item.status,
                userName: item.userName,
              },
            ],
          });
        } else {
          listPDF[index].Tarefas.push({
            fin: item.dataFinal,
            init: item.dataInicio,
            justificativa: item.justificativa,
            name: item.atividade,
            obervacao: item.observacao,
            status: item.status,
            userName: item.userName,
          });
        }
      });

      const doc = new jsPDF(); // eslint-disable-line

      // doc.addFileToVFS('Montserrat-Regular.ttf', MontserratRegular);
      // doc.addFileToVFS('Montserrat-Medium.ttf', MontserratMedium);
      // doc.addFileToVFS('Montserrat-SemiBold.ttf', MontserratSemiBold);
      // doc.addFont('Montserrat-Regular.ttf', 'Montserrat', 'normal');
      // doc.addFont('Montserrat-Medium.ttf', 'Montserrat', 'medium');
      // doc.addFont('Montserrat-SemiBold.ttf', 'Montserrat', 'bold');

      // doc.setFont('Montserrat', 'normal');

      doc.setFontSize(18);
      doc.setTextColor(2, 2, 2);
      doc.setFontType('bold');
      const titulo =
        clienteIdSelect !== -1
          ? `Todas as visitas do cliente ${getNameCliente(clienteIdSelect)}`
          : `${
              userIdSelect !== -1
                ? `Todas as visitas do supervisor ${getNameUser(userIdSelect)}`
                : 'Visitas de Postos dos clientes'
            }`;

      doc.text(titulo, 103, 15, 'center');

      let spacement = 30;
      let page = 1;

      listPDF.forEach((item) => {
        doc.setFontSize(16);
        doc.setFontType('bold');
        doc.text(
          `${item.postoName} - ${item.dataMarcada} - ${item.status}`,
          20,
          spacement,
        );
        doc.setFontSize(14);
        doc.setFontType('normal');
        if (item.dataAprov || item.userAprov) {
          doc.text(`${item.dataAprov} - ${item.userAprov}`, 20, spacement + 5);
        }
        let indexSpace = 0;
        item.Tarefas.forEach((task) => {
          // console.log('PARTE 6');
          if (spacement + 45 + 45 * indexSpace > 290) {
            doc.insertPage(page + 1);
            page += 1;
            spacement = 20;
            indexSpace = 0;
          }
          doc.text('Tarefa', 20, spacement + 15 + 45 * indexSpace);
          doc.text(task.name, 60, spacement + 15 + 45 * indexSpace);
          doc.text('Hora inicial', 20, spacement + 20 + 45 * indexSpace);
          doc.text(task.init, 60, spacement + 20 + 45 * indexSpace);
          doc.text('Hora final', 20, spacement + 25 + 45 * indexSpace);
          doc.text(task.fin, 60, spacement + 25 + 45 * indexSpace);
          doc.text('Status', 20, spacement + 30 + 45 * indexSpace);
          doc.text(task.status, 60, spacement + 30 + 45 * indexSpace);
          doc.text('Justificativa', 20, spacement + 35 + 45 * indexSpace);
          doc.text(
            task.justificativa || ' - ',
            60,
            spacement + 35 + 45 * indexSpace,
          );
          doc.text('Observação', 20, spacement + 40 + 45 * indexSpace);
          doc.text(
            task.obervacao || ' - ',
            60,
            spacement + 40 + 45 * indexSpace,
          );
          doc.text('Supervisor', 20, spacement + 45 + 45 * indexSpace);
          doc.text(
            task.userName || ' - ',
            60,
            spacement + 45 + 45 * indexSpace,
          );

          indexSpace += 1;
        });
        spacement += 20 + 45 * indexSpace;
      });

      doc.autoPrint();
      doc.output('dataurlnewwindow');
    },
    [clienteIdSelect, getNameCliente, getNameUser, userIdSelect],
  );

  const listHistMemo: ItemTable[] = useMemo(() => {
    let list = listHist.map((item) => {
      const find = usersAll.find((it) => it.id === item.userId);
      const findAprov = usersAll.find((it) => it.id === item.userIdAprov);
      const findCli = clientes.find((it) => {
        return it.id === String(item.clientId);
      });
      const obj = item;
      if (find) {
        obj.userName = find.nome;
      }
      if (findAprov) {
        obj.userNameAprov = findAprov.nome;
      }
      if (findCli) {
        obj.clientName = findCli.name;
      }
      return obj;
    });
    if (userIdSelect !== -1) {
      list = list.filter((it) => it.userId === userIdSelect);
    }
    if (clienteIdSelect !== -1) {
      list = list.filter(
        (it) => String(it.clientId) === String(clienteIdSelect),
      );
    }
    return list;
  }, [listHist, userIdSelect, clienteIdSelect, usersAll, clientes]);

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Acompanhamento de Visitas de Postos</TitlePage>

        <Options>
          <div>
            <button
              type="button"
              className={!isSelectedSupervisor ? 'selected' : 'notSelected'}
              onClick={() => {
                setIsSelectedSupervisor(false);
                setUserIdSelect(-1);
              }}
            >
              Clientes
            </button>
          </div>
          <div>
            <button
              type="button"
              className={isSelectedSupervisor ? 'selected' : 'notSelected'}
              onClick={() => {
                setIsSelectedSupervisor(true);
                setClienteIdSelect(-1);
              }}
            >
              Supervisores
            </button>
          </div>
        </Options>

        <Filter>
          <ContainerInputDate>
            <input
              type="date"
              value={dateInit}
              onChange={(event) => {
                setDateInit(event.target.value);
              }}
            />
            <input
              type="date"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
            />
          </ContainerInputDate>
          {isSelectedSupervisor ? (
            <select
              value={userIdSelect}
              onChange={(event) => {
                setUserIdSelect(Number(event.target.value));
              }}
            >
              <option value={-1}>Todas os supervisores</option>
              {usersVig.map((it) => (
                <option key={it.id} value={it.id}>
                  {it.nome}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={clienteIdSelect}
              onChange={(event) => {
                setClienteIdSelect(Number(event.target.value));
              }}
            >
              <option value={-1}>Todas os clientes</option>
              {clientes.map((it) => (
                <option key={it.id} value={it.id}>
                  {it.name}
                </option>
              ))}
            </select>
          )}
        </Filter>
        <Table
          header={headers}
          body={listHistMemo.filter(
            (e, i) => i >= indexInitPg && i <= indexEndPg && !isLoading,
          )}
          tableId="TableHistoricRotas"
        />
        {isLoading && (
          <Animeted>
            <FiRefreshCw size={40} className="animate-spin" />
            <p>Carregando ...</p>
          </Animeted>
        )}

        <PaginationComponent
          arrayStateFiltered={listHistMemo}
          setIndexPagination={(indexInit, indexEnd) => {
            setIndexInitPg(indexInit);
            setIndexEndPg(indexEnd);
          }}
        />
        <Actions>
          <ButtonPDF>
            <button type="button" onClick={() => handlePDF(listHistMemo)}>
              PDF
            </button>
          </ButtonPDF>
          <NewImportExel
            fileName="RelatorioAnalitico"
            tableId="TableHistoricRotas"
          />
        </Actions>
      </Container>
    </>
  );
};
