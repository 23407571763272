import styled from 'styled-components';

interface ContainerProps {
  classNameProps: string;
}

export const Container = styled.div`
  max-width: auto;
  display: flex;
  //margin-right: 37px;
  //padding: 0px 20px;

  input {
    font-family: 'Arial';
    align-self: 'center';
    justify-self: 'center';
    //margin-right: 25px;
    border-left-width: 2px;
    border-top-width: 2px;
    border-right-width: 2px;
    border-color: '#439133';
    border-bottom-width: 10px;
    border: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
    padding-left: 0px;
    height: 40px;
    width: 230px;
  }
`;

export const ContainerLupa = styled.div<ContainerProps>`
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  border-top-left-radius: ${(props) =>
    props.classNameProps === 'search1' || props.classNameProps === 'search4'
      ? '10px'
      : '0px'};

  border-bottom-left-radius: ${(props) =>
    props.classNameProps === 'search2' || props.classNameProps === 'search4'
      ? '10px'
      : '0px'};
  padding: 8px;
  padding-left: 8px;
`;
