import React from 'react';

import { AuthProvider } from './auth';
import { DrawerProvider } from './drawer';
import { ToastProvider } from './toast';

interface Props {
  children: React.ReactNode;
}

const AppProvider: React.FC<Props> = ({ children }) => (
  <AuthProvider>
    <DrawerProvider>
      <ToastProvider>{children}</ToastProvider>
    </DrawerProvider>
  </AuthProvider>
);

export default AppProvider;
