import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: auto;
  margin-top: 5px;
  margin-right: 5px;
  max-height: 50vh;

  table {
    font-family: 'Montserrat', 'sans-serif';
    color: black;
    background-color: ${(props) => props.theme.colors.white};
    overflow-y: scroll;
    white-space: nowrap;
    text-align: left;
    font-weight: 500;
    font-size: 14px;

    min-width: 100%;

    td {
      padding-left: 48px;

      input.checkbox {
        width: 22px;
        height: 22px;
      }
    }

    th {
      padding-left: 48px;
      height: 60px;
      h3 {
        margin-top: auto;
        margin-bottom: auto;
        font-weight: 600 !important;
      }
    }

    .BtnEdit {
      background: ${(props) => props.theme.colors.gray12};
      height: 36px;
      width: 36px;
      text-align: center;
      border: none;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: ${(props) => props.theme.colors.white};
      }
      &:hover {
        background: ${(props) => shade(0.2, props.theme.colors.gray12)};
      }
    }

    .BtnRemove {
      background: ${(props) => props.theme.colors.error};
      height: 36px;
      width: 36px;
      text-align: center;
      border: none;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        color: ${(props) => props.theme.colors.white};
      }

      &:hover {
        background: ${(props) => shade(0.2, props.theme.colors.error)};
      }
    }

    tbody {
      tr:nth-child(odd) {
        background-color: ${(props) => props.theme.colors.white3};
        height: 60px;
        td {
          width: auto;
        }
      }
      tr:nth-child(even) {
        background-color: transparent;
        height: 60px;
        td {
          width: auto;
        }
      }
    }
  }
`;

export const ReportButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
