import React, { useCallback, useState, useEffect } from 'react';
import { FiWifiOff, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
} from './styles';

interface ModalConexao {
  onClose?(): void;
}

const Modal: React.FC<ModalConexao> = ({
  onClose = () => {
    '';
  },
}) => {
  const [conexao, setConexao] = useState(navigator.onLine);
  const history = useHistory();

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        setConexao(true);
        onClose();
      }
    },
    [onClose],
  );
  const signOut = useCallback(async () => {
    const token = localStorage.removeItem('@QrcodeSeguranca:token'); // eslint-disable-line
    const user = localStorage.removeItem('@QrcodeSeguranca:user');// eslint-disable-line
    const cpfUserMaster = localStorage.removeItem('@QrcodeSeguranca:cpfUserMaster');// eslint-disable-line
    const empresaPrincipal = localStorage.removeItem('@QrcodeSeguranca:empresaPrincipal');// eslint-disable-line
    const acesso = localStorage.removeItem('@QrcodeSeguranca:acesso');// eslint-disable-line
    const dataHoraToken = localStorage.removeItem('@QrcodeSeguranca:dataHoraToken');// eslint-disable-line

    history.go(0);
  }, [history]);

  const getData = useCallback(async () => {
    let tokenExpirado = false;
    const value = localStorage.getItem('@QrcodeSeguranca:dataHoraToken');

    if (value) {
      const valueFormat = value.replace(/"/g, '');
      const dataTokenMaisUmDia = new Date(valueFormat).setDate(
        new Date(valueFormat).getDate() + 1,
      );
      const dataAtual = new Date().getTime();
      tokenExpirado = dataAtual > dataTokenMaisUmDia;
    }

    if (tokenExpirado) signOut();
  }, [signOut]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div>
        {!conexao && (
          <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
            <Container>
              <ContainerButton>
                <button
                  type="button"
                  onClick={() => {
                    setConexao(true);
                    onClose();
                  }}
                >
                  <FiX />
                </button>
              </ContainerButton>
              <ContainerHeader>
                <FiWifiOff />
              </ContainerHeader>
              <ContainerHeaderDados>
                <p className="titulo">Sem Conexão</p>
              </ContainerHeaderDados>
              <ContainerHeaderDados>
                <p>Por favor, se conecte para ter acesso ao sistema.</p>
              </ContainerHeaderDados>
              <ContainerButtonAtualizar>
                <button type="button" onClick={() => history.go(0)}>
                  Recarregar
                </button>
              </ContainerButtonAtualizar>
            </Container>
          </ContainerModal>
        )}
      </div>
    </>
  );
};

export default Modal;
