import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import { Container, Containt, ContainerFooter, ContainerText } from './styles';

interface AlertProps {
  message: string;
  onClickStatus(status: boolean): void;
}

const MessageAlertRegister: React.FC<AlertProps> = ({
  message,
  onClickStatus = (status: boolean) => {
    return status;
  },
}) => {
  return (
    <Container>
      <Containt>
        <FiAlertTriangle size={60} />
        <ContainerText>
          <p>{message}</p>
          <p>Deseja prosseguir?</p>
        </ContainerText>
        <ContainerFooter>
          <button type="button" onClick={() => onClickStatus(true)}>
            Sim
          </button>
          <button type="button" onClick={() => onClickStatus(false)}>
            Não
          </button>
        </ContainerFooter>
      </Containt>
    </Container>
  );
};

export default MessageAlertRegister;
