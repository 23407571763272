import React from 'react';

const IconReport = React.memo(() => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M348 960 c-11 -18 -24 -20 -100 -20 -139 0 -128 38 -128 -450 0 -397
1 -411 20 -430 19 -19 33 -20 360 -20 327 0 341 1 360 20 19 19 20 33 20 430
0 488 11 450 -128 450 -76 0 -89 2 -100 20 -11 18 -23 20 -152 20 -129 0 -141
-2 -152 -20z m272 -70 l0 -50 -120 0 -120 0 0 50 0 50 120 0 120 0 0 -50z
m-280 -10 c0 -18 -7 -20 -70 -20 l-70 0 0 -370 0 -370 300 0 300 0 0 370 0
370 -70 0 c-64 0 -70 2 -70 20 0 20 5 21 88 18 l87 -3 0 -405 0 -405 -335 0
-335 0 -3 395 c-1 217 0 401 3 407 3 9 31 13 90 13 78 0 85 -2 85 -20z m15
-70 c4 -6 63 -10 145 -10 82 0 141 4 145 10 3 6 31 10 61 10 l54 0 0 -330 0
-330 -260 0 -260 0 0 330 0 330 54 0 c30 0 58 -4 61 -10z"
      />
      <path
        d="M320 720 c0 -19 7 -20 180 -20 173 0 180 1 180 20 0 19 -7 20 -180
20 -173 0 -180 -1 -180 -20z"
      />
      <path
        d="M320 620 c0 -19 7 -20 180 -20 173 0 180 1 180 20 0 19 -7 20 -180
20 -173 0 -180 -1 -180 -20z"
      />
      <path
        d="M320 520 c0 -18 7 -20 80 -20 73 0 80 2 80 20 0 18 -7 20 -80 20 -73
0 -80 -2 -80 -20z"
      />
      <path
        d="M652 428 c-7 -7 -12 -19 -12 -28 0 -20 -67 -100 -84 -100 -17 0 -73
61 -78 84 -8 45 -68 45 -76 0 -5 -26 -62 -84 -83 -84 -39 0 -51 -52 -16 -74
21 -14 50 2 55 30 5 26 62 84 82 84 20 0 77 -58 82 -84 8 -47 66 -45 78 3 7
31 56 88 87 103 15 7 29 21 31 31 7 37 -39 62 -66 35z"
      />
    </g>
  </svg>
));

export default IconReport;
export { IconReport };
