import styled from 'styled-components';
import Tooltip from '../Tooltip';

interface PropsCard {
  animate: boolean;
}

export const Card = styled.div<PropsCard>`

  #BtnAprov{
    background: ${(props) => props.theme.colors.primary};
    color:  ${(props) => props.theme.colors.secondary};
  }

  #BtnRegect{
    background: ${(props) => props.theme.colors.secondary};
    color:  ${(props) => props.theme.colors.primary};
  }

  #BtnEdit {
    background: ${(props) => props.theme.colors.secondary};
    color:  ${(props) => props.theme.colors.primary};
    height: 36px;
    width: 36px;
  }

  div.card {
    display: flex;
    max-height: 100%;
    height: 100px;
    width: 100%;
    background: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.gray1};
    box-sizing: border-box;
    border-radius: 4px;
    transition: 0.4s;
    overflow: auto;

    div.date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0px 24px;
      p {
        font-family: 'Montserrat';
        font-size: 24px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.black2};
        margin: 0px;
      }
      span {
        font-family: 'Montserrat';
        font-size: 24px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.black2};
      }
    }

    div.info {
      flex: 1;
      display: flex;
      height: 100%;
      padding-left: 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      min-width: 550px;

      p {
        color: ${(props) => props.theme.colors.primary};
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
      }

      div {
        display: flex;
        width: 100%;
        align-items: center;

        div {
          margin-right: 10px;

          svg {
            color: ${(props) => props.theme.colors.primary};
            margin-right: 5px;
          }
          span {
            color: ${(props) => props.theme.colors.primary};
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    div.option {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0px 5px;
      button {
        width: 120px;
        height: 36px;
        background: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.primary};
        border-radius: 4px;
        color: ${(props) => props.theme.colors.primary};
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        transition: 0.4s;
        margin: 5px;
      }
      /* button:hover {
        background: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.white};
      } */
    }

    div.bool {
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 16px 10px;
      color: ${(props) => props.theme.colors.black2};

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        margin: 0px 5px;
      }
      .can {
        background-color: ${(props) => props.theme.colors.error};
      }
      .pen {
        background-color: ${(props) => props.theme.colors.warning};
      }
      .and {
        background-color: ${(props) => props.theme.colors.info};
      }
      .fin {
        background-color: ${(props) => props.theme.colors.success};
      }
      .ina {
        background-color: ${(props) => props.theme.colors.primary};
      }
    }
  }
  div.card:hover {
    height: ${(props) => (props.animate ? 105 : 100)}px;
    width: ${(props) => (props.animate ? 101 : 100)}%;
    cursor: ${(props) => (props.animate ? 'cursor' : 'auto')};
    box-shadow: ${(props) =>
      props.animate ? '0px 8px 8px rgba(0, 0, 0, 0.15)' : 'none'};
  }
`;

interface Props {
  color: string;
}

export const Line = styled.div<Props>`
  width: 12px;
  height: 100%;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  background-color: ${(props) => props.color};
`;

export const Tool = styled(Tooltip)`
  height: 14px;
  width: 14px;

  svg {
    margin: 0;
  }

  span {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};

    &::before {
      border-color: ${(props) => props.theme.colors.primary} transparent;
    }
  }
`;
