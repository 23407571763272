import React, { useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';
import jsPDF from 'jspdf';
import QrCode from 'qrcode';
import domtoimage from 'dom-to-image-more';

import { Loading } from '../../Loading';
import backgroundImage from '../../../assets/background-qrcode.jpg';
import {
  Container,
  ButtonClose,
  DivClose,
  HeaderForm,
  ButtonForm,
  ContainerButtonForm,
  ContainerImage,
} from './styled';

interface Props {
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  imageQrString: string;
  name: string;
  id: number;
}

const BodyQrCode: React.FC<Props> = ({
  setVisible,
  imageQrString,
  name,
  id,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDownloadPNG = useCallback(() => {
    const generator = document.getElementById('htmltoimage');
    const options = {
      quality: 1,
    };
    domtoimage.toPng(generator, options).then((canvas: string) => {
      const link = document.createElement('a');
      link.download = `QRCODE-${name}.png`;
      link.href = canvas;
      link.click();
    });
  }, [name]);

  const handleDownloadPDF = useCallback(() => {
    try {
      setLoading(true);

      QrCode.toDataURL(String(id))
        .then((url) => {
          setLoading(false);

          const doc = new jsPDF('p', 'mm', 'a4'); // eslint-disable-line

          const img = new Image();
          img.src = backgroundImage;
          doc.addImage(img, 'PNG', 0, 0, 210, 300);

          doc.addImage(url, 'PNG', 56, 85, 100, 100);
          doc.setFontSize(22);
          const quebraLinha = doc.splitTextToSize(`${name}`, 110);
          doc.text(quebraLinha, 105, 200, {
            maxWidth: 110,
            align: 'center',
          });

          doc.autoPrint();
          doc.save(`QRCODE - ${name}`);
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (err) {
      setLoading(false);
    }
  }, [id, name]);

  return (
    <Container>
      <DivClose>
        <div style={{ height: 40, width: 40 }} />
        <HeaderForm style={{ flex: 1 }}>Qr code ronda</HeaderForm>
        <ButtonClose
          onClick={() => {
            setVisible?.(false);
          }}
        >
          <FiX />
        </ButtonClose>
      </DivClose>
      <ContainerImage id="htmltoimage" style={{ alignItems: 'center' }}>
        <img alt="QrCode" src={imageQrString} />
        <p className="title">{name}</p>
      </ContainerImage>
      <ContainerButtonForm style={{ flexDirection: 'row' }}>
        <ButtonForm
          background="#393B41"
          onClick={handleDownloadPDF}
          style={{ margin: 'auto' }}
        >
          PDF
        </ButtonForm>
        <div style={{ margin: 10 }} />
        <ButtonForm
          background="#393B41"
          onClick={handleDownloadPNG}
          style={{ margin: 'auto' }}
        >
          PNG
        </ButtonForm>
      </ContainerButtonForm>
      {loading && <Loading />}
    </Container>
  );
};

export default BodyQrCode;
