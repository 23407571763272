export const sortFunction = (a: { id: number }, b: { id: number }): number => {
  if (a.id > b.id) {
    return 1;
  }
  if (a.id < b.id) {
    return -1;
  }
  // a must be equal to b
  return 0;
};
