import React, { useState } from 'react';
import { Autocomplete, Option } from 'chakra-ui-simple-autocomplete';
import { Box, ChakraProvider } from '@chakra-ui/react';

import { Container, Pline } from './styled';
import { Tarefas } from '../../models/Tarefas';
import { Checklist } from '../CheckListAddDate';

interface Props {
  options: Checklist[];
  stateOptions: (prevState: any) => any;
}

const AutocompleteWrapper = ({ options, stateOptions }: Props): any => {
  const handleAddChecklist = (optionsEvent: Option[]): void => {
    const findValueState = options.find(
      (element) => element.name === optionsEvent[0].value,
    );

    if (findValueState === undefined && optionsEvent[0].value.length <= 255) {
      stateOptions([...options, { name: optionsEvent[0].value }]);
    }
  };

  return (
    <Container>
      <ChakraProvider>
        <div className="App">
          <Box maxW="md" style={{ marginTop: -20 }}>
            <Pline>limite de 255 caracteres</Pline>
            <Autocomplete
              maxLength={255}
              options={options.map((e) => {
                return { value: e.name, label: e.name };
              })}
              result={options.map((e) => {
                return { value: e.name, label: e.name };
              })}
              setResult={handleAddChecklist}
              placeholder="Adicionar checklist +"
              renderBadge={undefined}
            />
          </Box>
        </div>
      </ChakraProvider>
    </Container>
  );
};

export default AutocompleteWrapper;
