import React from 'react';

import { Container, Content } from './styles';

export const Privacidade: React.FC = () => {
  return (
    <Container>
      <Content>
        <h1>Política de Privacidade</h1>
        <h3>
          Olá, Bem-vindo a Política de Privacidade dos aplicativos do Grupo
          Servnac.
        </h3>
        <h3>• PARTES</h3>
        <p>
          Inicialmente, vamos nos apresentar apropriadamente. Ao acessar o
          nossos apps você, pessoa natural individualizada, identificada e
          previamente admitida, doravante denominada, encontra-se em contato a
          ORGANIZAÇÃO GRUPO SERVNAC.
        </p>

        <h3>• CADASTRO, ACESSO E SENHA</h3>
        <p>
          O acesso aos Conteúdos dos APLICATIVOS ocorre mediante cadastramento
          voluntário do USUÁRIO na plataforma. Assim, o USUÁRIO compromete-se a
          fornecer informações verdadeiras, corretas, atuais e completas sobre
          si mesmo, no momento do seu cadastramento, bem como a mantê-las
          atualizadas, responsabilizando-se civil e criminalmente por estas
          informações.
        </p>
        <p>
          O USUÁRIO criará ou indicará um E-MAIL para (“Login”) e uma senha
          privativa de caráter sigiloso, que funcionarão como sua identificação
          e chave de acesso para os Conteúdos dos Aplicativos.
        </p>
        <p>
          O Login e a senha são privativos e intransferíveis, não podendo ser
          objeto de qualquer tipo de comercialização ou cessão de uso, assumindo
          o USUÁRIO integral e exclusiva responsabilidade pelos mesmos, sem
          qualquer solidariedade com o APP.
        </p>
        <p>
          O USUÁRIO CONSENTE LIVRE, INFORMADA E EXPRESSAMENTE QUE OS DADOS
          INFORMADOS DURANTE O CADASTRAMENTO E/OU ORIUNDOS DA UTILIZAÇÃO DOS
          CONTÉUDOS SEJAM MANTIDOS PELOS APLICATIVOS E FORNECIDOS (I) ÀS
          AUTORIDADES PÚBLICAS COMPETENTES QUE AS SOLICITAREM FORMALMENTE, NOS
          TERMOS DA CONSTITUIÇÃO FEDERAL BRASILEIRA, DO MARCO CIVIL DA INTERNET,
          LEI GERAL DE PROTEÇÃO DE DADOS E DEMAIS LEGISLAÇÕES APLICÁVEIS E (II)
          A SEUS PARCEIROS ESTRATÉGICOS, COMERCIAIS OU TÉCNICOS, COM A
          FINALIDADE DE OFERECER MELHORES CONDIÇÕES DOS CONTEÚDOS AO USUÁRIO.
          ADEMAIS, O USUÁRIO DECLARA EXPRESSAMENTE E CONCORDA QUE OS APLICATIVOS
          COLETE INFORMAÇÕES PARA COMUNICAÇÃO DIRETA COM O USUÁRIO, VALIDAÇÃO DE
          ATUALIZAÇÃO DE CADASTRO, REALIZAÇÃO DE PESQUISAS ESTATÍSTICAS SEM
          IDENTIFICAÇÃO DO USUÁRIO, ORIENTAÇÕES E SOLICITAÇÕES DE MELHORIA DOS
          RECURSOS OFERTADOS.
        </p>
        <p>
          O USUÁRIO deve ter 18 (dezoito) anos ou mais para cadastrar-se no APP.
        </p>
        <h3>• RESPONSABILIDADES DO USUÁRIO</h3>
        <p>
          O USUÁRIO obriga-se a abster-se de fazer uso do(s) Conteúdo(s) da
          APLICAÇÃO,
        </p>
        <span>
          (a) violar a lei, a moral, os bons costumes, a propriedade
          intelectual, os direitos à honra, à vida privada, à imagem, à
          intimidade pessoal e familiar;
        </span>
        <br />
        <span>
          (b) estimular a prática de condutas ilícitas ou contrárias à moral e
          aos bons costumes;
        </span>
        <br />
        <span>
          (c) incitar a prática de atos discriminatórios, seja em razão de sexo,
          raça, raça, religião, crenças, idade ou qualquer outra condição;
        </span>
        <br />
        <span>
          (d) colocar à disposição ou possibilitar o acesso a mensagens,
          produtos ou serviços ilícitos, violentos, degradantes;
        </span>
        <br />
        <span>(e) enviar mensagens coletivas (SPAM) a grupos de usuários;</span>
        <br />
        <span>(f) induzir a um estado inaceitável de ansiedade ou temor;</span>
        <br />
        <span>
          (g) induzir ou incitar práticas perigosas, de risco ou nocivas para a
          saúde e para o equilíbrio psíquico;
        </span>
        <br />
        <span>
          (h) propagar conteúdos falsos, ambíguos, inexatos, exagerados ou
          extemporâneos, de forma que possam induzir a erro sobre seu objeto ou
          sobre as intenções ou propósitos do comunicador;
        </span>
        <br />
        <span> (i) violar o sigilo das comunicações;</span>
        <br />
        <span>
          (j) constituir publicidade ilícita, enganosa ou desleal, em geral, que
          configurem concorrência desleal;
        </span>
        <br />
        <span>(k) veicular, incitar ou estimular a pedofilia. </span>
        <br />
        <span>
          (l) incorporar vírus ou outros elementos físicos ou eletrônicos que
          possam danificar ou impedir o normal funcionamento da rede, do sistema
          ou dos equipamentos informáticos (hardware e software) de terceiros ou
          que possam danificar os documentos eletrônicos e arquivos armazenados
          nestes equipamentos informáticos;
        </span>
        <br />
        <span>
          (m) obter ou tentar obter acesso não-autorizado a outros sistemas ou
          redes de computadores;
        </span>
        <br />
        <span>
          (n) reproduzir, vender e distribuir produtos sem a devida autorização
          e o pagamento dos direitos autorais.
        </span>
        <br />
        <br />
        <p>
          O USUÁRIO assume, exclusivamente, sem restrições ou reservas, todos os
          ônus e responsabilidades decorrentes de seus atos e de sua conduta
          como usuário da Internet, sendo responsável pela má utilização dos
          Conteúdos disponibilizado pelos APLICATIVOS.
        </p>

        <h3>• DA PROPRIEDADE INTELECTUAL E DIREITO AUTORAL</h3>
        <p>
          O acesso aos Conteúdos não conferirá ao USUÁRIO qualquer direito de
          propriedade ou de uso comercial sobre os Conteúdos de propriedade do
          GRUPO SERVNAC, os quais serão disponibilizado através dos APLICATIVOS,
          declarando-se o USUÁRIO ciente de que não poderá, em hipótese nenhuma,
          utilizar-se deste Conteúdo ou explorá-lo comercialmente ou
          reproduzi-lo em qualquer situação sem a devida citação da fonte e de
          todos os direitos pertencentes ao GRUPO.
        </p>
        <p>
          Os direitos de propriedade sobre todos os ícones, mensagens e outras
          imagens, assim como sobre o nome e marcas dos APLICATIVOS a ele
          pertencem, sendo expressamente vedado ao USUÁRIO modificar quaisquer
          dos ícones, mensagens ou demais imagens.
        </p>
        <h3>• DA AUTORIZACAO PARA USO DE DADOS</h3>
        <p>
          Autorizo a utilização de minha imagem, da minha localização e dados
          por meio de fotos, atualizações cadastrais, incluindo também
          documentações que vier a ser solicitar por meio desta modalidade
          digital na presente plataforma mobile e/ou Web disponibilizadas pelo
          GRUPO SERVNAC, incluindo documentação de atestados médicos.
        </p>
        <p>
          A autorização contida no presente instrumento limita-se ao paragrafo
          acima. Qualquer divulgacao, mobile e/ou WEB somente poderá se dar
          mediante consentimento expresso de todos que estão registrados.
        </p>

        <h3>• DA LEI GERAL DE PROTEÇÃO DE DADOS</h3>
        <p>
          A SERVNAC utiliza-se de dados pessoais ora cadastrados de forma
          voluntária pelo titular, com a finalidade de capacitação no uso dos
          aplicativos, restrigindo o seu uso aos colaboradores e fornecedores
          vinculados à empresa. Para o presente fim, a empresa classifica-se
          como CONTROLADORA dos dados. Para fins da Lei Geral de Proteção de
          Dados, Lei nº 13.709/2018, necessário se faz o aceite e conhecimento
          dos termos adiante expostos. Necessário esclarecer:
        </p>
        <p>
          a) Dados Pessoais: dados e informações obtidas através de meio online
          ou offline, capazes de identificar ou tornar identificáveis pessoas
          físicas (titular), incluindo dados que possam ser combinados com
          outras informações para identificar um indivíduo, e/ou que se
          relacionem com a sua identidade, características, comportamento ou que
          influenciem na maneira como ele é tratado ou avaliado, incluindo
          números identificativos, dados locacionais ou identificadores
          eletrônicos.
        </p>
        <p>
          b) Dados Sensíveis: Dados pessoais referentes à origem racial ou
          étnica, convicção religiosa, opinião política, filiação a sindicato ou
          a organização de caráter religioso, filosófico ou político, dado
          referente à saúde ou à vida sexual, dado genético ou biométrico,
          quando vinculado a uma pessoa natural, ou qualquer dado que, quando
          combinado com outras informações, possa influenciar na forma como o
          titular do dado é tratado, ou seja, possa eventualmente ensejar algum
          tipo de prática discriminatória vedada pela legislação.
        </p>
        <p>
          c) Anonimização (bem como termos relacionados como anonimizar,
          anonimizados): todo e qualquer meio e processo técnico razoável e
          disponível na ocasião do tratamento (adiante definido) de dados
          pessoais que resulte na não identificação do titular do referido dado
          pessoal. Os dados anonimizados não serão considerados dados pessoais
          para os fins da legislação aplicável, salvo quando o processo de
          anonimização ao qual foram submetidos for revertido, ou quando, com
          esforços razoáveis, puder ser revertido.
        </p>
        <p>
          d) Tratamento (bem como os termos relacionados tratar, tratados): toda
          e qualquer operação realizada com dados pessoais, incluindo a coleta,
          produção, recepção, classificação, utilização, acesso, reprodução,
          transmissão, distribuição, processamento, arquivamento, armazenamento,
          eliminação, avaliação ou controle da informação, modificação,
          comunicação, transferência, difusão ou extração.
        </p>
        <p>
          e) Controlador: pessoa natural ou jurídica, de direito público ou
          privado, a quem competem as decisões referentes ao tratamento de dados
          pessoais, principalmente em relação à determinação das finalidades e
          dos meios de tratamento dos dados pessoais.
        </p>
        <p>
          f) Operador: pessoa natural ou jurídica, de direito público ou
          privado, que realiza o tratamento de dados pessoais em nome do
          Controlador.
        </p>
        <p>
          g) Base Legal: hipóteses legais que autorizam o tratamento de dados
          pessoais, conforme elencadas na Lei nº 13.709/2018.
        </p>
        <p>
          h) O CONTROLADOR poderá tratar os dados pessoais nos termos do
          contrato firmado, para cumprir as finalidades relacionadas à execução
          do seu objeto, com base no art. 7º, V da Lei nº 13.709/18.
        </p>
        <p>
          i) O CONTROLADOR se compromete a adotar medidas técnicas e
          organizacionais adequadas e razoáveis, de acordo com o padrão de
          mercado e a legislação brasileira, para garantir a confidencialidade,
          disponibilidade e a integridade das informações e dados pessoais aos
          quais tiver acesso em decorrência do presente contrato, sendo-lhe
          vedada a divulgação ou compartilhamento com terceiros e a utilização
          de dados pessoais sem o consentimento do titular e do controlador dos
          dados.
        </p>
        <p>
          j) O CONTROLADOR garante que cumprirá todas as políticas, regras e
          orientações de segurança da informação relacionadas à proteção dos
          dados pessoais, incluindo questões relativas a armazenamento,
          criptografia, controles de acesso (dupla autenticação, manutenção de
          inventário detalhado) e serviços de firewall, a fim de protegê-los
          contra perdas, destruições, alterações, divulgações e acessos não
          autorizados, sejam esses acidentais ou não (o Incidente), devendo
          adotar medidas para garantir adequada segurança contra os riscos
          apresentados em decorrência da natureza dos dados pessoais.
        </p>
        <p>
          l) O CONTROLADOR compromete-se a informar ao titular dos dados caso(s)
          de ocorrência(s) de incidente ou tratamento não autorizado de dados
          pessoais.
        </p>
        <p>
          m) Quando necessário para a prestação da finalidade contratual e
          veracidade das informações entregues, o titular dos dados
          disponibilizará ao CONTROLADOR a documentação necessária.
        </p>
        <p>
          n) O CONTROLADOR declara e reconhece que, em razão da natureza dos
          dados pessoais, somente será autorizada a comunicação e/ou o uso
          compartilhado de dados pessoais com terceiros após a autorização do
          titular dos dados, incluindo, mas não se limitando às hipóteses em que
          houver objetivo de obter vantagem econômica. Caso o CONTROLADOR
          compartilhe com terceiros os dados pessoais fornecidos pelo titular,
          será imposto aos terceiros as mesmas condições presentes neste Termo.
        </p>
        <p>
          o) Caso o titular solicite a exclusão de seus dados pessoais junto à
          empresa ou caso o período de retenção dos referidos dados seja
          finalizado junto ao CONTROLADOR, o referido evento será encaminhado ao
          CONTROLADOR, caso detenha o referido dado pessoal, para fins de
          descarte dos dados em sua empresa. O encaminhamento da solicitação
          será realizado, preferencialmente, via e-mail ou via carta com AR.
        </p>
        <p>
          p) A Política de Privacidade da empresa, a qual pode ser acessada pelo
          seu endereço eletrônico, é parte complementar ao presente Termo.
        </p>

        <h3>• DISPOSIÇÕES GERAIS</h3>
        <p>
          O USUÁRIO, expressamente, aceita que os APLICATIVOS enviem mensagens
          de caráter informativo e referentes a comunicações específicas
          inerentes ao objeto deste Termo, Anexo e/ou sobre quaisquer ofertas e
          Conteúdos oferecidos pela CORPORACAO.
        </p>
        <p>
          O GRUPO SERVNAC reserva-se o direito de, a seu critério e a qualquer
          tempo, modificar, adicionar ou remover quaisquer cláusulas ou
          condições deste Termo, comunicando o USUÁRIO por qualquer outro
          eletrônico, ou telefonico quando a alteração implicar em restrições
          das condições inicialmente pactuadas.
        </p>
        <p>
          De igual modo, o GRUPO SERVNAC reserva-se o direito de, a seu critério
          e a qualquer tempo, alterar, tanto em forma, como em conteúdo,
          descontinuar, cancelar, suspender quaisquer dos Conteúdos, utilidade
          ou aplicação, disponibilizados por si ou por terceiros, desde que
          comunique o USUÁRIO por qualquer meio eletrônico, ou telefonico não
          implicando qualquer infração ao presente Termos.
        </p>
        <p>
          O GRUPO SERVNAC ou os APLICATIVOS não serão responsáveis pelo
          pagamento de indenizações referente às perdas e danos, lucros
          cessantes, quaisquer danos indiretos e/ou danos diretos incorridos em
          virtude da utilização indevida dos Conteúdos.
        </p>
        <p>
          A presente Política de Privacidade será regido pelas leis brasileiras.
        </p>
      </Content>
    </Container>
  );
};
