import React, { useCallback, useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import jsPDF from 'jspdf';
import QrCode from 'qrcode';
import moment from 'moment';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import ListRegistButton from '../../components/ListRegistButton';
import NewImportExel from '../../components/NewImportExel';
import { NewSearch } from '../../components/NewSearch';
import PaginationComponent from '../../components/Pagination';
import Table from '../../components/Table';
import { TitlePage } from '../../components/TitlePage/styled';
import { sortFunction } from '../../components/UltilitiesFunctions/sortFunction';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import ModalConexao from '../../components/ModalConexao';
import { Filiais } from '../../models/Filiais';

import backgroundImage from '../../assets/background-qrcode.jpg';
import { ContainerPageDefault } from '../../components/ContainerPageDefault/styled';
import {
  BoxItem,
  ButtonForm,
  CheckBox,
  ContainerCadastro,
  ContainerChecklist,
  DivLineBottom,
  HeaderForm,
  InputForm,
  LabelInputForm,
  Names,
  SelectForm,
} from '../../components/ElementsForms/styles';
import { SelectFilial } from '../CadastroSetores/styles';
import { ButtonQrCode, ContainerButtons } from './styles';

interface Ronda {
  id: number;
  name: string;
  companyId: number;
  Setor: {
    id: number;
    name: string;
    companyId: number;
    createdAt: string;
    updatedAt: string;
  };
  Categoria: {
    id: number;
    name: string;
    companyId: number;
    createdAt: string;
    updatedAt: string;
  };
  riscoId: number;
  active: boolean;
  VistoriasNome?: string;
  Vistorias: any[];
  Andar: string;
  createdAt: string;
}

const headerTable = [
  { name: '', field: 'checked', sortable: true },
  { name: 'Qr code', field: 'qrCode', sortable: true },
  { name: 'Filial', field: 'companyId', sortable: true },
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Vistorias', field: 'VistoriasNome', sortable: true },
  { name: 'Setor', field: 'Setor.name', sortable: true },
  { name: 'Andar', field: 'Andar', sortable: true },
  { name: 'Risco', field: 'riscoId', sortable: true },
  { name: 'Categoria', field: 'Categoria.name', sortable: true },
  { name: 'Criada em', field: 'createdAt', sortable: true },
  { name: 'Edit', field: 'editOption', sortable: true },
  { name: 'Remover', field: 'deleteOption', sortable: true },
  { name: 'Ativo', field: 'deleteOptionFilial', sortable: true },
];

const FiltersOptions = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Vistorias', field: 'VistoriasNome', sortable: true },
];

interface Riscos {
  id: number;
  name: string;
  companyId: number;
  color: string;
  createdAt: string;
  updatedAt: string;
}

interface RondaCheck extends Ronda {
  checked: boolean;
}

interface IaddRonda {
  id?: number;
  Andar: string;
  name: string;
  companyId: number;
  riscoId: number;
  active: boolean;
  Setor: {
    id: number;
  };
  Categoria: {
    id: number;
  };
  Vistorias: any[];
}

const CadastroAmbiente: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const { colors } = useContext(ThemeContext);
  const { go } = useHistory();

  const [filiais, setFiliais] = useState<Filiais[]>([]);
  const [rondas, setRondas] = useState<RondaCheck[]>([]);
  const [riscos, setRiscos] = useState<any[]>([]);
  const [setores, setSetores] = useState<any[]>([]);
  const [categorias, setCategorias] = useState<any[]>([]);
  const [vistorias, setVistorias] = useState<Riscos[]>([]);
  const [vistoriasSelected, setVistoriasSelected] = useState<number[]>([]);
  const [rondasFiltered, setRondasFiltered] = useState<RondaCheck[]>([]);
  const [regist, setRegist] = useState(false);
  const [checkedState, setCheckedState] = useState<any[]>([]);
  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const [idRonda, setIdRonda] = useState(0);
  const [filialRonda, setFilialRonda] = useState(0);
  const [nameRonda, setNameRonda] = useState('');
  const [andarRonda, setAndarRonda] = useState('');
  const [riscoRonda, setRiscoRonda] = useState(0);
  const [setorRonda, setSetorRonda] = useState(0);
  const [categoriaRonda, setCategoriaRonda] = useState(0);

  const [loading, setLoading] = useState(false);

  const [addRonda, setAddRonda] = useState<IaddRonda>({
    Andar: '',
    name: '',
    companyId: filialRonda,
    riscoId: 1,
    active: true,
    Setor: {
      id: setorRonda,
    },
    Categoria: {
      id: categoriaRonda,
    },
    Vistorias: vistoriasSelected,
  });

  const handleCancel = (): void => {
    // console.log(addRonda);
  };

  const handleConfirmDelete = useCallback(
    (idRondaDelete: number): void => {
      Swal.fire({
        title: 'Tem certeza que deseja deletar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: colors.success,
        cancelButtonColor: colors.error,
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          const obj = { id: idRondaDelete };
          api
            .delete('/rondas', { data: obj })
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Ronda deleteda com sucesso!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
              setTimeout(() => {
                // setCadastrar(false);
                go(0);
              }, 1000);
            })
            .catch((err) => {
              // console.log(err.response.data);
              if (err.response.data.error) {
                Swal.fire({
                  icon: 'error',
                  title: `${err.response.data.error}`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                  showConfirmButton: false,
                });
              } else {
                console.log('ERRR', err.message);
                Swal.fire({
                  icon: 'error',
                  title: 'Ocorreu um erro ao deletar Ronda!',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                  showConfirmButton: false,
                });
              }
            });
        }
      });
    },
    [colors.error, colors.success, go],
  );

  const handleSubmit = (): void => {
    if (idRonda !== 0) {
      api
        .put('/rondas', {
          id: idRonda,
          Andar: andarRonda,
          name: nameRonda,
          companyId: filialRonda,
          riscoId: riscoRonda,
          active: true,
          Setor: {
            id: setorRonda,
          },
          Categoria: {
            id: categoriaRonda,
          },
          Vistorias: vistoriasSelected.map((e) => {
            return { id: e };
          }),
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Ronda cadastrada com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            setRegist(false);
            // eslint-disable-next-line no-restricted-globals
            history.go(0);
          }, 1000);
        })
        .catch(() => {
          // console.log('Num foi não hein');
        });
    } else {
      api
        .post('/rondas', {
          Andar: andarRonda,
          name: nameRonda,
          companyId: filialRonda,
          riscoId: riscoRonda,
          active: true,
          Setor: {
            id: setorRonda,
          },
          Categoria: {
            id: categoriaRonda,
          },
          Vistorias: vistoriasSelected.map((e) => {
            return { id: e };
          }),
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Ronda cadastrada com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            setRegist(false);
            // eslint-disable-next-line no-restricted-globals
            history.go(0);
          }, 1000);
        })
        .catch(() => {
          // console.log('Num foi não hein');
        });
    }
  };

  const getItems = useCallback(async () => {
    const getFiliais = await api.get(`/empresas/${empresaPrincipal[0].ID}`);

    const getRondas = await api.get(
      `/rondas?companyId=${empresaPrincipal[0].ID}`,
    );
    const getRiscos = await api.get(
      `/riscos?companyId=${empresaPrincipal[0].ID}`,
    );

    const getVistorias = await api.get(
      `/cadVistorias?companyId=${empresaPrincipal[0].ID}`,
    );

    const getSetores = await api.get(
      `/setores?companyId=${empresaPrincipal[0].ID}`,
    );

    const getCategorias = await api.get(
      `/categorias?companyId=${empresaPrincipal[0].ID}`,
    );

    getRondas.data.map((curr: Ronda) => {
      // eslint-disable-next-line no-param-reassign
      curr.VistoriasNome = curr.Vistorias.map(
        (vistoria) => vistoria.name,
      ).toString();
      return curr;
    }, []);

    const allVistorias = getVistorias.data.sort(sortFunction);

    const checked = new Array(allVistorias.length).fill(false);

    const allRond: Ronda[] = getRondas.data;

    setFiliais(getFiliais.data);
    setRondas(allRond.map((i) => ({ ...i, checked: false })));
    setSetores(getSetores.data);
    setCategorias(getCategorias.data);
    setRondasFiltered(allRond.map((i) => ({ ...i, checked: false })));
    setRiscos(getRiscos.data);
    setVistorias(allVistorias);
    setCheckedState(checked);
  }, [empresaPrincipal]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const handleCheckedVist:
    | React.MouseEventHandler<HTMLInputElement>
    | undefined = ({ currentTarget }): void => {
    const position = Number(currentTarget.name.split('-')[1]);
    const { checked } = currentTarget;
    const idVistoria = vistorias[position].id;
    const newVistorias = vistoriasSelected.filter((e) => e !== idVistoria);

    const updatedCheckedState = checkedState.map((item, index) => {
      if (index === position) {
        return !item;
      }
      return item;
    });

    if (checked) {
      setVistoriasSelected([...vistoriasSelected, idVistoria]);
    } else {
      setVistoriasSelected(newVistorias);
    }
    setCheckedState(updatedCheckedState);
  };

  const handleSubmitEdit = async (idRondaEdit: number): Promise<void> => {
    // eslint-disable-next-line no-shadow
    const ronda = rondas.find(({ id }) => id === idRondaEdit);

    ronda?.Vistorias.forEach((e) => {
      checkedState[
        vistorias.findIndex((eVistoria) => eVistoria.id === e.id)
      ] = true;
    });

    setIdRonda(idRondaEdit);
    setNameRonda(ronda?.name!);
    setAndarRonda(ronda?.Andar!);
    setRiscoRonda(ronda?.riscoId!);
    setFilialRonda(ronda?.companyId!);
    setSetorRonda(ronda?.Setor.id!);
    setCategoriaRonda(ronda?.Categoria.id!);
    setVistoriasSelected(ronda?.Vistorias.map((e) => e.id)!);
    setAddRonda({
      id: idRondaEdit,
      Andar: ronda?.Andar!,
      name: ronda?.name!,
      companyId: ronda?.companyId!,
      riscoId: riscoRonda,
      active: ronda?.active!,
      Setor: {
        id: ronda?.Setor.id!,
      },
      Categoria: {
        id: ronda?.Categoria.id!,
      },
      Vistorias: ronda?.Vistorias.map((e) => e.id)!,
    });
  };

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true);
      // console.log('TESTE', idQrCodes);
      const idQrCodes = rondasFiltered.filter((i) => i.checked);
      const doc = new jsPDF('p', 'mm', 'a4'); // eslint-disable-line

      const promises = idQrCodes.map((item) =>
        QrCode.toDataURL(item.id.toString()),
      );

      Promise.all(promises).then((resp) => {
        const { length } = resp;
        const width = 215;
        const height = 300;

        resp.forEach((url, index) => {
          const img = new Image();
          img.src = backgroundImage;
          doc.addImage(img, 'PNG', 0, 0, width, height);
          doc.addImage(url, 'PNG', 56, 85, 100, 100);
          const quebraLinha = doc.splitTextToSize(idQrCodes[index].name, 110);
          doc.text(quebraLinha, 105, 200, {
            maxWidth: 110,
            align: 'center',
          });
          // console.log(index, length, index !== length - 1, 'ADD');
          if (index !== length - 1) {
            doc.addPage();
          }
        });

        doc.autoPrint();
        doc.save(`QRCODES-${moment().format('YYYYMMDD-HHmm')}`);
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  }, [rondasFiltered]);

  return (
    <>
      <ModalConexao />
      <ContainerPageDefault>
        <TitlePage>Rondas</TitlePage>
        <ListRegistButton
          object={{ list: 'Listar', regist: 'Registrar' }}
          setStateCadastrar={setRegist}
          stateCadastrar={regist}
        />
        {!regist && (
          <>
            <NewSearch
              arraySetState={setRondasFiltered}
              arrayState={rondas}
              selectFilters={FiltersOptions}
            />
            <Table
              body={rondasFiltered.filter(
                (e, i) => i >= indexInitPg && i <= indexEndPg,
              )}
              header={headerTable}
              tableId="tableRondas"
              filiais={filiais}
              riscos={riscos}
              setEditId={(idEdit) => {
                handleSubmitEdit(idEdit);
                setRegist(true);
              }}
              setRemoveId={(idEdit) => {
                handleConfirmDelete(idEdit);
              }}
              setCheck={(id) => {
                setRondasFiltered(
                  rondasFiltered.map((i) => {
                    if (i.id === id) {
                      return { ...i, checked: !i.checked };
                    }
                    return i;
                  }),
                );
              }}
            />
            <PaginationComponent
              arrayStateFiltered={rondasFiltered}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />
            <div style={{ display: 'flex', columnGap: 10 }}>
              <NewImportExel fileName="TableRondas" tableId="tableRondas" />
              {rondasFiltered.filter((i) => i.checked).length > 0 && (
                <ButtonQrCode type="button" onClick={handleDownload}>
                  Exportar Qr-Codes Marcados
                </ButtonQrCode>
              )}
            </div>
          </>
        )}
        {regist && (
          <ContainerCadastro>
            <HeaderForm>Cadastro de rondas</HeaderForm>
            <DivLineBottom />
            <SelectFilial
              name="companyId"
              id="companyId"
              onChange={({ currentTarget }) => {
                setFilialRonda(Number(currentTarget.value));
              }}
              style={{ marginBottom: '41px' }}
              value={filialRonda}
            >
              <option value="">Selecione a filial</option>
              {filiais.map((e) => {
                return <option value={e.ID}>{e.NOME.toUpperCase()}</option>;
              })}
            </SelectFilial>
            <LabelInputForm>Nome</LabelInputForm>
            <InputForm
              placeholder="Digite aqui"
              id="name"
              type="text"
              onChange={({ currentTarget }) => {
                setNameRonda(currentTarget.value);
              }}
              value={nameRonda}
            />
            <LabelInputForm>Andar</LabelInputForm>
            <InputForm
              placeholder="Digite aqui"
              id="Andar"
              type="text"
              defaultValue={addRonda.Andar}
              onChange={({ currentTarget }) => {
                setAndarRonda(currentTarget.value);
              }}
              value={andarRonda}
            />
            <LabelInputForm>Riscos</LabelInputForm>
            <SelectForm
              id="riscoId"
              onChange={({ currentTarget }) => {
                setRiscoRonda(Number(currentTarget.value));
              }}
              value={riscoRonda}
              style={{ marginBottom: '41px' }}
            >
              <option value="">Selecionar Risco</option>;
              {riscos.map((e) => {
                return <option value={e.id}>{e.name.toUpperCase()}</option>;
              })}
            </SelectForm>
            <LabelInputForm>Setor</LabelInputForm>
            <SelectForm
              id="Setor"
              onChange={({ currentTarget }) => {
                setSetorRonda(Number(currentTarget.value));
              }}
              value={setorRonda}
              style={{ marginBottom: '41px' }}
            >
              <option value="">Selecionar setor</option>;
              {setores.map((e) => {
                return <option value={e.id}>{e.name.toUpperCase()}</option>;
              })}
            </SelectForm>
            <LabelInputForm>Categoria</LabelInputForm>
            <SelectForm
              id="Categoria"
              onChange={({ currentTarget }) => {
                setCategoriaRonda(Number(currentTarget.value));
              }}
              style={{ marginBottom: '41px' }}
              value={categoriaRonda}
            >
              <option value="">Selecionar categoria</option>;
              {categorias.map((e) => {
                return <option value={e.id}>{e.name.toUpperCase()}</option>;
              })}
            </SelectForm>
            <LabelInputForm>Vistorias</LabelInputForm>
            <ContainerChecklist>
              {vistorias.map((e, i) => {
                return (
                  <BoxItem>
                    <CheckBox
                      value={e.id}
                      type="checkbox"
                      id="Vistorias"
                      name={`vistoria-${i}`}
                      checked={checkedState[i]}
                      onClick={handleCheckedVist}
                    />
                    <Names>{e.name}</Names>
                  </BoxItem>
                );
              })}
            </ContainerChecklist>

            <ContainerButtons>
              <ButtonForm background="#FFFFFF" onClick={handleCancel}>
                Cancelar
              </ButtonForm>
              <ButtonForm background="#393B41" onClick={handleSubmit}>
                Cadastrar
              </ButtonForm>
            </ContainerButtons>
          </ContainerCadastro>
        )}
      </ContainerPageDefault>
    </>
  );
};
export default CadastroAmbiente;
