import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import IconExcluir from '../../assets/excluir.png';
import {
  InputForm,
  LabelInputForm,
} from '../../pages/CadastroCliente copy/styles';
import AutocompleteWrapper from '../AutoComplete';
import {
  ButtomRemoveDate,
  Container,
  ContainerInputDate,
  DivInput,
} from './styled';

export interface Checklist {
  id?: string;
  name: string;
  companyId?: number;
  postoId?: string;
  active?: boolean;
  createdAt?: string;
}
interface Props {
  setTarefas: Dispatch<SetStateAction<Checklist[]>>;
  tarefas: Checklist[];
}

const CheckListAddDate: React.FC<Props> = ({ setTarefas, tarefas }) => {
  const [checklist, setChecklist] = useState<Checklist[]>(tarefas);

  const handleRemoveChecklistState: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ): void => {
    const buttonId = parseInt(event.currentTarget.id, 10);
    const currentChecklist = checklist.filter(
      (element, index) => index !== buttonId,
    );

    setChecklist(currentChecklist);
  };

  const getItems = useCallback(() => {
    setChecklist(tarefas);
  }, [tarefas]);

  useEffect(() => {
    getItems();
  }, [getItems, tarefas]);

  useEffect(() => {
    setTarefas(checklist);
  }, [checklist]);

  return (
    <Container>
      <LabelInputForm>Checklist</LabelInputForm>

      <div className="Autocomplete">
        <AutocompleteWrapper options={checklist} stateOptions={setChecklist} />
      </div>

      <ContainerInputDate>
        {checklist.sort().map((element, index) => {
          if (element.name !== '') {
            return (
              <DivInput style={{ width: '350px' }}>
                <InputForm
                  type="text"
                  value={element.name}
                  disabled
                  style={{
                    width: '250px',
                    padding: '0',
                    border: 'none',
                    marginBottom: '0',
                  }}
                />
                <ButtomRemoveDate
                  type="button"
                  id={index.toString()}
                  onClick={handleRemoveChecklistState}
                  style={{
                    marginRight: '0px',
                    marginTop: 'auto',
                    marginBottom: 'bottom',
                  }}
                >
                  <img src={IconExcluir} alt="Icon excluir" />
                </ButtomRemoveDate>
              </DivInput>
            );
          }
          return null;
        })}
      </ContainerInputDate>
    </Container>
  );
};

export default CheckListAddDate;
