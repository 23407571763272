import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';

import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Users } from '../../models/User';
import { Clientes } from '../../models/Clientes';
import { Filiais } from '../../models/Filiais';
import { ModalEditPreCadastro } from './ModalEdit';
import ListRegistButton from '../../components/ListRegistButton';
import { TitlePage } from '../../components/TitlePage';
import Table from '../../components/Table';
import PaginationComponent from '../../components/Pagination';
import NewImportExel from '../../components/NewImportExel';
import ModalConexao from '../../components/ModalConexao';
import DaysWeek from '../../components/DaysWeek';
import { maskCpf, maskTel } from '../../components/Input/mask';
import { Loading } from '../../components/Loading';
import { validationUser } from './validation';
import api from '../../services/api';

import { ButtonForm, LabelInputForm } from '../CadastroCliente copy/styles';
import {
  BoxMiddle,
  BoxRowInput,
  Container,
  ContainerCadastro,
  HeaderForm,
  InputForm,
} from './styles';

const headers = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'Filial', field: 'IdEmpresaFilial', sortable: true },
  { name: 'Status', field: 'activeSlider', sortable: true },
  { name: 'Editar', field: 'editOptionFilial', sortable: false },
  // { name: 'CNPJ', field: 'CNPJ', sortable: true },
  // { name: 'Status', field: 'ATIVO', sortable: true },
];

// const selectFilterUsers = [
//   { name: 'Nome', field: 'name', sortable: true },
//   { name: 'CPF', field: 'cpf', sortable: true },
//   { name: 'Filial', field: 'IdEmpresaFilial', sortable: true },
//   // { name: 'CNPJ', field: 'CNPJ', sortable: true },
//   // { name: 'Status', field: 'ATIVO', sortable: true },
// ];

export interface RegisterUser {
  cpf: string;
  IdEmpresas: number;
  IdEmpresaFilial: number;
  name: string;
  acesso: number;
  email: string;
  telefone: string;
  postoId?: number;
  diasTrab: string;
  turnoIni: string;
  turnoFim: string;
  origem: string;
  turno: string;
  alternada: boolean;
  dtAdmissao: string;
}

interface UserWithSlide extends Users {
  activeSlider: boolean;
}

const PreCadastroUsuarios: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const history = useHistory();
  const [cadastrar, setCadastrar] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [users, setUsers] = useState<Users[]>([]);
  const [usersFiltered, setUsersFiltered] = useState<UserWithSlide[]>([]);

  const [empresas, setEmpresas] = useState<Filiais[]>([]);
  const [clientes, setClientes] = useState<Clientes[]>([]);

  const [postos, setPostos] = useState<any[]>([]);
  const [turnoSup, setTurnoSup] = useState('Diurno');

  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const [currentFilial, setCurrentFilial] = useState('');
  const [currentCliente, setCurrentCliente] = useState('');
  const [currentPosto, setCurrentPosto] = useState('0');
  const [periodicidade, setPeriodicidade] = useState<string[]>([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [editId, setEditId] = useState(-1);
  const [userEdit, setUserEdit] = useState<RegisterUser>({
    cpf: '',
    IdEmpresas: empresaPrincipal[0].ID,
    IdEmpresaFilial: 0,
    name: '',
    acesso: 99,
    email: '',
    telefone: '',
    postoId: parseInt(currentPosto, 10),
    diasTrab: periodicidade.join(' - '),
    turnoIni: '',
    turnoFim: '',
    origem: 'cad',
    turno: 'Diurno',
    alternada: false,
    dtAdmissao: '',
  });
  const [registNameUser, setRegistNameUser] = useState<RegisterUser>({
    cpf: '',
    IdEmpresas: empresaPrincipal[0].ID,
    IdEmpresaFilial: 0,
    name: '',
    acesso: 99,
    email: '',
    telefone: '',
    postoId: parseInt(currentPosto, 10),
    diasTrab: periodicidade.join(' - '),
    turnoIni: '',
    turnoFim: '',
    origem: 'cad',
    turno: 'Diurno',
    alternada: false,
    dtAdmissao: '',
  });

  const getClientesData = useCallback(async () => {
    try {
      const getClientes = await api.get(
        `/clientes/empresa?empresaId=${empresaPrincipal[0].ID}`,
      );
      setClientes(getClientes.data);
    } catch (e) {
      console.log(e);
    }
  }, [empresaPrincipal]);

  const handleRegisterNewUser = async (e: {
    target: { id: string; value: string };
  }): Promise<void> => {
    const idElement = e.target.id;
    const valueElement = e.target.value;

    // registNameUser.idElement = valueElement;

    if (idElement === 'IdEmpresaFilial') {
      setCurrentFilial(valueElement);
      setRegistNameUser({
        ...registNameUser,
        IdEmpresaFilial: Number(valueElement),
      });
    }

    if (idElement === 'IdCliente') {
      const getPostos = await api.get(
        `/postos/cliente?clienteId=${valueElement}`,
      );

      setCurrentCliente(valueElement);
      setPostos(getPostos.data);
    }

    if (idElement === 'postoId') {
      setCurrentPosto(valueElement);
      setRegistNameUser({
        ...registNameUser,
        postoId: Number(valueElement),
      });
    }
    if (idElement === 'acesso') {
      setRegistNameUser({
        ...registNameUser,
        acesso: Number(valueElement),
      });
    }
    if (idElement === 'name') {
      setRegistNameUser({
        ...registNameUser,
        name: valueElement,
      });
    }
    if (idElement === 'cpf') {
      setRegistNameUser({
        ...registNameUser,
        cpf: maskCpf(valueElement),
      });
    }
    if (idElement === 'email') {
      setRegistNameUser({
        ...registNameUser,
        email: valueElement,
      });
    }
    if (idElement === 'telefone') {
      setRegistNameUser({
        ...registNameUser,
        telefone: maskTel(valueElement),
      });
    }
    if (idElement === 'turnoIni') {
      // Suponhamos que as variáveis time1 e time2 vêm de um input qualquer
      const time1 = registNameUser.turnoIni;
      const time2 = registNameUser.turnoFim;

      const date1 = new Date(`2020-01-01 ${time1}`);
      const date2 = new Date(`2020-01-01 ${time2}`);

      // Verificamos se o primeiro horário é igual, maior ou menor que o segundo
      if (date1.getTime() > date2.getTime()) {
        console.log(`${time1} maior que ${time2}`);
      } else {
        console.log(`${time1} menor que ${time2}`);
      }

      setRegistNameUser({
        ...registNameUser,
        turnoIni: valueElement,
      });
    }
    if (idElement === 'turnoFim') {
      // Suponhamos que as variáveis time1 e time2 vêm de um input qualquer
      const time1 = registNameUser.turnoIni;
      const time2 = registNameUser.turnoFim;

      const date1 = new Date(`2020-01-01 ${time1}`);
      const date2 = new Date(`2020-01-01 ${time2}`);

      // Verificamos se o primeiro horário é igual, maior ou menor que o segundo
      if (date1.getTime() > date2.getTime()) {
        console.log(`${time1} maior que ${time2}`);
      } else {
        console.log(`${time1} menor que ${time2}`);
      }

      setRegistNameUser({
        ...registNameUser,
        turnoFim: valueElement,
      });
    }
    if (idElement === 'typeSeman') {
      setRegistNameUser({
        ...registNameUser,
        alternada: valueElement === 'Alternada',
      });
    }
    if (idElement === 'dtAdmissao') {
      setRegistNameUser({
        ...registNameUser,
        dtAdmissao: valueElement,
      });
    }
  };

  function formatarCPF(str: string): string {
    const CPF: string = str.replace('.', '').replace('.', '').replace('-', '');
    return CPF;
  }

  function formatarTelefone(str: string): string {
    const telefone: string = str
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')
      .replace(' ', '');
    return telefone;
  }

  const handleSubmit = async (): Promise<void> => {
    const obj: RegisterUser = {
      ...registNameUser,
      cpf: formatarCPF(registNameUser.cpf),
      telefone: formatarTelefone(registNameUser.telefone),
      diasTrab: periodicidade.join(' - '),
      turno: turnoSup,
    };

    if (obj.postoId === 0) {
      delete obj.postoId; // Se posto tiver valor 0 da erro no back, aqui estou tirando caso seja 0
    }

    const returnValidation = validationUser({
      ...registNameUser,
      cpf: formatarCPF(registNameUser.cpf),
      telefone: formatarTelefone(registNameUser.telefone),
      diasTrab: periodicidade.join(' - '),
    });

    if (returnValidation === true) {
      setLoading(true);
      api
        .post('/empresas/saveFuncionarios', obj)
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Funcionario cadastrado com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            setCadastrar(false);
            // eslint-disable-next-line no-restricted-globals
            history.go(0);
          }, 1000);
        })
        .catch(() => {
          // console.log(e.message);
          // console.log(e.response.data);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao cadastrar o funcionário!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
        });
    }
  };

  const getItems = useCallback(async (): Promise<void> => {
    const getUsers: Users[] = (await api.get(`/empresas/funcionarios/all`))
      .data;
    const userFilter: Users[] = getUsers.filter(
      (item) => item.IdEmpresas === empresaPrincipal[0].ID,
    );

    const getEmpresas = await api.get(`/empresas/${empresaPrincipal[0].ID}`);

    setEmpresas(getEmpresas.data);
    // setUsers(userFilter);
    setUsersFiltered(
      userFilter.map((i) => ({ ...i, activeSlider: !!i.ATIVO })),
    );
  }, [empresaPrincipal]);

  useEffect(() => {
    getItems();
    getClientesData();
  }, [getClientesData, getItems]);

  const handleUpdateStatus = useCallback(
    (idUser: number, bool: boolean) => {
      api
        .put('/empresas/updateFuncionarios', { ID: idUser, ATIVO: bool })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Status editado com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            history.go(0);
          }, 1000);
        })
        .catch(() => {
          // console.log(e.message);
          // console.log(e.response.data);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao editar o funcionário!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
        });
    },
    [history],
  );

  const clientesOptions: Clientes[] = useMemo(() => {
    return clientes.filter((item) => item.empresaId === Number(currentFilial));
  }, [clientes, currentFilial]);

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage title="Usuários pré cadastrados" />

        <ListRegistButton
          setStateCadastrar={setCadastrar}
          stateCadastrar={cadastrar}
          object={{ list: 'Listar', regist: 'Cadastrar' }}
        />

        {!cadastrar && (
          <>
            <Table
              body={usersFiltered.filter(
                (_, i) => i >= indexInitPg && i <= indexEndPg,
              )}
              header={headers}
              tableId="tableUsers"
              filiais={empresas}
              setEditId={(id: number) => {
                setEditId(id);
                const find = usersFiltered.find((item) => item.ID === id);
                if (find) {
                  setShowModalEdit(true);
                  setUserEdit({
                    ...find,
                    acesso: parseInt(find.acesso, 10),
                    postoId: parseInt(find.postoId, 10),
                  });
                }
              }}
              setUpdateSlide={(id, bool) => {
                handleUpdateStatus(id, bool);
              }}
            />

            <PaginationComponent
              arrayStateFiltered={usersFiltered}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />
            <NewImportExel tableId="tableUsers" fileName="users" />
          </>
        )}
        {cadastrar && (
          <ContainerCadastro>
            <HeaderForm>Pré cadastro</HeaderForm>
            <BoxRowInput>
              <div className="containerInputAndLabel">
                <LabelInputForm>Tipo de acesso:</LabelInputForm>
                <select
                  id="acesso"
                  className="selectStyle"
                  onChange={handleRegisterNewUser}
                >
                  <option value="99">Selecione o tipo</option>
                  <option value="0">Colaborador</option>
                  <option value="1">Coordenador</option>
                  <option value="2">Supervisor</option>
                  <option value="3">Vigilante</option>
                </select>
              </div>
              <div className="containerInputAndLabel">
                <LabelInputForm>Filial:</LabelInputForm>
                <select
                  id="IdEmpresaFilial"
                  className="selectStyle"
                  onChange={handleRegisterNewUser}
                >
                  <option value="">Selecionar Filial</option>
                  {empresas.map((e) => {
                    return (
                      <option
                        key={Math.random()}
                        value={e.ID}
                        selected={e.ID.toString() === currentFilial}
                      >
                        {e.NOME}
                      </option>
                    );
                  })}
                </select>
              </div>
            </BoxRowInput>

            {(registNameUser.acesso === 3 && currentFilial !== '') ||
              (registNameUser.acesso === 0 && currentFilial !== '') ||
              (currentCliente !== '' && (
                <BoxRowInput>
                  {((registNameUser.acesso === 3 && currentFilial !== '') ||
                    (registNameUser.acesso === 0 && currentFilial !== '')) && (
                    <div className="containerInputAndLabel">
                      <LabelInputForm>Selecionar cliente:</LabelInputForm>
                      <select
                        id="IdCliente"
                        className="selectStyle"
                        onChange={handleRegisterNewUser}
                      >
                        <option value="">Selecionar Cliente</option>
                        {clientesOptions.map((e) => {
                          return (
                            <option
                              key={Math.random()}
                              value={e.id}
                              selected={e.id.toString() === currentCliente}
                            >
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}

                  {currentCliente !== '' && (
                    <div className="containerInputAndLabel">
                      <LabelInputForm>Posto:</LabelInputForm>
                      <select
                        id="postoId"
                        className="selectStyle"
                        onChange={handleRegisterNewUser}
                      >
                        <option value="">Selecionar Posto</option>
                        {postos.map((e) => {
                          return (
                            <option
                              key={Math.random()}
                              value={e.id}
                              selected={e.id.toString() === currentPosto}
                            >
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                </BoxRowInput>
              ))}

            {registNameUser.acesso !== 99 && (
              <>
                <BoxRowInput>
                  <div className="containerInputAndLabel">
                    <LabelInputForm>Nome:</LabelInputForm>
                    <InputForm
                      placeholder="Digite aqui"
                      id="name"
                      onChange={handleRegisterNewUser}
                    />
                  </div>

                  <div className="containerInputAndLabel">
                    <LabelInputForm>CPF:</LabelInputForm>
                    <InputForm
                      placeholder="Digite aqui"
                      id="cpf"
                      value={registNameUser.cpf}
                      onChange={handleRegisterNewUser}
                    />
                  </div>
                </BoxRowInput>
                {registNameUser.acesso === 0 && (
                  <BoxRowInput>
                    <div className="containerInputAndLabel">
                      <LabelInputForm>E-mail:</LabelInputForm>
                      <InputForm
                        placeholder="Digite aqui"
                        id="email"
                        onChange={handleRegisterNewUser}
                      />
                    </div>

                    <div className="containerInputAndLabel">
                      <LabelInputForm>Telefone:</LabelInputForm>
                      <InputForm
                        placeholder="Digite aqui"
                        id="telefone"
                        value={registNameUser.telefone}
                        onChange={handleRegisterNewUser}
                      />
                    </div>
                  </BoxRowInput>
                )}
                <BoxRowInput>
                  <div className="containerInputAndLabel">
                    <LabelInputForm>Tipo de Escala</LabelInputForm>
                    <select
                      className="selectStyle"
                      id="typeSeman"
                      onChange={handleRegisterNewUser}
                    >
                      <option value="Dia">Semanal</option>
                      <option value="Alternada">Alternada</option>
                    </select>
                  </div>
                  <div className="containerInputAndLabel">
                    <LabelInputForm>Dias trabalhados:</LabelInputForm>
                    <DaysWeek
                      setPeriodicidade={setPeriodicidade}
                      periodicidade={periodicidade}
                    />
                  </div>
                </BoxRowInput>
                <BoxRowInput>
                  <div className="containerInputAndLabel">
                    <LabelInputForm>Turno de Trabalho</LabelInputForm>
                    <select
                      className="selectStyle"
                      value={turnoSup}
                      onChange={(event) => {
                        // console.log(event.target.value);
                        setTurnoSup(event.target.value);
                      }}
                    >
                      <option value="Diurno">DIURNO</option>
                      <option value="Noturno">NOTURNO</option>
                    </select>
                  </div>
                  <div className="containerInputAndLabel">
                    <LabelInputForm>Data de admissão:</LabelInputForm>
                    <InputForm
                      placeholder="Digite aqui"
                      id="dtAdmissao"
                      type="date"
                      onChange={handleRegisterNewUser}
                    />
                  </div>
                </BoxRowInput>

                {registNameUser.acesso === 3 && (
                  <BoxRowInput>
                    <div className="containerInputAndLabel">
                      <LabelInputForm>Horário inicial do turno:</LabelInputForm>
                      <InputForm
                        placeholder="Digite aqui"
                        id="turnoIni"
                        onChange={handleRegisterNewUser}
                        type="time"
                      />
                    </div>
                    <div className="containerInputAndLabel">
                      <LabelInputForm>Horário final do turno:</LabelInputForm>
                      <InputForm
                        placeholder="Digite aqui"
                        id="turnoFim"
                        onChange={handleRegisterNewUser}
                        type="time"
                      />
                    </div>
                  </BoxRowInput>
                )}

                <div className="containerButtons">
                  <ButtonForm
                    background="#FFFFFF"
                    onClick={() => setCadastrar(false)}
                  >
                    Cancelar
                  </ButtonForm>
                  <ButtonForm background="#393B41" onClick={handleSubmit}>
                    Cadastrar
                  </ButtonForm>
                </div>
              </>
            )}
          </ContainerCadastro>
        )}
        {loading && <Loading />}
        <ModalEditPreCadastro
          isVisible={showModalEdit}
          onClose={() => setShowModalEdit(false)}
          idUser={editId}
          userPre={userEdit}
        />
      </Container>
    </>
  );
};

export default PreCadastroUsuarios;
