import axios from 'axios';

const api = axios.create({
  // baseURL: 'https://385f-191-7-48-185.ngrok-free.app',
  // baseURL: 'https://161.35.50.129/',
  // baseURL: 'https://dev.api.appgestaoqrseguranca.com/',
  baseURL: 'https://api.appgestaoqrseguranca.com/',
});

export default api;
