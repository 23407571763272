import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import Leaflet from 'leaflet';
import {
  MapContainer,
  TileLayer,
  Marker,
  Polyline,
  Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
// import mapboxgl from 'mapbox-gl';
// import ReactMapboxGl, {
//   Layer,
//   Marker as MarkerGL,
//   Source,
//   Feature,
//   // eslint-disable-next-line import/no-unresolved
// } from 'react-mapbox-gl';
import { ThemeContext } from 'styled-components';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { orderByDistance } from 'geolib';

// import CardRotas from '../../components/CardRotas';
// import 'mapbox-gl/dist/mapbox-gl.css';
import { Container, Line } from './styles';
import { Loading } from '../../components/Loading';
import {
  // GetDirectionsGoogleMaps,
  GetDirectionsMapbox,
  // tokenGoogleApi,
  // tokenMapBox,
} from './interface';
// import { Rota } from '../../models/Rota';
// import CardAgendamentoRota from '../../components/CardAgendamentoRota';
import { IreturnGroupRotas } from '../DashboardRotas';
import CardAgendamentoVisita from '../../components/CardAgendamentoVisita';
// import { searchSupervisor } from '../../components/UltilitiesFunctions/searchSupervisor';
import api from '../../services/api';
import {
  mapPoint,
  mapPointActive,
  mapPointAndamento,
  mapPointCancel,
  mapPointInacabado,
  mapPointPendent,
  mapPointSuccess,
} from './mapIcon';

interface Params {
  rota: IreturnGroupRotas;
}

interface PostoOfClient {
  id: number;
  name: string;
  endereco: string;
  latitude: number;
  longitude: number;
  status: string;
}

interface Coord {
  latitude: number;
  longitude: number;
}

interface Coordenates {
  coords: Coord[];
  distance: number;
  time: number;
}

interface AcceptRotaPost {
  supervisorId: number;
  data: string;
  visitas: number[];
}

const DetailsRotaAprov: React.FC = () => {
  // const [modalVisible, setModalVisible] = useState(false);
  const [visitasAprov, setVisitasAprov] = useState<number[]>([]);
  const [visitasReprov, setVisitasReprov] = useState<number[]>([]);
  const history = useHistory();
  const { rota } = useLocation().state as Params;
  const { colors } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const [map, setMap] = useState<Leaflet.Map | undefined | null>(undefined);

  const [rotasAll, setRotasAll] = useState<PostoOfClient[]>([]);
  const [melhorRotaPosto, setMelhorRotaPosto] = useState<PostoOfClient[]>([]);
  const [visibleMelhorRota, setVisibleMelhorRota] = useState(false);
  const [initPosition, setInitPosition] = useState<Coord>({
    latitude: -3.841422,
    longitude: -38.5155225,
  });
  const [coordsMelhor, setCoordsMelhor] = useState<Coordenates>({
    coords: [],
    distance: 0,
    time: 0,
  });
  const [notGetCoordsRota, setNotGetCoordsRota] = useState(false);

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  // const Map = ReactMapboxGl({
  //   accessToken: tokenMapBox,
  // });

  const handleVisit = (id: number, cmd: 'add' | 'rem'): void => {
    const indexId = visitasAprov.findIndex((e) => e === id);
    const indexIdRem = visitasReprov.findIndex((e) => e === id);

    const newVisit = visitasAprov.filter((e) => e !== id);
    const newVisitRem = visitasReprov.filter((e) => e !== id);

    if (cmd === 'add' && indexId === -1) {
      setVisitasAprov([...visitasAprov, id]);
      setVisitasReprov(newVisitRem);
    } else if (cmd === 'rem' && indexIdRem === -1) {
      setVisitasAprov(newVisit);
      setVisitasReprov([...newVisitRem, id]);
    }
  };

  const acceptRotas = useCallback(
    ({ supervisorId, data, visitas }: AcceptRotaPost) => {
      api
        .post(`/rotas/acceptRota`, {
          supervisorId,
          data,
          visitas,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Visitas geradas com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            history.go(0);
          }, 1000);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro gerar aceites das visitas!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
        });
    },
    [history],
  );

  const handleSendAprov = (): void => {
    const sumAprovs = visitasAprov.length + visitasReprov.length;

    if (sumAprovs === rota.Visita.length) {
      acceptRotas({
        supervisorId: rota.supervisorId,
        data: rota.data,
        visitas: visitasAprov,
      });

      /*
      setLoading(true);
      api
        .post(`/rotas/acceptRota`, {
          supervisorId: rota.supervisorId,
          data: rota.data,
          visitas: visitasAprov,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Visitas geradas com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            history.go(0);
          }, 1000);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro gerar aceites das visitas!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
        });
        */
    } else {
      const visitsOutMarked = rota.Visita.length - sumAprovs;
      Swal.fire({
        title: `Tem certeza que deseja cancelar a(s) outra(s) ${visitsOutMarked} visitas?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: colors.success,
        cancelButtonColor: colors.error,
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          acceptRotas({
            supervisorId: rota.supervisorId,
            data: rota.data,
            visitas: visitasAprov,
          });
        }
      });
    }
  };

  // const getCoords2 = useCallback(async (coords: PostoOfClient[]): Promise<
  //   Coordenates
  // > => {
  //   try {
  //     setNotGetCoordsRota(false);
  //     console.log(coords);

  //     const leng = coords.length;
  //     const locations = coords
  //       .map((item, index) => {
  //         if (leng - 1 === index) {
  //           return `${item.longitude},${item.latitude}`;
  //         }
  //         return `${item.longitude},${item.latitude};`;
  //       })
  //       .join('');
  //     console.log(locations);

  //     const locations2 = coords
  //       .map((item) => `${item.longitude},${item.latitude}`)
  //       .join(';');
  //     console.log(locations2);

  //     const resp = await Axios.get(
  //       `https://api.mapbox.com/directions/v5/mapbox/driving/${locations}?geometries=geojson&access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`,
  //     );

  //     console.log('RESP', resp.data);

  //     if (coords.length <= 1) {
  //       return {
  //         coords: [],
  //         distance: 0,
  //         time: 0,
  //       };
  //     }
  //     if (coords.length <= 5) {
  //       const origin = `${coords[0].latitude},${coords[0].longitude}`;
  //       const auxElem = coords.length - 1;
  //       let waypoint = '';
  //       for (let i = 1; i < auxElem; i += 1) {
  //         if (i === 0) {
  //           waypoint = `${coords[i].latitude},${coords[i].longitude}`;
  //         } else {
  //           waypoint = `${waypoint}|${coords[i].latitude},${coords[i].longitude}`;
  //         }
  //       }
  //       const destination = `${coords[auxElem].latitude},${coords[auxElem].longitude}`;

  //       console.log('PRES-----------------');

  //       // const resp = await Axios.get(
  //       //   'https://api.mapbox.com/directions/v5/mapbox/driving/-84.518641,39.134270;-84.512023,39.102779?geometries=geojson&access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w',
  //       // );

  //       // console.log('RESP', resp.data);

  //       // const resp = await fetch(
  //       //   `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
  //       // )
  //       //   .then((response) => response.json())
  //       //   .then((response) => response);
  //       // console.log('POS RESPONE', resp);

  //       // https://api.mapbox.com/directions/v5/mapbox/cycling/-84.518641,39.134270;-84.512023,39.102779?geometries=geojson&access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w'

  //       const response = await apiMapGoogle.get(
  //         `/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
  //       );

  //       // const response = await Axios.get(
  //       //   `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
  //       //   {
  //       //     headers: {
  //       //       'Access-Control-Allow-Origin': '*',
  //       //       'Access-Control-Allow-Methods': 'GET',
  //       //       'Access-Control-Allow-Headers': 'Accept',
  //       //       // 'Origin, X-Requested-With,
  //       //       // 'Content-Type',
  //       //       // 'Accept',
  //       //     },
  //       //   },
  //       // );
  //       console.log('RESP', response);

  //       const getData: GetDirectionsGoogleMaps = response.data;
  //       const coordFinal: Coordenates = {
  //         coords: [],
  //         distance: 0,
  //         time: 0,
  //       };
  //       getData.routes[0].legs.forEach((item) => {
  //         const coordenadas = item.steps.map((itemStep) => {
  //           return [
  //             {
  //               latitude: itemStep.start_location.lat,
  //               longitude: itemStep.start_location.lng,
  //             },
  //             {
  //               latitude: itemStep.end_location.lat,
  //               longitude: itemStep.end_location.lng,
  //             },
  //           ];
  //         });
  //         coordFinal.coords = coordFinal.coords.concat(...coordenadas);
  //         coordFinal.distance += item.distance.value;
  //         coordFinal.time += item.duration.value;
  //       });
  //       return coordFinal;
  //     }
  //     const auxPost = coords.slice(0, 5);
  //     const auxRest = coords.slice(4);
  //     const finalCoords = await getCoords2(auxPost);
  //     const newCoords = await getCoords2(auxRest);

  //     setNotGetCoordsRota(false);

  //     return {
  //       coords: finalCoords.coords.concat(newCoords.coords),
  //       distance: finalCoords.distance + newCoords.distance,
  //       time: finalCoords.time + newCoords.time,
  //     };
  //   } catch (err) {
  //     console.log(err);
  //     console.log(err.response);
  //     setLoading(false);
  //     setNotGetCoordsRota(true);
  //     return {
  //       coords: [],
  //       distance: 0,
  //       time: 0,
  //     };
  //   }
  // }, []);

  const getCoords = useCallback(async (coords: PostoOfClient[]): Promise<
    Coordenates
  > => {
    try {
      setNotGetCoordsRota(false);
      // console.log(coords);

      if (coords.length <= 1) {
        return {
          coords: [],
          distance: 0,
          time: 0,
        };
      }

      const locations = coords
        .map((item) => `${item.longitude},${item.latitude}`)
        .join(';');
      // console.log(locations);

      const resp = await Axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${locations}?geometries=geojson&access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`,
      );

      // console.log('RESP', resp.data);
      const result: GetDirectionsMapbox = resp.data;
      setNotGetCoordsRota(false);

      return {
        coords: result.routes[0].geometry.coordinates.map((item) => ({
          latitude: item[1],
          longitude: item[0],
        })),
        distance: result.routes[0].distance,
        time: result.routes[0].duration,
      };

      // if (coords.length <= 5) {
      //   const origin = `${coords[0].latitude},${coords[0].longitude}`;
      //   const auxElem = coords.length - 1;
      //   let waypoint = '';
      //   for (let i = 1; i < auxElem; i += 1) {
      //     if (i === 0) {
      //       waypoint = `${coords[i].latitude},${coords[i].longitude}`;
      //     } else {
      //       waypoint = `${waypoint}|${coords[i].latitude},${coords[i].longitude}`;
      //     }
      //   }
      //   const destination = `${coords[auxElem].latitude},${coords[auxElem].longitude}`;

      //   console.log('PRES-----------------');

      //   // const resp = await Axios.get(
      //   //   'https://api.mapbox.com/directions/v5/mapbox/driving/-84.518641,39.134270;-84.512023,39.102779?geometries=geojson&access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w',
      //   // );

      //   // console.log('RESP', resp.data);

      //   // const resp = await fetch(
      //   //   `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
      //   // )
      //   //   .then((response) => response.json())
      //   //   .then((response) => response);
      //   // console.log('POS RESPONE', resp);

      //   // https://api.mapbox.com/directions/v5/mapbox/cycling/-84.518641,39.134270;-84.512023,39.102779?geometries=geojson&access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w'

      //   const response = await apiMapGoogle.get(
      //     `/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
      //   );

      //   // const response = await Axios.get(
      //   //   `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${waypoint}&key=${tokenGoogleApi}`,
      //   //   {
      //   //     headers: {
      //   //       'Access-Control-Allow-Origin': '*',
      //   //       'Access-Control-Allow-Methods': 'GET',
      //   //       'Access-Control-Allow-Headers': 'Accept',
      //   //       // 'Origin, X-Requested-With,
      //   //       // 'Content-Type',
      //   //       // 'Accept',
      //   //     },
      //   //   },
      //   // );
      //   console.log('RESP', response);

      //   const getData: GetDirectionsGoogleMaps = response.data;
      //   const coordFinal: Coordenates = {
      //     coords: [],
      //     distance: 0,
      //     time: 0,
      //   };
      //   getData.routes[0].legs.forEach((item) => {
      //     const coordenadas = item.steps.map((itemStep) => {
      //       return [
      //         {
      //           latitude: itemStep.start_location.lat,
      //           longitude: itemStep.start_location.lng,
      //         },
      //         {
      //           latitude: itemStep.end_location.lat,
      //           longitude: itemStep.end_location.lng,
      //         },
      //       ];
      //     });
      //     coordFinal.coords = coordFinal.coords.concat(...coordenadas);
      //     coordFinal.distance += item.distance.value;
      //     coordFinal.time += item.duration.value;
      //   });
      //   return coordFinal;
      // }
      // const auxPost = coords.slice(0, 5);
      // const auxRest = coords.slice(4);
      // const finalCoords = await getCoords(auxPost);
      // const newCoords = await getCoords(auxRest);

      // setNotGetCoordsRota(false);

      // return {
      //   coords: finalCoords.coords.concat(newCoords.coords),
      //   distance: finalCoords.distance + newCoords.distance,
      //   time: finalCoords.time + newCoords.time,
      // };
    } catch (err) {
      console.log(err);
      // console.log(err.response);
      setLoading(false);
      setNotGetCoordsRota(true);
      return {
        coords: [],
        distance: 0,
        time: 0,
      };
    }
  }, []);

  const MelhorRotaCall = useCallback(
    async (showMelhorRota: boolean) => {
      // function distaciaPontos(
      //   coord1: PostoOfClient,
      //   coord2: PostoOfClient,
      // ): number {
      //   function calculo(x1: number, x2: number): number {
      //     const x3 = (x2 - x1) ** 2;
      //     return x3;
      //   }
      //   const resul =
      //     calculo(coord1.latitude, coord2.latitude) +
      //     calculo(coord1.longitude, coord2.longitude);

      //   return Math.sqrt(resul);
      // }

      // function MelhorRota(
      //   coord1: PostoOfClient,
      //   list: PostoOfClient[],
      // ): { distancia: number; resul: PostoOfClient[] } {
      //   if (list.length === 0) {
      //     return { distancia: 0, resul: [coord1] };
      //   }
      //   if (list.length === 1) {
      //     const coord2 = list[0];

      //     const dist = distaciaPontos(coord1, coord2);
      //     const result = MelhorRota(coord2, []);
      //     return {
      //       distancia: dist + result.distancia,
      //       resul: [coord1, ...result.resul],
      //     };
      //   }
      //   let menorDist = 999999999999999999999;
      //   let menorResult: PostoOfClient[] = [];
      //   for (let i = 0; i < list.length; i += 1) {
      //     const newCoord = list[i];
      //     const dist = distaciaPontos(coord1, newCoord);
      //     const newList = list.filter((e, index) => index !== i);
      //     const result = MelhorRota(newCoord, newList);
      //     if (result.distancia + dist < menorDist) {
      //       menorDist = result.distancia + dist;
      //       menorResult = result.resul;
      //     }
      //   }

      //   return {
      //     distancia: menorDist,
      //     resul: [coord1, ...menorResult],
      //   };
      // }

      setLoading(true);

      if (!showMelhorRota) {
        setLoading(false);
      } else if (rotasAll.length > 1) {
        // const result = MelhorRota(
        //   {
        //     endereco: '',
        //     id: -1,
        //     longitude: initPosition.longitude,
        //     latitude: initPosition.latitude,
        //     name: '',
        //     status: 'voce',
        //   },
        //   rotasAll,
        // );
        const firstPosition = {
          latitude: initPosition.latitude,
          longitude: initPosition.longitude,
        };
        const resu = orderByDistance(
          firstPosition,
          rotasAll.map((i) => ({
            latitude: i.latitude,
            longitude: i.longitude,
          })),
        ) as Coord[];
        // console.log(resu);
        const resultOrderVisitas: PostoOfClient[] = [];
        resu.forEach((item) => {
          const find = rotasAll.find(
            (i) =>
              i.latitude === item.latitude && i.longitude === item.longitude,
          );
          if (find !== undefined) {
            // console.log('found rotaUnique', item)
            resultOrderVisitas.push(find);
          }
        });
        // console.log(resultOrderVisitas);
        // setMelhorRotaPosto(resultOrderVisitas.filter((_, index) => index !== 0));
        setMelhorRotaPosto(resultOrderVisitas);
        const coordsPosto = await getCoords(resultOrderVisitas);

        setLoading(false);
        setCoordsMelhor(coordsPosto);
      } else {
        setLoading(false);
      }
    },
    [getCoords, initPosition.latitude, initPosition.longitude, rotasAll],
  );

  const getLoading = useCallback(() => {
    const post: PostoOfClient[] = [];
    rota.Visita.forEach((item) => {
      post.push({
        endereco: item.Posto.endereco,
        id: item.id,
        latitude: item.Posto.latitude,
        longitude: item.Posto.longitude,
        name: item.Posto.name,
        status: item.status || '',
      });
    });

    setLoading(true);
    setRotasAll(post);
    setLoading(false);
  }, [rota]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        setInitPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
    getLoading();
  }, [getLoading]);

  const getPoints = useCallback((status: string) => {
    switch (status) {
      case 'Concluido':
        return mapPointSuccess;
      case 'Concluido com pendencias':
        return mapPointInacabado;
      case 'Andamento':
        return mapPointAndamento;
      case 'Aprovada':
        return mapPointPendent;
      case 'A iniciar':
        return mapPointPendent;
      case 'Cancelado':
        return mapPointCancel;
      case 'Não realizado':
        return mapPointCancel;
      default:
        return mapPoint;
    }
  }, []);

  return (
    <Container>
      <div className="navigate">
        <button type="button" onClick={handleBack}>
          <FiArrowLeft />
        </button>
        <p>Detalhes da Rota</p>
      </div>
      <form>
        <h2>Detalhes da Rota</h2>
        <Line />
        <div className="content">
          <div>
            <p>Rota</p>
            <input disabled value={rota.rotaId} />
          </div>
          <p style={{ width: 14 }} />
          <div>
            <p>Colaborador</p>
            <input disabled value={rota.supervisorName} />
          </div>
        </div>
        <div className="content">
          <div>
            <p>Data</p>
            <input type="date" disabled value={rota.data} />
          </div>
        </div>
        {rota.Visita.filter((i) => i.status !== 'A Aprovar').length > 0 && (
          <div className="content">
            <div>
              <p>Data de Aprovação</p>
              <input type="date" disabled value={rota.dataAprov} />
            </div>
            <p style={{ width: 14 }} />
            <div>
              <p>Coordenador de Aprovação</p>
              <input disabled value={rota.coordenadorName} />
            </div>
          </div>
        )}
        <Line />
        <h2>Detalhamento de visitas</h2>
        {rota.Visita.map((itemVisita, index) => {
          if (itemVisita.status === 'A Aprovar') {
            return (
              <div key={`${itemVisita.id}-${index.toString()}`}>
                <CardAgendamentoVisita
                  nameSupervisor={rota.supervisorName}
                  item={itemVisita}
                  option
                  onAprovar={(id) => {
                    handleVisit(Number(id), 'add');
                  }}
                  onRejeitar={(id) => {
                    handleVisit(Number(id), 'rem');
                  }}
                  // onModal={() => {
                  //   setModalVisible(!modalVisible);
                  // }}
                  // offModal={() => {
                  //   setModalVisible(!modalVisible);
                  // }}
                  // modalVisible={modalVisible}
                />
                <div style={{ height: 16 }} />
              </div>
            );
          }
          return (
            <div key={`${itemVisita.id}-${index.toString()}`}>
              <CardAgendamentoVisita
                nameSupervisor={rota.supervisorName}
                item={itemVisita}
                onAprovar={(id) => {
                  handleVisit(Number(id), 'add');
                }}
                onRejeitar={(id) => {
                  handleVisit(Number(id), 'rem');
                }}
                // onModal={() => {
                //   setModalVisible(!modalVisible);
                // }}
                // offModal={() => {
                //   setModalVisible(!modalVisible);
                // }}
                // modalVisible={modalVisible}
              />
              <div style={{ height: 16 }} />
            </div>
          );
        })}
        {rota.status === 'A Aprovar' && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              id="BtnSend"
              type="button"
              onClick={() => {
                handleSendAprov();
              }}
            >
              Enviar
            </button>
          </div>
        )}
        <Line />
      </form>
      <div className="map">
        <span>Mapa</span>
        <MapContainer
          center={[initPosition.latitude, initPosition.longitude]}
          zoom={12}
          whenCreated={setMap}
          scrollWheelZoom
          style={{ width: '100%', height: '100%' }}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`}
          />
          <Marker
            icon={mapPointActive}
            position={[initPosition.latitude, initPosition.longitude]}
            title="Você está aqui!"
          >
            <Popup>
              <span>Você está aqui!</span>
            </Popup>
          </Marker>
          {rotasAll.map((element, index) => {
            return (
              <div key={index.toString()}>
                <Marker
                  icon={getPoints(element.status)}
                  position={[element.latitude, element.longitude]}
                  keyboard
                  // eventHandlers={{
                  //   click: () => {
                  //     handleEventClickMarkers(mark, index);
                  //   },
                  // }}
                >
                  <Popup>
                    <span>{element.name}</span>
                    <h4>{element.endereco}</h4>
                    <h3>
                      {element.status === 'Aprovada'
                        ? 'A iniciar'
                        : element.status}
                    </h3>
                  </Popup>
                </Marker>
                {/* {activePopup !== null && ( */}
                {/* <Popup
                  position={[
                    filterFloat(activePopup?.LATITUDE_CLIENTE || '0'),
                    filterFloat(activePopup?.LONGITUDE_CLIENTE || '0'),
                  ]}
                  closeButton={false}
                  minWidth={240}
                  maxWidth={240}
                  className="map-popup"
                  eventHandlers={{
                    tooltipclose: () => {
                      setActivePopup(null);
                    },
                  }}
                >
                  <div>
                    <h3>{activePopup?.NOME_CLIENTE}</h3>
                    <p>
                      {activePopup?.ENDERECO_COMPLETO} -{' '}
                      {activePopup?.NOME_SUPERVISOR}
                    </p>
                  </div>
                </Popup> */}
                {/* )} */}
              </div>
            );
          })}
          {/* {title === 'light' && (
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`}
          />
        )}
        {title === 'dark' && (
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w`}
          />
        )} */}
          {loading && (
            <div style={{ position: 'absolute', zIndex: 9999 }}>
              <Loading />
            </div>
          )}
          <Polyline
            positions={coordsMelhor.coords.map((item) => [
              item.latitude,
              item.longitude,
            ])}
            color={colors.primary}
            weight={4}
          />
        </MapContainer>
      </div>
      {/* <div style={{ height: '40px' }} />
      <div className="map">
        <Map
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/mapbox/streets-v11"
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          center={[initPosition.longitude, initPosition.latitude]}
          zoom={[12]}
          movingMethod="flyTo"
        >
          <div>
            <MarkerGL
              key="voceaqui"
              coordinates={[initPosition.longitude, initPosition.latitude]}
              anchor="bottom"
            >
              <ItemMap>
                <img src={logoPoint} alt="Voce esta aqui" />
                <span>Você está aqui!</span>
              </ItemMap>
            </MarkerGL>
            {rotasAll.map((item, index) => {
              return (
                <MarkerGL
                  key={`${item.id}-${index.toString()}`}
                  coordinates={[item.longitude, item.latitude]}
                  anchor="bottom"
                >
                  <ItemMap>
                    <img src={logoPoint} alt={item.name} />
                    <span>{item.name}</span>
                  </ItemMap>
                </MarkerGL>
              );
            })}
            {visibleMelhorRota && (
              <div>
                <Source
                  id="example_id"
                  geoJsonSource={{
                    type: 'geojson',
                    data: {
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: coordsMelhor.coords.map((item) => [
                          item.longitude,
                          item.latitude,
                        ]),
                      },
                      properties: {
                        title: 'Mapbox DC',
                        'marker-symbol': 'monument',
                      },
                    },
                  }}
                />
                <Layer
                  type="line"
                  id="example_id_marker"
                  paint={{
                    'line-color': colors.primary,
                    'line-width': 5,
                  }}
                  sourceId="example_id"
                />
              </div>
            )}
          </div>
        </Map>
      </div> */}
      <div className="resul">
        {!visibleMelhorRota ? (
          <button
            type="button"
            onClick={() => {
              setVisibleMelhorRota(true);
              MelhorRotaCall(true);
            }}
          >
            Visualizar Melhor Rota
          </button>
        ) : (
          <>
            {!notGetCoordsRota ? (
              <div>
                <p>{rota.rotaId}</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <span>
                    Distância: {(coordsMelhor.distance / 1000).toFixed(2)}Km
                  </span>
                  |<span>Duração: {Math.floor(coordsMelhor.time / 60)}min</span>
                </div>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <p>Ordem de visitas</p>
                  {melhorRotaPosto.map((item, index) => (
                    <div
                      style={{ display: 'flex', flexDirection: 'column' }}
                      key={`${item.id}-${index.toString()}`}
                    >
                      <h4 style={{ textAlign: 'center' }}>{item.name}</h4>
                      <span>{item.endereco}</span>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <p>{rota.rotaId}</p>
                <span>Não foi possível identificar rota.</span>
              </div>
            )}
          </>
        )}
      </div>
      <div style={{ height: 50 }} />
      {loading && <Loading />}
    </Container>
  );
};

export default DetailsRotaAprov;
