import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
`;

export const ContainerButton = styled.div`
  margin-top: 30px;
  margin-left: -20px;
  justify-content: space-between;
  align-items: space-between;
  button {
    background-color: transparent;
    height: auto;
    border: 0px;
    color: #ffffff;
  }
  input {
    display: none;
  }
  label {
    cursor: pointer;
    color: #ffffff;
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  padding: 20px;
  h1 {
    margin-bottom: 20px;
    font-size: 16px;
  }
  img {
    height: 200px;
    width: 170px;
    background-color: #ffffff;
    border-radius: 3px;
  }
  input {
    width: 170px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    height: 40px;
    padding: 5px 5px;
    border-radius: 5px;
    border: 0px;
    margin-top: 5px;
    color: #454545;
  }
`;
export const ButtonBorder = styled.div`
  margin-top: 11px;
  background-color: #439133;
  border-radius: 15px;
  border: 0px;
  padding: 10px 16px;
  justify-self: flex-end;
  align-self: flex-end;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: translateX(10px);
  }
`;

export const ContainerHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  overflow-x: auto;
`;
