export const returnNameAcess = (stringIndex: '0' | '1' | '2' | '3'): string => {
  const acessos = {
    '0': 'Colaborador',
    '1': 'Coordenador',
    '2': 'Supervisor',
    '3': 'Vigilante',
  };

  return acessos[stringIndex];
};
