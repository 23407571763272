import React, { useState } from 'react';
import { Container } from './styles';

interface DropDownInterfaceSetor {
  ID_SETOR: number;
  NAME_SETOR: string;
  // onSearch: (arg: string) => void;
}

interface DropDownInterfaceCategoria {
  ID_CATEGORIA: number;
  DESCRICAO: string;
  // onSearch: (arg: string) => void;
}

interface DropDownInterfaceTiposDeLimpezas {
  ID_DIAS_LIMPEZA: number;
  DESCRICAO: string;
}

interface DropDownInterfaceRiscos {
  ID_RISCO: number;
  NAME_RISCO: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DropDown = ({ onChangeItems, objetoEnum, minWidth, valorDrop }: any) => {
  // console.log(`objetoEnum CATEGORIA: ${objetoEnum[0].ID_CATEGORIA}`);
  // console.log(`objetoEnum SETOR: ${objetoEnum[0].ID_SETOR}`);
  const [value, setValue] = useState(valorDrop);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onChangeItem = (valor: string) => {
    setValue(`${valor}`);
    onChangeItems(valor);
  };

  return (
    <Container>
      <select
        style={{ minWidth }}
        value={value}
        onChange={(e) => onChangeItem(e.target.value)}
      >
        {objetoEnum[0].ID_SETOR !== undefined &&
          objetoEnum.map((drop: DropDownInterfaceSetor) => (
            <option key={drop.ID_SETOR} value={drop.NAME_SETOR}>
              {drop.NAME_SETOR}
            </option>
          ))}

        {objetoEnum[0].ID_CATEGORIA !== undefined &&
          objetoEnum.map((drop: DropDownInterfaceCategoria) => (
            <option key={drop.ID_CATEGORIA} value={drop.DESCRICAO}>
              {drop.DESCRICAO}
            </option>
          ))}

        {objetoEnum[0].ID_DIAS_LIMPEZA !== undefined &&
          objetoEnum.map((drop: DropDownInterfaceTiposDeLimpezas) => (
            <option key={drop.ID_DIAS_LIMPEZA} value={drop.DESCRICAO}>
              {drop.DESCRICAO}
            </option>
          ))}

        {objetoEnum[0].ID_RISCO !== undefined &&
          objetoEnum.map((drop: DropDownInterfaceRiscos) => (
            <option key={drop.ID_RISCO} value={drop.NAME_RISCO}>
              {drop.NAME_RISCO}
            </option>
          ))}
      </select>
    </Container>
  );
};

export default DropDown;
