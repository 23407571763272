import { IResultFilter, IActionUpdateFilterMap } from './types';

export function updateFilterMap(
  resultFilter: IResultFilter,
): IActionUpdateFilterMap {
  return {
    type: 'UPDATE_RESULT_FILTER',
    payload: {
      resultFilter,
    },
  };
}
