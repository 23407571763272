import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const ContainerHeader = styled.div`
  display: flex;
  height: 75px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  padding-left: 32px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);

  button.drawer {
    height: 1.2rem;
    width: 1.2rem;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;

    svg {
      height: 100%;
      width: 100%;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const ContainerPerfil = styled.div`
  width: 300px;
  height: 100%;
  margin-left: auto;
  align-items: center;
  display: inline-flex;
`;

export const LineVertical = styled.div`
  width: 2px;
  height: 70%;
  background-color: ${(props) => props.theme.colors.gray5};
`;

export const ContainerUser = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding-left: 16px;
  padding-right: 16px;
`;

export const NameUser = styled.h4`
  margin-top: 18px;
  margin-bottom: 6px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.025em;
  color: ${(props) => props.theme.colors.gray10};
`;
export const UserOffice = styled.h5`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  letter-spacing: 0.025em;

  color: ${(props) => props.theme.colors.gray8};
`;

interface PropsVectorImg {
  status?: string;
}

export const VectorImg = styled.img<PropsVectorImg>`
  margin: auto;
`;

interface PropsModalMenuUser {
  nameClass?: string;
}

export const ModalMenuUser = styled.div<PropsModalMenuUser>`
  align-items: center;
  padding: 16px 0px 0px;
  background: ${(props) => props.theme.colors.white};

  z-index: 3;

  position: absolute;

  width: 300px;
  height: 230px;

  top: 85px;
  right: 5px;

  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding-top: 16px;
`;

export const CardMenuLateral = styled.div`
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;

  position: static;
  width: 100%;
  height: 46px;
  left: 0px;
  top: 0px;

  /* Tons de branco/Branco */

  background: ${(props) => props.theme.colors.white};

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
`;

export const CardMenuLateralLogOff = styled.div`
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  border-top: 2px solid ${(props) => props.theme.colors.gray1};
  border-radius: 0px 0px 10px 10px;

  position: static;
  width: 100%;
  height: 76px;
  left: 0px;
  top: 0px;

  /* Tons de branco/Branco */

  background: ${(props) => props.theme.colors.white3};

  /* Inside Auto Layout */
  margin-top: auto;
`;

export const TitleCard = styled.p`
  /* Label */

  position: static;
  width: 147px;
  height: 14px;
  left: 0px;
  top: 0px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  /* Tons de cinza/Cinza 11 */

  color: ${(props) => props.theme.colors.black4};

  /* Inside Auto Layout */

  order: 1;
  flex-grow: 0;
  margin-right: 5px;
`;

export const TextCard = styled.p`
  /* Label */

  position: static;
  height: 14px;
  left: 36px;
  top: 3px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  /* Tons de cinza/Cinza 12 */

  color: ${(props) => props.theme.colors.black4};

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const ButtonNoneBorder = styled.button`
  border: none;
  background-color: transparent;
`;

export const PhotoUser = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.gray1};

  width: 38px;
  height: 38px;

  margin-left: 20px;
`;

export const LogoApp = styled.h1`
  text-align: center;
  font-size: 20;
  color: ${(props) => props.theme.colors.primary};
  margin-top: auto;
  margin-bottom: auto;
`;

export const LogoAppSeg = styled.h1`
  text-align: center;
  font-weight: 700;
  font-size: 20;
  color: ${(props) => props.theme.colors.primary};
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 5px;
`;
