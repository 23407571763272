import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { FiType, FiEdit2, FiTrash } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ThemeContext } from 'styled-components';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import HeaderTable from '../../components/HeaderTable';
import getValidationErrors from '../../utils/getValidationErrors';
import ModalConexao from '../../components/ModalConexao';
import Input from '../../components/Input';
import Search from '../../components/Search-isolate';
import PaginationComponent from '../../components/Pagination';
import DropDownPagination from '../../components/DropDownPagination';

import {
  Container,
  Aba,
  ContainerAba,
  ContainerCadastro,
  ContainerButton,
  AnimationContainer,
  ContainerTable,
  ContainerSearch,
  ContainerPagination,
  ContainerSemResultados,
} from './styles';

interface SignUpFormData {
  ocorrencia: string;
  ocorrenciaEdit: string;
}

interface ResponseGet {
  companyId: number;
  id: number;
  name: string;
}

interface EmpresasInterface {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

const headers = [
  { name: 'Empresa', field: 'companyId', sortable: true },
  { name: 'Ocorrência', field: 'name', sortable: true },
  { name: 'Editar', field: 'editar', sortable: false },
  { name: 'Remover', field: 'remover', sortable: false },
];

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '30', id: '30' },
  { valor: '40', id: '40' },
  { valor: '50', id: '50' },
  { valor: 'Todos', id: '1' },
];

const CadastroOcorrencia: React.FC = () => {
  const { empresaPrincipal, cpfUserMaster } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);
  const { colors } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [ocorrencia, setProcedimento] = useState('');
  const [editOcorrencia, setEditOcorrencia] = useState('');
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [cadastrar, setCadastrar] = useState(true);
  const [editar, setEditar] = useState(false);
  const [ocorrenciaEdit, setOcorrenciaEdit] = useState<ResponseGet>();
  const [searchEmpresa, setSearchEmpresa] = useState('');
  const [searchOcorrencia, setSearchOcorrencia] = useState('');

  const [empresas] = useState<EmpresasInterface[]>([]);
  const [empresaId, setEmpresaId] = useState<number>(0);
  const [editEmpresaId, setEditEmpresaId] = useState<number>();

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [verificaResponse, setVerificaResponse] = useState(false);

  useEffect(() => {
    setLoading(true);

    api
      .get(
        `ocorrencia/?id=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
        }`,
      )
      .then((data) => {
        api
          .get(
            `empresas/${
              empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
            }`,
          )
          .then((dataTemp) => {
            dataTemp.data.forEach((filial: EmpresasInterface) => {
              if (filial.ATIVO === true) {
                empresas.push(filial);
              }
            });

            setResponse(data.data);
            setVerificaResponse(true);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            if (navigator.onLine) {
              Swal.fire({
                icon: 'info',
                title: 'Erro ao carregar dados, por favor atualize a página!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            }
          });
      })
      .catch(() => {
        setLoading(false);
        if (navigator.onLine) {
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          ocorrencia: Yup.string().required(
            'Nome da ocorrência é obrigatório!',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let verificaOcorrenciaDuplicada = false;
        response.forEach((elementTemp) => {
          if (elementTemp.name.toLowerCase() === ocorrencia.toLowerCase())
            verificaOcorrenciaDuplicada = true;
        });

        if (!verificaOcorrenciaDuplicada) {
          await api
            .post('/ocorrencia/', {
              name: ocorrencia,
              companyId: empresas[empresaId].ID,
            })
            .then(() => {
              setLoading(false);
              Swal.fire({
                icon: 'success',
                title: 'Ocorrência cadastrada com sucesso!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Error ao cadastrar ocorrência!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            });
        } else {
          setLoading(false);
          Swal.fire({
            icon: 'info',
            title: 'Ocorrência duplicada!',
            text: 'Já existe uma ocorrêcia cadastrada com o mesmo nome.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao cadastrar ocorrência!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [empresas, empresaId, ocorrencia, history],
  );

  const handleSubmitEditar = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefEdit.current?.setErrors({});

        const schema = Yup.object().shape({
          ocorrenciaEdit: Yup.string().required(
            'Nome da ocorrência é obrigatório!',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .put(`/ocorrencia/${ocorrenciaEdit?.id}`, {
            name: editOcorrencia,
            companyId: editEmpresaId,
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Ocorrência editada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao editar ocorrência!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRefEdit.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao editar ocorrência!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [ocorrenciaEdit, editOcorrencia, editEmpresaId, history],
  );

  const handleDelete = useCallback(
    async (dados: ResponseGet) => {
      try {
        setLoading(true);

        await api
          .delete(`/ocorrencia/${dados.id}`)
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Ocorrência deletada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao deletar ocorrência!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao deletar ocorrência!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [history],
  );

  const confirmHandleDelete = useCallback(
    (dados: ResponseGet) => {
      Swal.fire({
        title: 'Tem certeza que deseja deletar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: colors.success,
        cancelButtonColor: '#800000',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleDelete(dados);
        }
      });
    },
    [handleDelete, colors],
  );

  const handleAlertMaster = useCallback(() => {
    if (cpfUserMaster.length && cpfUserMaster[0].Id_Empresas === null) {
      Swal.fire({
        icon: 'info',
        title: 'Função bloqueada!',
        text:
          'Usuário espectador master não pode cadastrar uma ocorrência, pois o mesmo não está vinculado à alguma empresa.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else if (verificaResponse) {
      setCadastrar(false);
      setSearchEmpresa('');
      setSearchOcorrencia('');
    }
  }, [cpfUserMaster, verificaResponse]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (searchEmpresa) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.companyId.toString().includes(searchEmpresa),
      );
    }
    if (searchOcorrencia) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.name.toLowerCase().includes(searchOcorrencia.toLowerCase()),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object') {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }
        return (
          reversed *
          a[sorting.field].toString().localeCompare(b[sorting.field].toString())
        );
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    searchEmpresa,
    searchOcorrencia,
    ITEMS_PER_PAGE,
    currentPage,
    sorting,
  ]);

  return (
    <>
      <ModalConexao />
      <Container>
        <Aba>
          <ContainerAba className="aba" cor={cadastrar}>
            <button
              style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
              type="button"
              onClick={() => setCadastrar(true)}
            >
              Listar Ocorrências
            </button>
          </ContainerAba>
          <ContainerAba className="aba1" cor={!cadastrar}>
            <button
              style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
              type="button"
              onClick={handleAlertMaster}
            >
              Cadastrar Ocorrência
            </button>
          </ContainerAba>
        </Aba>

        <ContainerSearch>
          {cadastrar && !editar && (
            <>
              <Search
                className="search4"
                onSearch={(value: string) => {
                  setSearchEmpresa(value);
                }}
                nomePlaceHolder="Buscar Empresa"
              />

              <Search
                className="search4"
                onSearch={(value: string) => {
                  setSearchOcorrencia(value);
                }}
                nomePlaceHolder="Buscar Ocorrência"
              />
            </>
          )}
        </ContainerSearch>

        <ContainerCadastro cor={!cadastrar}>
          {!cadastrar && (
            <AnimationContainer>
              <h1>Cadastro de Ocorrência</h1>
              <Form ref={formRef} onSubmit={handleSubmit}>
                {/* <div className="divDropDown">
                  <p>Filial: </p>
                  <select
                    name="empresa"
                    className="selectEmpresa"
                    onChange={(e) => setEmpresaId(e.target.selectedIndex)}
                  >
                    {empresas.map((empresa) => {
                      return <option key={empresa.ID}>{empresa.NOME}</option>;
                    })}
                  </select>
                </div> */}
                <div>
                  <Input
                    name="ocorrencia"
                    icon={FiType}
                    placeholder="Nome da Ocorrência"
                    onValue={(value) => setProcedimento(value)}
                  />
                </div>
                <ContainerButton>
                  <button id="btnSubmit" type="submit">
                    Cadastrar
                  </button>
                </ContainerButton>
              </Form>
            </AnimationContainer>
          )}
          {cadastrar &&
            !editar &&
            (response.length === 0 ? (
              <ContainerSemResultados>
                <h2>Não existem ocorrências cadastradas ainda.</h2>
              </ContainerSemResultados>
            ) : (
              <div className="divTable">
                <ContainerTable>
                  <table id="tableListagemOcorrencias">
                    <HeaderTable
                      headers={headers}
                      onSorting={(field: string, order: string) =>
                        setSorting({ field, order })
                      }
                    />
                    <tbody>
                      {responseData.map((res) => (
                        <tr key={res.name + res.id}>
                          <td>{res.companyId}</td>
                          <td>{res.name}</td>
                          <td>
                            <button
                              type="button"
                              style={{
                                backgroundColor: '#013700',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                                paddingTop: '4px',
                                paddingBottom: '0px',
                                borderRadius: '5px',
                              }}
                              onClick={() => {
                                setEditar(true);
                                setOcorrenciaEdit(res);
                                setEditEmpresaId(res.companyId);
                                setEditOcorrencia(res.name);
                                setSearchEmpresa('');
                                setSearchOcorrencia('');
                              }}
                            >
                              <FiEdit2 />
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              style={{
                                backgroundColor: '#5F0000',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                                paddingTop: '4px',
                                paddingBottom: '0px',
                                borderRadius: '5px',
                              }}
                              onClick={() => {
                                confirmHandleDelete(res);
                              }}
                            >
                              <FiTrash />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ContainerTable>

                <ContainerPagination>
                  {response.length > 0 && (
                    <>
                      <div className="divPaginacao">
                        {/* <Pagination
                          total={totalItems}
                          itemsPerPage={ITEMS_PER_PAGE}
                          currentPage={currentPage}
                          onPageChange={(page: number) => setCurrentPage(page)}
                        /> */}
                        <DropDownPagination
                          onChangeItems={(value: string) => {
                            setCurrentPage(1);
                            if (value === 'Todos') {
                              setITEMS_PER_PAGE(1);
                            } else {
                              setITEMS_PER_PAGE(Number(value));
                            }
                          }}
                          objetoEnum={dropDown}
                          minWidth={30}
                        />
                      </div>

                      <ReactHTMLTableToExcel
                        id="export-excel"
                        className="btn"
                        table="tableListagemOcorrencias"
                        filename={`listagem-ocorrencias-${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        sheet="tablexls"
                        buttonText="Exportar Excel"
                      />
                    </>
                  )}
                </ContainerPagination>
              </div>
            ))}
        </ContainerCadastro>
        {cadastrar && editar && (
          <ContainerCadastro cor={cadastrar}>
            <AnimationContainer>
              <h1>Editar Ocorrência</h1>
              <Form ref={formRefEdit} onSubmit={handleSubmitEditar}>
                {/* <div className="divDropDown">
                  <p>Filial: </p>
                  <select
                    name="empresa"
                    className="selectEmpresa"
                    onChange={(e) =>
                      setEditEmpresaId(empresas[e.target.selectedIndex].ID)}
                    defaultValue={editCompanyName(editEmpresaId)}
                  >
                    {empresas.map((empresa) => {
                      return <option key={empresa.ID}>{empresa.NOME}</option>;
                    })}
                  </select>
                </div> */}
                <div>
                  <Input
                    name="ocorrenciaEdit"
                    icon={FiType}
                    placeholder="Nome do Ambiente"
                    value={editOcorrencia}
                    onValue={(value) => setEditOcorrencia(value)}
                  />
                </div>
                <ContainerButton>
                  <button
                    id="btnCancel"
                    type="button"
                    onClick={() => setEditar(false)}
                  >
                    Cancelar
                  </button>
                  <button id="btnSave" type="submit">
                    Salvar
                  </button>
                </ContainerButton>
              </Form>
            </AnimationContainer>
          </ContainerCadastro>
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default CadastroOcorrencia;
