interface Props {
  kmInitial: number;
  kmFinal: number;
}

export function getKmRealizado({ kmFinal, kmInitial }: Props): number {
  if (kmInitial <= kmFinal) {
    return kmFinal - kmInitial;
  }
  return kmInitial - kmFinal;
}
