import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import ImgLogin from '../../assets/sign-in-background.jpg';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: inline-flex;
  width: 100%;
  height: 100vh;
  background-color: red;
`;

export const ContainerBoxGrey = styled.div`
  width: 50%;
  height: 100%;
  background-color: #393b41;
  display: flex;
  @media screen and (max-width: 1152px) {
    width: 0%;
  }
`;

export const ContainerBoxWhite = styled.div`
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  overflow: auto;
  @media screen and (max-width: 1152px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: auto;
  min-height: 100vh;
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${appearFromLeft} 1s;

  img {
    margin-top: 10px;
    width: 290px;
    height: 200px;
  }

  form {
    width: 340px;
    text-align: left;
    h1 {
      margin-bottom: 15px;
      color: #2f2f2f;
      font-size: 24px;
      font-weight: 700;
    }

    a {
      color: #2f2f2f;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      font-family: 'Roboto', sans-serif;

      &:hover {
        color: ${shade(0.2, '#2f2f2f')};
      }
    }
  }

  > a {
    color: #2f2f2f;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;

    svg {
      margin-right: 8px;
    }

    &:hover {
      color: ${shade(0.2, '#fff')};
    }

    display: flex;
    align-items: center;
  }
  /**Ele estiliza apenas o 'a' que estar dentro do content. Assim, ele não
  sobrescreve nenhuma estilização de quaisquer outro 'a' que esteja em outro
  componente. */
`;

export const ContainerLogoApp = styled.div`
  width: 360px;
  margin: auto;
`;
export const LogoApp = styled.h1`
  color: #ffffff;
  margin-bottom: auto;
  font-size: 64px;
  text-align: center;
`;

export const LogoAppSeg = styled.h1`
  color: #ffffff;
  margin-bottom: auto;
  font-size: 64px;
  text-align: center;
  font-weight: 700;
`;

export const HeaderInput = styled.h1`
  color: #2f2f2f;
  font-size: 16px !important;
  font-weight: 400;
  margin-top: 44px;
`;

export const LinkForgotPass = styled.h3`
  color: #acacac;
  text-align: center;
  margin-top: 32;
`;

export const TextButton = styled.h3`
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  margin: auto;
`;

export const Line = styled.hr`
  width: 140%;
  margin-left: -20%;
  margin-bottom: 32px;
  margin-top: 32px;
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-left: 0px;
    align-items: flex-start;
  }
`;
