import { shade } from 'polished';
import styled from 'styled-components';

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonQrCode = styled.button`
  background: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.white};
  transition: 0.2s;

  &:hover {
    background: ${(props) => shade(0.2, props.theme.colors.primary)};
  }
`;
