import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { FiGrid, FiSearch } from 'react-icons/fi';
import { IoFilter } from 'react-icons/io5';
import { RiFileExcel2Line } from 'react-icons/ri';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import api from '../../services/api';
// import CardAgendamentoRota from '../../components/CardAgendamentoRota';
import PaginationComponent from '../../components/Pagination';
import {
  Container,
  Title,
  ContentFilter,
  Line,
  List,
  ExportEcel,
  ButtonOption,
  GridUni,
} from './styles';
import { GetRota, Rota } from '../../models/Rota';
import { Loading } from '../../components/Loading';
import ModalConexao from '../../components/ModalConexao';
// import { ModalEditRota } from './ModalEdit';

export const DashboardAgendamentoRota: React.FC = () => {
  const [search, setSearch] = useState('');
  const [selectFilter, setSelectFilter] = useState('');
  const [optionView, setOptionView] = useState(true);
  const [agendamentoRota, setAgendamentoRota] = useState<Rota[]>([]);
  const [rotasAprovar, setRotasAprovar] = useState<Rota[]>([]);
  const [agendamentoFilter, setAgendamentoFilter] = useState<Rota[]>([]);
  const [loading, setLoading] = useState(false);
  // const [isModalEdit, setIsModalEdit] = useState(false);
  // const [rotaEdit, setRotaEdit] = useState<any | null>(null);
  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const renderCard = useCallback((item: Rota, index: number) => {
    return (
      <div className="card" key={`${item.id}-${index.toString()}`}>
        <div className="info">
          <p>{item.visitas.pop()?.status || 'A iniciar'}</p>
          <h3>{item.name}</h3>
          <span>{item.date}</span>
        </div>
      </div>
    );
  }, []);

  const visitToRota = useCallback((visitasRota: GetRota[]): Rota[] => {
    const rot: Rota[] = [];

    visitasRota.forEach((item) => {
      if (item.Visita.Posto.latitude && item.Visita.Posto.longitude) {
        const findIndex = rot.findIndex((i) => {
          return i.date === item.data && i.supervisor.id === item.supervisorId;
        });

        if (findIndex !== -1) {
          rot[findIndex].visitas.push(item);
        } else {
          rot.push({
            date: item.data,
            id: item.rotaId,
            visitas: [item],
            visitasIdGambiarra: item.visitaId,
            name: `Rota ${item.rotaId}`,
            supervisor: {
              id: item.supervisorId,
              name: '',
            },
          });
        }
      }
    });
    return rot;
  }, []);

  const getLoading = useCallback(async () => {
    // const today = moment(new Date()).add(1, 'day').format('YYYY-MM-DD');
    const today = moment(new Date()).format('YYYY-MM-DD');
    setLoading(true);
    api
      .get(`/rotas?dataIni=${today}&dataFim=${today}`)
      .then((resp) => {
        const rotas: GetRota[] = resp.data;
        const visitasInit: GetRota[] = [];
        const visitasApr: GetRota[] = [];
        rotas.forEach((item) => {
          if (item.status === 'A Aprovar') {
            visitasApr.push(item);
          } else if (item.status !== 'Reprovada') {
            visitasInit.push(item);
          }
        });

        // const rotasInit: Rota[] = visitToRota(visitasInit);
        // const rotasApr: Rota[] = visitToRota(visitasApr);
        setLoading(false);
        setAgendamentoRota(visitToRota(visitasInit));
        setRotasAprovar(visitToRota(visitasApr));
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [visitToRota]);

  useEffect(() => {
    getLoading();
  }, [getLoading]);

  const handleAprovar = useCallback((rota: Rota) => {
    setLoading(true);
    api
      .post(`/rotas/acceptRota`, {
        id: rota.id,
        visitas: rota.visitas.map((item) => item.visitaId),
      })
      .then(() => {
        setLoading(false);

        Swal.fire({
          icon: 'success',
          title: 'Rota aceita com sucesso!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          showConfirmButton: false,
        });
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          history.go(0);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        // console.log('ERR', err.message);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao aceitar a rota!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
          showConfirmButton: false,
        });
      });
  }, []);

  const handleRejeitar = useCallback((rota: Rota) => {
    // setLoading(true);
    Swal.fire({
      title: 'Você deseja realmente rejeitar a visita?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar!',
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .post(`/rotas/acceptRota`, {
            id: rota.id,
            visitas: [],
          })
          .then((resp) => {
            setLoading(false);
            // console.log(resp.data);
            Swal.fire(
              'Rejeitada!',
              'A visita foi rejeitada com sucesso.',
              'success',
            );
            setTimeout(() => {
              // eslint-disable-next-line no-restricted-globals
              history.go(0);
            }, 1000);
          })
          .catch((err) => {
            setLoading(false);
            // console.log('ERR', err.message);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao rejeitar a rota!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
          });
      }
    });
  }, []);

  const agendamentosPendente: Rota[] = useMemo(() => {
    let agendsRota: Rota[] = agendamentoRota;
    if (search) {
      agendsRota = agendsRota.filter((item) => item.name.includes(search));
    }
    if (selectFilter === 'Filtro: por semana') {
      agendsRota = agendsRota.filter((item) =>
        item.visitas.find((it) => it.Visita.Posto.typePeriodicidade === 'SEM'),
      );
    }
    if (selectFilter === 'Filtro: por mes') {
      agendsRota = agendsRota.filter((item) =>
        item.visitas.find((it) => it.Visita.Posto.typePeriodicidade === 'MEN'),
      );
    }
    if (selectFilter === 'Filtro: por ano') {
      agendsRota = agendsRota.filter((item) =>
        item.visitas.find((it) => it.Visita.Posto.typePeriodicidade === 'ANO'),
      );
    }

    setAgendamentoFilter(agendsRota);
    return agendsRota;
  }, [agendamentoRota, search, selectFilter]);

  const agendaStatusAIniciar = useMemo(() => {
    return agendamentosPendente.filter((item) =>
      item.visitas.find((it) => it.status === 'Aprovada' || !it.status),
    );
  }, [agendamentosPendente]);
  const agendaStatusFinalizado = useMemo(() => {
    return agendamentosPendente.filter((item) =>
      item.visitas.find((it) => it.status === 'Concluido' || !it.status),
    );
  }, [agendamentosPendente]);
  const agendaStatusAndamento = useMemo(() => {
    return agendamentosPendente.filter((item) =>
      item.visitas.find((it) => it.status === 'Andamento' || !it.status),
    );
  }, [agendamentosPendente]);
  const agendaStatusFimPendent = useMemo(() => {
    return agendamentosPendente.filter((item) =>
      item.visitas.find(
        (it) => it.status === 'Concluido com pendencias' || !it.status,
      ),
    );
  }, [agendamentosPendente]);
  const agendaStatusCancelado = useMemo(() => {
    return agendamentosPendente.filter((item) =>
      item.visitas.find((it) => it.status === 'Não realizado' || !it.status),
    );
  }, [agendamentosPendente]);

  // if (agendamentoRota.length === 0 || rotasAprovar.length === 0) {
  //   return <></>;
  // }

  return (
    <>
      <ModalConexao />
      <Container>
        <Title>Acompanhamento de Rotas</Title>
        <ContentFilter>
          <div className="input">
            <FiSearch />
            <input
              value={search}
              type="text"
              placeholder="Buscar"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          <select
            value={selectFilter}
            onChange={(e) => {
              setSelectFilter(e.target.value);
            }}
          >
            <option value="Todos">Todos</option>
            <option value="Filtro: por semana">Filtro: por semana</option>
            <option value="Filtro: por mes">Filtro: por mês</option>
            <option value="Filtro: por ano">Filtro: por ano</option>
          </select>
          <ButtonOption
            choose={optionView}
            type="button"
            onClick={() => setOptionView(true)}
          >
            <IoFilter />
          </ButtonOption>
          <ButtonOption
            choose={!optionView}
            type="button"
            onClick={() => setOptionView(false)}
          >
            <FiGrid />
          </ButtonOption>
        </ContentFilter>
        {optionView ? (
          <>
            {rotasAprovar.map((item) => {
              return (
                <div>
                  {/* <CardAgendamentoRota
                  typeCard="aprov"
                  item={item}
                  option={false}
                  onAprovar={handleAprovar}
                  onRejeitar={handleRejeitar}
                  onModal={() => {
                    setIsModalEdit(true);
                    setRotaEdit(item);
                  }}
                  offModal={() => {
                    setIsModalEdit(false);
                  }}
                  modalVisible={isModalEdit}
                /> */}
                  <div style={{ height: 16 }} />
                </div>
              );
            })}

            <Line />
            <List>
              {agendamentoRota.map((item, index) => {
                return (
                  <div>
                    {/* <CardAgendamentoRota
                    item={item}
                    key={`${item.id}-${index.toString()}`}
                    option={false}
                    offModal={() => {
                      setIsModalEdit(false);
                    }}
                    modalVisible={false}
                  /> */}
                    <div style={{ height: 16 }} />
                  </div>
                );
              })}
            </List>
            <PaginationComponent
              arrayStateFiltered={agendamentoFilter}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />
          </>
        ) : (
          <div style={{ display: 'flex', overflow: 'auto', marginTop: 30 }}>
            <GridUni>
              <div className="title pen">
                <p>A iniciar</p>
                <div>{agendaStatusAIniciar.length}</div>
              </div>
              {agendaStatusAIniciar.map((item, index) =>
                renderCard(item, index),
              )}
            </GridUni>
            <div style={{ width: 35 }} />
            <GridUni>
              <div className="title fin">
                <p>Finalizada</p>
                <div>{agendaStatusFinalizado.length}</div>
              </div>
              {agendaStatusFinalizado.map((item, index) =>
                renderCard(item, index),
              )}
            </GridUni>
            <div style={{ width: 35 }} />
            <GridUni>
              <div className="title and">
                <p>Em andamento</p>
                <div>{agendaStatusAndamento.length}</div>
              </div>
              {agendaStatusAndamento.map((item, index) =>
                renderCard(item, index),
              )}
            </GridUni>
            <div style={{ width: 35 }} />
            <GridUni>
              <div className="title ina">
                <p>Finalizado com Pendências</p>
                <div>{agendaStatusFimPendent.length}</div>
              </div>
              {agendaStatusFimPendent.map((item, index) =>
                renderCard(item, index),
              )}
            </GridUni>
            <div style={{ width: 35 }} />
            <GridUni>
              <div className="title can">
                <p>Cancelado</p>
                <div>{agendaStatusCancelado.length}</div>
              </div>
              {agendaStatusCancelado.map((item, index) =>
                renderCard(item, index),
              )}
            </GridUni>
          </div>
        )}
        {/* <ModalEditRota
        isOpen={isModalEdit}
        onClose={() => setIsModalEdit(false)}
      /> */}
        <ExportEcel>
          <RiFileExcel2Line />
          <p>Exportar Excel</p>
        </ExportEcel>
        {loading && (
          <div style={{ position: 'absolute', zIndex: 9999 }}>
            <Loading />
          </div>
        )}
      </Container>
    </>
  );
};
