import { Reducer } from 'redux';
import { StateDrawer } from './types';

const INITIAL_STATE: StateDrawer = {
  active: false,
};

const clientRegister: Reducer<StateDrawer> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case 'UPDATE_DRAWER_ACTIVE': {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
export default clientRegister;
