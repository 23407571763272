import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { FiType, FiEdit2, FiTrash } from 'react-icons/fi';
import { IoMdRemove } from 'react-icons/io';
import { MdAdd } from 'react-icons/md';
import { BiUpArrow, BiDownArrow } from 'react-icons/bi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ThemeContext } from 'styled-components';
import { uuid } from 'uuidv4';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import HeaderTable from '../../components/HeaderTable';
import getValidationErrors from '../../utils/getValidationErrors';
import ModalConexao from '../../components/ModalConexao';
import Input from '../../components/Input';
import Search from '../../components/Search-isolate';
import PaginationComponent from '../../components/Pagination';
import DropDownPagination from '../../components/DropDownPagination';
import ModalAddClientes from '../../components/ModalAddClientes';
import MessageAlertRegister from '../../components/MessageAlertRegister';
import { maskCep } from '../../components/Input/mask';

import {
  Container,
  Aba,
  ContainerAba,
  ContainerCadastro,
  ContainerButton,
  AnimationContainer,
  ContainerTable,
  ContainerSearch,
  ContainerPagination,
  ContainerDivCliente,
  ContainerSemResultados,
  ContainerSequentialRote,
  ContainerObservation,
  ContainerClientesGeral,
  ContainerCard,
} from './styles';
import CardRotas from '../../components/CardRotas';

interface SignUpFormData {
  nome: string;
  nomeEdit: string;
}

interface ResponseGet {
  ATIVO: boolean;
  ID: number;
  Id_EmpresaFilial: number;
  NOME: string;
  clientes: ResponseGetClientes[];
  clientesName: string[];
  clientesId: number[];
}

interface ResponseGetClientes {
  A1_BAIRRO: string;
  A1_CEP: string;
  A1_COD: string;
  A1_END: string;
  A1_EST: string;
  A1_MUN: string;
  A1_NOME: string;
  ID_ROTA: number;
  Id_EmpresaFilial: number;
  NOME: string;
  SEQUENCIAL_ROTA: number;
  id_cliente: number;
  id_table_rotasClientes: number;
  id: number;
  status: boolean;
}

interface ResponseGetClientesGeral {
  A1_BAIRRO: string;
  A1_CEP: string;
  A1_COD: string;
  A1_END: string;
  A1_EST: string;
  A1_MUN: string;
  A1_NOME: string;
  ATIVO: boolean;
  ID_A1: number;
  Id_EmpresaFilial: number;
  LATITUDE_CLIENTE: string;
  LONGITUDE_CLIENTE: string;
  id: number;
  id_table_rotasClientes: number;
  status: boolean;
}

interface EmpresasInterface {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

const headers = [
  { name: 'Filial', field: 'Id_EmpresaFilial', sortable: true },
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'Cliente', field: 'clientes', sortable: true },
  { name: 'Editar', field: 'editar', sortable: false },
  { name: 'Remover', field: 'remover', sortable: false },
];

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '30', id: '30' },
  { valor: '40', id: '40' },
  { valor: '50', id: '50' },
  { valor: 'Todos', id: '1' },
];

const CadastroVeiculo: React.FC = () => {
  const { user, empresaPrincipal, cpfUserMaster } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const formRefClients = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);
  const formRefClientsEdit = useRef<FormHandles>(null);
  const { colors } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [cadastrar, setCadastrar] = useState(true);

  const [responsePostRote, setResponsePostRote] = useState<ResponseGet>();
  const [showRegisterClients, setShowRegisterClients] = useState(false);
  const [showRegisterClientsEdit, setShowRegisterClientsEdit] = useState(false);

  const [empresas] = useState<EmpresasInterface[]>([]);
  const [empresaId, setEmpresaId] = useState<number>(0);
  const [editEmpresaId, setEditEmpresaId] = useState<number>();

  const [editar, setEditar] = useState(false);
  const [rotaEdit, setRotaEdit] = useState<ResponseGet>();
  const [nomeEdit, setEditNome] = useState('');

  const [searchNome, setSearchNome] = useState('');
  const [searchCliente, setSearchCliente] = useState('');
  const [searchFilial, setSearchFilial] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);

  const [nome, setNome] = useState('');
  const [idsClientes, setIdsClientes] = useState<number[]>([]);
  const [modalAddClientes, setModalAddClientes] = useState(false);
  const [dadosClientesGeralModal, setDadosClientesGeralModal] = useState<
    ResponseGetClientesGeral[]
  >([]);
  const [dadosClienteModal, setDadosClienteModal] = useState<
    ResponseGetClientes[]
  >([]);
  const [dadosClienteModalCopia, setDadosClienteModalCopia] = useState<
    ResponseGetClientes[]
  >([]);
  const [dadosClienteModalEdit, setDadosClienteModalEdit] = useState<
    ResponseGetClientes[]
  >([]);
  const [divSelecionada, setDivSelecionada] = useState<number>(-1);
  const [dadosClienteModalId, setDadosClienteModalId] = useState<number[]>([]);
  const [dadosClienteModalNome, setDadosClienteModalNome] = useState(['']);
  const [dadosClienteModalEndereco, setDadosClienteModalEndereco] = useState([
    '',
  ]);
  const [dadosClienteModalCep, setDadosClienteModalCep] = useState(['']);
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  let priorityPositionClient = 1;

  useEffect(() => {
    const getItems = async (): Promise<void> => {
      setLoading(true);

      try {
        const responseRotasData = await api.get(
          `rotas/getAllRotas/${
            empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
          }`,
        );
        const responseRotasClientesData = await api.get(
          `rotas/clienteRotas/?id=${
            empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
          }`,
        );
        const responseClientesData = await api.get(
          `/clientes/?companyId=${
            empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
          }`,
        );
        const responseEmpresasFiliaisData = await api.get(
          `empresas/${
            empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
          }`,
        );

        responseEmpresasFiliaisData.data.forEach(
          (filial: EmpresasInterface) => {
            if (filial.ATIVO === true) {
              empresas.push(filial);
            }
          },
        );

        const responseClientesGeralTemp: ResponseGetClientesGeral[] = [];
        responseClientesData.data.forEach(
          (element: ResponseGetClientesGeral) => {
            if (element.LATITUDE_CLIENTE && element.LONGITUDE_CLIENTE)
              responseClientesGeralTemp.push(element);
          },
        );

        const reponseRotasDataTemp: ResponseGet[] = [];
        for (let i = 0; i < responseRotasData.data.length; i += 1) {
          const listClients = [];
          const listClientsName = [];
          const listClientsIds = [];
          for (let j = 0; j < responseRotasClientesData.data.length; j += 1) {
            if (
              responseRotasData.data[i].ID ===
              responseRotasClientesData.data[j].ID_ROTA
            ) {
              listClients.push(responseRotasClientesData.data[j]);
              listClientsName.push(responseRotasClientesData.data[j].A1_NOME);
              listClientsIds.push(
                responseRotasClientesData.data[j].id_table_rotasClientes,
              );
            }
          }

          reponseRotasDataTemp.push({
            ...responseRotasData.data[i],
            clientes: listClients,
            clientesName: listClientsName,
            clientesId: listClientsIds,
          });
        }

        setResponse(reponseRotasDataTemp);
        setDadosClientesGeralModal(responseClientesGeralTemp);
        setVerificaResponse(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        if (navigator.onLine) {
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      }
    };

    getItems();
  }, [empresaPrincipal, empresas, user]);

  const verificaIdsClientes = useCallback((): number[] => {
    if (!cadastrar) {
      dadosClienteModal.forEach((cliente) => {
        if (idsClientes.indexOf(cliente.id_cliente) === -1) {
          if (cliente.id_cliente !== undefined) {
            idsClientes.push(cliente.id_cliente);
          } else {
            idsClientes.push(cliente.id);
          }
        }
      });
    } else {
      dadosClienteModalEdit.forEach((cliente) => {
        if (idsClientes.indexOf(cliente.id_cliente) === -1) {
          if (cliente.id_cliente !== undefined) {
            idsClientes.push(cliente.id_cliente);
          } else {
            idsClientes.push(cliente.id);
          }
        }
      });
    }

    return idsClientes;
  }, [cadastrar, dadosClienteModal, dadosClienteModalEdit, idsClientes]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .post('/rotas', {
            nome,
            stringComparacao: nome.replace(/ /g, '').toLowerCase(),
            Id_EmpresaFilial: empresas[empresaId].ID,
          })
          .then((res) => {
            setResponsePostRote(res.data);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Rota cadastrada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: true,
            });

            setShowRegisterClients(true);
          })
          .catch((err) => {
            setLoading(false);
            if (
              err.response.data.message ===
              'Rota já está cadastrada na base de dados'
            ) {
              Swal.fire({
                icon: 'error',
                title: 'Erro ao cadastrar rota!',
                text: 'A rota já está cadastrada na base de dados.',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Erro ao cadastrar rota!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            }
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao cadastrar rota!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [empresaId, empresas, nome],
  );

  const handleSubmitClients = useCallback(async () => {
    try {
      setLoading(true);

      if (dadosClienteModal.length === 0) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Favor selecionar no mínimo 1 cliente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } else {
        setIdsClientes([]);

        const locacoesSequencialRota = verificaIdsClientes();

        await api
          .post('rotas/createRota', {
            Id_Dom_Rota: responsePostRote?.ID,
            locacoesId: locacoesSequencialRota,
            Id_EmpresaFilial: empresas[empresaId].ID,
            sequencialRota: locacoesSequencialRota,
          })
          .then(() => {
            setIdsClientes([]);
            setLoading(false);

            Swal.fire({
              icon: 'success',
              title: 'Clientes vinculados com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });

            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);

            Swal.fire({
              icon: 'error',
              title: 'Erro ao vincular clientes!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      }
    } catch (error) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Erro ao cadastrar rota!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [
    dadosClienteModal.length,
    empresaId,
    empresas,
    history,
    responsePostRote,
    verificaIdsClientes,
  ]);

  const handleSubmitEditar = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefEdit.current?.setErrors({});

        const schema = Yup.object().shape({
          nomeEdit: Yup.string().required('Nome é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .put(`rotas/changeNomeRota`, {
            id: rotaEdit?.ID,
            nome: nomeEdit,
          })
          .then(() => {
            // setDadosClienteModalEdit([]);
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Rota editada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: true,
            });

            setShowRegisterClientsEdit(true);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao editar rota!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRefEdit.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao editar rota!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [rotaEdit, nomeEdit],
  );

  const handleSubmitClientsEditar = useCallback(async () => {
    try {
      setLoading(true);

      if (dadosClienteModalEdit.length === 0) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Favor selecionar 1 cliente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } else {
        setIdsClientes([]);

        const locacoesSequencialRota = verificaIdsClientes();

        await api
          .post(`rotas/createRota`, {
            Id_Dom_Rota: rotaEdit?.ID,
            locacoesId: locacoesSequencialRota,
            sequencialRota: locacoesSequencialRota,
            Id_EmpresaFilial: editEmpresaId,
          })
          .then(() => {
            setIdsClientes([]);
            setDadosClienteModalEdit([]);
            setLoading(false);

            Swal.fire({
              icon: 'success',
              title: 'Lista de clientes editada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch((err) => {
            setIdsClientes([]);
            setLoading(false);

            if (
              err.response.data.message ===
              'Rota não pode ser editada com o mesmo dia de inicio'
            ) {
              Swal.fire({
                icon: 'error',
                title: 'Erro ao editar lista de clientes!',
                text:
                  'Não foi possível editar pois existem um ou mais agendamentos associados a essa rota para a data atual.',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Erro ao editar lista de clientes!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            }
          });
      }
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao editar lista de clientes!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [
    dadosClienteModalEdit,
    verificaIdsClientes,
    rotaEdit,
    editEmpresaId,
    history,
  ]);

  const handleDelete = useCallback(
    async (dados: ResponseGet) => {
      try {
        setLoading(true);

        await api
          .delete(`/rotas/${dados.ID}`)
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Rota deletada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch((err) => {
            setLoading(false);
            if (
              err.response.data.message.trim() ===
              'Rota não pode ser deletada, pois já possui agendamento'
            ) {
              Swal.fire({
                icon: 'error',
                title: 'Erro ao deletar rota!',
                text:
                  'Não foi possível excluir pois existem um ou mais agendamentos associados a essa rota.',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Erro ao deletar rota!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            }
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao deletar rota!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [history],
  );

  const confirmHandleDelete = useCallback(
    (dados: ResponseGet) => {
      Swal.fire({
        title: 'Tem certeza que deseja deletar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: colors.success,
        cancelButtonColor: '#800000',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleDelete(dados);
        }
      });
    },
    [handleDelete, colors],
  );

  const styleDivClientes = useCallback(
    (idCliente: number) => {
      if (!cadastrar) {
        const newDadosClienteModal = dadosClienteModal.map((cliente) => {
          return cliente.id_table_rotasClientes === idCliente
            ? { ...cliente, status: true }
            : { ...cliente, status: false };
        });

        setDadosClienteModal(newDadosClienteModal);
      } else {
        const newDadosClienteModal = dadosClienteModalEdit.map((cliente) => {
          return cliente.id_table_rotasClientes === idCliente
            ? { ...cliente, status: true }
            : { ...cliente, status: false };
        });

        setDadosClienteModalEdit(newDadosClienteModal);
      }
    },
    [cadastrar, dadosClienteModal, dadosClienteModalEdit],
  );

  const removerDivSelecionada = useCallback(
    (idCliente: number) => {
      if (!cadastrar) {
        if (idCliente !== -1) {
          dadosClienteModal.forEach((cliente, index) => {
            dadosClienteModalCopia.push(cliente);
            if (cliente.id_table_rotasClientes === idCliente) {
              dadosClienteModalCopia.splice(index, 1);
            }
          });

          setDadosClienteModal([...dadosClienteModalCopia]);
          setDadosClienteModalCopia([]);
          setDivSelecionada(-1);
        } else {
          Swal.fire({
            icon: 'info',
            title: 'Favor selecionar um cliente primeiro!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      } else if (idCliente !== -1) {
        dadosClienteModalEdit.forEach((cliente, index) => {
          dadosClienteModalCopia.push(cliente);
          if (cliente.id_table_rotasClientes === idCliente) {
            dadosClienteModalCopia.splice(index, 1);
          }
        });

        setDadosClienteModalEdit([...dadosClienteModalCopia]);
        setDadosClienteModalCopia([]);
        setDivSelecionada(-1);
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Favor selecionar um cliente primeiro!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [
      cadastrar,
      dadosClienteModal,
      dadosClienteModalCopia,
      dadosClienteModalEdit,
    ],
  );

  const verificarClienteDuplicado = useCallback(
    (cliente) => {
      if (!cadastrar) {
        for (let i = 0; i < dadosClienteModal.length; i += 1) {
          dadosClienteModalId.push(dadosClienteModal[i].id_table_rotasClientes);
        }

        if (dadosClienteModal.length === 0) {
          setDadosClienteModal([...dadosClienteModal, cliente]);
        } else if (dadosClienteModalId.indexOf(cliente.id) !== -1) {
          Swal.fire({
            icon: 'info',
            title: 'Cliente duplicado!',
            text: 'O cliente em questão já foi adicionado.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: true,
          });
        } else {
          setDadosClienteModal([...dadosClienteModal, cliente]);
        }
      } else if (dadosClienteModalEdit.length === 0) {
        setDadosClienteModalEdit([cliente]);
      } else {
        for (let i = 0; i < dadosClienteModalEdit.length; i += 1) {
          dadosClienteModalNome.push(dadosClienteModalEdit[i].A1_NOME.trim());
          dadosClienteModalEndereco.push(
            dadosClienteModalEdit[i].A1_END.trim(),
          );
          dadosClienteModalCep.push(dadosClienteModalEdit[i].A1_CEP.trim());
        }

        if (
          dadosClienteModalNome.indexOf(cliente.A1_NOME.trim()) !== -1 &&
          dadosClienteModalEndereco.indexOf(cliente.A1_END.trim()) !== -1 &&
          dadosClienteModalCep.indexOf(cliente.A1_CEP.trim()) !== -1
        ) {
          Swal.fire({
            icon: 'info',
            title: 'Cliente duplicado!',
            text: 'O cliente em questão já foi adicionado.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: true,
          });
        } else {
          setDadosClienteModalEdit(dadosClienteModalEdit.concat(cliente));
        }
      }

      setDadosClienteModalId([]);
      setDadosClienteModalNome([]);
      setDadosClienteModalEndereco([]);
      setDadosClienteModalCep([]);
    },
    [
      cadastrar,
      dadosClienteModal,
      dadosClienteModalCep,
      dadosClienteModalEdit,
      dadosClienteModalEndereco,
      dadosClienteModalId,
      dadosClienteModalNome,
    ],
  );

  const changePosition = useCallback((arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  }, []);

  const handleUpPriorityPosition = useCallback(
    (client: ResponseGetClientes | ResponseGetClientesGeral) => {
      if (!cadastrar) {
        const currentPosition = dadosClienteModal.findIndex(
          (clientTemp) => JSON.stringify(clientTemp) === JSON.stringify(client),
        );

        const dadosClienteModalTemp = changePosition(
          dadosClienteModal,
          currentPosition,
          currentPosition === 0 ? currentPosition : currentPosition - 1,
        );

        setDadosClienteModal([...dadosClienteModalTemp]);
      } else {
        const currentPosition = dadosClienteModalEdit.findIndex(
          (clientTemp) => JSON.stringify(clientTemp) === JSON.stringify(client),
        );

        const dadosClienteModalTemp = changePosition(
          dadosClienteModalEdit,
          currentPosition,
          currentPosition === 0 ? currentPosition : currentPosition - 1,
        );

        setDadosClienteModalEdit([...dadosClienteModalTemp]);
      }
    },
    [cadastrar, changePosition, dadosClienteModal, dadosClienteModalEdit],
  );

  const handleDownPriorityPosition = useCallback(
    (client: ResponseGetClientes | ResponseGetClientesGeral) => {
      if (!cadastrar) {
        const currentPosition = dadosClienteModal.findIndex(
          (clientTemp) => JSON.stringify(clientTemp) === JSON.stringify(client),
        );

        const lastPosition = dadosClienteModal.length - 1;

        const dadosClienteModalTemp = changePosition(
          dadosClienteModal,
          currentPosition,
          currentPosition === lastPosition
            ? currentPosition
            : currentPosition + 1,
        );

        setDadosClienteModal([...dadosClienteModalTemp]);
      } else {
        const currentPosition = dadosClienteModalEdit.findIndex(
          (clientTemp) => JSON.stringify(clientTemp) === JSON.stringify(client),
        );

        const lastPosition = dadosClienteModalEdit.length - 1;

        const dadosClienteModalTemp = changePosition(
          dadosClienteModalEdit,
          currentPosition,
          currentPosition === lastPosition
            ? currentPosition
            : currentPosition + 1,
        );

        setDadosClienteModalEdit([...dadosClienteModalTemp]);
      }
    },
    [cadastrar, changePosition, dadosClienteModal, dadosClienteModalEdit],
  );

  const editCompanyName = useCallback(
    (companyId: number | undefined): string => {
      let reponseName = '';

      empresas.forEach((empresa) => {
        if (empresa.ID === companyId) reponseName = empresa.NOME;
      });

      return reponseName;
    },
    [empresas],
  );

  const handleAlertMaster = useCallback(
    (acao: string, dataEdit?) => {
      if (
        acao === 'cadastrar' &&
        cpfUserMaster.length &&
        cpfUserMaster[0].Id_Empresas === null
      ) {
        Swal.fire({
          icon: 'info',
          title: 'Função bloqueada!',
          text: `Usuário espectador master não pode cadastrar uma nova rota, pois o mesmo não está vinculado à alguma empresa.`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } else if (
        acao === 'editar' &&
        cpfUserMaster.length &&
        cpfUserMaster[0].Id_Empresas === null
      ) {
        Swal.fire({
          icon: 'info',
          title: 'Função bloqueada!',
          text: `Usuário espectador master não pode editar uma rota, pois o mesmo não está vinculado à alguma empresa.`,
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } else if (
        acao === 'editar' &&
        (!cpfUserMaster.length || cpfUserMaster[0].Id_Empresas !== null)
      ) {
        setEditar(true);
        setRotaEdit(dataEdit);
        setEditEmpresaId(dataEdit.Id_EmpresaFilial);
        setEditNome(dataEdit.NOME);
        setDadosClienteModalEdit(dataEdit.clientes);
        setSearchNome('');
        setSearchCliente('');
        setSearchFilial('');
      } else if (verificaResponse) {
        setCadastrar(false);
        setSearchNome('');
        setSearchCliente('');
        setSearchFilial('');
      }
    },
    [cpfUserMaster, verificaResponse],
  );

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (searchFilial) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.Id_EmpresaFilial.toString().includes(searchFilial.toString()),
      );
    }

    if (searchNome) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }

    if (searchCliente) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.clientesName
          .join(', ')
          .toLowerCase()
          .includes(searchCliente.toLowerCase()),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (typeof a[sorting.field] === 'object') {
          return (
            reversed *
            a[sorting.field]
              .join(', ')
              .localeCompare(b[sorting.field].join(', '))
          );
        }
        if (typeof a[sorting.field] === 'string') {
          return reversed * a[sorting.field].localeCompare(b[sorting.field]);
        }
        return (
          reversed *
          a[sorting.field].toString().localeCompare(b[sorting.field].toString())
        );
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    searchFilial,
    searchNome,
    searchCliente,
    sorting,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  return (
    <>
      {modalAddClientes && (
        <ModalAddClientes
          onClose={() => setModalAddClientes(!modalAddClientes)}
          dadosClientesGeral={dadosClientesGeralModal}
          onAdd={(props) => verificarClienteDuplicado(props)}
        />
      )}

      {modalEdit && (
        <MessageAlertRegister
          message={`Ao editar essa rota, todos os seus agendamentos referentes serão DELETADOS, 
          sendo necessário recadastrar todos os agendamentos deletados. Uma medida paliativa seria
          não editar mas sim cadastrar uma NOVA rota com os devidos clientes e vinculá-la ao agendamento 
          referente.
          Obs.: Caso exista algum agendamento marcado para a data de hoje (${moment().format(
            'DD/MM/yyyy',
          )}), a edição não é permitida.`}
          onClickStatus={(status) =>
            status
              ? [setModalEdit(false), handleSubmitClientsEditar()]
              : history.go(0)}
        />
      )}

      <ModalConexao />
      <Container>
        <h1 style={{ marginTop: '10px' }}>Rondas</h1>
        <Aba>
          <ContainerAba className="aba" cor={cadastrar}>
            <button
              style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
              type="button"
              onClick={() => {
                setCadastrar(true);
                setDadosClienteModal([]);
              }}
            >
              Listar Rotas
            </button>
          </ContainerAba>
          <ContainerAba className="aba1" cor={!cadastrar}>
            <button
              style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
              type="button"
              onClick={() => handleAlertMaster('cadastrar')}
            >
              Cadastrar Rota
            </button>
          </ContainerAba>
        </Aba>

        <ContainerSearch>
          {cadastrar && !editar && (
            <>
              <Search
                className="search4"
                onSearch={(value: string) => {
                  setSearchFilial(value);
                }}
                nomePlaceHolder="Buscar Filial"
              />
              <Search
                className="search4"
                onSearch={(value: string) => {
                  setSearchNome(value);
                }}
                nomePlaceHolder="Buscar Nome"
              />
              <Search
                className="search4"
                onSearch={(value: string) => {
                  setSearchCliente(value);
                }}
                nomePlaceHolder="Buscar Cliente"
              />
            </>
          )}
        </ContainerSearch>

        <ContainerCadastro cor={!cadastrar}>
          {!cadastrar && (
            <div>
              <AnimationContainer>
                <h1>Cadastro de Rota</h1>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  {!showRegisterClients && (
                    <>
                      <div className="divDropDown">
                        <p>Filial: </p>
                        <select
                          name="empresa"
                          className="selectEmpresa"
                          onChange={(e) => setEmpresaId(e.target.selectedIndex)}
                        >
                          {empresas.map((empresa) => {
                            return (
                              <option key={empresa.ID}>{empresa.NOME}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div>
                        <Input
                          name="nome"
                          icon={FiType}
                          placeholder="Nome"
                          onValue={(value) => setNome(value)}
                        />
                      </div>
                      <ContainerButton>
                        <button id="btnSubmit" type="submit">
                          Cadastrar
                        </button>
                      </ContainerButton>
                    </>
                  )}

                  {showRegisterClients && (
                    <>
                      <div className="divDropDown">
                        <p>Filial: </p>
                        <select
                          name="empresa"
                          className="selectEmpresa"
                          disabled
                          defaultValue={empresas[empresaId].NOME}
                        >
                          {empresas.map((empresa) => {
                            return (
                              <option key={empresa.ID}>{empresa.NOME}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div>
                        <Input
                          name="nome"
                          icon={FiType}
                          value={nome}
                          disabled
                          placeholder="Nome"
                        />
                      </div>
                    </>
                  )}
                </Form>

                <Form ref={formRefClients} onSubmit={handleSubmitClients}>
                  {showRegisterClients && (
                    <>
                      <ContainerClientesGeral>
                        <div className="divClientesBtn">
                          <h2>Clientes/Postos</h2>
                          <button
                            type="button"
                            onClick={() => {
                              dadosClientesGeralModal.length > 0
                                ? setModalAddClientes(!modalAddClientes)
                                : Swal.fire({
                                    icon: 'info',
                                    title: 'Listagem de clientes/postos vazia!',
                                    text:
                                      'Favor cadastrar ao menos um cliente/posto.',
                                    showClass: {
                                      popup:
                                        'animate__animated animate__fadeInDown',
                                    },
                                    hideClass: {
                                      popup:
                                        'animate__animated animate__fadeOutUp',
                                    },
                                    footer:
                                      '<a href="/cadastro-cliente-posto">Ir para cadastro de clientes/postos</a>',
                                    showConfirmButton: false,
                                  });
                            }}
                          >
                            <MdAdd size={22} />
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              removerDivSelecionada(divSelecionada)}
                          >
                            <IoMdRemove size={22} />
                          </button>
                        </div>

                        <ContainerObservation>
                          <h5>
                            Obs.: Certifíque-se de ajustar a ordem de prioridade
                            para a visita aos clientes/postos
                          </h5>
                        </ContainerObservation>

                        {dadosClienteModal.length === 0 && (
                          <div className="divClientesVazio">
                            <h2>Nenhum cliente/posto selecionado</h2>
                          </div>
                        )}

                        {dadosClienteModal !== undefined &&
                          dadosClienteModal.map((cliente) => (
                            <main key={cliente.id_table_rotasClientes}>
                              <ContainerDivCliente
                                className="divClientes"
                                divSelecionada={cliente.status}
                                onClick={() => [
                                  setDivSelecionada(
                                    cliente.id_table_rotasClientes,
                                  ),
                                  styleDivClientes(
                                    cliente.id_table_rotasClientes,
                                  ),
                                ]}
                              >
                                <div>
                                  <p>
                                    <strong>Nome: </strong>
                                    {cliente.A1_NOME}
                                  </p>
                                  <p>
                                    <strong>COD: </strong>
                                    {cliente.A1_COD}
                                  </p>
                                  <p>
                                    <strong>CEP: </strong>
                                    {maskCep(cliente.A1_CEP)}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <strong>Endereço: </strong>
                                    {cliente.A1_END}
                                  </p>
                                  <p>
                                    <strong>Bairro: </strong>
                                    {cliente.A1_BAIRRO}
                                  </p>
                                  <p>
                                    <strong>Município: </strong>
                                    {cliente.A1_MUN}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <strong>Estado: </strong>
                                    {cliente.A1_EST}
                                  </p>
                                </div>
                              </ContainerDivCliente>

                              <ContainerSequentialRote>
                                <button
                                  type="button"
                                  className="buttonUp"
                                  onClick={() =>
                                    handleUpPriorityPosition(cliente)}
                                >
                                  <BiUpArrow size={24} />
                                </button>
                                <span>{priorityPositionClient++}º</span>
                                <button
                                  type="button"
                                  className="buttonDown"
                                  onClick={() =>
                                    handleDownPriorityPosition(cliente)}
                                >
                                  <BiDownArrow size={24} />
                                </button>
                              </ContainerSequentialRote>
                            </main>
                          ))}
                      </ContainerClientesGeral>

                      <ContainerButton>
                        <button id="btnSubmit" type="submit">
                          Cadastrar
                        </button>
                      </ContainerButton>
                    </>
                  )}
                </Form>
              </AnimationContainer>
            </div>
          )}
          {cadastrar &&
            !editar &&
            (response.length === 0 ? (
              <ContainerSemResultados>
                <h2>Não existem rotas cadastradas ainda.</h2>
              </ContainerSemResultados>
            ) : (
              <>
                {/* <ContainerCard>
                  {responseData.map((item) => {
                    return <CardRotas item={item} />;
                  })}
                </ContainerCard> */}
                <ContainerPagination>
                  {response.length > 0 && (
                    <>
                      <div className="divPaginacao">
                        <DropDownPagination
                          onChangeItems={(value: string) => {
                            setCurrentPage(1);
                            if (value === 'Todos') {
                              setITEMS_PER_PAGE(1);
                            } else {
                              setITEMS_PER_PAGE(Number(value));
                            }
                          }}
                          objetoEnum={dropDown}
                          minWidth={30}
                        />
                        {/* <Pagination
                          total={totalItems}
                          itemsPerPage={ITEMS_PER_PAGE}
                          currentPage={currentPage}
                          onPageChange={(page: number) => setCurrentPage(page)}
                        /> */}
                      </div>

                      <ReactHTMLTableToExcel
                        id="export-excel"
                        className="btn"
                        table="tableListagemRotas"
                        filename={`listagem-rotas-${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        sheet="tablexls"
                        buttonText="Exportar Excel"
                      />
                    </>
                  )}
                </ContainerPagination>
                <div className="divTable">
                  <ContainerTable>
                    <table id="tableListagemRotas">
                      <HeaderTable
                        headers={headers}
                        onSorting={(field: string, order: string) =>
                          setSorting({ field, order })
                        }
                      />
                      <tbody>
                        {responseData.map((res) => (
                          <tr key={uuid()}>
                            <td>
                              {res.Id_EmpresaFilial !== null
                                ? res.Id_EmpresaFilial
                                : '-'}
                            </td>
                            <td>
                              {res.NOME !== null && res.NOME.trim() !== ''
                                ? res.NOME
                                : '-'}
                            </td>
                            <td>
                              {res.clientesName !== undefined &&
                              res.clientesName.length > 0
                                ? res.clientesName.join(', ')
                                : '-'}
                            </td>
                            <td>
                              <button
                                type="button"
                                style={{
                                  backgroundColor: '#013700',
                                  paddingLeft: '30px',
                                  paddingRight: '30px',
                                  paddingTop: '4px',
                                  paddingBottom: '0px',
                                  borderRadius: '5px',
                                }}
                                onClick={() => handleAlertMaster('editar', res)}
                              >
                                <FiEdit2 />
                              </button>
                            </td>
                            <td>
                              <button
                                type="button"
                                style={{
                                  backgroundColor: '#5F0000',
                                  paddingLeft: '30px',
                                  paddingRight: '30px',
                                  paddingTop: '4px',
                                  paddingBottom: '0px',
                                  borderRadius: '5px',
                                }}
                                onClick={() => {
                                  confirmHandleDelete(res);
                                }}
                              >
                                <FiTrash />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </ContainerTable>

                  <ContainerPagination>
                    {response.length > 0 && (
                      <>
                        <div className="divPaginacao">
                          {/* <Pagination
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={(page: number) =>
                              setCurrentPage(page)
                            }
                          /> */}
                          <DropDownPagination
                            onChangeItems={(value: string) => {
                              setCurrentPage(1);
                              if (value === 'Todos') {
                                setITEMS_PER_PAGE(1);
                              } else {
                                setITEMS_PER_PAGE(Number(value));
                              }
                            }}
                            objetoEnum={dropDown}
                            minWidth={30}
                          />
                        </div>

                        <ReactHTMLTableToExcel
                          id="export-excel"
                          className="btn"
                          table="tableListagemRotas"
                          filename={`listagem-rotas-${moment().format(
                            'DD-MM-YYYY',
                          )}`}
                          sheet="tablexls"
                          buttonText="Exportar Excel"
                        />
                      </>
                    )}
                  </ContainerPagination>
                </div>
              </>
            ))}
        </ContainerCadastro>
        {cadastrar && editar && (
          <ContainerCadastro cor={cadastrar}>
            <AnimationContainer>
              <h1>Editar Rota</h1>
              <Form ref={formRefEdit} onSubmit={handleSubmitEditar}>
                {!showRegisterClientsEdit && (
                  <>
                    <div className="divDropDown">
                      <p>Filial: </p>
                      <select
                        name="empresa"
                        className="selectEmpresa"
                        onChange={(e) =>
                          setEditEmpresaId(empresas[e.target.selectedIndex].ID)}
                        defaultValue={editCompanyName(editEmpresaId)}
                      >
                        {empresas.map((empresa) => {
                          return (
                            <option key={empresa.ID}>{empresa.NOME}</option>
                          );
                        })}
                      </select>
                    </div>
                    {/* <div className="divInput">
                      <Input
                        name="filialEdit"
                        icon={FiType}
                        disabled
                        placeholder="Filial"
                        value={editCompanyName(editEmpresaId)}
                      />
                    </div> */}
                    <div className="divInput">
                      <Input
                        name="nomeEdit"
                        icon={FiType}
                        placeholder="Status"
                        value={nomeEdit}
                        onValue={(value) => setEditNome(value)}
                      />
                    </div>
                    <ContainerButton>
                      <button
                        id="btnCancel"
                        type="button"
                        onClick={() => {
                          setEditar(false);
                          setShowRegisterClientsEdit(false);
                          setDadosClienteModalEdit([]);
                        }}
                      >
                        Cancelar
                      </button>
                      <button id="btnSave" type="submit">
                        Salvar e Continuar
                      </button>
                    </ContainerButton>
                  </>
                )}

                {showRegisterClientsEdit && (
                  <>
                    <div className="divDropDown">
                      <p>Filial: </p>
                      <select
                        name="empresa"
                        className="selectEmpresa"
                        disabled
                        defaultValue={editCompanyName(editEmpresaId)}
                      >
                        {empresas.map((empresa) => {
                          return (
                            <option key={empresa.ID}>{empresa.NOME}</option>
                          );
                        })}
                      </select>
                    </div>
                    {/* <div className="divInput">
                      <Input
                        name="filialEdit"
                        icon={FiType}
                        disabled
                        placeholder="Filial"
                        value={editCompanyName(editEmpresaId)}
                      />
                    </div> */}
                    <div className="divInput">
                      <Input
                        name="nomeEdit"
                        icon={FiType}
                        disabled
                        placeholder="Status"
                        value={nomeEdit}
                      />
                    </div>
                  </>
                )}
              </Form>

              <Form
                ref={formRefClientsEdit}
                onSubmit={() => setModalEdit(true)}
              >
                {showRegisterClientsEdit && (
                  <>
                    <ContainerClientesGeral>
                      <div className="divClientesBtn">
                        <h2>Clientes/Postos</h2>
                        <button
                          type="button"
                          onClick={() => {
                            dadosClientesGeralModal.length > 0
                              ? setModalAddClientes(!modalAddClientes)
                              : Swal.fire({
                                  icon: 'info',
                                  title: 'Listagem de clientes/postos vazia!',
                                  text:
                                    'Favor cadastrar ao menos um cliente/posto.',
                                  showClass: {
                                    popup:
                                      'animate__animated animate__fadeInDown',
                                  },
                                  hideClass: {
                                    popup:
                                      'animate__animated animate__fadeOutUp',
                                  },
                                  footer:
                                    '<a href="/cadastro-cliente-posto">Ir para cadastro de clientes/postos</a>',
                                  showConfirmButton: false,
                                });
                          }}
                        >
                          <MdAdd size={22} />
                        </button>
                        <button
                          type="button"
                          onClick={() => removerDivSelecionada(divSelecionada)}
                        >
                          <IoMdRemove size={22} />
                        </button>
                      </div>

                      <ContainerObservation>
                        <h5>
                          Obs.: Certifíque-se de ajustar a ordem de prioridade
                          para a visita aos clientes/postos
                        </h5>
                      </ContainerObservation>

                      {dadosClienteModalEdit.length === 0 && (
                        <div className="divClientesVazio">
                          <h2>Nenhum cliente/posto selecionado</h2>
                        </div>
                      )}

                      {dadosClienteModalEdit !== undefined &&
                        dadosClienteModalEdit.map((cliente) => (
                          <main
                            key={`${cliente.id_table_rotasClientes}-${cliente.NOME}-${cliente.A1_CEP}-${cliente.A1_COD}`}
                          >
                            <ContainerDivCliente
                              className="divClientes"
                              divSelecionada={cliente.status}
                              onClick={() => [
                                setDivSelecionada(
                                  cliente.id_table_rotasClientes,
                                ),
                                styleDivClientes(
                                  cliente.id_table_rotasClientes,
                                ),
                              ]}
                            >
                              <div>
                                <p>Nome: {cliente.A1_NOME}</p>
                                <p>COD: {cliente.A1_COD}</p>
                                <p>CEP: {maskCep(cliente.A1_CEP)}</p>
                              </div>
                              <div>
                                <p>Endereço: {cliente.A1_END}</p>
                                <p>Bairro: {cliente.A1_BAIRRO}</p>
                                <p>Município: {cliente.A1_MUN}</p>
                              </div>
                              <div>
                                <p>Estado: {cliente.A1_EST}</p>
                              </div>
                            </ContainerDivCliente>

                            <ContainerSequentialRote>
                              <button
                                type="button"
                                className="buttonUp"
                                onClick={() =>
                                  handleUpPriorityPosition(cliente)
                                }
                              >
                                <BiUpArrow size={24} />
                              </button>
                              <span>{priorityPositionClient++}º</span>
                              <button
                                type="button"
                                className="buttonDown"
                                onClick={() =>
                                  handleDownPriorityPosition(cliente)
                                }
                              >
                                <BiDownArrow size={24} />
                              </button>
                            </ContainerSequentialRote>
                          </main>
                        ))}
                    </ContainerClientesGeral>

                    <ContainerButton>
                      <button
                        id="btnCancel"
                        type="button"
                        onClick={() => {
                          setEditar(false);
                          setShowRegisterClientsEdit(false);
                          setDadosClienteModalEdit([]);
                        }}
                      >
                        Cancelar
                      </button>
                      <button id="btnSave" type="submit">
                        Salvar
                      </button>
                    </ContainerButton>
                  </>
                )}
              </Form>
            </AnimationContainer>
          </ContainerCadastro>
        )}
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default CadastroVeiculo;
