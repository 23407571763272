import React, {
  SelectHTMLAttributes,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
// import {
//   CategoriasInterface,
//   SetoresInterface,
// } from '../../pages/Cadastros/Ambientes';
import {
  ResponseGetUsuario,
  ResponseGetPeriodicidade,
  ResponseGetEmpresas,
} from '../../pages/CadastroAgendaRonda';

import { Container, Error } from './styles';
import { Ronda } from '../../models/Rondas';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  type?:
    | 'company'
    | 'status'
    | 'categories'
    | 'sectors'
    | 'users'
    | 'environments'
    | 'frequency'
    | 'week';
  defaultValue?: string;
  optionsDataCompany?: ResponseGetEmpresas[];
  optionsDataStatus?: string[];
  // optionsDataCategories?: CategoriasInterface[];
  // optionsDataSectors?: SetoresInterface[];
  optionsDataUsers?: ResponseGetUsuario[];
  optionsDataEnvironments?: Ronda[];
  optionsDataFrequency?: ResponseGetPeriodicidade[];
  widthProps?: string;
  onValue?: (arg: string) => void;
}

export const SelectCustom: React.FC<SelectProps> = ({
  name,
  type = 'company',
  optionsDataCompany,
  optionsDataStatus,
  // optionsDataCategories,
  // optionsDataSectors,
  defaultValue,
  optionsDataUsers,
  optionsDataEnvironments,
  optionsDataFrequency,
  widthProps = '100%',
  onValue,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [valueChange, setValueChange] = useState('');
  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    // Se for vazio será false se estiver preenchido será true.
    setIsFilled(!!selectRef.current?.value);
  }, []);

  const handleSelectChange = useCallback(
    (e) => {
      if (onValue) {
        onValue(e.target.value);
      }
      setValueChange(e.target.value);
    },
    [onValue],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      widthProps={widthProps}
    >
      <select
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        ref={selectRef}
        value={valueChange || ''}
        onChange={(eForChange) => {
          // console.log('asd');
          handleSelectChange(eForChange);
        }}
        {...rest}
      >
        <option value="0 - teste">
          {`Selecionar ${defaultValue}` || 'Selecioar opções'}{' '}
        </option>
        {optionsDataCompany &&
          type === 'company' &&
          optionsDataCompany.map((option) => (
            <option key={option.ID} value={`${option.ID} - ${option.NOME}`}>
              {option.NOME}
            </option>
          ))}

        {optionsDataStatus &&
          ['status', 'week'].includes(type) &&
          optionsDataStatus.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}

        {/* {optionsDataCategories &&
          type === 'categories' &&
          optionsDataCategories.map((option) => (
            <option key={option.ID_CATEGORIA} value={option.DESCRICAO}>
              {option.DESCRICAO}
            </option>
          ))} */}

        {/* {optionsDataSectors &&
          type === 'sectors' &&
          optionsDataSectors.map((option) => (
            <option key={option.id} value={option.name}>
              {option.name}
            </option>
          ))} */}

        {optionsDataUsers &&
          type === 'users' &&
          optionsDataUsers.map((option) => (
            <option key={option.id} value={`${option.id} - ${option.nome}`}>
              {option.nome}
            </option>
          ))}

        {optionsDataEnvironments &&
          type === 'environments' &&
          optionsDataEnvironments.map((option) => (
            <option key={option.id} value={`${option.id} - ${option.name}`}>
              {option.name}
            </option>
          ))}

        {optionsDataFrequency &&
          type === 'frequency' &&
          optionsDataFrequency.map((option) => (
            <option key={option.ID} value={`${option.ID} - ${option.NOME}`}>
              {option.NOME}
            </option>
          ))}
      </select>

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
