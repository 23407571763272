export const returnNameClient = (
  stringIndex: string | undefined,
  client: any[] | undefined,
): string | undefined => {
  if (client?.length !== 0) {
    const nameClient = client?.find((e?) => e.id === stringIndex);
    return nameClient?.name;
  }
  return `${stringIndex}-`;
};
