import React from 'react'; // useMemo, // useEffect, // useState, // useRef, // useCallback,
// import { FiUser, FiType, FiEdit2, FiTrash, FiFile } from 'react-icons/fi';
// import { BiDuplicate } from 'react-icons/bi';
// import { FormHandles } from '@unform/core';
// import { Form } from '@unform/web';
// import * as Yup from 'yup';
// import Swal from 'sweetalert2';
// import { useHistory } from 'react-router-dom';
// // @ts-ignore
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import moment from 'moment';
// import { useAuth } from '../../hooks/auth';
// import { Loading } from '../../components/Loading';
// import api from '../../services/api';
// import getValidationErrors from '../../utils/getValidationErrors';
import ModalConexao from '../../components/ModalConexao';
// import Input from '../../components/Input';
// import DropDownPagination from '../../components/DropDownPagination';
// import Checkbox from '../../components/CheckBox';
// import HeaderTable from '../../components/HeaderTable';
// import Search from '../../components/Search';
// import ModalQrCode from '../../components/ModalQrCode';
// import PaginationComponent from '../../components/Pagination';
// import DrawerApp from '../../components/Drawer';

import { Container } from './styles';

const CadastroOcorrenciaSupervisor: React.FC = () => {
  return (
    <>
      <ModalConexao />
      <Container />
    </>
  );
};

export default CadastroOcorrenciaSupervisor;
