import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 35px;
  height: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h2`
  color: #2f2f2f;
  font-size: 16px;
  height: fit-content;
  font-weight: 700;
  margin: 0;
  margin-left: calc(50% - 83px);
`;

export const ButtonClose = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.error};
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  border-radius: 4px;
  margin-left: auto;

  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 30px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
`;

export const InputDate = styled.input`
  background: ${(props) => props.theme.colors.secondary};
  border: 1px solid ${(props) => props.theme.colors.gray1};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 16px;
  width: 100%;
  display: flex;
  align-items: center;

  & + p {
    margin-top: 18px;
  }

  input {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    border: none;

    flex: 1;
    display: flex;
    align-items: center;

    color: ${(props) => props.theme.colors.black2};

    &::placeholder {
      color: ${(props) => props.theme.colors.gray8};
    }

    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.white} inset;
  }

  input:-webkit-autofill {
    -webkit-text-fill-color: ${(props) => props.theme.colors.gray8} !important;
  }
`;

export const ButtonReport = styled.button`
  width: 48%;
  height: 50px;
  background-color: #393b41;
  color: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.gray12};
  border-radius: 8px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const EmptyText = styled.h2`
  font-size: 24px;
  color: 'grey';
`;
