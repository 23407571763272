import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import { InputOverview } from '../../components/InputOverview';
import ModalConexao from '../../components/ModalConexao';
import { useAuth } from '../../hooks/auth';
import avatar from '../../assets/avatar.png';
import {
  maskTel,
  maskDateOfBirth,
  maskCpf,
} from '../../components/InputOverview/mask';
import Button from '../../components/Button';

import { Container, Content } from './styles';

interface SignUpFormData {
  cpf: string;
  nome: string;
  email: string;
  dataNascimento: string;
  telefone: string;
  passwordOld: string;
  password: string;
  passwordConfirm: string;
  empresaPrincipal: string;
  nivelAcesso: string;
}

const Profile: React.FC = () => {
  const { user, empresaPrincipal, updateUser } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [nome] = useState(user.nome);
  const [cpf] = useState(user.cpf);
  const [email, setEmail] = useState(user.email);
  const [telefone, setTelefone] = useState(user.telefone);
  const [dateNascimento] = useState(user.dataNascimento);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório !'),
          email: Yup.string()
            .required('E-mail obrigatório !')
            .email('Digite um email válido !'),
          dataNascimento: Yup.string().required(
            'Digite sua Data de Nascimento!',
          ),
          telefone: Yup.string().required('Telefone obrigatório !'),
          passwordOld: Yup.string(),
          password: Yup.string().when('passwordOld', {
            is: (val) => !!val.length,
            then: Yup.string().required('Campo Obrigatório'),
            otherwise: Yup.string(),
          }),
          passwordConfirm: Yup.string()
            .when('passwordOld', {
              is: (val) => !!val.length,
              then: Yup.string().required('Campo Obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), null], 'Confirmação Incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { passwordOld, password, passwordConfirm } = data;

        await api
          .put('/users/update', {
            nome: nome.trim(),
            email: email.trim(),
            telefone: telefone.trim(),
            ...(passwordOld
              ? {
                  oldPassword: passwordOld.trim(),
                  newPassword: password.trim(),
                  passwordConfirmation: passwordConfirm.trim(),
                }
              : {}),
          })
          .then((response) => {
            updateUser(response.data.user);
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Dados atualizados!',
            });
            const timer = setTimeout(() => {
              history.go(0);
            }, 2000);
            return () => clearTimeout(timer);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro ao atualizar!',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar',
          description:
            'Ocorreu um erro ao atualizar os dados, por favor verifique os campos.',
        });
      }
    },
    [nome, email, telefone, updateUser, addToast, history],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setLoading(true);
        const data = new FormData();
        data.append('avatar', e.target.files[0]);
        data.append('user_id', user.id);
        api
          .patch('/users/avatar', data)
          .then((response) => {
            updateUser(response.data.user);
            Swal.fire({
              icon: 'success',
              title: 'Foto salva com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);

            setLoading(false);
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Erro ao salvar a Foto!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setLoading(false);
          });
      }
    },
    [history, updateUser, user.id],
  );

  return (
    <>
      <ModalConexao />

      <Container>
        <h1>Meu perfil</h1>

        <Content>
          <section>
            <img
              src={user && user.avatar_url ? user.avatar_url : avatar}
              alt="avatar"
            />

            <article>
              <input type="file" id="avatar" onChange={handleAvatarChange} />
            </article>
          </section>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <section>
              <article>
                <p>Nome completo</p>
                <InputOverview
                  name="nome"
                  placeholder="João Oliveira"
                  value={nome}
                  disabled
                />
              </article>

              <article>
                <p>CPF</p>
                <InputOverview
                  name="cpf"
                  placeholder="000.000.000-00"
                  value={maskCpf(cpf)}
                  disabled
                />
              </article>

              <article>
                <p>E-mail</p>
                <InputOverview
                  name="email"
                  placeholder="joaooliveira@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </article>

              <article>
                <p>Data de nascimento</p>
                <InputOverview
                  name="dataNascimento"
                  placeholder="01/01/1999"
                  value={maskDateOfBirth(dateNascimento)}
                  disabled
                />
              </article>

              <article>
                <p>Telefone</p>
                <InputOverview
                  name="telefone"
                  placeholder="(00) 0 0000-0000"
                  mask="phone"
                  value={maskTel(telefone)}
                  onChange={(e) => setTelefone(e.target.value)}
                />
              </article>

              <article>
                <p>Empresa principal</p>
                <InputOverview
                  name="empresaPrincipal"
                  placeholder="Empresa principal"
                  value={
                    empresaPrincipal !== undefined &&
                    empresaPrincipal !== null &&
                    empresaPrincipal.length > 0
                      ? empresaPrincipal[0].NOME
                      : 'Todas as empresas'
                  }
                  disabled
                />
              </article>

              {/* <article>
                <p>Nível de acesso</p>
                <InputOverview
                  name="nivelAcesso"
                  placeholder="Nível de acesso"
                  value={getNivel}
                  disabled
                />
              </article> */}
            </section>

            <section>
              <article>
                <p>Senha atual</p>
                <InputOverview name="passwordOld" placeholder="********" />
              </article>

              <article>
                <p>Nova senha</p>
                <InputOverview name="password" placeholder="********" />
              </article>

              <article>
                <p>Confirmar senha</p>
                <InputOverview name="passwordConfirm" placeholder="********" />
              </article>
            </section>

            <aside>
              <Button type="submit">Salvar alterações</Button>
            </aside>
          </Form>
        </Content>
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default Profile;
