import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromFront = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.7);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  animation: ${appearFromFront} 300ms;
  position: relative;
  width: 600px;
  height: 500px;
  max-width: 90%;
  max-height: 80%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;

  .teste {
    max-height: 93%;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #999999;
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px #999999;
      background-color: #b3b3b3;
    }
  }

  .zoomImg {
    animation: ${appearFromFront} 300ms;
    border-radius: 10px;
    background-color: ${shade(0.2, '#fff')};
    border: 1px solid #800000;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    width: 560px;
    height: 445px;

    div {
      border-radius: 10px;
    }
  }
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: transparent;
    padding: 2px;
    margin-left: auto;
  }
`;

export const ContainerHeader = styled.div`
  .historicoVazio {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    background-color: #d9d9d9;
    padding: 15px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;

    p {
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
    }
  }
`;

export const ContainerHeaderDados = styled.div`
  display: flex;
  margin: 0 20px 0 20px;
  justify-content: center;
  .titulo {
    font-weight: bold;
    margin-top: 20px;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
  }
  p {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 18px;
  }
  strong {
    background-color: #d9d9d9;
    color: #000;
    padding: 10px;
    border-radius: 10px;
  }
`;

export const ContainerButtonAtualizar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 50%;
  right: 50%;
  button {
    padding: 10px;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    outline: none;
    min-width: 200px;

    background: ${(props) => props.theme.colors.success};
    &:hover {
      background: ${(props) => props.theme.colors.success};
    }
  }
`;

export const ContainerButtonsPdfExcel = styled.div`
  position: absolute;
  display: flex;
  bottom: -20px;
  left: 82%;
  right: 50%;
  padding: 0;
  margin: 0;

  svg {
    margin-bottom: -5px;
  }

  div {
    #pdf {
      padding: 10px;
      color: #ffffff;
      border-radius: 5px;
      border: none;
      outline: none;
      background-color: #b30000;
      min-width: 40px;

      &:hover {
        background-color: ${shade(0.2, '#b30000')};
      }
    }
    #excel {
      padding: 10px;
      color: #ffffff;
      border-radius: 5px;
      border: none;
      outline: none;
      margin-left: 5px;
      background-color: #006600;
      min-width: 40px;

      &:hover {
        background-color: ${shade(0.2, '#006600')};
      }
    }
  }
`;

export const ContainerButtonDetalhes = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 50%;
  right: 50%;

  button {
    padding: 10px;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    outline: none;
    min-width: 93px;
    background: ${(props) => props.theme.colors.success};
    &:hover {
      background: ${(props) => props.theme.colors.success};
    }

    & + button {
      margin-left: 14px;
      background-color: #800000;

      &:hover {
        background-color: ${shade(0.2, '#800000')};
      }
    }
  }
`;

export const ContainerMap = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  background-color: #d9d9d9;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
  animation: ${appearFromFront} 300ms;

  #iconImgNull {
    background-color: #fff;
    border-radius: 50%;
    border: none;
    outline: none;
    width: 128px;
    height: 128px;
    transition: transform 0s;
    cursor: auto;

    &:hover {
      transform: scale(1);
    }

    svg {
      color: #262626;
      margin-bottom: -5px;
    }
  }

  .btnDetalhes {
    background-color: ${(props) => props.theme.colors.black3};
    border-radius: 5px;
    border: none;
    outline: none;
    width: 110px;
    height: 110px;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
      background-color: ${(props) => shade(0.2, props.theme.colors.black3)};
    }

    svg {
      color: #fff;
      margin-bottom: -5px;
    }
  }

  .btnImg {
    background-color: transparent;
    border: none;
    outline: none;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.08);
    }
    img {
      width: 128px;
      height: 128px;
      border-radius: 50%;
    }
  }

  div {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 16px;
    p {
      margin: 6px 0 6px 20px;
    }
    strong {
      color: #000;
      font-weight: bold;
    }
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  overflow-x: auto;

  .divSearch {
    display: flex;
    & + .divSearch {
      margin-left: 25px;
    }
    div {
      div {
        background-color: #d9d9d9;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      svg {
        margin-bottom: 2px;
      }
      & + div {
        margin-left: 25px;
      }
    }
  }

  .search1 {
    background-color: #d9d9d9;
    width: 227px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const ContainerTableMacon = styled.div`
  display: none;
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 35vh;
  overflow-x: auto;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 10px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;
