import React from 'react';
import { CircleButtonSlider, Slider } from './styles';

interface IsliderButton {
  activeStatus: boolean;
  onUpdate: () => void;
}

export const SliderButtonDefault: React.FC<IsliderButton> = ({
  activeStatus,
  onUpdate,
}) => {
  return (
    <div>
      <Slider active={activeStatus}>
        <CircleButtonSlider
          style={{ border: 'none' }}
          active={activeStatus}
          type="button"
          onClick={onUpdate}
        />
      </Slider>
    </div>
  );
};
