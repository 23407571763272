import React from 'react';
import { ContainerBackground, ContainerModal, BodyModal } from './styled';

interface Props {
  component?: React.FC;
  isVisible: boolean;
  id?: string;
  children: React.ReactNode;
}

const NewModal: React.FC<Props> = ({ children, isVisible, id = '' }) => {
  return (
    <>
      <ContainerBackground isVisible={isVisible} />
      <ContainerModal isVisible={isVisible}>
        <BodyModal id={id}>{children}</BodyModal>
      </ContainerModal>
    </>
  );
};

export default NewModal;
