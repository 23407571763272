import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  cor: boolean;
}

interface ContainerPropsIcons {
  cor?: string;
}

interface ContainerPropsButtons {
  cor?: boolean;
}

interface ContainerPropsSearch {
  filter: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
`;

const appearFromTop = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to{
    opacity: 0;
    transform: translateY(-18px);
  }
`;

export const ContainerBackground = styled.div`
  display: flex;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #3c3946;
`;

export const Content = styled.div`
  flex: 1;
  justify-content: space-between;
  overflow: hidden;

  .itensMap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -15px;
  }

  .itemAmbiente {
    color: #fff;
    background: ${(props) => props.theme.colors.success};
    width: 100%;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    border-radius: 5px;
    border: none;
    outline: none;
    display: flex;
    transition: background-color 0.3s;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &:hover {
      background-color: ${(props) => shade(0.3, props.theme.colors.success)};
    }

    strong {
      display: flex;
      font-size: 18px;
    }

    hr {
      margin-top: 2px;
      width: 100%;
      background-color: #f0f0f0;
      border: none;
      height: 0.1px;
    }

    svg {
      margin-left: auto;
      color: #fff;
    }
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.success};
  padding-top: 12px;
  border-radius: 5px 5px 0px 0px;

  header {
    width: 100%;
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.colors.success};
  }

  img {
    width: 64px;
    height: 64px;
    margin-top: 0px;
    margin-right: 5px;
    padding: 6px;
  }
`;

export const Title = styled.h1`
  display: flex;
  color: #fff;
  margin-top: 3px;
  margin-bottom: 3px;
  font-family: 'Roboto', sans-serif;
  line-height: 25px;

  div {
    margin-top: 9px;
    font-size: 25px;
  }

  p {
    margin: 0;
    font-size: 16px;
  }
`;

export const ContainerItem = styled.div<ContainerPropsIcons>`
  display: flex;
  transition: transform 0.2s;
  border-radius: 5px;
  margin-bottom: 15px;
  padding-right: 15px;
  animation: ${appearFromRight} 1s;
  width: 50%;

  &:hover {
    transform: translateY(-8px);
  }

  header {
    width: 100%;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonShowFilter = styled.button<ContainerPropsSearch>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  min-width: 60px;
  min-height: 29px;
  border: none;
  outline: none;
  border-radius: 0 7px 7px 0;
  background: ${(props) => props.theme.colors.primary};
  transition: 0.2s;
  position: absolute;
  top: 178px;
  margin-left: 8px;
  border-left: ${(props) =>
    props.filter
      ? `3.8px ${props.theme.colors.success} solid`
      : `3.8px ${props.theme.colors.gray2} solid`};
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    svg {
      margin: 0px 13px 3px 9px;
    }
  }
  &:hover {
    filter: brightness(1.1);
    transform: scale(1.1);
  }
`;

export const ContainerTitleSecond = styled.div`
  height: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  margin-bottom: 26px;

  .button {
    padding-top: 10px;
    padding-bottom: 8px;
    border-radius: 0px 0px 5px 5px;
    background: ${(props) => props.theme.colors.success};
    text-align: center;
    text-transform: uppercase;
    position: sticky;
    overflow: hidden;
    transition: 0.3s;
    &:after {
      position: absolute;
      transition: 0.3s;
      content: '';
      width: 0;
      left: 50%;
      bottom: 0;
      background: ${(props) => props.theme.colors.success};
    }
    &:nth-of-type(1):after {
      height: 120%;
      left: -10%;
      transform: skewX(15deg);
      z-index: -1;
    }
    &:hover {
      //cursor: pointer;
      &:nth-of-type(1) {
        //color: #5bcaff;
      }
      &:nth-of-type(1):after {
        left: -10%;
        width: 120%;
      }
    }
  }
`;

export const ContainerSemResultados = styled.div`
  width: auto;
  border-radius: 10px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.success};
  h2 {
    margin: 0;
    text-align: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: #fbfbfb;
  width: 100%;
  height: 100%;
  /* background-color: grey; */
  padding: 30px 30px 30px 30px;
  flex-direction: column;
  h1 {
    strong {
      font-weight: 700;
    }
  }
`;

export const ContainerSearch = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-template-columns: 1fr 0.05fr;
  gap: 20px;
`;

export const ContainerTable = styled.div`
  font-family: 'Montserrat', 'sans-serif';

  max-width: 100%;
  max-height: calc(100vh - 401px);
  margin-top: 30px;
  border-radius: 4px;
  overflow: auto;
`;

export const DivSelectFilter = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: black;
  width: 350px;
  height: 48px;
  border-radius: 4px;
  background-color: #fff;
  position: flex;
  padding-top: 13px;
  padding-left: 10px;
  strong {
    font-weight: 700;
  }
`;
export const SelectFilter = styled.select`
  border: none;
`;

export const ContainerTypeVisualization = styled.div`
  display: inline-flex;
  div {
    height: 48px;
    width: 50px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: 10px;
    svg {
      margin: 7px auto;
    }
    #card {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: block;
      path {
        fill: #b0b0b0;
      }
    }
    #list {
      border-radius: 50%;
      width: 24px;
      height: 36px;
      display: block;

      path {
        fill: #b0b0b0;
      }
    }
  }
`;

export const ContainerExportAndPag = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerFilter = styled.div`
  margin-left: auto;
  margin-right: auto;
  select {
    font-weight: 700;
    color: #444444;
    width: 160px;
    height: 50px;
    border: none;
  }
  span {
    color: black;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  align-items: center;
  justify-content: center;
  input {
    margin-top: 5px;
    font-family: Montserrat, 'sans-serif';
    font-weight: 500;
    color: #ffffff;
    width: 250px;
    height: 40px;
    margin-right: 2px;
    background-color: #393b41;
    border-radius: 4px;
    border: none;
  }
`;
