import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { FiRefreshCw } from 'react-icons/fi';

import { useAuth } from '../../hooks/auth';
import { GetRotaComplete } from '../../models/Rota';
import { UsuarioBD } from '../../models/User';
import { Clientes } from '../../models/Clientes';
import Table from '../../components/Table';
import PaginationComponent from '../../components/Pagination';
import NewImportExel from '../../components/NewImportExel';
import ModalConexao from '../../components/ModalConexao';
import api from '../../services/api';
import { getInfoDetailsOfCoords } from '../../utils/getRouterDurationAndTimeOfRota';
import { formatedTimebySeconds } from '../../utils/formatedTimeByMinuts';

import {
  Container,
  TitlePage,
  Filter,
  Options,
  Actions,
  Animeted,
  ContainerInputDate,
} from './styles';
import { getValuesInJustificativa } from '../../utils/getValuesInJustificativa';
import { diffTimeHours } from '../../utils/diffTimeHours';
import { getSumTime } from '../../utils/getSumTime';
import { getKmRealizado } from '../../utils/getKmRealizado';

interface ItemTable {
  postoId: number;
  type: 'Intercorrência' | 'Visita';
  userId: number;
  userIdAprov: number;
  clientId: number;
  visitaId: string;
  postoName: string;
  userName: string;
  status: string;
  justificativa: string;
  dataMarcada: string;
  dateAprov: string;
  userNameAprov: string;
  timeIni: string;
  timeFim: string;
  clientName: string;
  timeLimpeza: string;
  endereco: string;
  hourIni: string;
  hourFim: string;
  kmInitial: string;
  kmFinal: string;
  kmPrevisto: string;
  timePrevisto: string;
  timeRota: string;
  timeAllPercurs: string;
  kmAllPercurs: string;
}

interface ExtraItemTable extends ItemTable {
  rotaId: string;
  latitude: number;
  longitude: number;
}

const headers = [
  { name: 'Id Visita', field: 'visitaId', sortable: false },
  { name: 'Tipo', field: 'type', sortable: true },
  { name: 'RotaId', field: 'rotaId', sortable: true },
  { name: 'Cliente', field: 'clientName', sortable: true },
  { name: 'Posto', field: 'postoName', sortable: true },
  { name: 'Endereço', field: 'endereco', sortable: true },
  { name: 'Nome do supervisor', field: 'userName', sortable: true },
  { name: 'Data da Visita', field: 'dataMarcada', sortable: true },
  { name: 'Hora Inicial do Percurso', field: 'hourIni', sortable: true },
  { name: 'KM Inicial do Percurso', field: 'kmInitial', sortable: true },
  { name: 'Hora Final do Percurso', field: 'hourFim', sortable: true },
  { name: 'KM Final do Percurso', field: 'kmFinal', sortable: true },
  { name: 'Tempo Previsto do Percurso', field: 'timePrevisto', sortable: true },
  { name: 'KM Previsto do Percurso', field: 'kmPrevisto', sortable: true },
  { name: 'Tempo total do Percurso', field: 'timeAllPercurs', sortable: true },
  { name: 'KM Total do Percurso', field: 'kmAllPercurs', sortable: true },
  { name: 'Hora Inicial da Visita', field: 'timeIni', sortable: true },
  { name: 'Hora Final da Visita', field: 'timeFim', sortable: true },
  { name: 'Tempo total da Visita', field: 'timeLimpeza', sortable: true },
  { name: 'Tempo de Execução da Rota', field: 'timeRota', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Justificativa', field: 'justificativa', sortable: true },
];

export const RelatorioVisitas: React.FC = () => {
  const { empresaPrincipal } = useAuth();

  const [isSelectedSupervisor, setIsSelectedSupervisor] = useState(false);
  const [dateInit, setDateInit] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [clienteIdSelect, setClienteIdSelect] = useState(-1);
  const [clientes, setClientes] = useState<Clientes[]>([]);
  const [userIdSelect, setUserIdSelect] = useState(-1);
  const [usersVig, setUsersVig] = useState<UsuarioBD[]>([]);
  const [usersAll, setUsersAll] = useState<UsuarioBD[]>([]);
  const [listHist, setListHist] = useState<ItemTable[]>([]);
  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const tempoLimpeza = useCallback((dado: string, dado2: string): string => {
    if (dado && dado2) {
      const diff = moment(new Date(dado), 'DD/MM/YYYY HH:mm:ss').diff(
        moment(new Date(dado2), 'DD/MM/YYYY HH:mm:ss'),
      );

      const horas =
        moment.duration(diff).hours().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).hours().toString().replace('-', '')}`
          : moment.duration(diff).hours().toString().replace('-', '');
      const minutos =
        moment.duration(diff).minutes().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).minutes().toString().replace('-', '')}`
          : moment.duration(diff).minutes().toString().replace('-', '');
      const segundos =
        moment.duration(diff).seconds().toString().replace('-', '').length === 1
          ? `0${moment.duration(diff).seconds().toString().replace('-', '')}`
          : moment.duration(diff).seconds().toString().replace('-', '');
      const horario = `${horas}:${minutos}:${segundos}`;
      return horario;
    }
    return '';
  }, []);

  useEffect(() => {
    // console.log('chegou aqui');
    if (dateInit === '' || dateEnd === '') return;
    setIsLoading(true);
    api
      .get(`/rotas?dataIni=${dateInit}&dataFim=${dateEnd}`)
      .then(async (resp) => {
        const rotasHist: GetRotaComplete[] = resp.data;
        // const listSTatus: string[] = [];
        // rotasHist
        //   .filter((item) => item.approvedDate !== null)
        //   .forEach((item) => {
        //     const find = listSTatus.find((status) => status === item.rotaId);
        //     if (find === undefined) {
        //       const status = `${item.status}-${item.type}`;
        //       listSTatus.push(item.rotaId);
        //     }
        //   });
        // console.log(listSTatus);
        const vistApr = rotasHist.filter(
          (it) =>
            it.status !== 'A Aprovar' &&
            it.status !== 'Rejeitada' &&
            it.status !== 'Reprovada' &&
            it.type !== 'checkIn' &&
            it.type !== 'checkOut',
        );
        // console.log(vistApr.filter((item) => item.supervisorId === 2130));
        const objExtraItem: ExtraItemTable[] = [];
        vistApr.forEach((item) => {
          const find = objExtraItem.find((i) => i.visitaId === item.visitaId);
          if (!find) {
            const {
              hourFinal,
              hourInitial,
              kmFinal,
              kmInitial,
              justificativa,
            } = getValuesInJustificativa(item.justificativa || '');
            const timePercurs =
              hourFinal === '' || hourInitial === ''
                ? ''
                : diffTimeHours({
                    hourFinalHHmmss: hourFinal,
                    hourInicialHHmmss: hourInitial,
                  });

            const kmFinalS = kmFinal === -1 ? '' : `${kmFinal} km`;
            const kmInitialS = kmInitial === -1 ? '' : `${kmInitial} km`;
            const kmRealizadoS =
              kmFinal === -1 || kmInitial === -1
                ? ''
                : `${getKmRealizado({ kmFinal, kmInitial })} km`;
            const timeFim =
              item.Historico.length > 0 ? item.Historico[0].timeFim : '';
            const timeLimpeza =
              item.Historico.length > 0
                ? tempoLimpeza(
                    !item.Historico[0].timeIni
                      ? item.Historico[0].createdAt
                      : item.Historico[0].timeIni,
                    !item.Historico[0].timeFim
                      ? item.Historico[0].updatedAt
                      : item.Historico[0].timeFim,
                  )
                : '';
            const timeRota =
              timePercurs === '' || timeLimpeza === ''
                ? ''
                : getSumTime({
                    timeHHmmss1: timePercurs,
                    timeHHmmss2: timeLimpeza,
                  });

            const obj: ExtraItemTable = {
              rotaId: item.rotaId,
              clientId: item.Visita.Posto.clienteId,
              clientName: '',
              timeFim,
              timeIni:
                item.Historico.length > 0 ? item.Historico[0].timeIni : '',
              type:
                item.type === 'intercorrencia' ? 'Intercorrência' : 'Visita',
              dataMarcada: moment(item.data).format('DD/MM/YYYY'),
              endereco: item.Visita.Posto.endereco,
              justificativa,
              postoId: item.Visita.postoId,
              postoName: item.Visita.Posto.name,
              status: item.status === 'Aprovada' ? 'A Iniciar' : item.status,
              timeLimpeza,
              userId: item.supervisorId,
              userName: '',
              visitaId: item.visitaId,
              dateAprov: !item.approvedDate
                ? ''
                : moment(item.approvedDate).format('DD/MM/YYYY'),
              userIdAprov: !item.approvedBy ? -1 : item.approvedBy,
              userNameAprov: '',
              hourFim: hourFinal,
              hourIni: hourInitial,
              kmFinal: kmFinalS,
              kmInitial: kmInitialS,
              kmPrevisto: '',
              timePrevisto: '',
              latitude: item.Visita.Posto.latitude,
              longitude: item.Visita.Posto.longitude,
              timeRota,
              timeAllPercurs: timePercurs,
              kmAllPercurs: kmRealizadoS,
            };
            objExtraItem.push(obj);
          }
        });

        // const rotas: RotaPrew[] = [];
        // objExtraItem.forEach((item) => {
        //   const findIndex = rotas.findIndex(
        //     (rot) => rot.rotaId === item.rotaId,
        //   );
        //   if (findIndex === -1) {
        //     const newRot: RotaPrew = {
        //       rotaId: item.rotaId,
        //       kmInitial: item.kmInitial,
        //       kmFinal: item.kmFinal,
        //       coords: [
        //         {
        //           latitude: item.latitude,
        //           longitude: item.longitude,
        //         },
        //       ],
        //     };
        //     rotas.push(newRot);
        //   } else {
        //     rotas[findIndex].kmFinal =
        //       rotas[findIndex].kmFinal === 0
        //         ? item.kmFinal
        //         : rotas[findIndex].kmFinal;
        //     rotas[findIndex].kmInitial =
        //       rotas[findIndex].kmInitial === 0
        //         ? item.kmInitial
        //         : rotas[findIndex].kmInitial;
        //     rotas[findIndex].coords.push({
        //       latitude: item.latitude,
        //       longitude: item.longitude,
        //     });
        //   }
        // });
        // console.log(objExtraItem.map((item) => item.coords));
        const result = await Promise.all(
          objExtraItem.map((item) =>
            getInfoDetailsOfCoords({
              coords: [
                {
                  latitude: -3.8414166,
                  longitude: -38.5159087,
                },
                {
                  latitude: item.latitude,
                  longitude: item.longitude,
                },
              ],
            }),
          ),
        );

        const listItemTable: ItemTable[] = [];
        objExtraItem.forEach((extraItem, index) => {
          const kmPrevisto = (result[index].distance / 1000).toFixed(2);
          const timePrevisto = formatedTimebySeconds({
            seconds: Math.floor(result[index].time),
          });
          const objItemTable: ItemTable = {
            ...extraItem,
            kmPrevisto: `${kmPrevisto} km`,
            timePrevisto,
          };

          listItemTable.push(objItemTable);
        });

        setListHist(listItemTable);
      })
      .catch((err) => {
        console.log(err.message);
        // console.log('Errrrroooooooooooooooooooooooooooooooooooooooooooooooo');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dateEnd, dateInit, tempoLimpeza]);

  const getClients = useCallback(() => {
    api
      .get(
        `/clientes/empresa?empresaId=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].ID : '0'
        }`,
      )
      .then((response) => {
        const resp: Clientes[] = response.data;
        // console.log(resp);
        setClientes(
          resp.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          }),
        );
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [empresaPrincipal]);

  const getUsers = useCallback(async () => {
    const usersGet = await api.get(
      `/allUsers/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
      }`,
    );
    const usersResp: UsuarioBD[] = usersGet.data;
    // console.log(usersAll);
    setUsersVig(
      usersResp
        .filter((item) => item.acesso === '2')
        .sort((a, b) => {
          if (a.nome < b.nome) return -1;
          if (a.nome > b.nome) return 1;
          return 0;
        }),
    );
    setUsersAll(usersResp);
  }, [empresaPrincipal]);

  useEffect(() => {
    getUsers();
    getClients();
  }, [getClients, getUsers]);
  // console.log('listHist', listHist);
  const listHistMemo: ItemTable[] = useMemo(() => {
    let list = listHist.map((item) => {
      const find = usersAll.find((it) => it.id === item.userId);
      const findAprov = usersAll.find((it) => it.id === item.userIdAprov);
      const findCli = clientes.find((it) => {
        return it.id === String(item.clientId);
      });
      const obj = item;
      if (find) {
        obj.userName = find.nome;
      }
      if (findAprov) {
        obj.userNameAprov = findAprov.nome;
      }
      if (findCli) {
        obj.clientName = findCli.name;
      }
      return obj;
    });
    if (userIdSelect !== -1) {
      list = list.filter((it) => it.userId === userIdSelect);
    }
    if (clienteIdSelect !== -1) {
      list = list.filter(
        (it) => String(it.clientId) === String(clienteIdSelect),
      );
    }
    return list.sort((a, b) => {
      if (a.dataMarcada > b.dataMarcada) return 1;
      if (b.dataMarcada > a.dataMarcada) return -1;
      return 0;
    });
  }, [listHist, userIdSelect, clienteIdSelect, usersAll, clientes]);

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Relatório de Visitas</TitlePage>

        <Options>
          <div>
            <button
              type="button"
              className={!isSelectedSupervisor ? 'selected' : 'notSelected'}
              onClick={() => {
                setIsSelectedSupervisor(false);
                setUserIdSelect(-1);
              }}
            >
              Clientes
            </button>
          </div>
          <div>
            <button
              type="button"
              className={isSelectedSupervisor ? 'selected' : 'notSelected'}
              onClick={() => {
                setIsSelectedSupervisor(true);
                setClienteIdSelect(-1);
              }}
            >
              Supervisores
            </button>
          </div>
        </Options>

        <Filter>
          <ContainerInputDate>
            <input
              type="date"
              value={dateInit}
              onChange={(event) => {
                // console.log(event.target.value);
                setDateInit(event.target.value);
              }}
            />
            <input
              type="date"
              value={dateEnd}
              onChange={(event) => {
                setDateEnd(event.target.value);
              }}
            />
          </ContainerInputDate>
          {isSelectedSupervisor ? (
            <select
              value={userIdSelect}
              onChange={(event) => {
                setUserIdSelect(Number(event.target.value));
              }}
            >
              <option value={-1}>Todas os supervisores</option>
              {usersVig.map((it) => (
                <option key={it.id} value={it.id}>
                  {it.nome}
                </option>
              ))}
            </select>
          ) : (
            <select
              value={clienteIdSelect}
              onChange={(event) => {
                setClienteIdSelect(Number(event.target.value));
              }}
            >
              <option value={-1}>Todas os clientes</option>
              {clientes.map((it) => (
                <option key={it.id} value={it.id}>
                  {it.name}
                </option>
              ))}
            </select>
          )}
        </Filter>
        <Table
          header={headers}
          body={listHistMemo.filter(
            (e, i) => i >= indexInitPg && i <= indexEndPg && !isLoading,
          )}
          tableId="TableHistoricRotas"
        />
        {isLoading && (
          <Animeted>
            <FiRefreshCw size={40} className="animate-spin" />
            <p>Carregando ...</p>
          </Animeted>
        )}

        <PaginationComponent
          arrayStateFiltered={listHistMemo}
          setIndexPagination={(indexInit, indexEnd) => {
            setIndexInitPg(indexInit);
            setIndexEndPg(indexEnd);
          }}
        />
        <Actions>
          <NewImportExel
            fileName="RelatorioSintetico"
            tableId="TableHistoricRotas"
          />
        </Actions>
      </Container>
    </>
  );
};
