import { shade } from 'polished';

export default {
  title: 'dark',

  colors: {
    primary: '#393B41',
    secondary: '#FFFFFF',

    warning: '#E2B93B',
    error: '#EB5757',
    success: '#27AE60',
    successShade: '#0b2e19',
    info: '#2F80ED',

    black: '#050505',
    black2: '#2F2F2F',
    black3: '#444444',
    black4: '#525252',

    gray1: '#D9D9D9',
    gray2: '#CBCBCB',
    gray3: '#BCBCBC',
    gray4: '#ACACAC',
    gray5: '#B2B2B2',
    gray6: '#999999',
    gray7: '#8C8C8C',
    gray8: '#808080',
    gray9: '#737373',
    gray10: '#666666',
    gray11: '#595959',
    gray12: '#4C4C4C',

    white: '#FFFFFF',
    white2: '#FBFBFB',
    white3: '#F6F6F6',
    white4: '#F1F1F1',
  },
};
