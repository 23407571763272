import React, { useState, useEffect, useMemo, useCallback } from 'react';
import 'react-day-picker/lib/style.css';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri';
import ModalAmbiente from '../../components/ModalHistoricoRonda';
import { useAuth } from '../../hooks/auth';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import Search from '../../components/Search';
import imgQrCode from '../../assets/codigo-qr.svg';
import Excel from '../../assets/excel.png';
import cardStyle from '../../assets/CardStyle.svg';
import listStyle from '../../assets/ListStyle.svg';
import Tooltip from '../../components/Tooltip';
import ModalConexao from '../../components/ModalConexao';

import {
  Container,
  ContainerTable,
  ContainerSearch,
  DivSelectFilter,
  SelectFilter,
  ContainerBackground,
  Content,
  ContainerTitle,
  Title,
  ContainerTitleSecond,
  ButtonShowFilter,
  ContainerSemResultados,
  ContainerItem,
  ContainerTypeVisualization,
  ContainerFilter,
  ContainerButton,
  ContainerExportAndPag,
} from './styles';
import CardRonda from '../../components/CardRonda';

interface ResponseGet {
  ID: number;
  AMBIENTE_NOME: string;
  EMPRESA: string;
  OCORRENCIAS: [];
}

interface ResponseGetHistoric {
  avatar: string;
  data: string;
  dataFinal: string;
  email_usuario: string;
  id_usuario: number;
  imagem: string;
  nome_usuario: string;
  observacao: string;
  ocorrencia: string;
  telefone: string;
}

const Dashboard: React.FC = () => {
  const { user, empresaPrincipal } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [porcentagem, setPorcentagem] = useState(0);
  const [isFocusedDateInit, setIsFocusedDateInit] = useState('');
  const [isFocusedDateEnd, setIsFocusedDateEnd] = useState('');
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [modalDetalhesAmbiente, setModalDetalhesAmbiente] = useState(true);
  const [dadosModal, setDadosModal] = useState<ResponseGet>();
  const [dadosHistoricoModal, setDadosHistoricoModal] = useState<
    ResponseGetHistoric[]
  >([]);
  const [dadosHistoricoDatasModal, setDadosHistoricoDatasModal] = useState('');
  const [searchNomeDoAmbiente, setSearchNomeDoAmbiente] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const dataInicio = moment().format('YYYY-MM-01');
  const dataFim = moment().format('YYYY-MM-DD');

  const [showSearch, setShowSearch] = useState(false);
  const [showSearchAnimation, setShowSearchAnimation] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setLoading(true);

    api
      .get(
        `/environments/periodo/?id=${
          empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
        }&dataI=${dataInicio}&dataF=${dataFim}`,
        {
          onDownloadProgress(progress) {
            setPorcentagem((progress.loaded * 100) / progress.total);
          },
        },
      )
      .then((data) => {
        setResponse(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (navigator.onLine) {
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      searchDataInicial.length === 10 &&
      searchDataFinal.length === 10 &&
      searchDataInicial.substr(0, 1) !== '0' &&
      searchDataFinal.substr(0, 1) !== '0' &&
      searchDataInicial <= searchDataFinal
    ) {
      setPorcentagem(0);
      setLoading(true);
      api
        .get(
          `/environments/periodo/?id=${
            empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '0'
          }&dataI=${searchDataInicial}&dataF=${searchDataFinal}`,
          {
            onDownloadProgress(progress) {
              setPorcentagem((progress.loaded * 100) / progress.total);
            },
          },
        )
        .then((data) => {
          setResponse(data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao carregar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    } else if (
      searchDataInicial.length === 10 &&
      searchDataFinal.length === 10 &&
      searchDataInicial.substr(0, 1) !== '0' &&
      searchDataFinal.substr(0, 1) !== '0' &&
      searchDataInicial > searchDataFinal
    ) {
      if (navigator.onLine) {
        Swal.fire({
          icon: 'info',
          title: 'Intervalo de datas invalido!',
          text: 'Favor inserir as datas inicial e final corretamente.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    }
  }, [
    dataFim,
    dataInicio,
    empresaPrincipal,
    history,
    searchDataFinal,
    searchDataInicial,
    user,
  ]);

  const handleMaskDate = useCallback((data) => {
    const dataTemp = data.split('-').reverse().join('/');

    return dataTemp;
  }, []);

  const requestHistoricoUsuario = useCallback(
    (dado: ResponseGet) => {
      setDadosHistoricoModal([]);
      setLoading(true);

      if (
        searchDataInicial.length === 10 &&
        searchDataFinal.length === 10 &&
        searchDataInicial.substr(0, 1) !== '0' &&
        searchDataFinal.substr(0, 1) !== '0'
      ) {
        api
          .get(
            `/dash/environments/info?environmentId=${dado.ID}&dataInicio=${searchDataInicial}&dataFim=${searchDataFinal}`,
          )
          .then((data) => {
            if (data.data.res[0] === 'Intervalo de datas inválidos') {
              setLoading(false);
              Swal.fire({
                icon: 'error',
                title: 'Intervalo de datas inválidos!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            } else {
              setDadosHistoricoModal(data.data.res);
              setDadosHistoricoDatasModal(
                `De ${handleMaskDate(searchDataInicial)} até ${handleMaskDate(
                  searchDataFinal,
                )}`,
              );
              setLoading(false);
              setModalDetalhesAmbiente(!modalDetalhesAmbiente);
            }
          })
          .catch(() => {
            if (navigator.onLine) {
              setModalDetalhesAmbiente(false);
              setLoading(false);
              Swal.fire({
                icon: 'info',
                title: 'Erro ao carregar dados, por favor atualize a página!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            }
          });
      } else {
        api
          .get(
            `/dash/environments/info?environmentId=${dado.ID}&dataInicio=${dataInicio}&dataFim=${dataFim}`,
          )
          .then((data) => {
            setDadosHistoricoModal(data.data.res);
            setDadosHistoricoDatasModal(
              `De ${handleMaskDate(dataInicio)} até ${handleMaskDate(dataFim)}`,
            );
            setLoading(false);
            setModalDetalhesAmbiente(!modalDetalhesAmbiente);
          })
          .catch(() => {
            if (navigator.onLine) {
              setModalDetalhesAmbiente(false);
              setLoading(false);
              Swal.fire({
                icon: 'info',
                title: 'Erro ao carregar dados, por favor atualize a página!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            }
          });
      }
    },
    [
      dataInicio,
      dataFim,
      modalDetalhesAmbiente,
      searchDataInicial,
      searchDataFinal,
      handleMaskDate,
    ],
  );

  const changeStateFilter = useCallback(() => {
    setShowSearchAnimation(!showSearchAnimation);

    if (showSearch) {
      setTimeout(() => {
        setShowSearch(!showSearch);
      }, 500);
    } else {
      setShowSearch(!showSearch);
    }
  }, [showSearch, showSearchAnimation]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (searchNomeDoAmbiente) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.AMBIENTE_NOME.toString()
          .toLowerCase()
          .includes(searchNomeDoAmbiente.toLowerCase()),
      );
    }
    return computedResponses;
  }, [response, searchNomeDoAmbiente]);

  return (
    <>
      <ModalConexao />
      {!modalDetalhesAmbiente &&
        dadosModal &&
        dadosHistoricoModal &&
        dadosHistoricoDatasModal && (
          <ModalAmbiente
            onClose={() => {
              setModalDetalhesAmbiente(true);
            }}
            dados={dadosModal}
            dadosHistorico={dadosHistoricoModal}
            dadosHistoricoDatas={dadosHistoricoDatasModal}
          />
        )}

      <Container>
        <h1>
          <strong>Rondas</strong>
        </h1>
        <ContainerSearch>
          <Search
            onSearch={(value: string) => {
              setSearch(value);
            }}
            nomePlaceHolder="Buscar"
          />
          <DivSelectFilter>
            <span>
              <strong>Filtrar</strong>
            </span>
            <SelectFilter id="selectFilter">
              <option value="semana">por semana</option>
              <option value="mes">por mês</option>
              <option value="ano">por ano</option>
              <option value="personalizado">personalizado ...</option>
            </SelectFilter>
          </DivSelectFilter>
          <ContainerTypeVisualization>
            <div>
              <svg
                id="list"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" />
              </svg>
            </div>
            <div>
              <svg
                id="card"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 3V11H11V3H3ZM9 9H5V5H9V9ZM3 13V21H11V13H3ZM9 19H5V15H9V19ZM13 3V11H21V3H13ZM19 9H15V5H19V9ZM13 13V21H21V13H13ZM19 19H15V15H19V19Z"
                />
              </svg>
            </div>
          </ContainerTypeVisualization>
        </ContainerSearch>
        <ContainerTable>{/* <CardRonda item={undefined} /> */}</ContainerTable>
        <ContainerExportAndPag>
          <ContainerFilter>
            <select>
              <option value="10">10 registros</option>
              <option value="25">25 registros</option>
              <option value="50">50 registros</option>
              <option value="all">Todas os registros</option>
            </select>
            <span>Mostrando 1 a 10 de 36</span>
          </ContainerFilter>
          <ContainerButton>
            <div>
              {/* <img src={Excel} alt="exel" /> */}
              <input type="button" id="teste" value="Exportar Exel" />
            </div>
          </ContainerButton>
        </ContainerExportAndPag>

        {/* <ContainerBackground style={{ overflow: 'auto' }}>
          <Content>
            <ContainerTitle>
              <header>
                <Title>
                  <img src={imgQrCode} alt="QrCode" />
                  <div>
                    {!!empresaPrincipal.length && (
                      <>
                        <strong>{empresaPrincipal[0].NOME}</strong>
                        <p>{`${empresaPrincipal[0].ENDERECO}, ${empresaPrincipal[0].NUMERO} - ${empresaPrincipal[0].BAIRRO} - ${empresaPrincipal[0].CIDADE}`}</p>
                      </>
                    )}

                    {!empresaPrincipal.length && (
                      <>
                        <strong>TODAS AS EMPRESAS</strong>
                        <p>Sem endereço</p>
                      </>
                    )}
                  </div>
                </Title>
              </header>
            </ContainerTitle>
            <ContainerTitleSecond>
              <div className="button">Histórico das Rondas</div>
              <ButtonShowFilter
                filter={showSearchAnimation}
                onClick={() => {
                  changeStateFilter();
                  setSearchNomeDoAmbiente('');
                  setSearchDataInicial('');
                  setSearchDataFinal('');
                }}
              >
                <Tooltip title="Filtros" className="classFilter">
                  {!showSearchAnimation && <RiFilterLine size={22} />}
                  {showSearchAnimation && <RiFilterOffLine size={22} />}
                </Tooltip>
              </ButtonShowFilter>
            </ContainerTitleSecond>

            {showSearch && (
              <ContainerSearch filter={showSearchAnimation}>
                <>
                  <Search
                    type="text"
                    className="search1"
                    onSearch={(value: string) => {
                      setSearchNomeDoAmbiente(value);
                    }}
                    nomePlaceHolder="Buscar Ronda"
                  />
                  <Search
                    type={isFocusedDateInit || 'text'}
                    className="search1"
                    onValue={searchDataInicial}
                    onSearch={(value: string) => {
                      setSearchDataInicial(value);
                    }}
                    onFocus={() => {
                      setIsFocusedDateInit('date');
                    }}
                    onBlur={() => {
                      searchDataInicial === '' && setIsFocusedDateInit('text');
                    }}
                    nomePlaceHolder="Data inicial"
                  />
                  <Search
                    type={isFocusedDateEnd || 'text'}
                    className="search1"
                    onValue={searchDataFinal}
                    onSearch={(value: string) => {
                      setSearchDataFinal(value);
                    }}
                    onFocus={() => {
                      setIsFocusedDateEnd('date');
                    }}
                    onBlur={() => {
                      searchDataFinal === '' && setIsFocusedDateEnd('text');
                    }}
                    nomePlaceHolder="Data final"
                  />
                </>
              </ContainerSearch>
            )}

            {responseData.length === 0 && (
              <ContainerSemResultados>
                <h2>
                  Não existe um histórico de rondas para o intervalo de datas
                  atual.
                </h2>
              </ContainerSemResultados>
            )}

            <div className="itensMap">
              {responseData.map((res) => (
                <ContainerItem key={`${res.ID}-${res.AMBIENTE_NOME}/HOME`}>
                  <button
                    className="itemAmbiente"
                    type="button"
                    onClick={() => {
                      setDadosModal(res);
                      requestHistoricoUsuario(res);
                    }}
                  >
                    <header>
                      <strong>{res.AMBIENTE_NOME}</strong>
                    </header>
                  </button>
                </ContainerItem>
              ))}
            </div>
          </Content>
        </ContainerBackground> */}
      </Container>
      {loading && <Loading porcentagem={porcentagem} />}
    </>
  );
};

export default Dashboard;
