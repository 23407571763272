import moment from 'moment';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { AiOutlineException } from 'react-icons/ai';
import { FiX } from 'react-icons/fi';
import { ThemeContext } from 'styled-components';
import Zoom from 'react-img-zoom';

import {
  ItemTableRonda,
  ItemUserRonda,
} from '../../pages/HistoricPatrol/HistoricRondaListVistoria';
import Button from '../Button';

import NewModal from '../NewModal';
import Search from '../Search';

import {
  ButtonClose,
  ButtonImg,
  ButtonImgNull,
  Card,
  Container,
  ContainerButtonDetalhes,
  ContainerHeaderDados,
  ContainerMapDetails,
  ContainerSearch,
  FilterStatus,
  List,
  ZoomImg,
} from './styles';

interface Props {
  isOpen: boolean;
  onClose(): void;
  datasFiltro: string;
  rondas: ItemUserRonda;
}

interface ItemRonda {
  date: string;
  rondaId: number;
  rondaName: string;
  status:
    | 'Concluído'
    | 'Concluído com Pendências'
    | 'Não Realizado'
    | 'Cancelado'
    | 'A iniciar';
  rondas: ItemTableRonda[];
}

export const ModalRondaDetails: React.FC<Props> = ({
  isOpen,
  onClose,
  datasFiltro,
  rondas,
}) => {
  const { colors } = useContext(ThemeContext);
  const [exibirDetalhes, setExibirDetalhes] = useState(false);
  const [search, setSearch] = useState('');
  const [rondaDetalhes, setRondaDetalhes] = useState<ItemTableRonda[]>([]);
  const [zoomImg, setZoomImg] = useState<'principal' | 'revisoes' | ''>('');
  const [img, setImg] = useState('');
  const [filStatus, setFilStatus] = useState('tod');

  const getStatus = useCallback((statusAll: string[]):
    | 'Concluído'
    | 'Concluído com Pendências'
    | 'Não Realizado'
    | 'Cancelado'
    | 'A iniciar' => {
    const valConc = statusAll.includes('Concluído');
    const valConcPen = statusAll.includes('Concluído com Pendências');
    const valNReal = statusAll.includes('Não Realizado');
    const valCanc = statusAll.includes('Cancelado');
    const valAIni = statusAll.includes('A iniciar');
    if (valConc && !valConcPen && !valNReal && !valCanc && !valAIni)
      return 'Concluído';
    if (valNReal && !valConcPen && !valConc && !valCanc && !valAIni)
      return 'Não Realizado';
    if (valAIni && !valConcPen && !valNReal && !valCanc && !valConc)
      return 'A iniciar';
    if (valCanc && !valConcPen && !valNReal && !valConc && !valAIni)
      return 'Cancelado';
    return 'Concluído com Pendências';
  }, []);

  const getColorStatus = useCallback(
    (
      status:
        | 'Concluído'
        | 'Concluído com Pendências'
        | 'Não Realizado'
        | 'Cancelado'
        | 'A iniciar',
    ) => {
      switch (status) {
        case 'A iniciar':
          return colors.info;
        case 'Cancelado':
          return colors.error;
        case 'Concluído':
          return colors.success;
        case 'Concluído com Pendências':
          return colors.successShade;
        case 'Não Realizado':
          return colors.error;
        default:
          return colors.warning;
      }
    },
    [
      colors.error,
      colors.info,
      colors.success,
      colors.successShade,
      colors.warning,
    ],
  );

  const listMemo: ItemRonda[] = useMemo(() => {
    const list: ItemRonda[] = [];
    rondas.dadosRonda.forEach((item) => {
      const findIndex = list.findIndex(
        (i) => i.date === item.diaMarcado && i.rondaId === item.idRonda,
      );
      if (findIndex === -1) {
        const obj: ItemRonda = {
          date: item.diaMarcado,
          rondaId: item.idRonda,
          rondaName: item.rondaName,
          rondas: [item],
          status: 'A iniciar',
        };
        list.push(obj);
      } else {
        list[findIndex].rondas.push(item);
      }
    });
    return list.map((i) => {
      const status = getStatus(i.rondas.map((r) => r.status));
      return { ...i, status };
    });
  }, [getStatus, rondas.dadosRonda]);

  const listFilterMemo = useMemo(() => {
    let list = listMemo;
    if (search) {
      list = list.filter((i) =>
        `${i.rondaName.toLocaleLowerCase()}|${i.date}`.includes(
          search.toLocaleLowerCase(),
        ),
      );
    }
    if (filStatus === 'con') {
      list = list.filter((i) => i.status === 'Concluído');
    }
    if (filStatus === 'cp') {
      list = list.filter((i) => i.status === 'Concluído com Pendências');
    }
    if (filStatus === 'ini') {
      list = list.filter((i) => i.status === 'A iniciar');
    }
    if (filStatus === 'rn') {
      list = list.filter((i) => i.status === 'Não Realizado');
    }
    if (filStatus === 'can') {
      list = list.filter((i) => i.status === 'Cancelado');
    }

    return list;
  }, [filStatus, listMemo, search]);

  const handleZoomImg = useCallback((imgString: string) => {
    setZoomImg('principal');
    setImg(imgString);
  }, []);

  const handleZoomImgRevisions = useCallback(() => {
    setZoomImg('revisoes');
  }, []);

  return (
    <NewModal isVisible={isOpen}>
      <ButtonClose>
        <button type="button" onClick={onClose}>
          <FiX size={22} />
        </button>
      </ButtonClose>
      <Container>
        {zoomImg === '' &&
          (!exibirDetalhes ? (
            <>
              <ContainerHeaderDados>
                Histórico do Colaborador
              </ContainerHeaderDados>
              <FilterStatus option={filStatus}>
                <button
                  type="button"
                  className="con"
                  onClick={() => setFilStatus('con')}
                >
                  <span>Concluído</span>
                </button>
                <button
                  type="button"
                  className="cp"
                  onClick={() => setFilStatus('cp')}
                >
                  <span>Concluído com Pendências</span>
                </button>
                <button
                  type="button"
                  className="ini"
                  onClick={() => setFilStatus('ini')}
                >
                  <span>A iniciar</span>
                </button>
                <button
                  type="button"
                  className="nr"
                  onClick={() => setFilStatus('nr')}
                >
                  <span>Não Realizado</span>
                </button>
                <button
                  type="button"
                  className="can"
                  onClick={() => setFilStatus('can')}
                >
                  <span>Cancelado</span>
                </button>
                <button
                  type="button"
                  className="tod"
                  onClick={() => setFilStatus('tod')}
                >
                  <span>Todos</span>
                </button>
              </FilterStatus>

              <ContainerSearch>
                <Search
                  onSearch={(value: string) => {
                    setSearch(value);
                  }}
                  nomePlaceHolder="Buscar"
                />

                <strong>{datasFiltro}</strong>
              </ContainerSearch>
              <List>
                {listFilterMemo.map((i, index) => (
                  <Card
                    key={`${i.rondaId} | ${i.date} | ${index.toString()}`}
                    onClick={() => {
                      setExibirDetalhes(true);
                      setRondaDetalhes(i.rondas);
                    }}
                  >
                    <div
                      className="line"
                      style={{ backgroundColor: getColorStatus(i.status) }}
                    />
                    <div className="info">
                      <div>
                        <p>Ronda</p>
                        <span>{i.rondaName}</span>
                      </div>
                      <div>
                        <p>Data Marcada</p>
                        <span>{i.date}</span>
                      </div>
                    </div>
                  </Card>
                ))}
              </List>
            </>
          ) : (
            <>
              <ContainerHeaderDados>
                Histórico do Colaborador
              </ContainerHeaderDados>
              <List>
                {rondaDetalhes
                  .filter((i) => i.tipoRonda === 'Principal')
                  .map((i) => {
                    return i.vistorias.map((vt) => (
                      <ContainerMapDetails
                        key={`${i.idRonda}-${vt.idVistoria}`}
                      >
                        {!vt.image || vt.image.split('/').pop() === 'null' ? (
                          <ButtonImgNull type="button">
                            <AiOutlineException size={80} />
                          </ButtonImgNull>
                        ) : (
                          <ButtonImg
                            type="button"
                            onClick={() => handleZoomImg(vt.image)}
                          >
                            <img
                              src={vt.image}
                              alt={!i.rondaName ? '-' : i.rondaName}
                            />
                          </ButtonImg>
                        )}

                        <ul>
                          <li>
                            <strong>Ronda: </strong>
                            <span>{!i.rondaName ? '-' : i.rondaName}</span>
                          </li>
                          <li>
                            <strong>Horário base (agendado): </strong>
                            <span>
                              {i.hourIniMarcado && i.hourFimMarcado
                                ? `${i.hourIniMarcado} - ${i.hourFimMarcado}`
                                : '-'}
                            </span>
                          </li>
                          <li>
                            <strong>Status: </strong>
                            <span>{vt.status}</span>
                          </li>
                          <li>
                            <strong>Data e hora inicial (realizado): </strong>
                            <span>
                              {!vt.timeIni
                                ? '-'
                                : moment(new Date(vt.timeIni)).format(
                                    'DD/MM/YYYY HH:mm:ss',
                                  )}
                            </span>
                          </li>
                          <li>
                            <strong>Data e hora final (realizado): </strong>
                            <span>
                              {!vt.timeEnd
                                ? '-'
                                : moment(new Date(vt.timeEnd)).format(
                                    'DD/MM/YYYY HH:mm:ss',
                                  )}
                            </span>
                          </li>
                          <li>
                            <strong>Procedimento: </strong>
                            <span>
                              {!vt.nameVistoria ? '-' : vt.nameVistoria}
                            </span>
                          </li>
                          <li>
                            <strong>Observação: </strong>
                            <span>{vt.observacao}</span>
                          </li>
                          <li>
                            <strong>Ocorrência: </strong>
                            <span>{vt.ocorrencia}</span>
                          </li>
                          <li>
                            <strong>Revisões (realizado): </strong>
                            <button
                              className="revs"
                              type="button"
                              onClick={() => {
                                handleZoomImgRevisions();
                              }}
                            >
                              Mostrar revisões
                            </button>
                          </li>
                        </ul>
                      </ContainerMapDetails>
                    ));
                  })}
              </List>
              <ContainerButtonDetalhes>
                <Button
                  type="button"
                  onClick={() => {
                    setSearch('');
                    setExibirDetalhes(false);
                    onClose();
                  }}
                >
                  Ok
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setExibirDetalhes(false);
                  }}
                >
                  Voltar
                </Button>
              </ContainerButtonDetalhes>
            </>
          ))}

        {zoomImg === 'principal' && (
          <ZoomImg>
            <Zoom img={img} zoomScale={1} width="100%" height={300} />
            <ContainerButtonDetalhes style={{ margin: 0 }}>
              <Button
                type="button"
                onClick={() => {
                  setSearch('');
                  setExibirDetalhes(false);
                  onClose();
                }}
              >
                Ok
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setZoomImg('');
                }}
              >
                Voltar
              </Button>
            </ContainerButtonDetalhes>
          </ZoomImg>
        )}
        {zoomImg === 'revisoes' && (
          <ZoomImg>
            {rondaDetalhes
              .filter((i) => i.tipoRonda !== 'Principal')
              .map((imgRevision) =>
                imgRevision.vistorias.map((vt) => (
                  <section key={vt.image}>
                    <span style={{ fontWeight: 500 }}>{vt.nameVistoria}</span>
                    <article style={{ marginTop: -30, marginBottom: -23 }}>
                      <span>
                        {vt.timeIni} ás {vt.timeEnd}
                      </span>
                    </article>

                    <span>{`Observação: ${vt.observacao}`}</span>

                    {vt.image ? (
                      <Zoom
                        img={vt.image}
                        zoomScale={1}
                        width="100%"
                        height={350}
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <AiOutlineException size={80} />
                      </div>
                    )}
                  </section>
                )),
              )}

            {rondaDetalhes.filter((i) => i.tipoRonda !== 'Principal').length ===
              0 && <h1>Histórico de revisões vazio</h1>}
            <ContainerButtonDetalhes style={{ margin: 0 }}>
              <Button
                type="button"
                onClick={() => {
                  setSearch('');
                  setExibirDetalhes(false);
                  onClose();
                }}
              >
                Ok
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setZoomImg('');
                }}
              >
                Voltar
              </Button>
            </ContainerButtonDetalhes>
          </ZoomImg>
        )}
      </Container>
    </NewModal>
  );
};
