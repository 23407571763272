import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';
import Swal from 'sweetalert2';

import NewModal from '../NewModal';

import { ButtonClose, Container, InputForm, Select } from './styles';

interface Props {
  visible: boolean;
  onClose: () => void;
  onAprov: (dateIni: string, dateEnd: string, supervisorId: number) => void;
  supervisores: { nome: string; id: number }[];
}

export const ModalAprovAutomatic: React.FC<Props> = ({
  onClose,
  visible,
  onAprov,
  supervisores,
}) => {
  const [dateIni, setDateIni] = useState('');
  const [dateEnd, setDateEnd] = useState('');
  const [supervisorId, setSupervisorId] = useState(-1);

  const handleSubmit = useCallback(() => {
    if (!dateIni || !dateEnd) {
      Swal.fire({
        icon: 'info',
        title: `Data não foi informada!`,
      });
      return;
    }
    if (dateIni > dateEnd) {
      Swal.fire({
        icon: 'info',
        title: `Data inicial maior que a final!`,
      });
      return;
    }
    const today = moment().format('YYYY-MM-DD');
    if (dateIni < today) {
      Swal.fire({
        icon: 'info',
        title: `Data inicial maior a data de hoje!`,
      });
      return;
    }
    if (supervisorId === -1) {
      Swal.fire({
        icon: 'info',
        title: `Não foi escolhido o supervisor!`,
      });
    } else {
      onClose();
      onAprov(dateIni, dateEnd, supervisorId);
    }
  }, [dateEnd, dateIni, onAprov, onClose, supervisorId]);

  return (
    <NewModal isVisible={visible}>
      <ButtonClose>
        <button
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          <FiX size={22} />
        </button>
      </ButtonClose>
      <Container>
        <h2>Aprovação Automática</h2>
        <p>
          A aprovação automática tem o intuíto de aprovar as futuras rotas
          criadas pelo sistema durante o período informado pelas datas abaixo.
          Não altera as rotas já estabelecidas ou em analise.
        </p>
        <p>Informe as datas e o supervisor para a aprovação automáica</p>
        <div className="dates">
          <InputForm
            value={dateIni}
            type="date"
            onChange={(event) => {
              setDateIni(event.target.value);
            }}
          />
          <InputForm
            value={dateEnd}
            type="date"
            onChange={(event) => {
              setDateEnd(event.target.value);
            }}
          />
        </div>
        <Select
          value={supervisorId}
          onChange={(e) => {
            setSupervisorId(parseInt(e.target.value, 10));
          }}
        >
          <option value={-1}>Escolher o Supervisor</option>
          {supervisores.map((supervisor) => (
            <option key={supervisor.id} value={supervisor.id}>
              {`${supervisor.nome.trim()}`}
            </option>
          ))}
        </Select>
        <div className="action">
          <button type="button" className="cancel" onClick={onClose}>
            <p>Cancelar</p>
          </button>
          <button type="button" className="confirm" onClick={handleSubmit}>
            <p>Confirmar</p>
          </button>
        </div>
      </Container>
    </NewModal>
  );
};
