import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 58px 72px 48px 49px;
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;
  overflow-x: hidden;

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;

export const TitlePage = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #262626;
`;

export const Options = styled.div`
  display: flex;
  column-gap: 1rem;

  div button {
    border: 1px solid transparent;
    color: ${(props) => props.theme.colors.black2};
    font-size: 16px;
    background-color: transparent;
    min-width: 140px;
    font-family: 'Montserrat', 'sans-serif';
    font-size: 16px !important;
    font-weight: 600 !important;
    transition: 0.4s;
  }
  div button.selected {
    border-bottom: 1px solid ${(props) => props.theme.colors.black2};
  }
`;

export const ContainerInputDate = styled.div`
  flex: 1;
  column-gap: 1rem;
  display: flex;
  width: 100%;

  input {
    border: 1px solid ${(props) => props.theme.colors.gray1};
    border-radius: 4px;
    height: 50px;
    width: 48%;
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray7};
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const Filter = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  column-gap: 1rem;

  select {
    border: 1px solid ${(props) => props.theme.colors.gray1};
    border-radius: 4px;
    width: 100%;
    height: 50px;
    justify-content: center;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray7};
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 550px) {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonPDF = styled.div`
  display: flex;

  button {
    width: 244px;
    height: 40px;

    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};

    display: flex;
    justify-content: center;
    border-radius: 4px;
    border: none;
    align-items: center;

    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    transition: 0.2s;

    &:hover {
      background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
    }
  }
`;

export const Animeted = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
  flex-direction: column;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .animate-spin {
    animation: spin 1s linear infinite;
  }

  p {
    font-family: Montserrat;
    font-size: 18px;
    color: ${(props) => props.theme.colors.black2};
    margin: 0px;
  }

  svg {
    color: ${(props) => props.theme.colors.black2};
  }
`;
