import jsPDF from 'jspdf';
import { ApontamentosClientes } from '../../models/ApontamentosClientes';

export const criarPDF = (apontamentos: ApontamentosClientes[]): void => {
  // eslint-disable-next-line new-cap
  const pdf = new jsPDF('P', 'mm', 'a4');

  pdf.setFont('Montserrat');
  pdf.setFontStyle('bold');
  pdf.setFontSize(14);

  pdf.text('Histórico de Apontamentos', 80, 20);

  apontamentos.forEach((item, index) => {
    pdf.setFont('Montserrat');
    pdf.setFontStyle('bold');
    pdf.setFontSize(12);

    pdf.text(`Data: ${item.dataManut}`, 15, 50 * index + 50);
    pdf.text(`Filial: ${item.companyName}`, 15, 50 * index + 55);
    pdf.text(`Cliente: ${item.clienteName}`, 15, 50 * index + 60);
    pdf.text(`Posto: ${item.Posto.name}`, 15, 50 * index + 65);
    pdf.text(`Colaborador: ${item.Colaborador.name}`, 15, 50 * index + 70);
    pdf.text(`Motivo: ${item.motivoName}`, 15, 50 * index + 75);
    pdf.text(
      `Supervisor da vistoria: ${item.supervisorName}`,
      15,
      50 * index + 80,
    );
    pdf.text(`Observação: -${item.observacao}`, 15, 50 * index + 85);
  });

  pdf.autoPrint();
  pdf.output('dataurlnewwindow');
};
