import { Reducer } from 'redux';
import { IStateResultFilter } from './types';

const INITIAL_STATE: IStateResultFilter = {
  resultFilter: {
    filter: {
      isFilterActive: true,
    },
    supervisor: { id: 1, name: 'Todos' },
    rondas: {
      isRouteStarted: false,
      isRouteFinished: false,
      isRouteDontStarted: false,
      isRouteCanceled: false,
    },
    rondasStatus: {
      isRouteStopIt: false,
      isRouteOccurrence: false,
      isRouteOkay: true,
    },
    routeDisable: { isRouteAllDisable: false },
  },
};

const map: Reducer<IStateResultFilter> = (state = INITIAL_STATE, action) => {
  // console.log('state');
  // console.log(state);
  // console.log('action');
  // console.log(action);

  switch (action.type) {
    case 'UPDATE_RESULT_FILTER': {
      // console.log('action.payload');
      // console.log(action.payload);
      return action.payload;
    }

    default: {
      return state;
    }
  }
};
export default map;
