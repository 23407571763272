import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useAuth } from '../../hooks/auth';
import ListRegistButton from '../../components/ListRegistButton';
import NewImportExel from '../../components/NewImportExel';
import NewInput from '../../components/NewInput';
import { NewSearch } from '../../components/NewSearch';
import PaginationComponent from '../../components/Pagination';
import Table from '../../components/Table';
import ModalConexao from '../../components/ModalConexao';
import api from '../../services/api';

import {
  ButtonForm,
  InputForm,
  LabelInputForm,
} from '../CadastroCliente copy/styles';
import { TitlePage } from '../../components/TitlePage/styled';
import { Container, ContainerCadastro } from './styles';

interface ResponseGet {
  ID?: number;
  NOME: string;
  Id_Empresas: number;
  ENDERECO: string;
  NUMERO: number;
  CNPJ: string;
  ATIVO?: boolean;
  // visitasLimit: number;
  visitasLimitDiu: number;
  visitasLimitNot: number;
}

const headers = [
  { name: 'NOME', field: 'NOME', sortable: true },
  { name: 'ENDEREÇO', field: 'ENDERECO', sortable: true },
  { name: 'NUMERO', field: 'NUMERO', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'ATIVO', field: 'ATIVO_FILIAL', sortable: true },
  { name: 'Edit', field: 'editOptionFilial', sortable: true },
];

const headersFilters = [
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'Endereço', field: 'ENDERECO', sortable: true },
  { name: 'Número', field: 'NUMERO', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  // { name: 'ATIVO', field: 'ATIVO_FILIAL', sortable: true },
  // { name: 'Edit', field: 'editOptionFilial', sortable: true },
];

const CadastroFilial: React.FC = () => {
  const [cadastrar, setCadastrar] = useState(false);
  const [currentFilialId, setCurrentFilialId] = useState(0);
  const [filiais, setFiliais] = useState<ResponseGet[]>([]);
  const [filiaisFiltered, setFiliaisFiltered] = useState<ResponseGet[]>([]);
  const history = useHistory();
  const { empresaPrincipal } = useAuth();

  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const [nomeFilial, setNomeFilial] = useState('');
  const [enderecoFilial, setEnderecoFilial] = useState('');
  const [numeroEnderecoFilial, setNumeroEnderecoFilial] = useState(0);
  const [cnpj, setCnpj] = useState('');
  const [visitasDelimit, setVisitasDelimit] = useState(1);
  const [visitasNoturnoDelimit, setVisitasNoturnoDelimit] = useState(0);

  const resetValues = useCallback(() => {
    setCurrentFilialId(0);
    setNomeFilial('');
    setEnderecoFilial('');
    setNumeroEnderecoFilial(0);
    setCnpj('');
    setVisitasDelimit(1);
    setVisitasNoturnoDelimit(0);
    setCadastrar(false);
  }, []);

  const getItens = useCallback(async () => {
    const getFiliais = await api.get(`empresas/${empresaPrincipal[0].ID ?? 0}`);
    // console.log(getFiliais.data);
    setFiliais(getFiliais.data);
    setFiliaisFiltered(getFiliais.data);
  }, [empresaPrincipal]);

  const handleSubmit = (): void => {
    if (nomeFilial === '') {
      Swal.fire({
        icon: 'info',
        title: 'Campo(s) vazio(s)!',
        text: 'Favor preecher o campo "Nome filial".',
      });
    } else if (enderecoFilial === '') {
      Swal.fire({
        icon: 'info',
        title: 'Campo(s) vazio(s)!',
        text: 'Favor preecher o campo "Endereço".',
      });
    } else if (numeroEnderecoFilial === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Campo(s) vazio(s)!',
        text: 'Favor preecher o campo "Número".',
      });
    } else if (cnpj === '') {
      Swal.fire({
        icon: 'info',
        title: 'Campo(s) vazio(s)!',
        text: 'Favor preecher o campo "CNPJ".',
      });
    } else if (currentFilialId === 0) {
      api
        .post('empresas/saveEmpresaFilial', {
          Id_Empresas: empresaPrincipal[0].ID,
          NOME: nomeFilial,
          ENDERECO: enderecoFilial,
          NUMERO: numeroEnderecoFilial,
          CNPJ: cnpj,
          visitasLimitDiu: visitasDelimit,
          visitasLimitNot: visitasNoturnoDelimit,
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Filial cadastrada com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            setCadastrar(false);
            resetValues();
            history.go(0);
          }, 1000);
        });
    } else {
      // addfiliais.ID = currentFilialId;
      api
        .put('/empresas/updateEmpresaFilial', {
          ID: currentFilialId,
          Id_Empresas: empresaPrincipal[0].ID,
          NOME: nomeFilial,
          ENDERECO: enderecoFilial,
          NUMERO: numeroEnderecoFilial,
          CNPJ: cnpj,
          visitasLimitDiu: visitasDelimit,
          visitasLimitNot: visitasNoturnoDelimit,
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Filial cadastrada com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          setTimeout(() => {
            setCadastrar(false);
            resetValues();
            history.go(0);
          }, 1000);
        });
    }
  };

  useEffect(() => {
    getItens();
  }, [getItens]);

  const editChargeFilial = useCallback(
    (id: number) => {
      const current = filiais.find((e) => e.ID === id);
      setCurrentFilialId(id);
      setNomeFilial(current?.NOME!);
      setEnderecoFilial(current?.ENDERECO!);
      setNumeroEnderecoFilial(current?.NUMERO!);
      setCnpj(current?.CNPJ!);
      setVisitasDelimit(current?.visitasLimitDiu! || 1);
      setCadastrar(true);
      setVisitasNoturnoDelimit(current?.visitasLimitNot || 0);
    },
    [filiais],
  );

  return (
    <>
      <ModalConexao />
      <Container id="container">
        <TitlePage>Filiais</TitlePage>
        <br />
        <ListRegistButton
          setStateCadastrar={setCadastrar}
          stateCadastrar={cadastrar}
          object={{ list: 'Listar', regist: 'Cadastrar' }}
        />

        {!cadastrar && (
          <>
            <NewSearch
              arraySetState={setFiliaisFiltered}
              arrayState={filiais}
              selectFilters={headersFilters}
            />
            <Table
              body={filiaisFiltered.filter(
                (e, i) => i >= indexInitPg && i <= indexEndPg,
              )}
              header={headers}
              tableId="tableFiliais"
              setEditId={(idCurrentFilial) => {
                editChargeFilial(idCurrentFilial);
              }}
            />
            <PaginationComponent
              arrayStateFiltered={filiaisFiltered}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />
            <NewImportExel tableId="tableFiliais" fileName="filiais" />
          </>
        )}
        {cadastrar && (
          <ContainerCadastro>
            <LabelInputForm>Filial:</LabelInputForm>
            <InputForm
              placeholder="Digite aqui"
              id="filial"
              value={nomeFilial}
              onChange={({ currentTarget }) => {
                setNomeFilial(currentTarget.value);
              }}
            />
            <LabelInputForm>Endereço:</LabelInputForm>
            <InputForm
              placeholder="Digite aqui"
              id="endereco"
              value={enderecoFilial}
              onChange={({ currentTarget }) => {
                setEnderecoFilial(currentTarget.value);
              }}
            />
            <LabelInputForm>Número:</LabelInputForm>
            <InputForm
              placeholder="Digite aqui"
              id="numero"
              type="number"
              value={numeroEnderecoFilial}
              onChange={({ currentTarget }) => {
                setNumeroEnderecoFilial(Number(currentTarget.value));
              }}
            />
            <div className="containerInputs">
              <div className="inputLabelContainer">
                <LabelInputForm>CNPJ:</LabelInputForm>
                <NewInput
                  name="cnpj"
                  setStateValue={setCnpj}
                  stateValue={cnpj}
                  placeholder="Digite aqui"
                  mask="cnpj"
                />
              </div>
              <div className="inputLabelContainer">
                <LabelInputForm>DELIMITADOR DIURNO:</LabelInputForm>
                <InputForm
                  placeholder="Digite aqui"
                  // id="cnpj"
                  type="number"
                  defaultValue={1}
                  min={1}
                  onChange={({ currentTarget }) => {
                    setVisitasDelimit(Number(currentTarget.value));
                  }}
                  value={visitasDelimit}
                />
              </div>
              <div className="inputLabelContainer">
                <LabelInputForm>DELIMITADOR NOTURNO:</LabelInputForm>
                <InputForm
                  placeholder="Digite aqui"
                  // id="cnpj"
                  type="number"
                  defaultValue={0}
                  min={0}
                  onChange={({ currentTarget }) => {
                    setVisitasNoturnoDelimit(Number(currentTarget.value));
                  }}
                  value={visitasNoturnoDelimit}
                />
              </div>
            </div>
            <div className="containerButtons">
              <ButtonForm background="#FFFFFF" onClick={() => resetValues()}>
                Cancelar
              </ButtonForm>
              <ButtonForm background="#393B41" onClick={() => handleSubmit()}>
                Cadastrar
              </ButtonForm>
            </div>
          </ContainerCadastro>
        )}
      </Container>
    </>
  );
};
export default CadastroFilial;
