import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';

import { isValid } from 'date-fns';
import * as S from './ReportContentModal.styled';
import { useGetVehicleReportData } from '../../../../services';
import { VehicleReport } from '../../../../components';
import { formatDate } from '../../../../utils';
import { Loading } from '../../../../components/Loading';

interface Props {
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  currentVehicle?: any;
}

export const ReportContentModal: React.FC<Props> = ({
  setVisible,
  currentVehicle,
}) => {
  const [dateEnd, setDateEnd] = useState(formatDate(new Date(), 'yyyy-MM-dd'));
  const [dateInit, setDateInit] = useState(
    formatDate(new Date(), 'yyyy-MM-dd'),
  );

  const { data: vehicleReportData, isLoading } = useGetVehicleReportData({
    params: {
      dateFim: `${dateEnd}T23:59:01`,
      dateIni: `${dateInit}T00:00:01`,
      licensePlate: currentVehicle?.placa,
    },
    enabled:
      isValid(new Date(dateEnd)) &&
      isValid(new Date(dateInit)) &&
      !!currentVehicle?.placa,
  });

  return (
    <S.Wrapper>
      <S.HeaderContainer>
        <S.Title>Relatório ronda veícular</S.Title>
        <S.ButtonClose onClick={() => !!setVisible && setVisible(false)}>
          <FiX />
        </S.ButtonClose>
      </S.HeaderContainer>
      <S.ContentContainer>
        <S.InputsContainer>
          <S.InputDate
            type="date"
            value={dateInit}
            onChange={(e) => setDateInit(e.target.value)}
          />
          <S.InputDate
            type="date"
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
          />
        </S.InputsContainer>
        {!!vehicleReportData?.length && (
          <VehicleReport
            currentVehicle={{
              ...currentVehicle,
              dateInit: dateInit.split('-').reverse().join('/'),
              dateEnd: dateEnd.split('-').reverse().join('/'),
            }}
            vehicleReportData={[...vehicleReportData]}
          />
        )}
        {!vehicleReportData?.length && (
          <S.EmptyContainer>
            {isLoading && <Loading />}
            {!isLoading && (
              <S.EmptyText>Não há dados a apresentar...</S.EmptyText>
            )}
          </S.EmptyContainer>
        )}
      </S.ContentContainer>
    </S.Wrapper>
  );
};
