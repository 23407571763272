import React from 'react';
import {
  Document,
  Page,
  Text,
  Image,
  Font,
  PDFViewer,
  View,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import * as S from './VehicleReport.styles';
import { Table, TableColumn } from './components';
import { GetVehicleReportDataResponse } from '../../services';

import { formatDate } from '../../utils';
import { useAuth } from '../../hooks/auth';

interface VehicleReportProps {
  vehicleReportData: GetVehicleReportDataResponse[] | undefined;
  currentVehicle: any;
}

export const VehicleReport = ({
  vehicleReportData,
  currentVehicle,
}: VehicleReportProps): JSX.Element => {
  const Teste = (): any => {
    const initialHeaderTable: TableColumn[] = [
      { label: 'Período', column: '-' },
      {
        label: `${currentVehicle.dateInit} à ${currentVehicle.dateEnd}`,
        column: 'placa',
      },
    ];

    const initialTable: TableColumn[] = [
      { label: 'Veículo', column: 'modelo' },
      { label: 'Placa', column: 'placa' },
      { label: 'Data início', column: 'dateInit' },
      { label: 'Data fim', column: 'dateEnd' },
    ];

    const tableColumns: TableColumn[] = [
      { label: 'Ponto de interesse', column: 'interestPoint' },
      { label: 'Chegada no ponto', column: 'dateTimeEntrance' },
      { label: 'Saída do ponto', column: 'dateTimeDeparture' },
      { label: 'Permanência', column: 'timeSpent' },
    ];

    return (
      <S.Wrapper>
        <S.PageContainer
          size="A4"
          style={{
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
          }}
        >
          <h1>Teste</h1>
          <S.HeaderContainer
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 8,
              fontWeight: 'bold',
            }}
          >
            <S.CreatedAtText style={{ marginLeft: 'auto' }}>
              Relatório de ronda veícular -{' '}
              {formatDate(new Date(), 'dd/MM/yyyy HH:mm:ss')}
            </S.CreatedAtText>
            <Image
              style={{ width: '30%', marginLeft: 'auto', marginRight: 'auto' }}
              src="https://i0.wp.com/blog.gruposervnac.com.br/wp-content/uploads/2020/12/Logo-Servnac.png?fit=885%2C183&ssl=1"
            />
          </S.HeaderContainer>

          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 32,
            }}
          >
            <View
              key={Math.random()}
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                padding: 4,
                backgroundColor: '#00562F',
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 'bold',
                  color: 'white',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                Relatório de ronda veícular
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              {initialHeaderTable.map((column) => (
                <View
                  key={Math.random()}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    padding: 4,
                    border: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    {column.label}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 32,
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: '#00562F',
              }}
            >
              {initialTable.map((column) => (
                <View
                  key={Math.random()}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    padding: 4,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                      color: 'white',
                    }}
                  >
                    {column.label}
                  </Text>
                </View>
              ))}
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
              key={Math.random()}
            >
              {initialTable.map((column) => (
                <View
                  key={Math.random()}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    border: 1,
                    padding: 4,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    {currentVehicle[column.column]}
                  </Text>
                </View>
              ))}
            </View>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              marginTop: 32,
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: '#00562F',
              }}
            >
              {tableColumns.map((column) => (
                <View
                  key={Math.random()}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    padding: 4,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 'bold',
                      color: 'white',
                    }}
                  >
                    {column.label}
                  </Text>
                </View>
              ))}
            </View>

            {vehicleReportData &&
              vehicleReportData.map((row: any) => (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                  key={Math.random()}
                >
                  {tableColumns.map((column) => (
                    <View
                      key={Math.random()}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        borderColor: 'green',
                        border: 1,
                        borderWidth: 1,
                        padding: 4,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        {row[column.column]}
                      </Text>
                    </View>
                  ))}
                </View>
              ))}
          </View>
          <Text
            style={{
              position: 'absolute',
              fontSize: 12,
              bottom: 30,
              left: 0,
              right: 0,
              textAlign: 'center',
              color: 'grey',
            }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </S.PageContainer>
      </S.Wrapper>
    );
  };

  return (
    <PDFViewer width="100%" height="100%">
      <Teste />
    </PDFViewer>
  );
};
