import Axios from 'axios';
import {
  Coord,
  GetDirectionsMapbox,
} from '../pages/DetailsRotaAprov/interface';

interface GetInfoDetailsOfCoordsProps {
  coords: Coord[];
}

interface GetInfoDetailsOfCoordsResult {
  coords: Coord[];
  distance: number;
  time: number;
}

export async function getInfoDetailsOfCoords({
  coords,
}: GetInfoDetailsOfCoordsProps): Promise<GetInfoDetailsOfCoordsResult> {
  try {
    if (coords.length <= 1) {
      return {
        coords: [],
        distance: 0,
        time: 0,
      };
    }

    const locations = coords
      .map((item) => `${item.longitude},${item.latitude}`)
      .join(';');
    const token =
      'pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXc3MDVpMXAzOTJ6cGY0dG1obWwyNCJ9.dLzgTkYOc5KELagg-hRP7w';

    const resp = await Axios.get(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${locations}?geometries=geojson&access_token=${token}`,
    );

    const result: GetDirectionsMapbox = resp.data;

    return {
      coords: result.routes[0].geometry.coordinates.map((item) => ({
        latitude: item[1],
        longitude: item[0],
      })),
      distance: result.routes[0].distance,
      time: result.routes[0].duration,
    };
  } catch (error) {
    // console.log('ERROR => ', error.message);
    return {
      coords: [],
      distance: 0,
      time: 0,
    };
  }
}
