import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useAuth } from '../../hooks/auth';
import ListRegistButton from '../../components/ListRegistButton';
import NewImportExel from '../../components/NewImportExel';
import { NewSearch } from '../../components/NewSearch';
import PaginationComponent from '../../components/Pagination';
import Table from '../../components/Table';
import ModalConexao from '../../components/ModalConexao';
import { TitlePage } from '../../components/TitlePage';
import api from '../../services/api';

import {
  ButtonForm,
  InputForm,
  LabelInputForm,
} from '../CadastroCliente copy/styles';
import { Container, ContainerCadastro } from './styles';

const headers = [
  { name: 'Filial', field: 'Id_EmpresaFilial', sortable: true },
  { name: 'Marca', field: 'marca', sortable: true },
  { name: 'Modelo', field: 'modelo', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Ano de Fabricação', field: 'anoFabricacao', sortable: true },
  { name: 'Ano do Modelo', field: 'anoModelo', sortable: true },
  { name: 'Placa', field: 'placa', sortable: true },
  { name: 'Quilometragem', field: 'km', sortable: true },
  { name: 'Editar', field: 'editar', sortable: false },
  { name: 'Relatório', field: 'report', sortable: false },
  // { name: 'qrCode', field: 'qrCode', sortable: false },
  { name: 'Remover', field: 'remover', sortable: false },
];

const headersFilter = [
  { name: 'Marca', field: 'marca', sortable: true },
  { name: 'Modelo', field: 'modelo', sortable: true },
  { name: 'Status', field: 'status', sortable: true },
  { name: 'Ano de Fabricação', field: 'anoFabricacao', sortable: true },
  { name: 'Ano do Modelo', field: 'anoModelo', sortable: true },
  { name: 'Placa', field: 'placa', sortable: true },
  { name: 'Quilometragem', field: 'km', sortable: true },
  // { name: 'Editar', field: 'editar', sortable: false },
  // { name: 'Remover', field: 'remover', sortable: false },
];

interface PropsGetVeiculos {
  Id_EmpresaFilial: string;
  anoFabricacao: string;
  anoModelo: string;
  createdAt: string;
  id: number;
  km: string;
  marca: string;
  modelo: string;
  placa: string;
  status: string;
  updatedAt: string;
}

const CadastroVeiculo: React.FC = () => {
  const [cadastrar, setCadastrar] = useState(false);
  const [veiculos, setVeiculos] = useState<PropsGetVeiculos[]>([]);
  const [veiculosFiltered, setVeiculosFiltered] = useState<PropsGetVeiculos[]>(
    [],
  );

  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);
  const history = useHistory();
  const { empresaPrincipal, user } = useAuth();

  const [addVeiculo, setAddVeiculo] = useState({
    marca: '',
    modelo: '',
    status: '',
    anoFabricacao: '',
    anoModelo: '',
    placa: '',
    km: '',
    Id_EmpresaFilial: user.companyId,
  });

  const getItems = useCallback(async () => {
    const getVeiculos = await api.get(
      `/veiculos/?id=${empresaPrincipal[0].Id_Empresas}`,
    );

    setVeiculos(getVeiculos.data);
    setVeiculosFiltered(getVeiculos.data);
  }, [empresaPrincipal]);

  const handleSubmit = useCallback(() => {
    api.post('/veiculos', addVeiculo).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Vaículo cadastrado com sucesso!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        showConfirmButton: false,
      });
      setTimeout(() => {
        setCadastrar(false);
        history.go(0);
      }, 1000);
    });
  }, [addVeiculo, history]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage title="Veículos" />
        <ListRegistButton
          setStateCadastrar={setCadastrar}
          stateCadastrar={cadastrar}
          object={{ list: 'Listar', regist: 'Cadastrar' }}
        />
        {!cadastrar && (
          <>
            <NewSearch
              arraySetState={setVeiculosFiltered}
              arrayState={veiculos}
              selectFilters={headersFilter}
            />
            <Table
              body={veiculosFiltered.filter(
                (e, i) => i >= indexInitPg && i <= indexEndPg,
              )}
              header={headers}
              tableId="tableVeiculos"
            />
            <PaginationComponent
              arrayStateFiltered={veiculosFiltered}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />
            <NewImportExel tableId="tableVeiculos" fileName="veiculos" />
          </>
        )}
        {cadastrar && (
          <ContainerCadastro>
            <LabelInputForm>Marca:</LabelInputForm>
            <InputForm
              name="marca"
              placeholder="Digite aqui"
              onChange={(e) => {
                setAddVeiculo({ ...addVeiculo, marca: e.currentTarget.value });
              }}
            />
            <LabelInputForm>Modelo:</LabelInputForm>
            <InputForm
              name="modelo"
              placeholder="Digite aqui"
              onChange={(e) => {
                setAddVeiculo({
                  ...addVeiculo,
                  modelo: e.currentTarget.value,
                });
              }}
            />
            <LabelInputForm>Status:</LabelInputForm>
            <InputForm
              name="status"
              placeholder="Digite aqui"
              onChange={(e) => {
                setAddVeiculo({ ...addVeiculo, status: e.currentTarget.value });
              }}
            />
            <LabelInputForm>Fabricação:</LabelInputForm>
            <InputForm
              name="fabricacao"
              placeholder="Digite aqui"
              onChange={(e) => {
                setAddVeiculo({
                  ...addVeiculo,
                  anoFabricacao: e.currentTarget.value,
                });
              }}
            />
            <LabelInputForm>Ano:</LabelInputForm>
            <InputForm
              name="ano"
              placeholder="Digite aqui"
              onChange={(e) => {
                setAddVeiculo({
                  ...addVeiculo,
                  anoModelo: e.currentTarget.value,
                });
              }}
            />
            <LabelInputForm>Placa:</LabelInputForm>
            <InputForm
              name="placa"
              placeholder="Digite aqui"
              onChange={(e) => {
                setAddVeiculo({ ...addVeiculo, placa: e.currentTarget.value });
              }}
            />
            <LabelInputForm>Quilometragem:</LabelInputForm>
            <InputForm
              name="km"
              placeholder="Digite aqui"
              onChange={(e) => {
                setAddVeiculo({ ...addVeiculo, km: e.currentTarget.value });
              }}
            />
            <div className="containerButtons">
              <ButtonForm
                background="#FFFFFF"
                onClick={() => {
                  setCadastrar(false);
                  setAddVeiculo({
                    marca: '',
                    modelo: '',
                    status: '',
                    anoFabricacao: '',
                    anoModelo: '',
                    placa: '',
                    km: '',
                    Id_EmpresaFilial: user.companyId,
                  });
                }}
              >
                Cancelar
              </ButtonForm>
              <ButtonForm background="#393B41" onClick={() => handleSubmit()}>
                Cadastrar
              </ButtonForm>
            </div>
          </ContainerCadastro>
        )}
      </Container>
    </>
  );
};

export default CadastroVeiculo;
