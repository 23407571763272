import React, { useEffect, useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { isBefore, parse } from 'date-fns';
import { InputOverview } from '../../../../../components/InputOverview';
import { ButtonRem } from '../../styles';
import * as S from './RevisionInputs.style';
import { TimeRev } from '../..';

interface RevisionInputsProps {
  index: number;
  revision: TimeRev;
  handleRevisionDeletion: () => void;
  onChangeValue: (newTime: TimeRev) => void;
}

const RevisionInputs = ({
  index,
  revision,
  onChangeValue,
  handleRevisionDeletion,
}: RevisionInputsProps): JSX.Element => {
  const [newRevision, setNewRevision] = useState<TimeRev>(revision);
  const [errorMessage, setErrorMessage] = useState('');

  const endTimeGreaterThanStartTime = ({ end, start }: TimeRev): boolean => {
    const horaInicio = parse(start, 'HH:mm', new Date());
    const horaFim = parse(end, 'HH:mm', new Date());
    return isBefore(horaFim, horaInicio);
  };

  const formattingMessageError = (revisionValue: TimeRev): void => {
    if (endTimeGreaterThanStartTime(revisionValue)) {
      return setErrorMessage(
        'A hora inicial precisa ser maior que a hora final.',
      );
    }
    if (
      revisionValue?.start &&
      revisionValue?.end &&
      revisionValue?.start === revisionValue?.end
    ) {
      return setErrorMessage(
        'O horário inicial deve ser diferente do horário final',
      );
    }
    if (!revisionValue?.start) {
      return setErrorMessage('O horário inicial deve preenchido');
    }
    if (!revisionValue?.end) {
      return setErrorMessage('O horário final deve preenchido');
    }

    return setErrorMessage('');
  };

  const handleAddRivisionHour = (hour: string, type: 'start' | 'end'): void => {
    if (!hour) return;
    const newRevisionFormatted = {
      ...newRevision,
      [type]: hour,
    };

    if (hour) {
      setNewRevision(newRevisionFormatted);

      formattingMessageError(newRevisionFormatted);
    }
  };

  useEffect(() => {
    if (!errorMessage) {
      onChangeValue(newRevision);
    }
  }, [errorMessage]);

  useEffect(() => {
    setNewRevision(revision);
  }, [revision]);

  return (
    <>
      <aside
        key={index.toString()}
        style={{
          paddingBottom: 0,
          borderBottom: 0,
          marginBottom: 10,
          alignItems: 'center',
        }}
      >
        <article>
          <InputOverview
            name={`startRevTime${index}`}
            type="time"
            value={newRevision.start}
            onChange={(e) => {
              handleAddRivisionHour(e.target?.value, 'start');
            }}
          />
        </article>

        <article>
          <InputOverview
            name={`endRevTime${index}`}
            type="time"
            value={newRevision.end}
            onChange={(e) => {
              handleAddRivisionHour(e.target?.value, 'end');
            }}
          />
        </article>
        <ButtonRem type="button" onClick={handleRevisionDeletion}>
          <FiTrash />
        </ButtonRem>
      </aside>
      {errorMessage && (
        <S.SpanError>
          <span>{errorMessage}</span>
        </S.SpanError>
      )}
    </>
  );
};

export default RevisionInputs;
