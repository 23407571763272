import styled from 'styled-components';
import backgroundImage from '../../../assets/background-qrcode.jpg';

export const Container = styled.div`
  font-family: 'Montserrat';

  padding: 0px 74px 30px 74px;

  align-items: center;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    padding: 22px;
  }

  .containerHourDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      width: 49.5%;
    }
  }

  #typePeriodicidade {
    color: ${(props) => props.theme.colors.black2};
    width: 282px;
    height: 48px;

    border: 1px solid ${(props) => props.theme.colors.white4};
    border-radius: 4px;

    font-size: 14px;
    font-weight: 600;
  }

  .title {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color: ${(props) => props.theme.colors.black2};
    text-align: center;
    width: 100%;
    font-weight: 700;
  }
`;

export const DivClose = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;
`;

export const ButtonClose = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.error};
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  border-radius: 4px;

  svg {
    color: ${(props) => props.theme.colors.white};
    font-size: 30px;
  }
`;

export const HeaderForm = styled.h3`
  margin-top: 35px;
  text-align: center;

  color: #2f2f2f;
  font-size: 16px;
  font-weight: 700;
`;

export const ContainerImage = styled.div`
  width: 420px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('${backgroundImage}') no-repeat center;
  background-size: 100% 100%;

  img {
    width: 50%;
  }
`;

export const ContainerButtonForm = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  flex-direction: column;

  img {
    margin: auto;
  }
  @media screen and (max-width: 500px) {
    img {
      width: calc(100vw - 60px);
      height: calc(100vw - 60px);
    }
  }
`;

interface PropsButtonForm {
  background: string;
}

export const ButtonForm = styled.button<PropsButtonForm>`
  width: 49.5%;
  height: 50px;
  background-color: ${(props) =>
    props.background === '#FFFFFF'
      ? props.theme.colors.white
      : props.theme.colors.primary};
  color: ${(props) =>
    props.background === '#FFFFFF'
      ? props.theme.colors.primary
      : props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.gray12};
  border-radius: 4px;
`;
