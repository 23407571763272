import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import Button from '../../components/Button';
import ListRegistButton from '../../components/ListRegistButton';
import NewImportExel from '../../components/NewImportExel';
import NewInput from '../../components/NewInput';
import { NewSearch } from '../../components/NewSearch';
import PaginationComponent from '../../components/Pagination';
import Table from '../../components/Table';
import { TitlePage } from '../../components/TitlePage/styled';
import { useAuth } from '../../hooks/auth';
import { Filiais } from '../../models/Filiais';
import ModalConexao from '../../components/ModalConexao';
import api from '../../services/api';

import {
  Container,
  ContainerCadastro,
  DivLineBottom,
  HeaderForm,
  LabelInputForm,
} from './styles';

interface GetVistorias {
  id: number;
  name: string;
  companyId: number;
  createdAt: string;
}

const headers = [
  { name: 'Identificador', field: 'id', sortable: true },
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Empresa', field: 'companyId', sortable: true },
  { name: 'Data de criação', field: 'createdAt', sortable: true },
  { name: 'Edit', field: 'editOption', sortable: true },
];
const headersFilter = [{ name: 'Nome', field: 'name', sortable: true }];

const DashboardOcorrencias: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const [vistorias, setVistorias] = useState<GetVistorias[]>([]);
  const [filiais, setFiliais] = useState<Filiais[]>([]);
  const [nameVistoria, setNameVistoria] = useState('');
  const [idVistoria, setIdVistoria] = useState(0);
  const [vistoriasFiltered, setVistoriasFiltered] = useState<GetVistorias[]>(
    [],
  );
  const [cadastrar, setCadastrar] = useState(false);

  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const handleSubmit = useCallback(async () => {
    if (nameVistoria !== '') {
      if (idVistoria !== 0) {
        api
          .put('/cadVistorias/', {
            id: idVistoria,
            name: nameVistoria,
            companyId: empresaPrincipal[0].ID,
          })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Vistoria cadastrada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              setCadastrar(false);
              // eslint-disable-next-line no-restricted-globals
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            console.log('Num foi não hein');
          });
      } else {
        api
          .post('/cadVistorias/', {
            name: nameVistoria,
            companyId: empresaPrincipal[0].ID,
          })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Vistoria cadastrada com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
              showConfirmButton: false,
            });
            setTimeout(() => {
              setCadastrar(false);
              // eslint-disable-next-line no-restricted-globals
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            console.log('Num foi não hein');
          });
      }
    } else {
      console.log('não enviou');
    }
  }, [empresaPrincipal, idVistoria, nameVistoria]);

  const getItems = useCallback(async () => {
    const vistoriasGet = await api.get(
      `cadVistorias?companyId=${empresaPrincipal[0].ID}`,
    );
    const filiasGet = await api.get(`/empresas/${empresaPrincipal[0].ID}`);
    setFiliais(filiasGet.data);
    setVistorias(vistoriasGet.data);
    setVistoriasFiltered(vistoriasGet.data);
  }, [empresaPrincipal]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const handleSubmitEdit = (idvistoriaEdit: number): void => {
    const vistoriaCurrent = vistorias.find(({ id }) => id === idvistoriaEdit);

    setNameVistoria(vistoriaCurrent?.name!);
    setIdVistoria(vistoriaCurrent?.id!);
    setCadastrar(true);
  };

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Vistorias</TitlePage>
        <ListRegistButton
          object={{ list: 'Listar', regist: 'Registrar' }}
          setStateCadastrar={setCadastrar}
          stateCadastrar={cadastrar}
        />
        {!cadastrar && (
          <>
            <NewSearch
              arraySetState={setVistoriasFiltered}
              arrayState={vistorias}
              selectFilters={headersFilter}
            />
            <Table
              header={headers}
              body={vistoriasFiltered.filter(
                (e, i) => i >= indexInitPg && i <= indexEndPg,
              )}
              tableId="TableVistorias"
              filiais={filiais}
              setEditId={(id) => {
                handleSubmitEdit(id);
              }}
            />
            <PaginationComponent
              arrayStateFiltered={vistoriasFiltered}
              setIndexPagination={(indexInit, indexEnd) => {
                setIndexInitPg(indexInit);
                setIndexEndPg(indexEnd);
              }}
            />
            <NewImportExel fileName="Vistorias" tableId="TableVistorias" />
          </>
        )}
        {cadastrar && (
          <ContainerCadastro>
            <HeaderForm>Cadastro de vistoria</HeaderForm>
            <DivLineBottom />
            <LabelInputForm>Nome</LabelInputForm>
            <NewInput
              name="vistorias"
              placeholder="Digite aqui..."
              setStateValue={setNameVistoria}
              stateValue={nameVistoria}
            />
            <div className="buttonContainer">
              <Button id="cancel">Cancelar</Button>
              <Button onClick={handleSubmit}>Salvar</Button>
            </div>
          </ContainerCadastro>
        )}
      </Container>
    </>
  );
};

export default DashboardOcorrencias;
