import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'antd';

import { ThemeContext } from 'styled-components';
import CarIcon from '../../assets/protecao.png';
import { ContainerMenuDrawer, List, LiList, ArrowSvg } from './styles';
import { useDrawer } from '../../hooks/drawer';

interface PropsOption {
  label: string;
  active: boolean;
}

const MenuLateral: React.FC = () => {
  const currentPage = useLocation();
  const { isActive, updateDrawer } = useDrawer();
  const { colors } = useContext(ThemeContext);
  const [currentOption, setCurrentOption] = useState('');
  const [showScreen, setShowScreen] = useState(true);

  useEffect(() => {
    const id = currentOption;
    const subListIds = [
      'listRondas',
      'listRotas',
      'listEmpresas',
      'listVeiculos',
      'listUsuarios',
    ];
    subListIds.filter((e: string) => {
      if (e === id) {
        document.getElementById(e)?.setAttribute('style', 'display:block');
      } else {
        document.getElementById(e)?.setAttribute('style', 'display:none');
      }
      return '';
    });
  }, [currentOption]);

  useEffect(() => {
    if (window.innerWidth < 1152) {
      setShowScreen(false);
    }
  }, []);

  const showDrawer = useMemo((): boolean => {
    if (showScreen) {
      return true;
    }
    return isActive;
  }, [isActive, showScreen]);

  const mql = window.matchMedia('(max-width: 1152px)');

  mql.addEventListener('change', () => {
    if (window.innerWidth > 1152) {
      setShowScreen(true);
    } else if (window.innerWidth < 1152) {
      setShowScreen(false);
    }
  });

  return (
    <>
      {currentPage.pathname !== '/' &&
        currentPage.pathname !== '/signup' &&
        currentPage.pathname !== '/forgot-password' &&
        currentPage.pathname !== '/politica-privacidade' &&
        showDrawer && (
          <>
            <ContainerMenuDrawer id="teste">
              <List>
                <li>
                  <Link to="/">
                    <div>
                      <svg
                        style={{ width: 15, height: 15 }}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.33333 8.83333H6.33333C6.79167 8.83333 7.16667 8.45833 7.16667 8V1.33333C7.16667 0.875 6.79167 0.5 6.33333 0.5H1.33333C0.875 0.5 0.5 0.875 0.5 1.33333V8C0.5 8.45833 0.875 8.83333 1.33333 8.83333ZM1.33333 15.5H6.33333C6.79167 15.5 7.16667 15.125 7.16667 14.6667V11.3333C7.16667 10.875 6.79167 10.5 6.33333 10.5H1.33333C0.875 10.5 0.5 10.875 0.5 11.3333V14.6667C0.5 15.125 0.875 15.5 1.33333 15.5ZM9.66667 15.5H14.6667C15.125 15.5 15.5 15.125 15.5 14.6667V8C15.5 7.54167 15.125 7.16667 14.6667 7.16667H9.66667C9.20833 7.16667 8.83333 7.54167 8.83333 8V14.6667C8.83333 15.125 9.20833 15.5 9.66667 15.5ZM8.83333 1.33333V4.66667C8.83333 5.125 9.20833 5.5 9.66667 5.5H14.6667C15.125 5.5 15.5 5.125 15.5 4.66667V1.33333C15.5 0.875 15.125 0.5 14.6667 0.5H9.66667C9.20833 0.5 8.83333 0.875 8.83333 1.33333Z"
                          fill="#808080"
                        />
                      </svg>
                    </div>
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Button
                    onClick={() => {
                      if (currentOption === 'listRondas') {
                        setCurrentOption('');
                      } else {
                        setCurrentOption('listRondas');
                      }
                    }}
                  >
                    <div>
                      <svg
                        style={{ width: 17, height: 12 }}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.8332 1.83329C14.8332 0.916626 14.0832 0.166626 13.1665 0.166626H10.6665V1.83329H13.1665V4.04163L10.2665 7.66663H7.33317V3.49996H3.99984C2.15817 3.49996 0.666504 4.99163 0.666504 6.83329V9.33329H2.33317C2.33317 10.7166 3.44984 11.8333 4.83317 11.8333C6.2165 11.8333 7.33317 10.7166 7.33317 9.33329H11.0665L14.8332 4.62496V1.83329ZM4.83317 10.1666C4.37484 10.1666 3.99984 9.79163 3.99984 9.33329H5.6665C5.6665 9.79163 5.2915 10.1666 4.83317 10.1666Z"
                          fill="#808080"
                        />
                        <path
                          d="M7.33317 1H3.1665V2.66667H7.33317V1Z"
                          fill="#808080"
                        />
                        <path
                          d="M14.833 6.83325C13.4497 6.83325 12.333 7.94992 12.333 9.33325C12.333 10.7166 13.4497 11.8333 14.833 11.8333C16.2163 11.8333 17.333 10.7166 17.333 9.33325C17.333 7.94992 16.2163 6.83325 14.833 6.83325ZM14.833 10.1666C14.3747 10.1666 13.9997 9.79159 13.9997 9.33325C13.9997 8.87492 14.3747 8.49992 14.833 8.49992C15.2913 8.49992 15.6663 8.87492 15.6663 9.33325C15.6663 9.79159 15.2913 10.1666 14.833 10.1666Z"
                          fill="#808080"
                        />
                      </svg>
                    </div>
                    Rondas
                    <ArrowSvg
                      id="ArrowSvg"
                      style={{ width: 17, height: 12, marginLeft: '49%' }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                        fill="#666666"
                      />
                    </ArrowSvg>
                  </Button>
                </li>
                <LiList id="listRondas" style={{ display: 'none' }}>
                  <Link to="/categoria">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Categorias</h5>
                    </div>
                  </Link>
                  <Link to="/setor">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Setores</h5>
                    </div>
                  </Link>
                  <Link to="/dashboard-vistoria-rondas">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Vistorias</h5>
                    </div>
                  </Link>
                  <Link to="/riscos-rondas">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Riscos</h5>
                    </div>
                  </Link>
                  <Link to="/rondas">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Rondas</h5>
                    </div>
                  </Link>
                  <Link to="/agenda-ronda">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Agenda</h5>
                    </div>
                  </Link>
                  <Link to="/historic-patrol">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Histórico de rondas</h5>
                    </div>
                  </Link>
                  <Link to="/auditoria-rondas">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Auditoria</h5>
                    </div>
                  </Link>
                </LiList>

                <li>
                  <Button
                    onClick={() => {
                      if (currentOption === 'listRotas') {
                        setCurrentOption('');
                      } else {
                        setCurrentOption('listRotas');
                      }
                      // verificationListMenu('listRotas');
                    }}
                  >
                    <div style={{ width: 80 }}>
                      <svg
                        style={{ width: 15, height: 17 }}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.99984 0.666626C2.77484 0.666626 0.166504 3.27496 0.166504 6.49996C0.166504 10.875 5.99984 17.3333 5.99984 17.3333C5.99984 17.3333 11.8332 10.875 11.8332 6.49996C11.8332 3.27496 9.22484 0.666626 5.99984 0.666626ZM5.99984 8.58329C4.84984 8.58329 3.9165 7.64996 3.9165 6.49996C3.9165 5.34996 4.84984 4.41663 5.99984 4.41663C7.14984 4.41663 8.08317 5.34996 8.08317 6.49996C8.08317 7.64996 7.14984 8.58329 5.99984 8.58329Z"
                          fill="#808080"
                        />
                      </svg>
                    </div>
                    Rotas
                    <ArrowSvg
                      id="ArrowSvg"
                      style={{ width: 17, height: 12, marginLeft: '58%' }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                        fill="#666666"
                      />
                    </ArrowSvg>
                  </Button>
                </li>
                <LiList id="listRotas" style={{ display: 'none' }}>
                  <Link to="/rotas">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Rotas</h5>
                    </div>
                  </Link>

                  <Link to="/MapsDashboard">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Mapa</h5>
                    </div>
                  </Link>
                  <Link to="/apontamentos">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Apontamentos</h5>
                    </div>
                  </Link>
                  <Link to="/rotas-clientes">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Relatórios Analíticos</h5>
                    </div>
                  </Link>
                  <Link to="/relatorio-visitas">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Relatórios Sintéticos</h5>
                    </div>
                  </Link>
                  <Link to="/auditoria-rotas">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Auditoria</h5>
                    </div>
                  </Link>
                </LiList>
                <li style={{ marginTop: 40 }}>
                  <Link to="/cadastro-cliente-posto">
                    <div style={{ width: 47 }}>
                      <svg
                        style={{ width: 16, height: 16, marginLeft: 10 }}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
                          fill="#808080"
                        />
                      </svg>
                    </div>
                    Clientes
                  </Link>
                </li>
                <li>
                  <Button
                    onClick={() => {
                      if (currentOption === 'listEmpresas') {
                        setCurrentOption('');
                      } else {
                        setCurrentOption('listEmpresas');
                      }
                    }}
                  >
                    <div>
                      <svg
                        style={{ width: 13, height: 17 }}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.66683 0.666626H2.00016C1.0835 0.666626 0.34183 1.41663 0.34183 2.33329L0.333496 15.6666C0.333496 16.5833 1.07516 17.3333 1.99183 17.3333H12.0002C12.9168 17.3333 13.6668 16.5833 13.6668 15.6666V5.66663L8.66683 0.666626ZM10.3335 14H3.66683V12.3333H10.3335V14ZM10.3335 10.6666H3.66683V8.99996H10.3335V10.6666ZM7.8335 6.49996V1.91663L12.4168 6.49996H7.8335Z"
                          fill="#808080"
                        />
                      </svg>
                    </div>
                    Empresas
                    <ArrowSvg
                      id="ArrowSvg"
                      style={{ width: 17, height: 12, marginLeft: '43%' }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                        fill="#666666"
                      />
                    </ArrowSvg>
                  </Button>
                </li>

                <LiList id="listEmpresas" style={{ display: 'none' }}>
                  <Link to="/cadastro-matriz">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Empresa Matriz</h5>
                    </div>
                  </Link>
                  <Link to="/cadastro-filial">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Filiais</h5>
                    </div>
                  </Link>
                </LiList>

                <li>
                  <Button
                    onClick={() => {
                      if (currentOption === 'listVeiculos') {
                        setCurrentOption('');
                      } else {
                        setCurrentOption('listVeiculos');
                      }
                    }}
                  >
                    <div>
                      {/* <svg
                    style={{ width: 13, height: 17 }}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66683 0.666626H2.00016C1.0835 0.666626 0.34183 1.41663 0.34183 2.33329L0.333496 15.6666C0.333496 16.5833 1.07516 17.3333 1.99183 17.3333H12.0002C12.9168 17.3333 13.6668 16.5833 13.6668 15.6666V5.66663L8.66683 0.666626ZM10.3335 14H3.66683V12.3333H10.3335V14ZM10.3335 10.6666H3.66683V8.99996H10.3335V10.6666ZM7.8335 6.49996V1.91663L12.4168 6.49996H7.8335Z"
                      fill="#808080"
                    />
                  </svg> */}
                      <img
                        src={CarIcon}
                        style={{ width: 20, height: 20 }}
                        alt="Buffering"
                      />
                    </div>
                    Veículos
                    <ArrowSvg
                      id="ArrowSvg"
                      style={{ width: 17, height: 12, marginLeft: '43%' }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                        fill="#666666"
                      />
                    </ArrowSvg>
                  </Button>
                </li>

                <LiList id="listVeiculos" style={{ display: 'none' }}>
                  <Link to="/cadastro-veiculo">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Veículos</h5>
                    </div>
                  </Link>
                </LiList>

                <li>
                  <Button
                    onClick={() => {
                      if (currentOption === 'listUsuarios') {
                        setCurrentOption('');
                      } else {
                        setCurrentOption('listUsuarios');
                      }
                    }}
                  >
                    <div style={{ width: 47 }}>
                      <svg
                        style={{ width: 18, height: 11 }}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M13.3335 5.16663C14.7168 5.16663 15.8252 4.04996 15.8252 2.66663C15.8252 1.28329 14.7168 0.166626 13.3335 0.166626C11.9502 0.166626 10.8335 1.28329 10.8335 2.66663C10.8335 4.04996 11.9502 5.16663 13.3335 5.16663ZM6.66683 5.16663C8.05016 5.16663 9.15849 4.04996 9.15849 2.66663C9.15849 1.28329 8.05016 0.166626 6.66683 0.166626C5.2835 0.166626 4.16683 1.28329 4.16683 2.66663C4.16683 4.04996 5.2835 5.16663 6.66683 5.16663ZM6.66683 6.83329C4.72516 6.83329 0.833496 7.80829 0.833496 9.74996V11C0.833496 11.4583 1.2085 11.8333 1.66683 11.8333H11.6668C12.1252 11.8333 12.5002 11.4583 12.5002 11V9.74996C12.5002 7.80829 8.6085 6.83329 6.66683 6.83329ZM13.3335 6.83329C13.0918 6.83329 12.8168 6.84996 12.5252 6.87496C12.5418 6.88329 12.5502 6.89996 12.5585 6.90829C13.5085 7.59996 14.1668 8.52496 14.1668 9.74996V11C14.1668 11.2916 14.1085 11.575 14.0168 11.8333H18.3335C18.7918 11.8333 19.1668 11.4583 19.1668 11V9.74996C19.1668 7.80829 15.2752 6.83329 13.3335 6.83329Z" />
                      </svg>
                    </div>
                    Usuários
                    <ArrowSvg
                      id="ArrowSvg"
                      style={{ width: 17, height: 12, marginLeft: '45%' }}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z"
                        fill="#666666"
                      />
                    </ArrowSvg>
                  </Button>
                </li>
                <LiList id="listUsuarios" style={{ display: 'none' }}>
                  <Link to="/pre-cadastro-usuarios">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Pré-cadastro</h5>
                    </div>
                  </Link>
                  <Link to="/listagem-usuarios">
                    <div>
                      <svg>
                        <rect width="2" height="48" fill={colors.primary} />
                      </svg>
                      <h5>Listagem</h5>
                    </div>
                  </Link>
                </LiList>
              </List>
            </ContainerMenuDrawer>
            {isActive && (
              <button
                type="button"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.15)',
                  border: 'none',
                  cursor: 'default',
                  width: '100%',
                  height: 'calc(100vh - 80px)',
                  position: 'absolute',
                }}
                onClick={() => updateDrawer(false)}
              >
                {' '}
              </button>
            )}
          </>
        )}
    </>
  );
};

export default MenuLateral;
