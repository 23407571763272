import React, { useState, useEffect, useCallback } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Tooltip, Zoom } from '@material-ui/core';

import moment from 'moment';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { Loading } from '../../components/Loading';
import { Calendario } from '../../components/Calendario';
import { Cadastro } from './Cadastro';
// import { ModalEditScheduling } from './ModalEditScheduling';
import Search from '../../components/Search';
import ModalConexao from '../../components/ModalConexao';

import { Container, Aba, ContainerAba } from './styles';
import { AgendaRonda, dataAgendaRonda } from '../../models/AgendaRonda';
import { Ronda } from '../../models/Rondas';
import { ModalEditScheduling } from './ModalEditScheduling';
import ListRegistButton from '../../components/ListRegistButton';

interface ResponseGetAgendamento {
  horaRevFinal: string[];
  horaRevInicial: string[];
  revisoes: number[];
}

export interface ResponseGetEmpresas {
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  ID: number;
  Id_Empresas: number;
  NOME: string;
  NUMERO: string;
}

export interface ResponseGetUsuario {
  ativo: number;
  avatar: string;
  avatar_url: string;
  companyId: number;
  coordenador: number;
  cpf: string;
  createdAt: string;
  dataNascimento: string;
  email: string;
  id: number;
  nome: string;
  password: string;
  telefone: string;
  tipo: string;
  updatedAt: string;
  motoboy: null;
  supervisor: true;
}

interface ResponseRevisao {
  idTableRevisao: number[];
  horaInicial: string[];
  horaFinal: string[];
}

export interface ResponseGetPeriodicidade {
  ID: number;
  NOME: string;
  DIAS_SEMANA: string;
  QTD_DIAS: number;
  HORA_BASE_INICIAL: string;
  HORA_BASE_FINAL: string;
  Id_EmpresasFilial: number;
  revisaoHoras: ResponseRevisao;
}

export interface ResponseGet {
  agendaAtiva: boolean;
  agendamento: ResponseGetAgendamento;
  cpfUser: string;
  diasSemana: string;
  horaBaseFinal: string;
  horaBaseInicial: string;
  idAgenda: number;
  idAmbientes: number;
  idEmpresaFilial: number;
  idEmpresaPrincipal: number;
  idPeriodicidade: number;
  idUsers: number;
  nomeAmbiente: string;
  nomePeriodicidade: string;
  nomeUser: string;
  qtdLimpezas: number;

  isRevisao?: boolean;
}

export const Agendamentos: React.FC = () => {
  const { empresaPrincipal } = useAuth();
  const { addToast } = useToast();

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [
    dadosAgendamentoEdit,
    setDadosAgendamentoEdit,
  ] = useState<AgendaRonda | null>(null);
  const [modalTitle, setModalTitle] = useState('');
  // const [showModalEdit, setShowModalEdit] = useState(false);
  // const [dadosAgendamentoEdit, setDadosAgendamentoEdit] = useState<
  //   AgendaRonda
  // >();
  // const [dadosSemanaEdit, setDadosSemanaEdit] = useState('');
  const [responseAgendamentos, setResponseAgendamentos] = useState<
    AgendaRonda[]
  >(dataAgendaRonda);
  const [responseEmpresas, setResponseEmpresas] = useState<
    ResponseGetEmpresas[]
  >([]);
  const [responseUsuarios, setResponseUsuarios] = useState<
    ResponseGetUsuario[]
  >([]);
  const [responseRondas, setResponseRondas] = useState<Ronda[]>([]);
  // const [responsePeriodicidades, setResponsePeriodicidades] = useState<
  //   ResponseGetPeriodicidade[]
  // >([]);
  // const [verificaResponse, setVerificaResponse] = useState(false);
  const [cadastrar, setCadastrar] = useState(false);

  // const [weekFilter, setWeekFilter] = useState('Semana');
  // const formRef = useRef<FormHandles>(null);

  const getItens = useCallback(async () => {
    setLoading(true);

    try {
      const urlGetAgendamentos =
        empresaPrincipal.length > 0
          ? `/agendarondas?companyId=${empresaPrincipal[0].Id_Empresas}`
          : '/agendas/showAgenda';

      const companyId =
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : '';
      // console.log(urlGetAgendamentos);
      const dataAgendamentos = await api.get(urlGetAgendamentos);
      // console.log('AGENDA', dataAgendamentos.data);
      const agendAll: AgendaRonda[] = dataAgendamentos.data;
      const dataEmpresas = await api.get(`empresas/${companyId}`);
      // console.log('EMPRESA', dataEmpresas.data);
      const dataUsers = await api.get(`/allUsers/${companyId}`);
      // console.log('USER', dataUsers.data);
      const dataRonda = await api.get(`/rondas?companyId=${companyId}`);
      // const dataRonda = await api.get(`/rondas?companyId=1`);
      // console.log('Ronda', dataRonda.data);

      setResponseEmpresas(
        dataEmpresas.data.filter((filial: ResponseGetEmpresas) => filial.ATIVO),
      );
      setResponseUsuarios(
        dataUsers.data,
        // dataUsers.data.filter(
        //   (user: ResponseGetUsuario) =>
        //     user.coordenador === 0 && !user.motoboy && !user.supervisor,
        // ),
      );
      const today = moment().format('YYYY-MM-DD');
      setResponseAgendamentos(
        agendAll.filter((i) => i.vigIni <= today && i.vigFim >= today),
      );
      setResponseRondas(dataRonda.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Erro ao carregar dados, favor recarregue a página.',
      });
    }
  }, [addToast, empresaPrincipal]);

  useEffect(() => {
    getItens();
  }, [getItens]);

  const handlePermitionRegister = useCallback(() => {
    setCadastrar(true);
  }, []);

  return (
    <>
      <ModalConexao />

      <Container>
        {empresaPrincipal.length > 0 ? (
          <Aba>
            <ContainerAba className="aba" cor={!cadastrar}>
              <button type="button" onClick={() => setCadastrar(false)}>
                Listar agendamentos
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={cadastrar}>
              <button type="button" onClick={() => handlePermitionRegister()}>
                Cadastrar agendamento
              </button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização, cadastro e edição de todos os agendamentos para as rondas e tarefas diárias"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        ) : (
          <Aba>
            <ContainerAba className="aba" cor={!cadastrar}>
              <button type="button">Listar agendamentos</button>
            </ContainerAba>

            <Tooltip
              title="Nessa página você tem acesso a visualização de todos os agendamentos para as rondas e tarefas diárias"
              arrow
              TransitionComponent={Zoom}
            >
              <span>
                <AiOutlineQuestionCircle />
              </span>
            </Tooltip>
          </Aba>
        )}

        {!cadastrar && (
          <>
            <Search
              onSearch={(value: string) => {
                setSearch(value);
              }}
              nomePlaceHolder="Buscar"
            />

            <Calendario
              onClickCardEdit={(x: AgendaRonda, y: string) => {
                setShowModalEdit(true);
                setDadosAgendamentoEdit(x);
                setModalTitle(y);
              }}
              data={responseAgendamentos}
              dataSearch={search}
            />
          </>
        )}

        {cadastrar && (
          <Cadastro
            dadosEmpresas={responseEmpresas}
            dadosUsuarios={responseUsuarios}
            dadosRondas={responseRondas}
          />
        )}

        {empresaPrincipal.length > 0 && dadosAgendamentoEdit && (
          <ModalEditScheduling
            isOpen={showModalEdit}
            agenda={dadosAgendamentoEdit}
            dadosSemana={modalTitle}
            dadosRondas={responseRondas}
            onRequestClose={() => setShowModalEdit(false)}
          />
        )}

        {loading && <Loading />}
      </Container>
    </>
  );
};
