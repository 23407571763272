import React from 'react';
import { Aba, ContainerAba } from './styles';

interface PropsListRegistButton {
  stateCadastrar: boolean;
  setStateCadastrar: React.Dispatch<React.SetStateAction<boolean>>;
  object: { list: string; regist: string };
}

const ListRegistButton: React.FC<PropsListRegistButton> = ({
  setStateCadastrar,
  stateCadastrar,
  object,
}) => {
  return (
    <Aba>
      <ContainerAba selected={stateCadastrar}>
        <button
          type="button"
          onClick={() => {
            setStateCadastrar(false);
          }}
        >
          {object.list}
          <div className="list" />
        </button>
      </ContainerAba>
      <ContainerAba selected={stateCadastrar}>
        <button
          type="button"
          onClick={() => {
            setStateCadastrar(true);
          }}
        >
          {object.regist}
          <div className="regist" />
        </button>
      </ContainerAba>
    </Aba>
  );
};

export default ListRegistButton;
