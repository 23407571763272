import React, {
  useState,
  useCallback,
  useEffect,
  memo,
  useContext,
} from 'react';

import Swal from 'sweetalert2';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';

import Table from '../../components/Table';
import { TitlePage } from '../../components/TitlePage/styled';
import { NewSearch } from '../../components/NewSearch';
import PaginationComponent from '../../components/Pagination';
import NewImportExel from '../../components/NewImportExel';
import ModalConexao from '../../components/ModalConexao';

import { Container } from './styles';

interface UsersResponse {
  id: number;
  nome: string;
  email: string;
  password: string;
  dataNascimento: string;
  cpf: string;
  telefone: string;
  companyId: number;
  companyName: string;
  ativo: number;
  coordenador: number;
  avatar: string;
  avatar_url: string;
  tipo: string;
  createdAt: string;
  updatedAt: string;
  supervisor: boolean;
  motoboy: string;
}

interface EmpresasResponse {
  Id_Empresas: number;
  ID: number;
  NOME: string;
  ATIVO: boolean;
  CNPJ: string;
  DATA_CRIACAO: string;
  DATA_INATIVACAO: string;
  ENDERECO: string;
  NUMERO: string;
}

const headerTableUsers = [
  { name: 'Empresa', field: 'companyName', sortable: true },
  { name: 'Nome', field: 'nome', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'E-mail', field: 'email', sortable: true },
  { name: 'Status', field: 'ativo', sortable: true },
  { name: 'Remover', field: 'deleteOption', sortable: true },
];

const selectFilterUsers = [
  { name: 'Empresa', field: 'companyName', sortable: true },
  { name: 'Nome', field: 'nome', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: true },
  { name: 'E-mail', field: 'email', sortable: true },
];

const ListagemUsuarios: React.FC = () => {
  const [users, setUsers] = useState<UsersResponse[]>([]);
  const [usersFiltered, setUsersFiltered] = useState<UsersResponse[]>([]);
  // const [empresas, setEmpresas] = useState([]);
  const { empresaPrincipal } = useAuth();
  const { colors } = useContext(ThemeContext);
  const { go } = useHistory();

  const [indexInitPg, setIndexInitPg] = useState(0);
  const [indexEndPg, setIndexEndPg] = useState(0);

  const getItens = useCallback(async () => {
    const responseUser = await api.get(
      `/allUsers/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
      }`,
    );
    const responseCompany = await api.get(
      `empresas/${
        empresaPrincipal.length > 0 ? empresaPrincipal[0].Id_Empresas : ''
      }`,
    );

    responseUser.data.forEach((userElement: UsersResponse, index: number) => {
      responseCompany.data.forEach((empresa: EmpresasResponse) => {
        if (empresa.ID === userElement.companyId) {
          responseUser.data[index].companyName = empresa.NOME;
        }
      });
    });

    setUsers(responseUser.data);
    setUsersFiltered(responseUser.data);
    // setEmpresas(responseCompany.data);
  }, [empresaPrincipal]);

  useEffect(() => {
    getItens();
  }, [getItens]);

  const handleConfirmDelete = useCallback(
    (idUser: number): void => {
      Swal.fire({
        title: 'Tem certeza que deseja deletar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: colors.success,
        cancelButtonColor: colors.error,
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/users/${idUser}`)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Usuário deletedo com sucesso!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
                showConfirmButton: false,
              });
              setTimeout(() => {
                // setCadastrar(false);
                go(0);
              }, 1000);
            })
            .catch((err) => {
              // console.log(err.response.data);
              if (err.response.data.error) {
                Swal.fire({
                  icon: 'error',
                  title: `${err.response.data.error}`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                  showConfirmButton: false,
                });
              } else {
                // console.log('ERRR', err.message);
                Swal.fire({
                  icon: 'error',
                  title: 'Ocorreu um erro ao deletar Usuário!',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                  showConfirmButton: false,
                });
              }
            });
        }
      });
    },
    [colors.error, colors.success, go],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <TitlePage>Listagem</TitlePage>
        <NewSearch
          arraySetState={setUsersFiltered}
          arrayState={users}
          selectFilters={selectFilterUsers}
        />
        <Table
          tableId="tableListagemAmbientes"
          body={usersFiltered.filter(
            (e, i) => i >= indexInitPg && i <= indexEndPg,
          )}
          header={headerTableUsers}
          setRemoveId={(id) => handleConfirmDelete(id)}
        />
        <PaginationComponent
          arrayStateFiltered={usersFiltered}
          setIndexPagination={(indexInit, indexEnd) => {
            setIndexInitPg(indexInit);
            setIndexEndPg(indexEnd);
          }}
        />
        <div className="widthTotal">
          <NewImportExel
            fileName="ListaUsuários"
            tableId="tableListagemAmbientes"
          />
        </div>
      </Container>
    </>
  );
};

export default memo(ListagemUsuarios);
