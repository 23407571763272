import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  height: 50px;
  margin-bottom: 41px;
  background-color: ${(props) => props.theme.colors.white};

  font-family: 'Montserrat', 'sans-serif';
  font-size: 16px;

  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding-left: 16px;
`;
