import { formatedTimebySeconds } from './formatedTimeByMinuts';

function getTimeInSecondsByHHmmss(timeHHmmss: string): number {
  const [hour, minute, seconds] = timeHHmmss.split(':');

  const minutesAll = Number(minute) + Number(hour) * 60;
  return minutesAll * 60 + Number(seconds);
}

interface Props {
  timeHHmmss1: string;
  timeHHmmss2: string;
}

export function getSumTime({ timeHHmmss1, timeHHmmss2 }: Props): string {
  const secondsAll1 = getTimeInSecondsByHHmmss(timeHHmmss1);
  const secondsAll2 = getTimeInSecondsByHHmmss(timeHHmmss2);

  return formatedTimebySeconds({ seconds: secondsAll1 + secondsAll2 });
}
