export interface GetDirectionsGoogleMaps {
  geocoded_waypoints: {
    geocoder_status: string;
    place_id: string;
    types: string[];
  }[];
  routes: {
    bounds: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
    copyrights: string;
    legs: {
      distance: {
        text: string;
        value: number;
      };
      duration: {
        text: string;
        value: number;
      };
      end_address: string;
      end_location: {
        lat: number;
        lng: number;
      };
      start_address: string;
      start_location: {
        lat: number;
        lng: number;
      };
      steps: {
        distance: {
          text: string;
          value: number;
        };
        duration: {
          text: string;
          value: number;
        };
        end_location: {
          lat: number;
          lng: number;
        };
        html_instructions: string;
        polyline: {
          points: string;
        };
        start_location: {
          lat: number;
          lng: number;
        };
        travel_mode: string;
      }[];
      traffic_speed_entry: string[];
      via_waypoint: string[];
    }[];
    overview_polyline: {
      points: string;
    };
    summary: string;
    warning: string[];
    waypoint_order: number[];
  }[];
}

export const tokenGoogleApi = 'AIzaSyBpz6iT8O8sKaRl7H293RXn5r8Uoz4ZBTw';

export const tokenMapBox =
  'pk.eyJ1Ijoid2ViYXBwY29sYWJvcmFkb3IiLCJhIjoiY2tpeXdqb2VnMWcyZTJ4cWp0azhvN2k5aSJ9.qCFISTHhke0RBsJn3GRd3Q';
