import React, { useState, useEffect } from 'react';
import { Container, ContainerSelectPaginations, UlPagination } from './styles';

interface PropsPaginate {
  arrayStateFiltered: any[];
  setIndexPagination(indexInit: number, indexEnd: number): void;
}

const PaginationComponent: React.FC<PropsPaginate> = ({
  arrayStateFiltered,
  setIndexPagination,
}) => {
  const [limit, setLimit] = useState(12);
  const [total, setTotal] = useState(arrayStateFiltered.length);
  const [offset, setOffset] = useState(0);

  const [maxIntens, setMaxIntens] = useState(9);
  const [maxLeft, setMaxLeft] = useState((maxIntens - 1) / 2);
  const [currentPage, setCurrentPage] = useState(
    offset ? offset / limit + 1 : 1,
  );
  const [totalPages, setTotalPages] = useState(Math.ceil(total / limit));

  const [firstPage, setFirstPage] = useState(
    Math.max(currentPage - maxLeft, 1),
  );

  useEffect(() => {
    setIndexPagination(offset, offset + limit - 1);
    setTotal(arrayStateFiltered.length);
    setTotalPages(Math.ceil(arrayStateFiltered.length / limit));
    setFirstPage(Math.max(currentPage - maxLeft, 1));
  }, [
    currentPage,
    arrayStateFiltered,
    limit,
    setIndexPagination,
    offset,
    maxLeft,
  ]);
  return (
    <Container>
      <ContainerSelectPaginations>
        <select
          className="strong textEvidence"
          onChange={(e) => {
            setLimit(Number(e.target.value));
          }}
        >
          <option value="12">12 registros</option>
          <option value="24">24 registros</option>
          <option value={total}>Todos registros</option>
        </select>

        <h4>
          Mostrando de {offset + 1} a {offset + limit} de{' '}
          {arrayStateFiltered.length}
        </h4>
      </ContainerSelectPaginations>
      <UlPagination>
        {Array.from({ length: Math.min(maxIntens, totalPages) })
          .map((item, index) => index + firstPage)
          .map((page) => {
            if (page <= totalPages) {
              return (
                <li key={page}>
                  <button
                    type="button"
                    className={page === currentPage ? 'activatedButton' : ''}
                    onClick={() => {
                      setOffset((page - 1) * limit);
                      setCurrentPage(((page - 1) * limit) / limit + 1);
                    }}
                  >
                    {page}
                  </button>
                </li>
              );
            }
            return (
              <li key={page} style={{ display: 'none' }}>
                <button
                  type="button"
                  onClick={() => {
                    setOffset((page - 1) * limit);
                    setCurrentPage(((page - 1) * limit) / limit + 1);
                  }}
                >
                  {page}
                </button>
              </li>
            );
          })}
      </UlPagination>
    </Container>
  );
};

export default PaginationComponent;
