import React, { useState } from 'react'; // useMemo, // useEffect, // useState, // useRef, // useCallback,
import { ModalBody } from 'react-bootstrap';
// import { FiUser, FiType, FiEdit2, FiTrash, FiFile } from 'react-icons/fi';
// import { BiDuplicate } from 'react-icons/bi';
// import { FormHandles } from '@unform/core';
// import { Form } from '@unform/web';
// import * as Yup from 'yup';
// import Swal from 'sweetalert2';
// import { useHistory } from 'react-router-dom';
// // @ts-ignore
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
// import moment from 'moment';
// import { useAuth } from '../../hooks/auth';
// import { Loading } from '../../components/Loading';
// import api from '../../services/api';
// import getValidationErrors from '../../utils/getValidationErrors';
import ModalConexao from '../../components/ModalConexao';
import NewModal from '../../components/NewModal';
// import Input from '../../components/Input';
// import DropDownPagination from '../../components/DropDownPagination';
// import Checkbox from '../../components/CheckBox';
// import HeaderTable from '../../components/HeaderTable';
// import Search from '../../components/Search';
// import ModalQrCode from '../../components/ModalQrCode';
// import PaginationComponent from '../../components/Pagination';
// import DrawerApp from '../../components/Drawer';

import AlertIcon from '../../assets/alerta.png';
import CloseIcon from '../../assets/close.png';

import { Container } from './styles';

const CadastroEmpresa: React.FC = () => {
  const [visible, setVisible] = useState('block');
  return (
    <>
      <ModalConexao />
      <Container style={{ display: visible }}>
        <NewModal isVisible>
          <div className="containerExit">
            <a
              href="/"
              onClick={() => {
                setVisible('none');
              }}
            >
              <img src={CloseIcon} alt="exit" id="exit" />
            </a>
          </div>
          <div className="bodyModal">
            <img src={AlertIcon} alt="Alert" />
            <h1 className="bodyModal">Permissão negada!</h1>
            <h2 className="bodyModal">
              Para cadastrar uma nova EMPRESA MATRIZ entre em contato com o
              suporte do sistema.
            </h2>
          </div>
        </NewModal>
      </Container>
    </>
  );
};

export default CadastroEmpresa;

// {
// 	"access_token": "IGQVJYWHhKQ281MzhjbTNwUW9yd2RpUHlkcnhDc1EtRmE1TWZAnOVlQN2hiQWJMeXpqaFNqUks4NVRlTS04ckJkVGk2dVNzSFJzZADBGbWlFWHlydjRrZAk9YSFZAxbHFOcHV0TTNQUGNta0hjVzRmaDRaUFNwMUVJNUNBcE9n",
// 	"user_id": 17841401775947972
// }

// {
// 	"id": "5276110699071611",
// 	"username": "gruposervnac"
// }

// {
// 	"access_token": "IGQVJWMEV1OVdtbG5fdnpwZA1hlRXNid0ZA2RTJQeW5vODRHYk10NTlzQkF3ME5sbDFwVURpOHdpbk5mUVBwLUtQLTZA3WlFKcFBoRE45aFQwOFZAjNllsRkxXZAXpVT211bk1DR2k1dHF3",
// 	"token_type": "bearer",
// 	"expires_in": 5173044
// }
