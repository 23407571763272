import styled from 'styled-components';

interface PropsSlider {
  active: boolean;
}
export const CircleButtonSlider = styled.input<PropsSlider>`
  height: 95%;
  width: 40%;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.secondary};
  margin-left: ${(props) => (props.active === true ? '0%' : '60%')};

  transition: margin-left 0.5s;
`;

export const Slider = styled.div<PropsSlider>`
  height: 20px;
  width: 50px;
  background-color: ${(props) =>
    props.active === true
      ? props.theme.colors.success
      : props.theme.colors.error};
  border-radius: 20px;

  transition: background-color 0.5s;
`;
