import React, {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import IconExcluir from '../../assets/excluir.png';
import {
  InputForm,
  LabelInputForm,
} from '../../pages/CadastroCliente copy/styles';
import {
  ButtomRemoveDate,
  Container,
  ContainerInputDate,
  DivInput,
} from './styled';

interface Props {
  periodicidade: string[];
  setPeriodicidade: Dispatch<SetStateAction<string[]>>;
  type?: string;
}

const InputAddDate: React.FC<Props> = ({
  setPeriodicidade,
  periodicidade,
  type,
}) => {
  // const [datePeriodicidade, setDatePeriodicidade] = useState<string[]>(data);
  const [testee, setTestee] = useState('');

  const handleAddDateState: React.FocusEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const valueInput = event.target.value;

    if (type === 'anu' && valueInput.length === 5) {
      if (periodicidade.indexOf(valueInput) === -1 && valueInput !== '') {
        // setDatePeriodicidade([...datePeriodicidade, valueInput]);
        setPeriodicidade([...periodicidade, valueInput]);
      }
    }

    if (type === 'men' && valueInput.length > 1) {
      if (periodicidade.indexOf(valueInput) === -1 && valueInput !== '') {
        setPeriodicidade([...periodicidade, valueInput]);
      }
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };
  const handleRemoveDateState: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ): void => {
    const buttonId = parseInt(event.currentTarget.id, 10);
    const currentPeriodicidade = periodicidade.filter(
      (_, index) => index !== buttonId,
    );

    setPeriodicidade(currentPeriodicidade);
  };

  const teste: ChangeEventHandler<HTMLInputElement> = (event): void => {
    const valor = event.currentTarget.value;

    if (type === 'men' && valor.length <= 2 && parseInt(valor, 10) < 31) {
      setTestee(valor);
    } else {
      setTestee('');
    }

    if (type === 'anu' && valor.length < 5) {
      const dig1 = valor.split('')[0] || '0';
      const dig2 = valor.split('')[1] || '0';
      const dig3 = valor.split('')[2] || '0';
      const dig4 = valor.split('')[3] || '0';

      if (parseInt(`${dig1}${dig2}`, 10) <= 31) {
        if (parseInt(`${dig3}${dig4}`, 10) <= 12) {
          setTestee(
            valor.replace(/\D/g, '').replace(/^(\d{2})(\d{2})+?$/, '$1/$2'),
          );
        } else {
          setTestee('');
        }
      } else {
        setTestee('');
      }
    }
  };

  const selectPlaceHolder = (): string => {
    if (type === 'men') {
      return 'Dia do mês (Ex: 12)';
    }
    if (type === 'anu') {
      return 'Dia e mês (Ex: 10/12)';
    }
    return '';
  };

  return (
    <Container>
      <LabelInputForm style={{ color: '#2f80ed' }}>
        Adicionar data:
      </LabelInputForm>
      <InputForm
        onBlur={handleAddDateState}
        value={testee}
        onChange={teste}
        placeholder={selectPlaceHolder()}
      />
      <ContainerInputDate>
        {periodicidade.sort().map((element, index) => {
          if (element !== '') {
            return (
              <DivInput>
                <InputForm
                  value={element}
                  disabled
                  style={{ width: '160px', padding: '0', border: 'none' }}
                />

                <ButtomRemoveDate
                  id={index.toString()}
                  onClick={handleRemoveDateState}
                >
                  <img src={IconExcluir} alt="Icon excluir" />
                </ButtomRemoveDate>
              </DivInput>
            );
          }
          return null;
        })}
      </ContainerInputDate>
    </Container>
  );
};

export default InputAddDate;
