import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ModalConexao from '../../components/ModalConexao';
import AlertEmail from '../../components/AlertEmail';
import CreditsSpan from '../../components/Credits';
import {
  ContainerBoxGrey,
  ContainerBoxWhite,
  Container,
  LogoApp,
  LogoAppSeg,
  ContainerLogoApp,
  LinkForgotPass,
} from '../SignIn/styles';

import { Content, AnimationContainer, HeaderInput } from './styles';

interface SignUpFormData {
  nome: string;
  email: string;
  emailConfirmation: string;
  cpf: string;
  telefone: string;
  password: string;
  dataNascimento: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (
  //     navigator.userAgent.match(/Android/i) ||
  //     navigator.userAgent.match(/webOS/i) ||
  //     navigator.userAgent.match(/iPhone/i) ||
  //     navigator.userAgent.match(/iPad/i) ||
  //     navigator.userAgent.match(/iPod/i) ||
  //     navigator.userAgent.match(/BlackBerry/i) ||
  //     navigator.userAgent.match(/Windows Phone/i)
  //   ) {
  //     history.push('/acesso-bloqueado');
  //   }
  // }, [history]);

  function formatarCPF(str: string): string {
    const CPF: string = str.replace('.', '').replace('.', '').replace('-', '');
    return CPF;
  }

  function formatarTelefone(str: string): string {
    const telefone: string = str
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')
      .replace(' ', '');
    return telefone;
  }

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório !'),
          email: Yup.string()
            .required('E-mail obrigatório !')
            .email('Digite um email válido !'),
          emailConfirmation: Yup.string().oneOf(
            [Yup.ref('email'), null],
            'E-mails precisam ser iguais!',
          ),
          cpf: Yup.string().required('CPF obrigatório !'),
          dataNascimento: Yup.string().required(
            'Data de Nascimento obrigatória !',
          ),
          telefone: Yup.string().required('Telefone obrigatório !'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos !'),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'Senhas precisam ser iguais!',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { nome, emailConfirmation, dataNascimento, password } = data;
        const cpf = formatarCPF(data.cpf);
        const telefone = formatarTelefone(data.telefone);

        await api
          .post('/users', {
            nome,
            email,
            emailConfirmation,
            password,
            dataNascimento,
            cpf,
            telefone,
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Cadastro realizado!',
              description: 'Você já pode fazer seu login.',
            });
            history.push('/');
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro ao cadastrar!',
              description: 'Verifique os dados!',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            'Ocorreu um erro ao fazer o Cadastro, Por favor verifique os campos.',
        });
      }
    },
    [addToast, email, history],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <ContainerBoxGrey>
          <ContainerLogoApp>
            <LogoApp>Gestão de</LogoApp>
            <LogoAppSeg>Segurança</LogoAppSeg>
          </ContainerLogoApp>
        </ContainerBoxGrey>
        <ContainerBoxWhite>
          <Content>
            <AnimationContainer>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    marginBottom: '10px',
                  }}
                >
                  Cadastro
                </h1>
                <HeaderInput>Nome Completo</HeaderInput>
                <Input name="nome" placeholder="João Oliveira" />
                <HeaderInput>E-mail</HeaderInput>
                <Input
                  name="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <AlertEmail email={email} />
                <HeaderInput>Confirmação de e-mail</HeaderInput>
                <Input
                  name="emailConfirmation"
                  placeholder="joaooliveira@gmail.com"
                />
                <HeaderInput>CPF</HeaderInput>
                <Input name="cpf" placeholder="000.000.000-00" />
                <HeaderInput>Data de nascimento</HeaderInput>
                <Input
                  name="dataNascimento"
                  placeholder="DD/MM/AAAA"
                  mask="dateOfBirth"
                />
                <HeaderInput>Telefone</HeaderInput>
                <Input
                  name="telefone"
                  placeholder="(00) 0000-0000"
                  mask="phone"
                />
                <HeaderInput>Senha</HeaderInput>
                <Input name="password" placeholder="******" />
                <HeaderInput>Confirmação de senha</HeaderInput>
                <Input name="confirmPassword" placeholder="******" />
                <Button type="submit">Cadastrar</Button>
              </Form>
              <Link to="/" style={{ marginTop: '10px' }}>
                <LinkForgotPass>Voltar ao início</LinkForgotPass>
              </Link>
            </AnimationContainer>
            <CreditsSpan />
          </Content>
        </ContainerBoxWhite>

        {loading && <Loading />}
      </Container>
    </>
  );
};

export default SignUp;
