import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { v4 as uuidv4 } from 'uuid';
import { updateFilterMap } from '../../store/modules/clientRegister/actions';

import { Input, Container } from './styles';

interface Props {
  adreesValue?: any;
  setAdreesValue?: any;
}

const MapGoogleMaps: React.FC<Props> = ({ adreesValue, setAdreesValue }) => {
  const [address, setAddress] = useState('');
  const dispatch = useDispatch();

  const handleSelect = async (value: string): Promise<void> => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    let latFormat: string | undefined = latLng.lat.toString();

    if (latLng.lat.toString().split('.').pop() !== undefined) {
      latFormat = latLng.lat.toString().split('.').pop();
      latFormat = latFormat?.substr(0, 7);
      latFormat = `${latLng.lat.toString().split('.')[0]}.${latFormat}`;
    }

    setAddress(value);
    let cepAddressState = '';
    let countyAddressState = '';
    let districtAddressState = '';
    let numberAddressState = '';
    let stateAddressState = '';
    let streetAddressState = '';

    results[0].address_components.forEach((element) => {
      switch (element.types[0]) {
        case 'street_number':
          numberAddressState = element.long_name;
          break;
        case 'route':
          streetAddressState = element.long_name;
          break;
        case 'political':
          districtAddressState = element.long_name;
          break;
        case 'administrative_area_level_2':
          countyAddressState = element.long_name;
          break;
        case 'administrative_area_level_1':
          stateAddressState = element.long_name;
          break;
        case 'postal_code':
          cepAddressState = element.long_name;
          break;
        default:
      }
    });

    setAdreesValue({
      endereco: value,
      cepAddress: cepAddressState || 'NE',
      countyAddress: countyAddressState || 'NE',
      districtAddress: districtAddressState || 'NE',
      latitudeAddress: latFormat,
      longitudeAddress: latLng.lng.toString(),
      numberAddress: numberAddressState || 'NE',
      stateAddress: stateAddressState || 'NE',
      streetAddress: streetAddressState || 'NE',
    });
    dispatch(
      updateFilterMap({
        cepAddress: cepAddressState || 'NE',
        countyAddress: countyAddressState || 'NE',
        districtAddress: districtAddressState || 'NE',
        latitudeAddress: latFormat,
        longitudeAddress: latLng.lng.toString(),
        numberAddress: numberAddressState || 'NE',
        stateAddress: stateAddressState || 'NE',
        streetAddress: streetAddressState || 'NE',
      }),
    );
  };

  const getItems = useCallback(async (): Promise<void> => {
    setAddress(adreesValue ? adreesValue.endereco : '');
  }, [adreesValue]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{ marginBottom: '41px' }}>
            <Input>
              <input
                {...getInputProps({ placeholder: 'Digite o endereço...' })}
                defaultValue={address}
                // onChange={({ currentTarget }) => {
                //   setAddress(currentTarget.value);
                // }}
              />
            </Input>

            <Container>
              {loading ? <div>...carregando</div> : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#41b6e6' : '#312e38',
                };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { style })}
                    key={uuidv4()}
                    className="suggestionDiv"
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </Container>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default MapGoogleMaps;
