import styled from 'styled-components';

export const SpanError = styled.div`
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  background-color: ${(props) => props.theme.colors.error};
  border-radius: 12px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -8px;
  margin-bottom: 8px;

  span {
    color: ${(props) => props.theme.colors.white};
    font-size: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
  }
`;
