import React, { useEffect, useCallback, useState } from 'react';
import { FiX } from 'react-icons/fi';
import Swal from 'sweetalert2';
import NewModal from '../NewModal';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { UsuarioBD } from '../../models/User';
import { ButtonClose, ContainerBody } from './styles';
import {
  DivLineBottom,
  HeaderForm,
  LabelInputForm,
} from '../../pages/DashboardOcorrencias/styles';
import {
  ButtonForm,
  ContainerButtonForm,
} from '../../pages/CadastroCliente copy/styles';

interface Props {
  // displayCardHide(): void;
  isOpen: boolean;
  onClose(): void;
  // returnId(id: number): void;
  item: any;
}

export const ModalEditRotaComplete: React.FC<Props> = ({
  item,
  isOpen,
  onClose,
}) => {
  const { empresaPrincipal } = useAuth();
  const [supervisores, setSupervisores] = useState<UsuarioBD[]>([]);
  const [currentSupervisor, setCurrentSupervisor] = useState(0);

  const getItens = useCallback(async () => {
    const responseFuncionarios = await api.get(
      `/allUsers/${empresaPrincipal[0].ID}`,
    );
    setSupervisores(
      responseFuncionarios.data.reduce((acc: any, curr: { acesso: string }) => {
        const valueAcumulate = acc;

        if (curr.acesso === '2') {
          valueAcumulate.push(curr);
          return valueAcumulate;
        }
        return valueAcumulate;
      }, []),
    );
  }, [empresaPrincipal]);

  const sendRoute = useCallback(async () => {
    if (currentSupervisor !== 0) {
      const data = await item.Visita.map(
        (visita: { status: string; id: string; data: string }) => {
          if (visita.status !== 'Concluido') {
            return {
              visitaId: Number(visita.id) || 0,
              supervisorId: currentSupervisor,
              data: visita.data,
            };
          }
        },
      );

      api
        .patch('/rotas/transferir', data)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Visita transferida para outro supervisor com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            showConfirmButton: false,
          });
          onClose();
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title:
              'Não foi possível finalizar a transferência, tente mais tarde!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Informe o supervisor! ',
      });
    }
  }, [currentSupervisor, item.Visita, onClose]);

  useEffect(() => {
    getItens();
  }, [getItens]);

  return (
    <NewModal isVisible={isOpen}>
      <ButtonClose>
        <button
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          <FiX size={22} />
        </button>
      </ButtonClose>
      <ContainerBody>
        <HeaderForm>Tranferência da visita</HeaderForm>
        <DivLineBottom />

        <LabelInputForm>Supervisor:</LabelInputForm>
        <select
          name="supervisores"
          id="supervisores"
          onChange={(e) => {
            setCurrentSupervisor(parseInt(e.target.value, 10));
          }}
        >
          <option value="0">Selecionar supervisor:</option>
          {supervisores &&
            supervisores.map((eSup) => {
              return (
                <option key={eSup.id} value={eSup.id}>
                  {eSup.nome}
                </option>
              );
            })}
        </select>
        <ContainerButtonForm>
          <ButtonForm
            type="button"
            background="#FFFFFF"
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </ButtonForm>
          <ButtonForm
            type="button"
            background="#393B41"
            onClick={() => {
              sendRoute();
            }}
          >
            Salvar
          </ButtonForm>
        </ContainerButtonForm>
      </ContainerBody>
    </NewModal>
  );
};
