import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { Container } from './styles';
import { ReactComponent as SearchIcon } from '../../assets/excel 1.svg';

interface PopsImportExel {
  tableId: string;
  fileName: string;
}

const NewImportExel: React.FC<PopsImportExel> = ({ fileName, tableId }) => {
  return (
    <Container>
      <ReactHTMLTableToExcel
        id="export-excel"
        className="btn"
        table={tableId}
        filename={`${fileName}-${moment().format('DD-MM-YYYY')}`}
        sheet="tablexls"
        buttonText="Exportar Excel"
      >
        <SearchIcon />
        Exportar Exel
      </ReactHTMLTableToExcel>
    </Container>
  );
};

export default NewImportExel;
