import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 58px 72px 48px 49px;
  width: 100%;
  max-height: calc(100vh - 80px);
  overflow: auto;

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #cancel {
      background-color: transparent;
      border: 1px solid ${(props) => props.theme.colors.gray12};
      color: ${(props) => props.theme.colors.gray12};
    }
    button {
      width: 45%;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 22px;
  }
`;

export const ButtonVizualizarPDF = styled.button`
  width: 244px;
  height: 40px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  border-radius: 4px;
  transition: 0.2s;

  &:hover {
    background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
  }
`;

export const DivButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 550px) {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;
  }
`;

export const DivFilter = styled.div`
  width: 100%;
  display: flex;
  column-gap: 1rem;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

export const ContainerInputDate = styled.div`
  flex: 1;
  column-gap: 1rem;
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 25px;

  @media screen and (max-width: 700px) {
    margin-bottom: -9px;
  }
`;

export const InputTextFilter = styled.div`
  height: 48px;
  width: 100%;

  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.white4};

  display: flex;
  svg {
    margin: auto 0;
  }

  input {
    width: 100%;
    /* margin-left: 10px; */
    border: none;
    ::placeholder {
      font-size: 16px;
      color: ${(props) => props.theme.colors.gray4};
    }
  }
`;
