import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromFront = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.7);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Containt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  height: 420px;
  border-radius: 7px;
  background: #fff;
  animation: ${appearFromFront} 0.4s;

  svg {
    margin-top: 25px;
    color: #ff8533;
    animation: glowing 2000ms infinite;
  }

  @keyframes glowing {
    0% {
      color: #ff0000;
      transform: scale(1);
    }
    25% {
      color: #ff8533;
      transform: scale(1.1);
    }
    50% {
      color: #ff0000;
      transform: scale(1);
    }
    75% {
      color: #ff8533;
      transform: scale(1.1);
    }
    100% {
      color: #ff0000;
      transform: scale(1);
    }
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  p {
    font-size: 16px;
    color: #666666;

    strong {
      font-weight: bold;
      color: #666666;
    }
    & + p {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0 0 7px 7px;

  button {
    width: 100%;
    height: 70px;
    background: #009900;
    border: none;
    border-radius: 0 0 0 7px;
    font-size: 18px;
    transition: 0.2s;
    &:hover {
      background: ${shade(0.1, '#009900')};
    }

    & + button {
      background: #cc0000;
      border-radius: 0 0 7px 0;
      transition: 0.2s;
      &:hover {
        background: ${shade(0.1, '#cc0000')};
      }
    }
  }
`;
