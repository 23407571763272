import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import ModalConexao from '../../components/ModalConexao';
import Input from '../../components/Input';
import Button from '../../components/Button';
import AlertEmail from '../../components/AlertEmail';
import CreditsSpan from '../../components/Credits';
import {
  Container,
  Content,
  AnimationContainer,
  ContainerBoxGrey,
  ContainerBoxWhite,
  ContainerLogoApp,
  LogoApp,
  LogoAppSeg,
  HeaderInput,
  LinkForgotPass,
  TextButton,
  Line,
} from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const [email, setEmail] = useState('');

  // useEffect(() => {
  //   if (
  //     navigator.userAgent.match(/Android/i) ||
  //     navigator.userAgent.match(/webOS/i) ||
  //     navigator.userAgent.match(/iPhone/i) ||
  //     navigator.userAgent.match(/iPad/i) ||
  //     navigator.userAgent.match(/iPod/i) ||
  //     navigator.userAgent.match(/BlackBerry/i) ||
  //     navigator.userAgent.match(/Windows Phone/i)
  //   ) {
  //     history.push('/acesso-bloqueado');
  //   }
  // }, [history]);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um email válido!'),
          password: Yup.string().required('Senha Obrigatória!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        await signIn({
          email: data.email,
          password: data.password,
        })
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na autenticação',
              description:
                'Ocorreu um erro ao fazer login, cheque as credenciais.',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          setLoading(false);
        }
        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login, cheque as credenciais.',
        });
      }
    },
    [signIn, addToast],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <ContainerBoxGrey>
          <ContainerLogoApp>
            <LogoApp>Gestão de</LogoApp>
            <LogoAppSeg>Segurança</LogoAppSeg>
          </ContainerLogoApp>
        </ContainerBoxGrey>
        <ContainerBoxWhite>
          <Content>
            <AnimationContainer>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Bem vindo!</h1>
                <HeaderInput>E-mail</HeaderInput>
                <Input
                  name="email"
                  placeholder="Insira seu e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <AlertEmail email={email} />
                <HeaderInput>Senha</HeaderInput>
                <Input name="password" placeholder="Insira sua senha" />

                <Button type="submit">
                  <TextButton>Entrar</TextButton>
                </Button>

                <Link to="forgot-password">
                  <LinkForgotPass>Esqueci minha senha</LinkForgotPass>
                </Link>
                <Line />
                <Button
                  type="button"
                  onClick={() => {
                    window.location.href = '/signup';
                  }}
                  style={{
                    backgroundColor: '#FFFF',
                    border: '2px solid #393B41',
                  }}
                >
                  <TextButton style={{ color: '#393B41' }}>
                    Cadastrar
                  </TextButton>
                </Button>
              </Form>
            </AnimationContainer>
            <CreditsSpan />
          </Content>
          {loading && <Loading />}
        </ContainerBoxWhite>
      </Container>
    </>
  );
};

export default SignIn;
