import { UseQueryResult, useQuery } from 'react-query';
import { getVehicleReport } from './getVehicleReportData';
import {
  UseGetVehicleReportProps,
  GetVehicleReportDataResponse,
} from './types';

export const useGetVehicleReportData = (
  props: UseGetVehicleReportProps,
): UseQueryResult<GetVehicleReportDataResponse[]> => {
  const { enabled, cacheTime, staleTime, params } = props;

  return useQuery(
    ['getUserData', params],
    async () => getVehicleReport({ params }),
    {
      ...(cacheTime !== undefined && { cacheTime }),
      ...(staleTime !== undefined && { staleTime }),
      ...(enabled !== undefined && { enabled }),
      refetchOnWindowFocus: false,
    },
  );
};
